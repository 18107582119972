import React from 'react';
import { dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
    addMonths
    , startOfMonth
    , startOfDay
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    , Chip
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import Emun from 'enum/Enum.js';
import renderCellExpand from './RenderCellExpand'
import RenderToolBar from '../Calendar/RenderToolBar'

const { Provider, Consumer } = React.createContext();
export const useStyles = makeStyles(theme => ({
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
    statusButton: {
        borderRadius: '5px',
        color: '#fff',
        width: '75px',
        '& .MuiChip-label': {
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }

}));

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const getCancelledColor = (params) => {
    switch (params) {
        case "APPROVED": return "#67c451";
        case "SUBMITTED": return "#67c451";
        case "REJECTED": return "#e14631";
        case "CANCELLED": return "#646464";
        default: return "#646464";
    }
}

const getStatusNo = (params) => {
    switch (params) {
        case "APPROVED": return 0;
        case "SUBMITTED": return 1;
        case "REJECTED": return 2;
        case "CANCELLED": return 3;
        default: return 999;
    }
}

const BookingHistoryComponent = props => {
    const classes = useStyles();
    const { bookingHistorys = [], project = [], selfService = [], selectionChecked } = props;
    const [gridData, setGridData] = React.useState([]);
    const ref = React.useRef()

    useEffect(() => {
        const fullBookings = []
        bookingHistorys.forEach(item => {
            if (!selectionChecked || project.includes(item.projectId) || selfService.includes(item.projectId)) {
                fullBookings.push({
                    ...item,
                    fullDate: new Date(item.bookDate),
                    statusNo: getStatusNo(item.status)
                })
            }
        })
        const refGrid = ref.current;
        refGrid && refGrid.children[1].children[1].scroll(0, 0)
        setGridData(fullBookings);
    }, [bookingHistorys, project, selfService, selectionChecked])

    const { viewDate, setViewDate } = props;


    const columns = [
        {
            field: 'fullDate',
            headerName: 'Date',
            width: 94,
            type: 'date',
            sortable: true,
            // editable: true,
            valueGetter: (params) => {
                // console.log(params)
                return format(startOfDay(new Date(params.value)), 'd MMM yyyy')
            },
            sortComparator: (v1, v2, param1, param2) => {
                return v1 - v2;
            }
        },
        {
            field: 'Day',
            headerName: 'Day',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 94,
            valueGetter: (params) => {
                return Emun.WEEKS[new Date(params.row.bookDate).getDay()]
            },
            sortComparator: (v1, v2, param1, param2) => {
                return param1.row.fullDate.getDay() - param2.row.fullDate.getDay();
            }
        },
        {
            field: 'refno',
            headerName: 'Ref no.',
            sortable: true,
            width: 90,
            // editable: true,
            renderCell: renderCellExpand
        },
        {
            field: 'capacity',
            headerName: 'No. of Researcher',
            // type: 'number',
            sortable: true,
            width: 155,
            // editable: true,
            renderCell: (params) => (
                <div style={{ width: '113px', textAlign: 'center' }}>{params.value}</div>
            ),
        },
        {
            field: 'statusNo',
            headerName: 'Status',
            sortable: true,
            width: 94,
            renderCell: (params) => (
                <Chip label={`${params.row.status.substring(0, 1)}${params.row.status.toLowerCase().substring(1, params.row.status.length)}`}
                    className={classes.statusButton}
                    style={{
                        backgroundColor: getCancelledColor(params.row.status),
                    }} />
            ),
        },
        {
            field: 'attendance',
            headerName: 'Actual Attendance',
            // type: 'number',
            sortable: true,
            width: 160,
            renderCell: (params) => (
                <div style={{ width: '113px', textAlign: 'center' }}>{params.value}</div>
            ),
        },
        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 140,
            sortable: true,
            flex: 1,
            renderCell: renderCellExpand
        },
    ];


    return (
        <Grid container flexDirection={'column'} style={{ height: '100%' }}>
            <Grid item xs={'auto'}>
                <RenderToolBar
                    date={viewDate}
                    label={format(startOfDay(viewDate), 'MMMM yyyy')}
                    setViewDate={setViewDate}
                />
            </Grid>
            <Grid item xs>
                <DataGrid
                    ref={ref}
                    rows={gridData}
                    columns={columns}
                    className={classes.dataGridRoot}
                    disableColumnMenu
                    disableSelectionOnClick
                    headerHeight={40}
                    rowHeight={40}
                    hideFooter={gridData.length < 100}
                />
            </Grid>
        </Grid>
    );
}

export default BookingHistoryComponent;