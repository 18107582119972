import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';

import SelectComponent from 'components/common/CommonSelect';
const AttendanceSelectCom = forwardRef(({value, onChange, options, ...res}, ref) => {

    const [newValue, setNewValue] = React.useState(value);
    const [newOptions, setNewOptions] = React.useState(options);
    const [isReload, setIsReload] = React.useState(false);

    
    useImperativeHandle(ref, () => ({

        refresh() {
            setIsReload(!isReload)
        },
        updateOptions(data) {
            setNewOptions(data)
        }
        
    }));

    const handleChange = (v, obj) => {
        setNewValue(v)
        onChange(v, obj)
    }
    return <SelectComponent
        displayEmpty={false} value={newValue}
        onChange={handleChange}
        style={{
            textTransform: 'none',
            fontSize: '13px',
            width: '100%',
        
        }}
        options={newOptions}
        {...res}
    />
})

export default AttendanceSelectCom;