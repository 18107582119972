import { useState, useEffect } from 'react';

function useAutoHozScroll(domRef, defaultAutoScroll, autoScrollRate = 50) {
    const [autoScroll, setAutoScroll] = useState(defaultAutoScroll || false);

    useEffect(() => {
        let scrollInterval = null
        let count = 0, flag = false
        if (autoScroll) {
            scrollInterval = setInterval(() => {
                let scrollBox = domRef.current;
                if(!scrollBox) return
                let scrollBoxOffsetWidth = scrollBox.offsetWidth,
                    scrollBoxScrollLeftWidth = scrollBox.scrollWidth,
                    scrollDistance = 1
                if (scrollBox.scrollLeft < (scrollBoxScrollLeftWidth - scrollBoxOffsetWidth)) {
                    scrollBox.scrollLeft = scrollBox.scrollLeft + scrollDistance
                } else {
                    flag = true
                    if (flag && count !== 40) {
                        count++
                    } else {
                        scrollBox.scrollLeft = 0
                        count = 0
                        flag = false
                    }
                }
            }, autoScrollRate)
        } else {
            clearInterval(scrollInterval)
        }
        return () => {
            clearInterval(scrollInterval)
        }
    }, [autoScroll, autoScrollRate])
    return {autoScroll, setAutoScroll};
}

export default useAutoHozScroll;