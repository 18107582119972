import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import Link from '@material-ui/core/Link';

export default function HadclGrid(props) {

    const { isClearFilter = true } = props;

    const gridOptions = {
        columnDefs: props.columnDefs,
        pagination: true,
        paginationPageSize: 5,
        paginationAutoPageSize: true,
        immutableData: true,
        getRowNodeId: function (data) {
            return data.id;
        },
        suppressRowClickSelection: true,
        rowSelection: "multiple",
        ...props
    }
    // console.log(gridOptions)
    return (
        <>

            {isClearFilter && <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Link href="#" onClick={props.clearFilter}>
                    Reset filter
                </Link>
            </div>}

            <AgGridReact
                rowData={props.rowData}
                gridOptions={gridOptions}
                onGridReady={props.onGridReady}
                onSelectionChanged={props.onSelectionChanged}
            />

        </>
    )
}