import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from 'images/cross_red.svg';
import SuccessIcon from 'images/circle_green.svg';
import { message } from "components/common/Constants";

function NewPWInput(props) {
    const { classes,
        control,
        atLeast8Valid,
        atLeast1Num,
        atLeast1LowAndUp,
        atLeast1Special,
        notContainEmail
    } = props;
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const WarnningMsg = (data) => {
        const styles = { marginLeft: '20px', paddingLeft: '20px', backgroundSize: '10px 10px', backgroundPositionY: '9px', backgroundRepeat: 'no-repeat' };
        if (data.isSuccess)
            return (
                <div style={{ color: 'green', backgroundImage: `url(${SuccessIcon})`, ...styles }}>
                    {data.message}
                </div>
            );
        else
            return (
                <div style={{ color: 'red', backgroundImage: `url(${ErrorIcon})`, ...styles }}>
                    {data.message}
                </div>
            );
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <FormControl fullWidth className={classes.input}>
                <Controller
                    as={TextField}
                    control={control}
                    name="newPassword"
                    required
                    label="New Password"
                    type={'text'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ 
                        maxLength: 64, 
                        style: { fontFamily: showNewPassword || 'text-security-disc' }
                    }}
                />
            </FormControl>
            <div>
                <WarnningMsg isSuccess={atLeast8Valid} message={message.AtLeast8Characters} />
                <WarnningMsg isSuccess={atLeast1Num} message={message.AtLeast1Number} />
                <WarnningMsg isSuccess={atLeast1LowAndUp} message={message.AtLeast1LowAndUp} />
                <WarnningMsg isSuccess={atLeast1Special} message={message.AtLeast1Special} />
                <WarnningMsg isSuccess={notContainEmail} message={message.NotContainEmail} />
            </div>
            <FormControl fullWidth className={classes.input}>
                <Controller
                    as={TextField}
                    control={control}
                    name="confirmPassword"
                    required
                    label="Confirm Password"
                    type={'text'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ 
                        maxLength: 64, 
                        style: { fontFamily: showConfirmPassword || 'text-security-disc' }
                    }}
                />
            </FormControl>
        </>
    );
}

export default NewPWInput;