import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { useEffect } from 'react';
import RenderToolDate from './RenderToolDate'
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt } from 'react-router-dom';
import LoadingScreen from 'components/common/LoadingScreen';
import BorderLinearProgress from './BorderLinearProgress';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { success, confirmReportSendEmail } from 'components/common/Constants';
import useModal from "hooks/useModal";
const useStyles2 = makeStyles((theme) => ({
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    button: {
        backgroundColor: '#dfdfdf ',
        color: '#000000 ',
        textTransform: 'none '
    },
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
            borderLeft: '1px solid #3d3d3d'
        },
        '& .MuiDataGrid-colCell:first-child': {
            // padding: '0 8px !important',
            borderLeft: '0'
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
}));

export const dateMonthMap = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
}
export default function ReportList(props) {
    const classes = useStyles();
    const currentClasses = useStyles2();
    const [lists, setLists] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const idRef = React.useRef(0);
    let history = useHistory();
    const showModal = useModal();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isViewDate, setIsViewDate] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState('')

    const [viewDate, setViewDate] = React.useState(startOfMonth(new Date()));

    const [userProjectList, setUserProjectList] = React.useState([]);
    const [selectedProject, setSelectedProject] = React.useState("");
    const [selectedProjectObj, setSelectedProjectObj] = React.useState({});

    const ref = React.useRef()

    const loadData = (payload) => {
        setIsLoading(true);


        axios.get('/progressReport/get-progress-reports', { params: payload }).then((res) => {
            setIsLoading(false);
            if (res.data) {
                const data = res.data.map((item) => {
                    item.id = idRef.current
                    idRef.current = idRef.current + 1
                    return item;
                })
                setLists(data);
                const refGrid = ref.current;
                refGrid && refGrid.children[1].children[1].scroll(0, 0)
            } else {
                setLists([]);
            }
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    React.useEffect(() => {
        const v1 = localStorage.getItem('viewDate')
        const v2 = localStorage.getItem('isViewDate')
        const v3 = localStorage.getItem('selectedProject')
        if (v1 || v2 || v3) {
            v1 && setViewDate(new Date(v1))
            v2 && setIsViewDate(v2 == 'true')
            if (v3) {
                const t = JSON.parse(v3)
                setSelectedProjectObj(t)
                setSelectedProject(t.projectId)
            }
            // console.log(v1, v2, v3)
            // if()
            localStorage.setItem('viewDate', '')
            localStorage.setItem('isViewDate', '')
            localStorage.setItem('selectedProject', '')
        } else {
            if (isViewDate) {
                loadData({
                    month: format(viewDate, 'yyyy-MM-dd')
                })
            } else {
                if (selectedProject) {
                    loadData({
                        projectId: selectedProject
                    })
                } else {
                    setLists([]);
                }

            }
        }

    }, [isViewDate, selectedProject, viewDate])

    React.useEffect(() => {
        getUserProject()
    }, [])

    const getUserProject = () => {
        axios.get('/progressReport/get-user-projects').then((res) => {
            if (res.data) {
                setUserProjectList(res.data)
            } else {
                setUserProjectList([])
            }

        }).catch(err => {
            console.log(err);
        })
    }

    const handleSelectProject = (item, obj) => {
        setSelectedProject(item)
        setSelectedProjectObj(obj)
    }

    useEffect(() => {
        const fullLists = []
        lists.forEach(item => {
            fullLists.push({
                ...item,
            })
        })
        setGridData(fullLists);
    }, [lists])

    const goDetail = (item) => {
        if (viewDate) {
            localStorage.setItem('viewDate', format(viewDate, 'yyyy/MM/dd'))
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        localStorage.setItem('isViewDate', isViewDate)
        localStorage.setItem('selectedProject', JSON.stringify(selectedProjectObj))
        history.push(`/progress-reports-management/form?projectId=${item.projectId}&yearMonth=${item.reportMonth}`);
    }

    const handleSendEmail = (data) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                    const requests = {
                        projectId: data.projectId,
                        yearMonth: data.reportMonth
                    }
                    setIsLoading(true);
                    axios.post('/progressReport/reminder-for-revision', requests)
                        .then(() => {
                            setIsLoading(false);
                            setErrorMsg(success.ReportSend)
                            // draftEditorLengthErrorRef.current = {}
                        }).catch(err => {
                            let errMsg = err.response?.data?.message;
                            setErrorMsg(errMsg ? errMsg : err.message)
                            setIsLoading(false);
                        });
                }}
            >
                <div style={{ padding: '0 10px' }} dangerouslySetInnerHTML={{ __html: confirmReportSendEmail(data.refNum, data.reportMonth) }}></div>

            </SystemConfirmDialog>
        ));

    }

    let dynamicsColumns = [];

    if (isViewDate) {

        dynamicsColumns = [{
            field: 'refNum',
            headerName: 'Project No.',
            flex: 1,
            sortable: true,
            renderCell: (pros) => {
                return (
                    <div>
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => goDetail(pros.row)}>{pros.value} </span>

                    </div>
                );
            },

        }]
    } else {
        dynamicsColumns = [{
            field: 'reportMonth',
            headerName: 'Month',
            // width: 94,
            type: 'date',
            flex: 1,
            // width: 150,
            sortable: true,
            // editable: true,
            renderCell: (pros) => {
                // console.log(params)
                return (
                    <div>
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => goDetail(pros.row)}>{pros.value && format(new Date(pros.value), 'MMM yyyy')} </span>

                    </div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }
        }]
    }


    const columns = [
        ...dynamicsColumns,
        {
            field: 'submissionStatus',
            headerName: 'Submission status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            // width: 170,
            renderCell: (pros) => {
                const isRed = (pros.row.statusDB !== 'SUBMITTED' && pros.row.statusDB !== 'REVIEWED' && isAfter(new Date(), new Date(pros.row.deadline))) || pros.row.statusDB == 'REVISED';
                const isSubmit = pros.row.statusDB == 'SUBMITTED' || pros.row.statusDB == 'REVIEWED'
                const style = isRed ? {
                    color: '#ff0909'
                } : {
                    color: isSubmit ? '#46ae4a' : '#000'
                }

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', ...style }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>

                    </div>

                );
            },

        },
        {
            field: 'submissionSaveDate',
            headerName: 'Submission/save date',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // flex: 1,
            width: 180,
            type: 'date',
            renderCell: (pros) => {
                let year = '', m = '', d = '';
                if (pros.value) {
                    year = format(new Date(pros.value), 'yyyy')
                    m = format(new Date(pros.value), 'MMM')
                    d = format(new Date(pros.value), 'dd')
                }
                const date = `${d} ${dateMonthMap[m] || ''} ${year}`;
                return (
                    <Grid container style={{ height: 40 }} alignContent='center'>
                        <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', height: 25, width: 150, lineHeight: '25px', padding: '0 10px', textAlign: 'center' }}>{date}</Grid>
                    </Grid>

                );
            },
            sortComparator: (v1, v2,) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },
        {
            field: 'progress',
            headerName: 'Progress',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // flex: 1,
            width: 150,
            renderCell: (pros) => {

                return (
                    <div style={{ overflow: 'hidden', lineHeight: 1.2, textOverflow: 'ellipsis', width: '100%' }}>
                        <Box alignItems="center">
                            <Box width="100%">
                                {`${Math.round(
                                    pros.value,
                                )}%`}
                            </Box>
                            <Box width="100%">
                                <BorderLinearProgress variant="determinate" expectedProgress={pros.row.expectedProgress || 0} value={pros.value} />
                            </Box>
                        </Box>
                    </div>
                );
            },

        },
        {
            field: 'projectMilestone',
            headerName: 'Project milestone',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {
                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div> </div>
                );
            },

        },
        {
            field: 'risk',
            headerName: 'Risk',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 50,
            // flex: 1,
            renderCell: (pros) => {
                const riskMap = {
                    LOW: <span style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: '#6ad157' }}></span>,
                    MEDIUM: <span style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: '#ffe04f' }}></span>,
                    HIGH: <span style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: '#ff534f' }}></span>,
                }

                return (
                    <Grid container justifyContent={'center'}>
                        {riskMap[pros.value] || ''}
                    </Grid>
                );
            },
            sortComparator: (val1, val2) => {
                const riskMap = {
                    LOW: 1,
                    MEDIUM: 2,
                    HIGH: 3,
                }
                const v1 = riskMap[val1] || 0
                const v2 = riskMap[val2] || 0
                return v1 - v2

            }
        },
        {
            field: 'reviewStatusBy',
            headerName: 'Review Status (by HADCL)',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>

                    </div>
                );
            },

        },
        {
            field: 'lastSaveSubmittedBy',
            headerName: 'Last Saved/ Submitted by',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}> <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                    </div>

                    </div>
                );
            },

        },
        {
            field: 'statusDB',
            headerName: 'Action',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 135,
            // flex: 1,
            renderCell: (pros) => {

                return (
                    <Grid container justifyContent={'center'}>
                        {pros.value == 'REVISED' && <Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            textTransform: 'none',
                            lineHeight: 1.2
                        }} className={classes.activeButton} onClick={() => { handleSendEmail(pros.row) }}>Reminder for <br />Revision</Button>}
                    </Grid>
                );
            },
            sortComparator: (val1, val2) => {
                const map = {
                    REVISED: 1,
                }
                const v1 = map[val1] || 0
                const v2 = map[val2] || 0
                return v1 - v2

            }
        },


        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 140,
            sortable: true,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }}>
                            {pros.value}
                        </div>
                    </div>
                );
            },

        },
    ];

    const handleChangeRadio = (isDate) => {
        if (isDate === isViewDate) return
        setIsViewDate(isDate)
        setSelectedProject('')
        setSelectedProjectObj({})
        setViewDate(startOfMonth(new Date()))
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }
    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Progress Report Summary
                    </Typography>
                </Grid>
                <Grid container style={{ paddingTop: 10 }} >
                    <Grid item style={{ lineHeight: '28px' }}>
                        View by
                    </Grid>
                    <Grid item >
                        <Button
                            startIcon={checkIcon(isViewDate)}
                            // className={classes.button}
                            style={{
                                textTransform: 'none',
                                lineHeight: '1.5',
                                fontSize: '13px'
                            }}
                            onClick={() => handleChangeRadio(true)}

                        >
                            <div style={{ color: '#000' }}>Month</div>
                        </Button>
                        <Button
                            startIcon={checkIcon(!isViewDate)}
                            style={{
                                textTransform: 'none',
                                lineHeight: '1.5',
                                fontSize: '13px'
                            }}
                            onClick={() => handleChangeRadio(false)}

                        >
                            <div style={{ color: '#000' }}>Project</div>
                        </Button>
                        <SelectComponent disabled={isViewDate} displayEmpty={false} value={selectedProject} onChange={handleSelectProject} style={{
                            textTransform: 'none',
                            fontSize: '13px',
                            minWidth: '100px',
                            maxWidth: '200px'
                        }} nameField="refNum" valueField="projectId" options={userProjectList} />
                    </Grid>

                </Grid>
                <div style={{ minHeight: 40 }}>
                    {isViewDate ? <RenderToolDate
                        date={viewDate}
                        label={format(startOfDay(viewDate), 'MMMM yyyy')}
                        setViewDate={setViewDate}
                    /> : <span style={{ lineHeight: '40px' }}>{selectedProjectObj.refNum}</span>}
                </div>
                <Grid item xs>
                    <DataGrid
                        // sortingOrder={['desc', 'asc']}
                        ref={ref}
                        rows={gridData}
                        columns={columns}
                        className={currentClasses.dataGridRoot}
                        disableColumnMenu
                        disableSelectionOnClick
                        headerHeight={40}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        // rowHeight={40}
                        hideFooter={gridData.length < 100}
                    />

                </Grid>
            </Grid>
            <LoadingScreen open={isLoading} />
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </Paper>
    );
}
