import Dialog from '@material-ui/core/Dialog';
import React, { useState, useEffect } from 'react';
import axios from 'CustomAxios';
import {
    Grid,
    IconButton,
    Button,
    Typography,
    DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import SelectComponent from 'components/common/CommonSelect';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useUser from 'hooks/UseFindUser';
import CloseIcon from '@material-ui/icons/Close';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import bwipjs from 'bwip-js';
import { AccessQrCodeViewContext } from 'components/accessQrCode/index';
import GoReportDialog from 'components/common/GoReportDialog';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 0,

    },
    title: {

        height: 40,
        paddingLeft: 20,
        fontSize: 13,
        color: '#f58220',
        backgroundColor: '#ffefe1'
    },
    red: {
        color: '#ff0000'
    },
    activeButton: {
        backgroundColor: '#f58220 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    label: {
        color: '#000'
    }
}));

export default function AccessQrCodePC(props) {
    const {
        onClose,  open,  occupiedOpen, setOccupiedOpen,  updateAlertOpen, setUpdateAlertOpen
        , project, projectList, remarks, token, refNum
        , handleSelect, optionsClick, reportRef,isLocked
    } = React.useContext(AccessQrCodeViewContext)

    const classes = useStyles();
    const renderQrCode = () => {
        return <>
            <DialogTitle
                style={{ textAlign: 'center', padding: '10px', position: 'relative', width: '100%' }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'Access QR Code'}
                </Typography>

                <IconButton aria-label="close" style={{ position: 'absolute', top: 5, right: 5, padding: 5, color: '#000' }} onClick={onClose}>
                    <CloseIcon style={{ fontSize: 28 }} size='small' />
                </IconButton>

            </DialogTitle>
            <Grid container justifyContent={'center'} style={{ marginBottom: 10 }}>
                <SelectComponent displayEmpty={false} value={project} onChange={handleSelect} options={projectList} valueField={'projectId'} nameField={'refNum'} optionclick={optionsClick} />
            </Grid>
            <Grid container justifyContent={'center'} style={{ padding: 10, display: token && !isLocked ? 'flex' : 'none' }}>
                <canvas id='qrcode_canvas' style={{ borderRadius: 8, width: 250, height: 250, }}></canvas>
            </Grid>
            <Grid container justifyContent={'center'} style={{ fontSize: 13, fontWeight: 600 }}> Remarks: </Grid>
            <Grid container justifyContent={'center'} style={{ fontSize: 13, marginBottom: 10, fontWeight: 600 }}>{remarks}</Grid>
            <Grid container justifyContent={'center'} style={{ fontSize: 13, fontWeight: 600 }}>Please be reminded to sign out using QR code when you leave.</Grid>
        </>
    }

    
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    // isQrCode && onClose()
                }}
                disableEscapeKeyDown
                // maxWidth="xs"
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
            >
                {/* <QRCodeSVG value="https://reactjs.org/" /> */}
                <Grid container style={{ width: 600, padding: '5px 20px 20px' }}>
                    {renderQrCode()}
                </Grid>
            </Dialog>
            <SystemMessageDialog open={updateAlertOpen} onClose={() => { setUpdateAlertOpen(false); onClose() }}>
                <div>No seat has been reserved on {format(new Date(), 'dd MMM yyyy')}.</div>
                <div>Please reserve your seat in Booking Management.
                </div>
            </SystemMessageDialog>
            <SystemMessageDialog open={occupiedOpen} onClose={() => { setOccupiedOpen(false); }}>
                <div>All reserved seat(s) for {refNum} is (are) occupied on </div>
                <div> {format(new Date(), 'dd MMM yyyy')}.</div>
                <div>Please manage your seat in Booking Management. </div>
            </SystemMessageDialog>
            <GoReportDialog ref={reportRef} onClose={onClose} />
        </>
    )
}
