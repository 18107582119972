import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../Styles';
import SelectComponent from '../SelectComponent';
const LeftEditComponent = ({
    isEdit,
    enableUpdateBooking,
    selectDateClick,
    selectAvailableDateClick,
    setResNoClick,
    updateCalendarNo,
    submitClick,
    cancelClick,
    allAvailableChecked,
    selectDateChecked,
    selectedDateText,
    resNo,
    selectedDateList,
    resNoInvalidMsg,
    isDirty,
    selfServiceList,
    projectList,
    selectionChoose,
    setSelectionChoose,
    setProject,
    project,
    selfService,
    setSelfService,
    setIsBatch,
    isBatch,
    setResNo,
    rejectReason,
    setRejectReason,
    bookingAllProject,
    bookingAllSelfService,
    setSelectedDateList,
    handleOpenDirtyCheck,
    bookingsList
}) => {
    const classes = useStyles();
    const theme = useTheme();
    
   
    
    const isAllProject = selectionChoose === 'APPROVED' || selectedDateList.length === 0;
    const getProjectList = () => {
        if (isAllProject) { 
            return projectList
        }else {
            const tempMap = {};
            selectedDateList.forEach(item => {
                tempMap[new Date(item.date)] = true;
            })
            let temp = {}
            bookingsList.forEach((item) => {
                if (tempMap[new Date(item.bookDate)]) {
                    if (selectionChoose === 'CANCELLED' && item.status !== 'CANCELLED') {
                        temp[item.projectId] = true
                    }
                    if (selectionChoose === 'REJECTED' && item.status !== 'REJECTED') {
                        temp[item.projectId] = true
                    }
                }
            })
            let newPL = projectList.filter(item => temp[item.projectId])
            // projectList
            return newPL;
        }
    }
    const getSelfServiceList = () => {
        if (isAllProject) { 
            return selfServiceList
        }else {
            const tempMap = {};
            selectedDateList.forEach(item => {
                tempMap[new Date(item.date)] = true;
            })
            let t = {}
            bookingsList.forEach((item) => {
                if (tempMap[new Date(item.bookDate)]) {
                    if (selectionChoose === 'CANCELLED' && item.status !== 'CANCELLED') {
                        t[item.projectId] = true
                    }
                    if (selectionChoose === 'REJECTED' && item.status !== 'REJECTED') {
                        t[item.projectId] = true
                    }
                }
            })
            let newSL = selfServiceList.filter(item => t[item.projectId])
            // projectList
            return newSL;
        }
    }
    
   
    const handleChoose = (e) => {
        const v = e.target.value;
        const choose = () => {
            setSelectionChoose(e.target.value)
           
            if (v !== 'APPROVED') {
                setProject('');
                setSelfService('');
                setIsBatch(false);
            } else {
                setProject([]);
                setSelfService([]);
                setTimeout(() => {
                    setIsBatch(true);
                }, 100)
                
            }
        }
        if (!selectionChoose && selectedDateList.length > 0) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    choose()
                },
            })
            return;
        }
        choose()
    }

    const handleChangeProject = (v) => {
        setProject(v);
        if (isBatch) {
            setSelfService([]);
        } else {
            setSelfService('');
        }
      
       
    }

    const handleChangeSelfService = (v) => {
        setSelfService(v);
        setResNo('1');
        if (isBatch) {
            setProject([]);
        } else {
            setProject('');
        }
     
    }


    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const disableNo = () => {
        if (Array.isArray(selfService) && selfService.length > 0 ) {
            return true;
        }
        if (selfService && !Array.isArray(selfService) ) {
            return true;
        }
        if (selectedDateList.length <= 0) {
            return true;
        }
        return false

    }
    const checkEditSelectIcon = (value) => {
        return value ? <CheckCircleIcon style={{ width: '20px', height: '20px', color: '#f58220',marginRight: 5 }} />
        : <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999',marginRight: 5 }} />
    }

    const renderSubmitBtn = () => {
            return <>{isEdit && <Grid container justifyContent={'center'} style={{ marginTop: '8px' }}>
            <Grid item container style={{ width: '200px', marginLeft: '5px' }}>
                <Grid item>
                    <Button disabled={!isDirty} className={isDirty ? classes.activeButton : classes.inActiveButton} onClick={() => submitClick()}>Submit</Button>
                </Grid>
                <Grid item style={{ marginLeft: '5px' }}>
                    <Button className={classes.cancelButton} onClick={() => cancelClick()}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
        }</>
    }
    const renderEdit = () => {
        const textMap = {
            'APPROVED': 'Batch Update',
            'CANCELLED': 'Batch Cancel',
            'REJECTED': 'Batch Reject',
        }
        return <><div style={{ padding: '10px 10px 0 20px' }}>
            <Grid container style={{marginBottom: 10}}>
                <Grid item>
                    <div className={classes.projectTitle} style={{ paddingTop: 0 }}>Choose Action</div>
                    <Select
                        value={selectionChoose}
                        className={classes.bookingModSelected}
                        onChange={handleChoose}
                        displayEmpty
                        renderValue={(value) => {
                            if (value === '' || value === undefined) {
                                return 'Please select';
                            }
                            return textMap[value]
                        }}
                        
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            PopoverClasses: { root: classes.modSelectedPopover },
                            MenuListProps: {

                            }
                        }}
                       
                        IconComponent={ChevronRightIcon}
                        classes={{
                            iconOpen: classes.iconOpen, 
                          }}
                    >
                        <MenuItem value={'APPROVED'}  style={{ backgroundColor: '#fff', paddingLeft: 10}}>
                            
                           
                            { checkEditSelectIcon(selectionChoose === 'APPROVED')}
                            <ListItemText primary={'Batch Update'} style={{ margin: '0', color: '#000'}} />
                        </MenuItem>
                        <Divider flexItem style={{ pointerEvents: 'none' }} />
                        <MenuItem value={'CANCELLED'} style={{ backgroundColor: '#fff', paddingLeft: 10 }}>
                            
                            { checkEditSelectIcon(selectionChoose === 'CANCELLED')}
                            <ListItemText primary={'Batch Cancel'} style={{ margin: '0', color: '#000' }} />

                        </MenuItem>
                        <Divider flexItem style={{ pointerEvents: 'none' }} />
                        <MenuItem value={'REJECTED'} style={{ backgroundColor: '#fff', paddingLeft: 10 }}>
                           
                            { checkEditSelectIcon(selectionChoose === 'REJECTED')}
                            <ListItemText primary={'Batch Reject'} style={{ margin: '0', color: '#000' }} />
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {
                selectionChoose && <Grid container >
                    
                    <Grid item>
                        <div className={classes.projectTitle} style={{ paddingTop: 0 }}>Project No.</div>
                        <SelectComponent
                            allText={isBatch && 'All project'} multiple={isBatch} value={project} onChange={handleChangeProject} options={selectionChoose === 'APPROVED' ? bookingAllProject: getProjectList()} valueField={'projectId'} nameField={'refNum'}
                            IconComponent={ChevronRightIcon}
                            classes={{
                                iconOpen: classes.iconOpen, 
                            }}
                            style={{
                                width: 160,
                            }}
                        />
                        <div className={classes.projectTitle}>Self-Service No.</div>
                        <SelectComponent
                            allText={isBatch && 'ALL Self-Service'} multiple={isBatch}
                            value={selfService} onChange={handleChangeSelfService} options={selectionChoose === 'APPROVED' ? bookingAllSelfService : getSelfServiceList()} valueField={'projectId'} nameField={'refNum'}
                            IconComponent={ChevronRightIcon}
                            classes={{
                                iconOpen: classes.iconOpen, 
                            }}
                            style={{
                                width: 160,
                            }}
                        />
                    </Grid>
                    <Grid container style={{paddingTop: 15}}>
                        <Button
                           
                            startIcon={checkIcon(allAvailableChecked)}
                            className={classes.selDateButton}
                            style={{
                                textTransform: 'none',
                                lineHeight: '1.5',
                                fontSize: '13px'
                            }}
                            // disabled={project.length === 0 && selfService.length === 0}
                            onClick={() => selectAvailableDateClick()}
                        >
                            <div className={classes.selDateLabel}>Select all available dates</div>
                        </Button>
                        <Grid container item>
                            <Button
                               
                                startIcon={checkIcon(selectDateChecked)}
                                className={classes.selDateButton}
                                style={{
                                    color: `${theme.palette.primary.main}`,
                                    textTransform: 'none',
                                    fontSize: '13px'
                                }}
                                // disabled={project.length === 0 && selfService.length === 0}
                                onClick={() => selectDateClick()}
                            >
                                <div className={classes.selDateLabel}>Select date</div>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container style={{ textAlign: 'center', marginTop: '8px' }}>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            defaultValue={selectedDateText()}
                            style={{ backgroundColor: 'white', width: 160 }}
                            className={classes.selDateText}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid container style={{ marginTop: '15px',}}>
                        {selectionChoose === 'APPROVED' && <><div>No. of researchers</div>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <TextField
                                    className={classes.ResNoText}
                                    value={resNo}
                                    onChange={(e) => setResNoClick(e.target.value)}
                                        disabled={disableNo()}
                                        onBlur={(e) => { setResNoClick(e.target.value === '0' ? '': e.target.value)}}
                                />
                            </Grid>
                            <Grid item >
                                <span style={{ lineHeight: '1', color: 'red' }}>{resNoInvalidMsg}</span>
                            </Grid>
                            </Grid></>}
                        
                        { selectionChoose === 'REJECTED' && <><div>Reason:</div>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <TextField
                                    value={rejectReason}
                                    onChange={(e) => setRejectReason(e.target.value)}
                                    className={classes.Reason}
                                />
                            </Grid>
                            </Grid></>}


                        <div style={{ marginTop: '15px' }}>
                            <Button className={enableUpdateBooking() ? classes.activeButton : classes.inActiveButton} onClick={() => updateCalendarNo()}
                                disabled={!enableUpdateBooking()}>Update Booking</Button>
                        </div>
                    </Grid>
                </Grid>
            }
            <Divider flexItem style={{ pointerEvents: 'none', width: '100%', marginTop: 10 }} />
            
           {renderSubmitBtn()}
        </div >
            
        </>
    }
    return (<>
        {renderEdit()}
    </>)
}

export default LeftEditComponent;