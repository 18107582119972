import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from 'Styles';

export default function LoadingScreen(props) {
    const classes = useStyles();

    return (
        <div >
            <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} className={classes.backdrop} open={props.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}