import { makeStyles } from "@material-ui/core/styles";
import homepage_bg from 'images/homepage_bg.jpg';

export const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: '100%'
    },
    autoMaxHeightItem: {
        flex: 1
    },
    backgourndImageClass: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: 'cover',
    },
    animInItem: {
        animation: `$animIn 1s ${theme.transitions.easing.easeInOut}`
    },
    '@keyframes animIn': {
        '0%': {
            opacity: 0.5
        },
        '100%': {
            opacity: 1
        }
    },
    animExitItem: {
        animation: `$animInOut 1s ${theme.transitions.easing.easeInOut}`,
        opacity: 0,
    },
    '@keyframes animInOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        }
    },
    leftWrapper: {
        backgroundColor: '#464646',
        minWidth: 667,
        position: 'relative'
    },
    rightWrapper: {
        flex: 1
    },
    titleFont: {
        fontSize: '13px !important',
        fontFamily: 'Quicksand-SemiBold !important'
    },
    stepperRoot: {
        backgroundColor: 'unset !important',
        paddingLeft: 15
    },
    stepperDotRoot: {
        width: '15px !important',
        height: '15px !important',
        backgroundColor: '#848484'
    },
    dotActiveRoot: {
        backgroundColor: theme.palette.primary.main
    },
    // LoginTopWrapper: {
    //     backgroundColor: 'white',
    //     height: '15%',
    //     borderBottom: `6px solid ${theme.palette.primary.main}`
    // },
    // LoginBottomWrapper: {
    //     height: 'calc(100% - 15%)',
    //     backgroundImage: `url(${homepage_bg})`,
    //     backgroundPosition: 'center center',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundAttachment: 'fixed',
    //     backgroundSize: 'cover',
    //     backgroundColor: '#464646',
    // },
    // LoginWrapper: {
    //     backgroundColor: 'white',
    //     borderRadius: '4px',
    //     padding: '48px 24px',
    //     width: '320px'
    // },
    // LoginTitle: {
    //     textTransform: 'uppercase',
    //     color: `${theme.palette.primary.main}`
    // },
    // LoginTitleWrapper: {
    //     paddingBottom: '8px'
    // },
    // LoginDescWrapper: {
    //     paddingBottom: '16px'
    // },
    LoginInputWrapper: {
        paddingTop: 20
    },
    loginPwdInputWrapper: {
        paddingTop: 17
    },
    blackColor: {
        color: '#000000 !important',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: '16px !important',
        marginBottom: 5
    },
    // RightPanelWrapper: {
    //     minWidth: '300px',
    //     [theme.breakpoints.down('lg')]: {
    //         width: "300px !important"
    //     },
    //     maxWidth: '500px',
    //     height: '450px',
    // },
    InfoWrapper: {
        // height: '125px',
        margin: '15px 15px 0 15px',
        padding: '12px 15px 15px 15px',
        opacity: 0.80,
        display: 'flex',
        flexDirection: 'column'
    },
    InfoDivider: {
        borderColor: `${theme.palette.primary.main} !important`,
        width: '90%'
    },
    InfoContainer: {
        paddingTop: '11px',
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: 13,
        color: '#000000',
        flex: 1
    },
    loginIcon: {
        paddingLeft: '9px',
    },
    iconDivider: {
        height: 5,
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
    welcomeFont: {
        // fontSize: 17,
        fontFamily: 'Quicksand-SemiBold',
        color: '#000000',
        [theme.breakpoints.up('md')]: {
            fontSize: 17,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 22,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 27,
        },
    },
    loginFont: {
        fontSize: 30,
        fontFamily: 'Quicksand-SemiBold',
        color: theme.palette.primary.main,
    },
    loginFormContainer: {
        padding: '25px 20px 0 25px'
    },
    forgetPwdWrapper: {
        paddingTop: 17
    },
    forgetPwd: {
        fontSize: 16,
        fontFamily: 'Quicksand-SemiBold',
    },
    inputStyle: {
        height: '30px',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: '16px !important',
        '& input': {
            padding: '0 !important'
        }
    },
    loginInputBox: {
        padding: '30px 29px 40px 30px',
        borderRadius: '30px',
        backgroundColor: '#ffefe1',
        marginTop: '65px !important'
    }
    // InfoContainer: {
    //     paddingTop: '8px',
    //     height: 'calc(100% - 25px)',
    //     width: '100%',
    //     overflow: 'auto',
    //     '&::-webkit-scrollbar': {
    //         width: 5,
    //         height: 1
    //     },
    //     '&::-webkit-scrollbar-thumb': {
    //         borderRadius: 5,
    //         background: '#D8D8D8'
    //     },
    //     '&::-webkit-scrollbar-track': {
    //         borderRadius: 5,
    //         background: 'unset',
    //     },
    //     scrollbarColor: '#D8D8D8 white',
    //     scrollbarWidth: 'thin',
    // },
}));