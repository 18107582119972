import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useStyles } from "Styles";
import Typography from '@material-ui/core/Typography';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from '../../CustomAxios';
import React, { useState } from 'react';
import { GridColDef } from '../common/HadclFormatter'
import { DateNoTime } from '../common/HadclFormatter'
import DeleteIcon from '@material-ui/icons/Delete';
import { useMsg } from '../../hooks/MsgProvider';
import useModal from "hooks/useModal";
import ConfirmDialog from "components/common/ConfirmDialog";
import { confirmDialogRequest } from '../common/Constants';
import HadclGrid from '../common/HadclGrid';
import Grid from '@material-ui/core/Grid';

const { forwardRef, useImperativeHandle } = React;

const ColResult = forwardRef((props, ref) => {
    const classes = useStyles();
    const { setSuccessMsg, setErrMsg } = useMsg();
    const [rowData, setRowData] = useState([]);
    const gridApi = React.useRef();
    const showModal = useModal();
    const [columnDefs] = useState([
        GridColDef({
            headerName: 'Action',
            field: 'notes',
            width: 100,
            autoHeight: false,
            sortable: false,
            filter: false,
            cellRendererFramework: (pros) => {
                return (
                    pros.data.status === 'Submitted' ? <div>
                        <DeleteIcon onClick={() => handleDeleteOpen(pros.data.id, pros.data.bookDate)}></DeleteIcon>
                    </div> :
                        <div></div>

                );
            }
        }),
        GridColDef({
            headerName: 'Ref No.',
            field: 'refno',
            width: 110,
        }),
        GridColDef({
            headerName: 'Access Date',
            field: 'bookDate',
            width: 130,
            valueGetter: (params) => {
                return (
                    DateNoTime(params.data.bookDate)
                );
            }
        }),
        GridColDef({
            headerName: 'No. of Access',
            field: 'capacity',
            width: 150,
            //minWidth: 50,
            //maxWidth: 100,
            filter: 'agNumberColumnFilter',
            comparator: ""
        }),
        GridColDef({
            headerName: 'Status',
            field: 'status',
            width: 110,
        }),
        GridColDef({
            headerName: 'Submission date',
            field: 'requestDate',
            width: 160,
            valueGetter: (params) => {
                return (
                    DateNoTime(params.data.requestDate)
                );
            }
        }),
        GridColDef({
            headerName: 'Last Updated',
            field: 'updateDate',
            width: 135,
            valueGetter: (params) => {
                return (
                    DateNoTime(params.data.updateDate)
                );
            }
        }),
        GridColDef({
            headerName: 'Remarks',
            field: 'remarks',
            flex: 1,
            minWidth: 150
        })
    ]);

    const onGridReady = (params) => {
        gridApi.current = params;
    }

    function clearFilter(event) {
        event.stopPropagation();
        event.preventDefault();

        gridApi.current.api.setFilterModel(null);
        gridApi.current.api.setSortModel(null);
    }

    const handleDeleteOpen = (bookingId, deleteDate) => {
        showModal((pros) => (
            <ConfirmDialog
                {...pros}
                description={confirmDialogRequest(deleteDate)}
                onConfirm={() => { handleDeleteConfirm(bookingId); }}
                onCancel={() => console.log("Deletion cancelled.")}
                cancelText="No"
                okText="Yes"
            />
        ));
    };

    const getBookRequestList = () => {
        axios.get('/bookRequest/current-user?userRoleType=COL_RESEARCHER')
            .then(({ data: result }) => {
                setRowData(result);
            }).catch(err => {
                setErrMsg(err);
            });
    }


    const handleDeleteConfirm = (bookingId) => {

        axios.put('/bookRequest/delete?id=' + bookingId)
            .then(res => {
                setSuccessMsg(res.data.message);
                getBookRequestList();
            }).catch(err => {
                setErrMsg(err);
            });
    };

    React.useEffect(getBookRequestList, []);


    useImperativeHandle(ref, () => ({

        refreshGrid() {
            getBookRequestList();
        }

    }));

    return (
        <div className={`ag-theme-alpine ${classes.hadclAgGrid}`}>
            <HadclGrid rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} clearFilter={clearFilter} />
        </div>
    );
}
)
export default ColResult;
