import { makeStyles } from "@material-ui/core/styles";
import homepage_bg from './images/homepage_bg.jpg';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
    inputLabel: {
        margin: theme.spacing(0, 0, 1, 0),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paperLogin: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paperMarginBottom: {
        marginBottom: theme.spacing(2),
    },
    margin: {
        margin: '8px',
        width: '100%'
    },
    marginRadio: {
        marginLeft: '20px',
        marginTop: '8px',
    },
    radio: {
        margin: theme.spacing(1, 2, 1, 0),
    },
    noMarginTop: {
        margin: theme.spacing(0, 2, 2, 0),
    },
    input: {
        margin: theme.spacing(1, 2, 1, 0),
        "& .MuiInputBase-input": {
            padding: theme.spacing(2)
        }
    },
    divBound: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #C5C5C5',
        borderRadius: '10px'
    },
    userEditForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        }
    },
    inputBookingDialog: {
        margin: theme.spacing(1, 0, 1, 0),
        "& .MuiAutocomplete-endAdornment": {
            top: "calc(50% - 22px)"
        }
    },
    root: {
        display: 'flex',
        "& .MuiPickersStaticWrapper-root": {
            minWidth: 290
        },
        "& .MuiPickerView-root": {
            [theme.breakpoints.down('sm')]: {
                width: "min(100%,320px)"
            },
        }
    },
    menuList: {
        display: 'flex',
        flexWrap: "wrap"
    },
    drawer: {
        width: 0
    },
    drawerOpen: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            /*width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,*/
            width: "100%",
            marginLeft: 0
        },
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        boxSizing: 'border-box',
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingTop: theme.spacing(3),
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    logo: {
        paddingTop: 5,
        paddingLeft: 15,
        objectFit: 'contain',
    },
    loginLogo: {
        paddingBottom: 5,
        objectFit: 'contain',
    },
    messagerBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    messagerAlert: {
        width: '100%',
    },
    shorterCalendar: {
        "& .MuiPickersCalendar-root": {
            minHeight: 240
        },
    },
    backdrop: {
        color: '#fff',
    },
    //hadclAgGrid: {
    //    height: 400,
    //    [theme.breakpoints.down('sm')]: {
    //        width: "min(100%, 350px)"
    //    }
    //},
    hadclAgGrid: {
        height: 400,
        [theme.breakpoints.down('sm')]: {
            '@supports (-webkit-touch-callout: none)': {
                width: "350px",
            }
        }
    },
    nhadclAgGrid: {
        height: 400,
        [theme.breakpoints.down('sm')]: {
            '@supports (-webkit-touch-callout: none)': {
                width: "350px",
            }
        },
        "& .ag-header": {
            backgroundColor: '#646464',
            // height: '40px !important',
            // minHeight: '40px !important',
            borderRadius: '3px'
        },
        "& .ag-icon-menu:before": {
            color: '#fff'
        },
        "& .ag-icon-desc:before": {
            color: '#fff'
        },
        "& .ag-icon-filter:before": {
            color: '#fff'
        },
        "& .ag-icon-asc:before": {
            color: '#fff'
        },
        "& .ag-header-label-icon": {
            color: '#fff'
        },
        "& .ag-header-cell-text": {
            color: '#fff',
            fontWeight: '500'
        },
        "& .ag-root-wrapper": {
            border: '0',
        },
        "& .ag-paging-panel": {
            border: '0',
        }
    },
    rightNote: {
        justifyContent: "flex-end",
        display: "flex",
        paddingRight: theme.spacing(2),
    },
    topMenu: {
        backgroundColor: '#F58220',
        fontSize: '15px',
        height: '40px',
        "& :first-child .nav-link": {
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },
        "& .nav-link": {
            padding: '8px 0',
            textAlign: 'center',
        },
        "& .nav-link:hover,.active": {
            backgroundColor: '#D66E00'
        },
        "& hr": {
            borderWidth: '1px'
        },
        // "& > div": {
        //     minWidth: '151px',
        //     maxWidth: '180px',
        // },
        "& :first-child": {
            minWidth: '0px'
        },
    },
    fixedTop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        // backgroundColor: '#F58220',
        zIndex: 999,
        overflow: 'hidden'
    },
    middleMenuBox: {
        backgroundImage: `linear-gradient(to top, rgb(0 0 0), transparent), url(${homepage_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // height: 'calc(100vh - 276px )',
        '& .MuiMobileStepper-dots': {
            width: '100%'
        },
        '& .MuiMobileStepper-dot': {
            backgroundColor: 'white',
            // width: '100px',
            borderRadius: '0',
            flex: '1',
            height: '5px'
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: '#FBA862'
        },
        '& .MuiMobileStepper-root': {
            justifyContent: 'center',
            margin: '0 auto',
            // width: '70%',
            maxWidth: '700px',
            height: '5px',
            padding: '0 20px',
            marginTop: '15px',
            borderRadius: '30px'
        }
    },
    middleMenuArrow: {
        backgroundColor: '#F58220 !important',
        height: '70px',
        width: '30px',
        minWidth: '0px !important',
        color: 'white !important',
        borderRadius: '0 !important',
        cursor: 'pointer'
    },
    middleSigle: {
        backgroundColor: 'white',
        borderRadius: '5px',
        opacity: '0.9',
        // margin: '0 10px !important',
        cursor: 'pointer',
        width: '219px',
        height: '219px'
    },
    middleMenuText: {
        width: '70%',
        textAlign: 'center',
        fontWeight: 'bold !important',
        margin: '8px auto 0 auto !important',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        fontFamily: 'Quicksand-Medium !important'
    },
    homeNewsLine: {
        marginTop: '5px !important',
        marginBottom: '0px !important'
    },
    bottomBox: {
        color: '#ffffff',
        fontSize: '13px',
        minHeight: '133.5px',
        padding: '20px 50px 0 50px',
        '& li,a': {
            padding: '0',
        },
        '& a': {
            color: '#ffffff !important',
        }
    },
    bellButton: {
        padding: '8px !important'
    },
    newsList: {
        height: '90px',
        overflow: 'auto'
    },
    new3DBox: {
        backgroundColor: 'white',
        borderRadius: '5px',
        fontSize: '14px',
        marginTop: '25px !important'
        // height: '160px'
    },
    threeDBox: {
        padding: '0px 10px 10px 10px !important',
        width: '240px !important'
    },
    moreLink: {
        color: 'black',
        fontWeight: 'bold',
        padding: '0px'
    },
    newTextBox: {
        [theme.breakpoints.up('lg')]: {
            fontSize: 30,
        },
        height: 90,
        fontSize: 19,
        // border: '2px solid #919090',
        fontWeight: '600',
        borderRadius: 2
    },
    moreHeader: {
        margin: '5px 0px !important',
        "& .nav-link:hover": {
            color: 'black'
        },
    },
    logoutButton: {
        border: '2px solid rgb(245, 130, 32)',
        borderRadius: '50px',
        padding: '0px 5px',
        marginLeft: '5px !important',
        color: 'rgb(245, 130, 32)',
        cursor: 'pointer'
    },
    emailIcon: {
        color: 'white',
        fontSize: '1.15rem !important'
    },
    visibilityHidden: {
        visibility: 'hidden'
    },
    videoArrow: {
        backgroundColor: '#F58220 !important',
        height: '45px',
        width: '20px',
        minWidth: '0px !important',
        color: 'white !important',
        borderRadius: '0 !important',
        cursor: 'pointer'
    },
    threeDImg: {
        width: '95%',
        margin: '10px auto'
    },
    videoRole: {
        padding: '0 5px',
        height: 29,
        lineHeight: '29px',
        color: "#fff",
        borderRadius: 5,
        fontSize: 12,
        textAlign: 'center'
    },
    exportButton: {
        backgroundColor: '#15a9d2 !important',
        color: '#fff !important',
        textTransform: 'none !important',
        fontSize: '15px !important',
        height: '30px !important'
    },
    modSelectedPopover: {
        '& .MuiPaper-root': {
            maxHeight: 450,
        }
    },
}));