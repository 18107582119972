import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, createContext } from 'react';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import useAuth from './../hooks/useAuth';
import { useMsg } from './../hooks/MsgProvider';
import useInformation from 'hooks/useInformation';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axios from '../CustomAxios';
import { useEffect } from 'react';
import useSysParam from 'hooks/UseSysParam';
import LoginPC from 'view/pc/login/Login.pc';
import LoginMobile from 'view/mobile/login/Login.mobile';
import RenderPcOrMobile from './RenderPcOrMobile';

export const LoginViewContext = createContext({});

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

const theme = themes => createMuiTheme({
    ...themes,
    components: {
        ...themes.components,
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: 8
                }
            }
        }
    }
});

function Login(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { loginUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { setErrMsg } = useMsg();
    const [showLockTip, setShowLockTip] = useState(false);
    const [lockTipContent, setLockTipContent] = useState('');
    const [backgroundImgList, setBackgroundImgList] = useState([]);
    const [activeBackgroundIndex, setActiveBackgroundIndex] = useState(null);
    const intervalRef = React.useRef();
    const activeBackgroundRef = React.useRef();
    const { paramTextValue: logoLink } = useSysParam('sys_logo_hyperlink');
    const { paramIntValue: noticeHeight } = useSysParam('important_notice_box_height');
    const { paramIntValue: inforHeight } = useSysParam('useful_information_height');
    const noticeHeightPx = `${noticeHeight}px`;
    const inforHeightPx = `${inforHeight}px`;
    const [loginRemainTime, setLoginRemainTime] = useState(0);
    const loginIntervalRef = React.useRef();

    const { handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: ''
        },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function onSubmit(event) {
        setIsLoading(true);
        setLoginRemainTime(5);
        loginUser({ username: email, password: password }).catch(err => {
            var isLock = err.response?.data?.isLock;
            setIsLoading(false);
            if (isLock) {
                setShowLockTip(true);
                setLockTipContent(err.response?.data?.message);
            }
            else {
                setErrMsg(err);
                setIsError(true)
            }
        });
    }

    const usefulInfo = useInformation('USEFUL_INFO').informations;
    const iptNotice = useInformation('IPT_NOTICE').informations;

    const getBackgroundImage = React.useCallback(() => {
        axios.get('/information/get-loginicons').then((res) => {
            const imgsData = res.data.icons;
            setBackgroundImgList(imgsData);
            if (Array.isArray(imgsData) && imgsData.length > 0) {
                activeBackgroundRef.current = imgsData[0];
                setActiveBackgroundIndex(0);
                clearInterval(intervalRef.current);
                intervalRef.current = setInterval(() => {
                    if (activeBackgroundRef.current) {
                        let index = activeBackgroundRef.current.id;
                        if (backgroundImgList.length <= index)
                            index = 0;
                        setActiveBackgroundIndex(index);
                        activeBackgroundRef.current = imgsData[index];
                    }
                }, res.data.intervalTime * 1000 || 10000);
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        })
    }, [backgroundImgList.length])

    useEffect(() => {
        getBackgroundImage();
    }, [getBackgroundImage])

    useEffect(() => {
        if (loginRemainTime === 5) {
            clearInterval(loginIntervalRef.current);
            loginIntervalRef.current = setInterval(() => {
                setLoginRemainTime(prev => prev - 1);
            }, 1000);
        }
        if (loginRemainTime === 0) {
            clearInterval(loginIntervalRef.current);
        }
        
    }, [loginRemainTime])

    return <LoginViewContext.Provider value={{
        isLoading, theme, backgroundImgList, noticeHeightPx, iptNotice, inforHeightPx, usefulInfo, activeBackgroundIndex, logoLink,
        handleSubmit, onSubmit, control, email, setEmail, errors, password, setPassword,
        handleClickShowPassword, handleMouseDownPassword, showPassword, showLockTip,
        setShowLockTip, lockTipContent,
        setErrMsg, isError, loginRemainTime
    }}>
        <RenderPcOrMobile pc={<LoginPC />} mobile={<LoginMobile />} />
    </LoginViewContext.Provider>
}
export default Login;