import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';

export default function LockPromptDialog(props) {
    const { onClose, open, content } = props;

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogContent dividers>
                <pre id="lock-message-dialog-description" dangerouslySetInnerHTML={{ __html: content }}
                    style={{
                        margin: 0,
                        fontSize: '1rem',
                        fontFamily: 'Arial',
                        whiteSpace: 'pre-line',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                        lineHeight: 1.43
                    }}
                ></pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    )
}
