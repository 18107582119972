import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
// import  from '@mui/material/Typography';
// import  from '@mui/material/Paper';
// import Popper from '@mui/material/Popper';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: '#d8d8d8',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#46ae4a',
  },
}))(LinearProgress);


export default BorderLinearProgress