import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
    addMonths
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , isAfter,
    isBefore
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    // , Dialog
    , DialogTitle
    , DialogContent
    , Popover
    , TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'CustomAxios';
import { useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import useAutoHozScroll from 'hooks/useAutoHozScroll';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import SmallCalendar from '../Calendar/SmallCalendar';
import UserIcon from '../../../images/user.svg';
import CustomTooltip from '../CustomTooltip';
const { Provider, Consumer } = React.createContext();
export const useStyles = makeStyles(theme => ({
    boldFont: {
        fontWeight: 'bold'
    },
    rejectedWrapper: {
        backgroundColor: '#e14631',
        padding: '0 4px',
    },
    // rejectedWrapper: {
    //     backgroundColor: '#e14631',
    //     width: '100%',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    eventWrapper: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    approvedWrapper: {
        backgroundColor: '#67c451',
        padding: '0 4px',
    },
    longValueClass: {
        width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            'display': 'none'
        },
        overflowX: 'auto',
        overflowY: 'none',
        scrollbarWidth: 'none',
    },
    // approvedWrapper: {
    //     backgroundColor: '#69C456',
    //     width: 'calc(100% - 25px)',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    approvedValueWrapper: {
        backgroundColor: '#67c451',
        display: 'inline-block',
        width: '25px',
        textAlign: 'center'
    },
    closeFont: {
        color: '#0064E7'
    },
    closeWrapper: {
        display: 'flex',
        backgroundColor: '#C9E0FF',
        width: '100%',
        // position: 'absolute',
        bottom: '0',
        textAlign: 'left',
        padding: '0 4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    moreWrapper: {
        position: 'absolute',
        right: 0,
        backgroundColor: '#C9E0FF',
        alignItems: 'center',
        height: '100%'
    },
    ellipsisFont: {
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    publicCloseDay: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        textAlign: 'left',
        backgroundColor: '#FEE2E2',
        padding: '0 4px',
        // overflow: 'hidden'
    },
    checkedBackground: {
        backgroundColor: '#FFEFE2'
    },
    invalidBackground: {
        backgroundColor: '#ffdbdb'
    },
    witheBackground: {
        backgroundColor: 'white'
    },
    eachNo: {
        '& input': {
            textAlign: 'center'
        }
    },
    dateFont: {
        fontSize: '13px !important'
    },
    remarkTitleFont: {
        color: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute !important',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    remarksContent: {
        backgroundColor: '#EEF5FF',
        color: '#0064E7',
        margin: 16,
        minWidth: '300px'
    },
    specQuicksand: {
        fontFamily: 'Quicksand-Medium',
        // fontSize: '13px !important'
    }
}));

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const RenderToolBar = (props) => {
    const [cAnchorEl, setCAnchorEl] = React.useState(null);
    const monthClick = (e) => {
        setCAnchorEl(e.currentTarget);
    }

    const closeSmallCalendar = () => {
        setCAnchorEl(null);
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { date, label } = props;
                    const setViewDate = calendarValue.setViewDate;
                    const isDirty = calendarValue.isDirty;
                    const handleOpenDirtyCheck = calendarValue.handleOpenDirtyCheck;
                    const isRelease = calendarValue.isRelease;
                    const resetLeftData = calendarValue.resetLeftData;
                    const toNextMonth = () => {
                        const handleNextMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), 1));
                            resetLeftData()
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleNextMonth })
                            return;
                        }
                        handleNextMonth();
                    }

                    const toLastMonth = () => {
                        const handleLastMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), -1));
                            resetLeftData()
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleLastMonth })
                            return;
                        }
                        handleLastMonth();
                    }

                    const selectEnabled = (bookingDate) => {
                        return isRelease(bookingDate);
                    }

                    return (<>
                        <Grid container alignItems={'baseline'} style={{ height: 40 }}>
                            <Grid item>
                                <IconButton onClick={toLastMonth}>
                                    <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ minWidth: '125px', textAlign: 'center', cursor: 'pointer', height: 34, lineHeight: '33px', border: '1px solid #d2d2d2', borderRadius: 3 }} onClick={(e) => monthClick(e)}>
                                {label}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={toNextMonth} disabled={!selectEnabled(addMonths(startOfMonth(date), 1))}>
                                    <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Popover
                            open={Boolean(cAnchorEl)}
                            onClose={() => setCAnchorEl(null)}
                            anchorEl={cAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SmallCalendar anchorEl={cAnchorEl} date={date} isDirty={isDirty} close={closeSmallCalendar} handleOpenDirtyCheck={handleOpenDirtyCheck} setViewDate={setViewDate} />
                        </Popover>
                    </>);
                }
            }
        </Consumer >
    );
}

const renderHeader = (e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays) => {
    const { label, date } = e;
    const isSunday = date.getDay() === 0;
    const style = {
        height: '25px'
    }
    return (
        <Grid style={style} container alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
            <Grid item style={{ width: '38px', textAlign: 'left' }}>
                {
                    // isEdit && weekRdoDisplays[date.getDay()].display &&
                    <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => setWeekChecked(date.getDay())}>
                        {
                            weekCheckedList[date.getDay()].checked ?
                                <CheckCircleIcon style={{ width: '20px', height: '20px' }} /> :
                                <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                        }
                    </IconButton>
                }
            </Grid>
            <Grid item className={`${isSunday && 'rbc-public-date'} ${classes.dateFont} ${classes.specQuicksand}`}>
                {label?.substring(0, 3).toUpperCase()}
            </Grid>
            <Grid item style={{ width: '38px' }}>

            </Grid>
        </Grid>
    )
}

const RenderDateHeader = (props) => {
    // console.log('ss', setSeAble)
    const classes = useStyles();
   
    const getDateItemColor = (dateItem) => {
        if (dateItem?.fullPercent >= 0.8) {
            return '#ED3A4E'
        }
        if (dateItem?.fullPercent >= 0.6) {
            return '#FFB448'
        }
        return '#50E4A7'

    }
    const getClassName = (isClose) => {
        return `${isClose ? 'rbc-public-date' : 'rbc-normal-date'} ${classes.boldFont} ${classes.dateFont}`
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { label, date } = props;
                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(date));
                    if (isOtherMonth)
                        return <></>;
                    const dateItem = calendarValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(date)));
                    const eventItem = calendarValue.eventList.find(item => isEqual(startOfDay(new Date(item.start)), startOfDay(date)));
                    const isPublicHoliday = eventItem?.remarks.toLowerCase().includes('public holiday');
                    let isClose = eventItem && !eventItem.available;
                    let isUpdate = true;
                    if (!dateItem && eventItem && !eventItem.available && !eventItem.capacity && !eventItem.remarks) {
                        isUpdate = false
                    }
                    const style = {
                        height: '24px'
                    }
                    const selectedItem = calendarValue.selectedDateList.find(item => isEqual(startOfDay(new Date(item.date)), startOfDay(date)));

                    return (
                        <>
                            <Grid container style={style} alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
                                <Grid item style={{ width: '38px', textAlign: 'left' }}>
                                    {
                                        // !cannotBook && calendarValue.isEdit && !isPreviousDate && isRelease() && notExpired && !isRejectDate &&
                                        calendarValue.isEdit &&
                                        <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => calendarValue.checkedDate(date)}>
                                            {
                                                selectedItem ? <CheckCircleIcon style={{ width: '20px', height: '20px' }} />
                                                    : <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                                            }
                                        </IconButton>
                                    }
                                </Grid>
                                <Grid item className={getClassName((isClose && isUpdate) || isPublicHoliday)}>
                                    {Number(label)}
                                </Grid>
                                <Grid item style={{ width: '38px' }}>
                                    {
                                        eventItem && !isClose && isUpdate ?
                                            (
                                                <div style={{ width: '6px', height: '6px', borderRadius: '50%', backgroundColor: getDateItemColor(eventItem), margin: '0 auto' }}></div>
                                            )
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </>
                    );
                }
            }
        </Consumer >
    )
}

const RenderRateCellWrapper = (props) => {
    const classes = useStyles();
    const remarkContainer = React.useRef(null);
    const remarksRef = React.useRef(null);

    const publicRemarkRef = React.useRef(null);
    const publicContainerRef = React.useRef(null);
    const dateContainerRef = React.useRef(null);

    const { setAutoScroll: setPubAutoScroll } = useAutoHozScroll(publicRemarkRef, true, 60);
    const { setAutoScroll: setDateAutoScroll } = useAutoHozScroll(remarksRef, true, 60);

    React.useEffect(() => {
        if (publicRemarkRef.current && publicContainerRef.current &&
            publicRemarkRef.current.clientWidth > publicContainerRef.current.clientWidth) {
            publicRemarkRef.current.classList.add(classes.longValueClass);
            setPubAutoScroll(true);
        }
    });

    React.useEffect(() => {
        if (remarksRef.current && dateContainerRef.current &&
            remarksRef.current.clientWidth > dateContainerRef.current.clientWidth) {
            remarksRef.current.classList.add(classes.longValueClass);
            setDateAutoScroll(true);
        }
    });


    const getCbg = (calendarValue, dateItem, value, isChecked) => {
        const isClose = dateItem && !dateItem.available;

        const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(value));
        const cannotBook = isOtherMonth || isClose || !dateItem;

        const isInvalid = calendarValue.submitResult && calendarValue.submitResult.invalidList &&
            calendarValue.submitResult.invalidList.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(value)));

        const isRelease = calendarValue.isRelease;

        let cbg = ''
        if (cannotBook || !isRelease()) cbg = 'rbc-off-range-bg';
        else if (calendarValue.isEdit && isChecked) cbg = classes.checkedBackground;
        if (isInvalid && isChecked) cbg = classes.invalidBackground;
        return cbg;
    }
    const renderNotPublicHoliday = (dateItem, isToday, isChecked, value, calendarValue) => {
        return <>
            <div style={{
                display: 'flex', position: 'absolute',
                bottom: '0',
                width: isToday ? 'calc(100% - 4px)' : '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginLeft: isToday ? '2px' : '0',
                marginBottom: isToday ? '2px' : '0'
            }}>
                <div style={{ width: 'calc(100%)', position: 'relative', height: 18 }} ref={remarkContainer}>
                    {dateItem.remarks &&
                        <div className={classes.closeWrapper} ref={dateContainerRef}>
                            <div
                                className={`${classes.boldFont} ${classes.closeFont} rbc-public-date ${classes.dateFont} ${classes.ellipsisFont}`}
                                ref={remarksRef}
                            >
                                {dateItem.remarks}
                            </div>
                        </div>
                    }
                </div>

            </div>
        </>
    }

    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { value } = props;
                    const dateItem = calendarValue.eventList.find(item => isEqual(startOfDay(new Date(item.start)), startOfDay(value)));



                    const isPublicHoliday = dateItem?.remarks.toLowerCase().includes('public holiday');

                    const isToday = isEqual(startOfDay(new Date()), startOfDay(value));
                    const isChecked = calendarValue.selectedDateList.some(item => isEqual(startOfDay(new Date(item.date)), startOfDay(value)));
                    const publicHolidaysStyle = isToday ? { width: 'calc(100% - 4px)', marginLeft: '2px', marginBottom: '2px' } : null



                    let cbg = getCbg(calendarValue, dateItem, value, isChecked);

                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(value));

                    return (
                        <div
                            className={`rbc-day-bg  ${cbg}`}
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div style={{
                                position: 'relative'
                                , height: '100%'
                            }} className={isOtherMonth ? 'otherMonth' : ''}>
                                {props.children}
                                {dateItem && !isPublicHoliday
                                    ?
                                    renderNotPublicHoliday(dateItem, isToday, isChecked, value, calendarValue)
                                    :
                                    (isPublicHoliday &&
                                        <div className={`${classes.boldFont} ${classes.publicCloseDay} rbc-public-date ${classes.dateFont}`}
                                            style={publicHolidaysStyle}
                                            ref={publicContainerRef}>
                                            <div ref={publicRemarkRef} className={`${classes.ellipsisFont}`}>{dateItem.remarks}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }}
        </Consumer >
    );
}

const RenderMonthEvent = (props) => {
    const classes = useStyles();
    const { capacity, available, releaseTime } = props.event;
    const valueWrapperRef = React.useRef(null);
    const valueRef = React.useRef(null);
    const { setAutoScroll } = useAutoHozScroll(valueRef, true, 60);

    React.useEffect(() => {
        if (valueRef.current && valueWrapperRef.current) {
            if (valueRef.current.clientWidth > valueWrapperRef.current.clientWidth) {
                valueRef.current.classList.add(classes.longValueClass);
                setAutoScroll(true);
            }
        }
    });
    const textMap = {
        COL_RESEARCHER: 'Project',
        SELF_RESEARCHER: 'Self-service'
    }
    return (
        <Consumer>
            {(calendarValue) => {
                const color = isBefore(new Date(), new Date(releaseTime)) ? '#b1b1b1' : '#15a9d2'
                return (
                    <div
                        // className={`${classes.eventWrapper}`}
                        ref={valueWrapperRef}
                    >
                        <Grid container justifyContent={"center"} style={{ height: 28 }}>
                            {available && <CustomTooltip title={<Grid >
                                <Grid >Release Date:</Grid>
                                <Grid >{releaseTime && format(new Date(releaseTime), 'dd MMM yyyy HH:mm')}</Grid>
                            </Grid>}><Grid item container style={{ width: "71px", border: `1px solid ${color}`, borderRadius: '15px', backgroundColor: '#fff', fontSize: 18, color: color, height: 28, overflow: 'hidden' }}>
                                <span style={{ width: 30, background: color, borderRadius: '50%', height: 28, marginLeft: -1, textAlign: 'center' }}><img src={UserIcon} style={{ height: 15, marginTop: -5 }} /></span>
                                <span style={{ width: 40, textAlign: 'center' }}>{capacity}</span>
                            </Grid></CustomTooltip>}
                        </Grid>
                        {available.split(',').map((item) => <div style={{ color: '#000', fontSize: 13, lineHeight: '14px', paddingLeft: 8 }}>{textMap[item]}</div>)}
                        
                    </div>
                );
            }}
        </Consumer>
    );
}

const CalendarComponent = props => {
    const { viewDate, isEdit, selectedDateList, addNo, removeNo, replaceNo, checkedDate, setCapacityList,
        setBookingsList, setWeekChecked, weekCheckedList, setViewDate, submitResult, releaseTimes,
        isRelease, todayHasBooking, setIsLoading, projectMaxQuota, isRejectDate, reloadFlag, weekRdoDisplays, resetLeftData } = props;

    const classes = useStyles();

    const [eventList, setEventList] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [bookingRes, setBookingRes] = React.useState([]);
    const bookingResMapRef = React.useRef(null);

    const { isDirty } = useDirtyCheck();

    const setRemarks = props.selectedProjectValue.setRemarks;

    React.useEffect(() => {
        setRemarks(null);
        setSelectedDate(null);
        setAnchorEl(null);
    }, [setRemarks, viewDate])

    React.useEffect(() => {
        // console.log(bookingRes, selectedDateList, bookingResMapRef.current, 'filterListFunc')
        const newList = [...bookingRes];
        const tMap = {}
        selectedDateList.length && selectedDateList.forEach(item => {
            tMap[format(item.date, 'yyyy-MM-dd')] = item;
            if (!bookingResMapRef.current[format(item.date, 'yyyy-MM-dd')]) {
                newList.push(item)
            }
        })
        const l = newList.map(item => {
            const date = item.bookingDate ? new Date(item.bookingDate) : item.date
            const t = tMap[format(date, 'yyyy-MM-dd')]
            const capacity = t && t.capacity ? t.capacity : item.capacity;
            const remarks = t ? t?.remarks : item.remarks;
            const available = t ? t.available : item.available;
            return {
                start: item?.bookingDate || item.date,
                end: item?.bookingDate || item.date,
                // title: item.status,
                capacity: capacity,
                quota: item.quota,
                remarks: remarks,
                available: available,
                uavailableReason: item.uavailableReason,
                releaseTime: item.releaseTime
            }
        })
        setEventList(l)

    }, [selectedDateList, selectedDateList.length])

    const projectCode = props.selectedProjectValue.selectedProject?.projectCode || '';
    const pt = props.selectedProjectValue.selectedProject?.projectType;
    const setDateList = props.selectedProjectValue.setDateList;
    const expiredDate = props.selectedProjectValue.selectedProject?.effectiveEndDate;
    const handleOpenDirtyCheck = props.selectedProjectValue.handleOpenDirtyCheck;
    // console.log('props.selectedProjectValue.effectiveEndDate', props.selectedProjectValue.effectiveEndDate)
    const getDateRangeBaseInfo = React.useCallback(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);


        const params = {
            startDate: format(minDate, "yyyy-MM-dd"),
            endDate: format(maxDate, "yyyy-MM-dd"),
            pt: ''
        }
        // console.log('props.selectedProjectValue.selectedProject?.projectType',)
        setIsLoading(true);
        axios.get('/booksetup/capacity-full', { params }).then((capacityRes) => {
            setDateList(capacityRes.data);
            setRemarks(capacityRes.data.filter(item => Boolean(item.remarks)));
            setCapacityList(capacityRes.data)
            if (capacityRes.data) {
                const tempMap = {};
                setEventList(capacityRes.data.map(item => {
                    tempMap[format(new Date(item.bookingDate), 'yyyy-MM-dd')] = item;
                    return {
                        start: item.bookingDate,
                        end: item.bookingDate,
                        // title: item.status,
                        capacity: item.capacity,
                        quota: item.quota,
                        remarks: item.remarks,
                        available: item.available,
                        uavailableReason: item.uavailableReason,
                        releaseTime: item.releaseTime
                    }
                }));
                bookingResMapRef.current = tempMap
                setBookingsList(capacityRes.data);
                setBookingRes(capacityRes.data)
            }
            else {
                setEventList([])
                setBookingsList([]);
                setBookingRes([])
            }
            setIsLoading(false);
            // axios.get('/bookrequest/get-user-bookings', { params: { ...params, projectCode } }).then((bookingsRes) => {
            //     setDateList(capacityRes.data);
            //     setRemarks(capacityRes.data.filter(item => Boolean(item.remarks)));
            //     setCapacityList(capacityRes.data)

            //     if (bookingsRes.data) {
            //         setEventList(bookingsRes.data.map(item => ({
            //             start: item.bookDate,
            //             end: item.bookDate,
            //             title: item.status,
            //             capacity: item.capacity,
            //             rejectReason: item.rejectReason
            //         })));
            //         setBookingsList(bookingsRes.data);
            //         setBookingRes(bookingsRes.data)
            //     }
            //     else {
            //         setEventList([])
            //         setBookingsList([]);
            //         setBookingRes([])
            //     }
            //     setIsLoading(false);
            // }).catch(err => {
            //     setIsLoading(false);
            //     console.log(err);
            // })
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    }, [projectCode, pt, setBookingsList, setCapacityList, setDateList, setRemarks, viewDate])

    useEffect(() => {
        getDateRangeBaseInfo();
    }, [getDateRangeBaseInfo, reloadFlag])

    const handleSelectDate = (e) => {
        props.handleSelectDate(e);

    }

    return (
        <Provider value={{
            anchorEl,
            setAnchorEl,
            dateList: props.selectedProjectValue.dateList,
            eventList,
            selectedDate,
            setSelectedDate,
            isEdit,
            selectedDateList,
            addNo,
            removeNo,
            replaceNo,
            checkedDate,
            viewDate,
            submitResult,
            releaseTimes,
            isRelease,
            todayHasBooking,
            expiredDate,
            setViewDate,
            isDirty,
            handleOpenDirtyCheck,
            selectedProject: props.selectedProjectValue.selectedProject,
            bookingRes,
            projectMaxQuota,
            isRejectDate,
            resetLeftData
        }}>
            <Calendar
                localizer={localizer}
                date={viewDate}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                view={Views.MONTH}
                dateList={props.selectedProjectValue.dateList}
                components={{
                    month: {
                        // event: (e) => renderEvent(e),
                        dateHeader: RenderDateHeader,
                        header: (e) => renderHeader(e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays),
                        event: RenderMonthEvent,
                    },
                    toolbar: RenderToolBar,//(e) => renderToolBar(e, classes, setViewDate, isRelease, props.selectedProjectValue.handleOpenDirtyCheck, isDirty),
                    dateCellWrapper: RenderRateCellWrapper//(e) => RenderRateCellWrapper(e, isEdit, replaceNo, selectedDateList)
                }}
                selectable={isEdit}
                onSelectSlot={(e) => handleSelectDate(e)}
                onSelecting={(e) => handleSelectDate(e)}
            />
            <Popover
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DialogTitle style={{ backgroundColor: '#FFEFE1' }} className={classes.remarkTitleFont}>
                    <span>{selectedDate && format(selectedDate, 'dd MMM yyyy')}{` (${new Date(selectedDate).toDateString().substring(0, 3)})`}</span>
                    <IconButton className={classes.closeButton} onClick={() => setAnchorEl(null)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.remarksContent}>
                    <Typography component={'div'}>Remarks:</Typography>
                    <Typography component={'div'}>
                        {props.selectedProjectValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(selectedDate)))?.remarks}
                    </Typography>
                </DialogContent>
            </Popover>
        </Provider>
    );
}

export default CalendarComponent;