
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    label: {
        minHeight: 32,
        padding: '6px 10px 4px 20px',
        lineHeight: '1.2',
        backgroundColor: '#f3f3f3',
    },
    title: {
        minHeight: 32,
        padding: '6px 10px 4px 20px',
        lineHeight: '1.2',
        backgroundColor: '#f3f3f3',
        fontWeight: 600
    },
    rightContent: {
        padding: '4px 10px 4px 20px',
        // lineHeight: '32px',
        lineHeight: '1.2',
        color: '#b1b1b1',
        fontSize:14
    },
    projectHeader: {
        backgroundColor: '#646464',
        // borderRadius: 3,
        textAlign: 'center',
        color: '#fff',
        // marginTop: 3
    },
    projectTh: {
        padding: '5px 0',
        borderRight: '1px solid #3d3d3d'
    },
    lineBottom: {
        // minHeight: 40,
        borderBottom: '1px solid #dcdcdc'
    },
    round: {
        display: 'inline-block',
        width: 5,
        height: 5,
        borderRadius: "50%",
        backgroundColor: '#b1b1b1',
        marginRight: 10,
        verticalAlign: 'middle'
    }
}));
export default function ExamplePart3(props) {
    const {
        open,
        onClose,
        onCancel,
        cancelText} = props;
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            disableEscapeKeyDown
            aria-labelledby="ExamplePart3-dialog"
            maxWidth={'md'}
        >
            <Grid container style={{width: "100%", fontSize: 16, fontWeight: 600, padding: 10}} className={classes.lineBottom}>
                Example
            </Grid>
            <Grid container style={{ maxHeight: 500, overflow: 'auto' }}>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.projectHeader}>
                    <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Part III</Grid>
                    <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Model &nbsp;<i>(Please fill in this part if any model(s) is/are requested in Part II)</i></Grid>
                </Grid>
                
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3}  className={classes.label}>Model:</Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>e.g. Cancer Detection Model</Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                        File name:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                    e.g. Model1_Random_Forest.bin/ Model2_glm.bin/ Model3_ResNet.bin
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3}  className={classes.label}>
                    Full file path:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                    {`e.g. {parent directory}/model/ Model1_Random_Forest.bin`}
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Algorithm:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                    e.g. Random Forest/ Logistic regression/ CNN ResNet 3
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Mechanism of the model:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        e.g. The model processes the patient data (image & structured data) and combined CNN & machine learning algorithm, and returns the probability of result to analyze the cancer. 
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Programming Language:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                    e.g. Python
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3}  className={classes.label}>
                    Sample script file:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        { `e.g. {parent directory}/folder/sample_script.py`}
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                        Script to load the model and run the prediction:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                            e.g. <br/>
                            Import abc
                            {`model_path <- ‘../model/Model1_Random_Forest.bin’`}<br/>
                            {`saved_model <- abc.loadModel(model_path)`}<br/>
                            saved_model.predict([Age, Age group])
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={`${classes.title} ${classes.lineBottom}`}>
                    Input &nbsp;<i style={{fontSize: 13, fontWeight: 'normal', lineHeight: '18px'}}>(Please provide details of the input parameters of the above model.)</i>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                        Input value, type and description:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g.<br/>
                        “Age”, integer, [10 – 100]<br/>
                            <span className={classes.round} />Must greater than 10-year-old<br/>
                            <span className={classes.round} />If “Age” is empty, then provide the average age from the sampling.<br/>
                            <span className={classes.round} />If “Age” is empty, then exclude it.<br/>
                            “Age group”, categorical data, [1: 11-20yr, 2: 21-30yr, 3: 31-40yr, …, 8: { `>`}80yr]<br/>
                        “Chest X-ray image”, integer array, size of the image must greater than 10KB<br/>
                        <span className={classes.round} />If “Chest X-ray image” is empty, then exclude it.
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Supplementary information: (optional)

                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g.<br/>
                            Chest X-ray image is converted from binary to encode64 integer array.
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={`${classes.title} ${classes.lineBottom}`}>
                    Output &nbsp;<i style={{fontSize: 13, fontWeight: 'normal', lineHeight: '18px'}}> (Please provide details of the result or output of the above model.)</i>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                        Output value and type:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g.<br/>
                            “Classification classes”, integer<br/>
                            “Predicted score”, double<br/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                        Description/ Explanation of output:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g.<br/>
                        “Classification classes” is [0: chest, 1: skull, 2: ankle].<br/>
                        “Predicted score” is the probability of having cancer.<br/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Usage of output:
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g.<br/>
                        “Classification classes” can be used to classify if the image is a chest, skull or ankle image. The images which are classified as “chest” will be chosen for further cancer detection.<br/>
                        “Predicted score” of inputted patient chest X-ray can be used to predict probability of having lung cancer {`(>0.7 = high probability; <0.5 = low probability)`}.<br/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} className={classes.label}>
                    Supplementary information:(optional)
                    </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <div>
                        e.g. Chest X-ray image is the encode64 integer array.
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ paddingBottom: '16px', marginTop: 25 }}>
                <Button color="primary"
                    style={{ backgroundColor: '#646464', padding: '10 24px', color: '#fff', marginLeft: 10, textTransform: 'none', }}
                    onClick={() => {
                        onCancel && onCancel();
                        onClose();
                    }}>
                    {cancelText || 'Back'}
                </Button>
            </Grid>
        </Dialog>
    )
}
