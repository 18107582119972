import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
    addMonths
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , isAfter,
    isBefore
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    // , Dialog
    , DialogTitle
    , DialogContent
    , Popover
    , TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'CustomAxios';
import { useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import useAutoHozScroll from 'hooks/useAutoHozScroll';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import SmallCalendar from './SmallCalendar';

const { Provider, Consumer } = React.createContext();
export const useStyles = makeStyles(theme => ({
    boldFont: {
        fontWeight: 'bold'
    },
    rejectedWrapper: {
        backgroundColor: '#e14631',
        padding: '0 4px',
    },
    // rejectedWrapper: {
    //     backgroundColor: '#e14631',
    //     width: '100%',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    eventWrapper: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    approvedWrapper: {
        backgroundColor: '#67c451',
        padding: '0 4px',
    },
    longValueClass: {
        width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            'display': 'none'
        },
        overflowX: 'auto',
        overflowY: 'none',
        scrollbarWidth: 'none',
    },
    // approvedWrapper: {
    //     backgroundColor: '#69C456',
    //     width: 'calc(100% - 25px)',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    approvedValueWrapper: {
        backgroundColor: '#67c451',
        display: 'inline-block',
        width: '25px',
        textAlign: 'center'
    },
    closeFont: {
        color: '#0064E7'
    },
    closeWrapper: {
        display: 'flex',
        backgroundColor: '#C9E0FF',
        width: '100%',
        // position: 'absolute',
        bottom: '0',
        textAlign: 'left',
        padding: '0 4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    moreWrapper: {
        position: 'absolute',
        right: 0,
        backgroundColor: '#C9E0FF',
        alignItems: 'center',
        height: '100%'
    },
    ellipsisFont: {
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    publicCloseDay: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        textAlign: 'left',
        backgroundColor: '#FEE2E2',
        padding: '0 4px',
        // overflow: 'hidden'
    },
    checkedBackground: {
        backgroundColor: '#FFEFE2'
    },
    invalidBackground: {
        backgroundColor: '#ffdbdb'
    },
    witheBackground: {
        backgroundColor: 'white'
    },
    eachNo: {
        '& input': {
            textAlign: 'center'
        }
    },
    dateFont: {
        fontSize: '14px !important'
    },
    remarkTitleFont: {
        color: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute !important',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    remarksContent: {
        backgroundColor: '#EEF5FF',
        color: '#0064E7',
        margin: 16,
        minWidth: '300px'
    },
    specQuicksand: {
        fontFamily: 'Quicksand-Medium',
        // fontSize: '13px !important'
    }
}));

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const RenderToolBar = (props) => {
    const [cAnchorEl, setCAnchorEl] = React.useState(null);
    const monthClick = (e) => {
        setCAnchorEl(e.currentTarget);
    }

    const closeSmallCalendar = () => {
        setCAnchorEl(null);
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { date, label } = props;
                    const setViewDate = calendarValue.setViewDate;
                    const isDirty = calendarValue.isDirty;
                    const handleOpenDirtyCheck = calendarValue.handleOpenDirtyCheck;
                    const toNextMonth = () => {
                        const handleNextMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), 1));
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleNextMonth })
                            return;
                        }
                        handleNextMonth();
                    }

                    const toLastMonth = () => {
                        const handleLastMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), -1));
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleLastMonth })
                            return;
                        }
                        handleLastMonth();
                    }

                    return (<>
                        <Grid container alignItems={'baseline'} style={{ height: 40 }}>
                            <Grid item>
                                <IconButton onClick={toLastMonth}>
                                    <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ minWidth: '125px', textAlign: 'center', cursor: 'pointer', height: 34, lineHeight: '33px', border: '1px solid #d2d2d2', borderRadius: 3 }} onClick={(e) => monthClick(e)}>
                                {label}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={toNextMonth} >
                                    <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Popover
                            open={Boolean(cAnchorEl)}
                            onClose={() => setCAnchorEl(null)}
                            anchorEl={cAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SmallCalendar anchorEl={cAnchorEl} date={date} isDirty={isDirty} close={closeSmallCalendar} handleOpenDirtyCheck={handleOpenDirtyCheck} setViewDate={setViewDate} />
                        </Popover>
                    </>);
                }
            }
        </Consumer >
    );
}

const renderHeader = (e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays) => {
    const { label, date } = e;
    const isSunday = date.getDay() === 0;
    const style = {
        height: '25px'
    }
    return (
        <Grid style={style} container alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
            <Grid item style={{ width: '38px', textAlign: 'left' }}>
                {
                    isEdit && weekRdoDisplays[date.getDay()].display &&
                    <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => setWeekChecked(date.getDay())}>
                        {
                            weekCheckedList[date.getDay()].checked ?
                                <CheckCircleIcon style={{ width: '20px', height: '20px' }} /> :
                                <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                        }
                    </IconButton>
                }
            </Grid>
            <Grid item className={`${isSunday && 'rbc-public-date'} ${classes.dateFont} ${classes.specQuicksand}`}>
                {label?.substring(0, 3).toUpperCase()}
            </Grid>
            <Grid item style={{ width: '38px' }}>

            </Grid>
        </Grid>
    )
}

const RenderDateHeader = (props) => {
    // console.log('ss', setSeAble)
    const classes = useStyles();
    const getItem = (calendarValue, date, status) => {
        return calendarValue.bookingRes.find(item => isEqual(startOfDay(new Date(item.bookDate)), startOfDay(date)) && item.status === status)
    }
    const getDateItemColor = (dateItem) => {
        if (dateItem?.fullPercent >= 0.8) {
            return '#ED3A4E'
        }
        if (dateItem?.fullPercent >= 0.6) {
            return '#FFB448'
        }
        return '#50E4A7'

    }
    const getClassName = (isClose) => {
        return `${isClose ? 'rbc-public-date' : 'rbc-normal-date'} ${classes.boldFont} ${classes.dateFont}`
    }
    const renderCheckBox = (cannotBook, calendarValue, isPreviousDate, isRelease, selectedItem, date, dateItem) => {
        const isRejectDate = calendarValue.isRejectDate(date);
        const expiredDate = calendarValue.expiredDate;
        const notExpired = startOfDay(new Date(expiredDate)) >= startOfDay(date);
        return <Grid item style={{ width: '38px', textAlign: 'left' }}>
            {
                !cannotBook && calendarValue.isEdit && !isPreviousDate && isRelease(dateItem?.releaseTime) && notExpired && !isRejectDate &&
                <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => calendarValue.checkedDate(date)}>
                    {
                        selectedItem ? <CheckCircleIcon style={{ width: '20px', height: '20px' }} />
                            : <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                    }
                </IconButton>
            }
        </Grid>
    }

    const renderContentBox = (calendarValue, bookingItem, selectedItem) => {
        return calendarValue.isEdit && bookingItem && !selectedItem ?
            <Grid container justifyItems={'center'} alignItems={'center'} textAlign={'center'}>
                <Grid item xs={3} md={3} lg={3}>

                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <TextField label="" variant="outlined"
                        className={`${classes.witheBackground} ${classes.eachNo}`}
                        value={bookingItem?.capacity}
                        disabled
                    />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                </Grid>
            </Grid> : null
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { label, date } = props;
                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(date));
                    if (isOtherMonth)
                        return <></>;
                    const selectedProject = calendarValue.selectedProject;
                    const bookingItem = getItem(calendarValue, date, 'APPROVED');
                    const rejectedItem = getItem(calendarValue, date, 'REJECTED');
                    const dateItem = calendarValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(date)));
                    const isClose = dateItem && !dateItem.available;
                    const todayBooking = calendarValue.todayHasBooking(date);
                    const isFull = dateItem && dateItem.quota >= dateItem.capacity && !todayBooking;
                    const style = {
                        height: '25px'
                    }
                    const selectedItem = calendarValue.selectedDateList.find(item => isEqual(startOfDay(new Date(item.date)), startOfDay(date)));
                    const cannotBook = !dateItem || isClose || isFull;
                    const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(date));
                    const isRelease = calendarValue.isRelease;
                    // console.log(calendarValue, date)
                    const projectMaxQuota = calendarValue.projectMaxQuota;



                    return (
                        <>
                            <Grid container style={style} alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
                                {renderCheckBox(cannotBook, calendarValue, isPreviousDate, isRelease, selectedItem, date, dateItem)}
                                <Grid item className={getClassName(isClose && isRelease(dateItem?.releaseTime))}>
                                    {Number(label)}
                                </Grid>
                                <Grid item style={{ width: '38px' }}>
                                    {
                                        dateItem && !isClose && isRelease(dateItem?.releaseTime) ?
                                            (
                                                <div style={{ width: '6px', height: '6px', borderRadius: '50%', backgroundColor: getDateItemColor(dateItem), margin: '0 auto' }}></div>
                                            )
                                            : null
                                    }
                                </Grid>
                            </Grid>
                            {
                                selectedItem ?
                                    <Grid container justifyItems={'center'} alignItems={'center'} textAlign={'center'}>
                                        <Grid item xs={3} md={3} lg={3}>
                                            {
                                                selectedProject?.projectType === 'COL_RESEARCHER' &&
                                                <IconButton size="small" style={{ padding: '0px' }} onClick={() => calendarValue.removeNo(date)}
                                                    disabled={selectedItem.no === 0 || (calendarValue.isPreviousDate(startOfDay(date)) && selectedItem.no === 1)}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <TextField label="" variant="outlined"
                                                className={`${classes.witheBackground} ${classes.eachNo}`}
                                                value={selectedItem?.no}
                                                onChange={(e) => calendarValue.replaceNo(date, e.target.value)}
                                                disabled={selectedProject?.projectType === 'SELF_RESEARCHER'}
                                                onBlur={e => calendarValue.replaceNo(date, (!calendarValue.isPreviousDate(startOfDay(date)) && e.target.value) || bookingItem?.capacity || '1')}
                                            />
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            {
                                                selectedProject?.projectType === 'COL_RESEARCHER' &&
                                                <IconButton size="small" style={{ padding: '0px' }}
                                                    onClick={() => calendarValue.addNo(date)}
                                                    disabled={selectedItem.no >= projectMaxQuota}>
                                                    <AddCircleOutlineOutlinedIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid> : null
                            }
                            {
                                renderContentBox(calendarValue, bookingItem, selectedItem)
                            }
                            {
                                rejectedItem && calendarValue.isEdit &&
                                <div className='rbc-row-segment'>
                                    <div className='rbc-event'>
                                        <div className='rbc-event-content'>
                                            <div
                                                className={`${classes.eventWrapper} ${classes.rejectedWrapper}`}

                                            >
                                                <Typography component={'div'} className={`${classes.dateFont}`}>
                                                    {'Rejected'}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    );
                }
            }
        </Consumer >
    )
}

const RenderRateCellWrapper = (props) => {
    const classes = useStyles();
    const remarkContainer = React.useRef(null);
    const remarksRef = React.useRef(null);

    const publicRemarkRef = React.useRef(null);
    const publicContainerRef = React.useRef(null);
    const dateContainerRef = React.useRef(null);

    const { setAutoScroll: setPubAutoScroll } = useAutoHozScroll(publicRemarkRef, true, 60);
    const { setAutoScroll: setDateAutoScroll } = useAutoHozScroll(remarksRef, true, 60);

    React.useEffect(() => {
        if (publicRemarkRef.current && publicContainerRef.current &&
            publicRemarkRef.current.clientWidth > publicContainerRef.current.clientWidth) {
            publicRemarkRef.current.classList.add(classes.longValueClass);
            setPubAutoScroll(true);
        }
    });

    React.useEffect(() => {
        if (remarksRef.current && dateContainerRef.current &&
            remarksRef.current.clientWidth > dateContainerRef.current.clientWidth) {
            remarksRef.current.classList.add(classes.longValueClass);
            setDateAutoScroll(true);
        }
    });


    const getCbg = (calendarValue, dateItem, value, isChecked) => {
        const todayBooking = calendarValue.todayHasBooking(value);
        const isClose = dateItem && !dateItem.available;
        const isFull = dateItem && dateItem.quota >= dateItem.capacity && !todayBooking;
        const cannotBook = !dateItem || isClose || isFull;
        const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(value));
        const isInvalid = calendarValue.submitResult && calendarValue.submitResult.invalidList &&
            calendarValue.submitResult.invalidList.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(value)));
        const expiredDate = calendarValue.expiredDate;
        const isRelease = calendarValue.isRelease;
        const isExpired = startOfDay(new Date(expiredDate)) < startOfDay(value);
        const isRejectDate = calendarValue.isRejectDate(value);

        let cbg = ''
        if (cannotBook || isPreviousDate || !isRelease(dateItem?.releaseTime) || isExpired || isRejectDate) cbg = 'rbc-off-range-bg';
        else if (calendarValue.isEdit && isChecked) cbg = classes.checkedBackground;
        if (isInvalid && isChecked) cbg = classes.invalidBackground;
        return cbg;
    }
    const renderNotPublicHoliday = (dateItem, isToday, isChecked, value, calendarValue) => {
        return <>
            <div style={{
                display: 'flex', position: 'absolute',
                bottom: '0',
                width: isToday ? 'calc(100% - 4px)' : '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginLeft: isToday ? '2px' : '0',
                marginBottom: isToday ? '2px' : '0'
            }}>
                <div style={{ width: isChecked && !calendarValue.isPreviousDate(startOfDay(value)) ? 'calc(100% - 18px)' : 'calc(100%)', position: 'relative', height: 21 }} ref={remarkContainer}>
                    {dateItem.remarks && calendarValue.isRelease(dateItem?.releaseTime) &&
                        <div className={classes.closeWrapper} ref={dateContainerRef}>
                            <div
                                className={`${classes.boldFont} ${classes.closeFont} rbc-public-date ${classes.dateFont} ${classes.ellipsisFont}`}
                                ref={remarksRef}
                            >
                                {dateItem.remarks}
                            </div>
                        </div>
                    }
                </div>
                {
                    isChecked && !calendarValue.isPreviousDate(startOfDay(value)) ?
                        <div style={{ backgroundColor: dateItem.remarks ? '#C9E0FF' : 'unset', display: 'flex', pointerEvents: 'auto' }}>
                            <IconButton
                                size="small"
                                style={{ padding: '0px' }}
                                onClick={() => calendarValue.replaceNo(value, 0)}
                            >
                                <DeleteIcon style={{ width: '18px', height: '21px', color: '#000' }} />
                            </IconButton>
                        </div>
                        : null
                }
            </div>
        </>
    }

    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { value } = props;
                    const dateItem = calendarValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(value)));



                    const isPublicHoliday = dateItem?.remarks.toLowerCase().includes('public holiday');

                    const isToday = isEqual(startOfDay(new Date()), startOfDay(value));
                    const isChecked = calendarValue.selectedDateList.some(item => isEqual(startOfDay(new Date(item.date)), startOfDay(value)));
                    const publicHolidaysStyle = isToday ? { width: 'calc(100% - 4px)', marginLeft: '2px', marginBottom: '2px' } : null



                    let cbg = getCbg(calendarValue, dateItem, value, isChecked);

                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(value));

                    return (
                        <div
                            className={`rbc-day-bg  ${cbg}`}
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div style={{
                                position: 'relative'
                                , height: '100%'
                                // , border: isToday && '2px solid #0064E7'
                                // , zIndex: isToday && '5'
                                // , pointerEvents: isToday && 'none'
                                // , backgroundColor: isToday && 'rgba(0,0,0,0)'
                            }} className={isOtherMonth ? 'otherMonth' : ''}>
                                {props.children}
                                {dateItem && !isPublicHoliday
                                    ?
                                    renderNotPublicHoliday(dateItem, isToday, isChecked, value, calendarValue)
                                    // <>
                                    //     <div style={{
                                    //         display: 'flex', position: 'absolute',
                                    //         bottom: '0',
                                    //         width: isToday ? 'calc(100% - 4px)' : '100%',
                                    //         justifyContent: 'space-between',
                                    //         alignItems: 'flex-end',
                                    //         marginLeft: isToday ? '2px' : '0',
                                    //         marginBottom: isToday ? '2px' : '0'
                                    //     }}>
                                    //         <div style={{ width: isChecked ? 'calc(100% - 18px)' : 'calc(100%)', position: 'relative', height: 21 }} ref={remarkContainer}>
                                    //             {dateItem.remarks &&
                                    //                 <div className={classes.closeWrapper} ref={dateContainerRef}>
                                    //                     <div
                                    //                         className={`${classes.boldFont} ${classes.closeFont} rbc-public-date ${classes.dateFont} ${classes.ellipsisFont}`}
                                    //                         ref={remarksRef}
                                    //                     >
                                    //                         {dateItem.remarks}
                                    //                     </div>
                                    //                 </div>
                                    //             }
                                    //         </div>
                                    //         {
                                    //             isChecked ?
                                    //                 <div style={{ backgroundColor: dateItem.remarks ? '#C9E0FF' : 'unset', display: 'flex', pointerEvents: 'auto' }}>
                                    //                     <IconButton
                                    //                         size="small"
                                    //                         style={{ padding: '0px' }}
                                    //                         onClick={() => calendarValue.replaceNo(value, 0)}
                                    //                     >
                                    //                         <DeleteIcon style={{ width: '18px', height: '21px', color: '#000' }} />
                                    //                     </IconButton>
                                    //                 </div>
                                    //                 : null
                                    //         }
                                    //     </div>
                                    // </>
                                    :
                                    (isPublicHoliday &&
                                        <div className={`${classes.boldFont} ${classes.publicCloseDay} rbc-public-date ${classes.dateFont}`}
                                            style={publicHolidaysStyle}
                                            ref={publicContainerRef}>
                                            <div ref={publicRemarkRef} className={`${classes.ellipsisFont}`}>{dateItem.remarks}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }}
        </Consumer >
    );
}

const RenderMonthEvent = (props) => {
    const classes = useStyles();
    const { capacity, title, rejectReason } = props.event;
    const valueWrapperRef = React.useRef(null);
    const valueRef = React.useRef(null);
    const { setAutoScroll } = useAutoHozScroll(valueRef, true, 60);

    React.useEffect(() => {
        if (valueRef.current && valueWrapperRef.current) {
            if (valueRef.current.clientWidth > valueWrapperRef.current.clientWidth) {
                valueRef.current.classList.add(classes.longValueClass);
                setAutoScroll(true);
            }
        }
    });

    return (
        <Consumer>
            {(calendarValue) => {
                if (calendarValue.isEdit) return <></>;

                return (
                    <div
                        className={`${classes.eventWrapper} ${title === 'APPROVED' && classes.approvedWrapper} ${title === 'REJECTED' && classes.rejectedWrapper}`}
                        ref={valueWrapperRef}
                    >
                        <Typography component={'div'} className={`${classes.dateFont}`}>
                            {title.substring(0, 1).toUpperCase()}{title.substring(1).toLowerCase()}{title === 'REJECTED' && rejectReason && <>:</>}
                        </Typography>
                        {title === 'APPROVED' &&
                            <Typography component={'div'} className={`${classes.dateFont}`} ref={valueRef}>
                                {capacity}
                            </Typography>
                        }
                        {title === 'REJECTED' && rejectReason &&
                            <Typography component={'div'} className={`${classes.dateFont}`} ref={valueRef}>
                                {rejectReason}
                            </Typography>
                        }
                    </div>
                );
            }}
        </Consumer>
    );
}

const CalendarComponent = props => {
    const { viewDate, isEdit, selectedDateList, addNo, removeNo, replaceNo, checkedDate, setCapacityList,
        setBookingsList, setWeekChecked, weekCheckedList, setViewDate, submitResult, releaseTimes,
        isRelease, todayHasBooking, setIsLoading, projectMaxQuota, isRejectDate, reloadFlag, weekRdoDisplays,
        isPreviousDate } = props;

    const classes = useStyles();

    const [eventList, setEventList] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [bookingRes, setBookingRes] = React.useState([]);


    const { isDirty } = useDirtyCheck();

    const setRemarks = props.selectedProjectValue.setRemarks;

    React.useEffect(() => {
        setRemarks(null);
        setSelectedDate(null);
        setAnchorEl(null);
    }, [setRemarks, viewDate])

    const projectCode = props.selectedProjectValue.selectedProject?.projectCode || '';
    const pt = props.selectedProjectValue.selectedProject?.projectType;
    const projectId = props.selectedProjectValue.selectedProject?.projectId;
    const setDateList = props.selectedProjectValue.setDateList;
    const expiredDate = props.selectedProjectValue.selectedProject?.effectiveEndDate;
    const handleOpenDirtyCheck = props.selectedProjectValue.handleOpenDirtyCheck;
    // console.log('props.selectedProjectValue.effectiveEndDate', props.selectedProjectValue.effectiveEndDate)
    const getDateRangeBaseInfo = React.useCallback(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);


        const params = {
            startDate: format(minDate, "yyyy-MM-dd"),
            endDate: format(maxDate, "yyyy-MM-dd"),
            pt: pt,
            projectId: projectId
        }
        // console.log('props.selectedProjectValue.selectedProject?.projectType',)
        setIsLoading(true);
        axios.get('/booksetup/capacity-full', { params }).then((capacityRes) => {
            axios.get('/bookrequest/get-user-bookings', { params: { ...params, projectCode } }).then((bookingsRes) => {
                const newCapacityRes = capacityRes?.data.filter((item) => !item.isLimit) || [];
                setDateList(newCapacityRes);
                setRemarks(newCapacityRes.filter(item => Boolean(item.remarks)));
                setCapacityList(newCapacityRes)

                if (bookingsRes.data) {
                    setEventList(bookingsRes.data.map(item => ({
                        start: item.bookDate,
                        end: item.bookDate,
                        title: item.status,
                        capacity: item.capacity,
                        rejectReason: item.rejectReason
                    })));
                    setBookingsList(bookingsRes.data);
                    setBookingRes(bookingsRes.data)
                }
                else {
                    setEventList([])
                    setBookingsList([]);
                    setBookingRes([])
                }
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
            })
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    }, [projectCode, pt, setBookingsList, setCapacityList, setDateList, setRemarks, viewDate])

    useEffect(() => {
        getDateRangeBaseInfo();
    }, [getDateRangeBaseInfo, reloadFlag])

    const handleSelectDate = (e) => {
        props.handleSelectDate(e);

    }

    return (
        <Provider value={{
            anchorEl,
            setAnchorEl,
            dateList: props.selectedProjectValue.dateList,
            selectedDate,
            setSelectedDate,
            isEdit,
            selectedDateList,
            addNo,
            removeNo,
            replaceNo,
            checkedDate,
            viewDate,
            submitResult,
            releaseTimes,
            isRelease,
            todayHasBooking,
            expiredDate,
            setViewDate,
            isDirty,
            handleOpenDirtyCheck,
            selectedProject: props.selectedProjectValue.selectedProject,
            bookingRes,
            projectMaxQuota,
            isRejectDate,
            isPreviousDate
        }}>
            <Calendar
                localizer={localizer}
                date={viewDate}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                view={Views.MONTH}
                dateList={props.selectedProjectValue.dateList}
                components={{
                    month: {
                        // event: (e) => renderEvent(e),
                        dateHeader: RenderDateHeader,
                        header: (e) => renderHeader(e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays),
                        event: RenderMonthEvent,
                    },
                    toolbar: RenderToolBar,//(e) => renderToolBar(e, classes, setViewDate, isRelease, props.selectedProjectValue.handleOpenDirtyCheck, isDirty),
                    dateCellWrapper: RenderRateCellWrapper//(e) => RenderRateCellWrapper(e, isEdit, replaceNo, selectedDateList)
                }}
                selectable={isEdit}
                onSelectSlot={(e) => handleSelectDate(e)}
                onSelecting={(e) => handleSelectDate(e)}
            />
            <Popover
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DialogTitle style={{ backgroundColor: '#FFEFE1' }} className={classes.remarkTitleFont}>
                    <span>{selectedDate && format(selectedDate, 'dd MMM yyyy')}{` (${new Date(selectedDate).toDateString().substring(0, 3)})`}</span>
                    <IconButton className={classes.closeButton} onClick={() => setAnchorEl(null)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.remarksContent}>
                    <Typography component={'div'}>Remarks:</Typography>
                    <Typography component={'div'}>
                        {props.selectedProjectValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(selectedDate)))?.remarks}
                    </Typography>
                </DialogContent>
            </Popover>
        </Provider>
    );
}

export default CalendarComponent;