import React from 'react';
import {
    startOfMonth
    , isEqual
    , addYears
    , getYear
    , getMonth
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
    monthEach: {
        padding: '3px 0',
        fontSize: '15px',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        margin: '5px auto',
        width: '52px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f58220',
            color: '#fff'
        }
    },
    active: {
        backgroundColor: '#f58220',
        color: '#fff'
    }
}));

const SmallCalendar = props => {
    const { date, close, isDirty, setViewDate, handleOpenDirtyCheck, resetLeftData} = props;
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(date);


    const toNextYear = () => {
        const handleNextYear = () => {
            
            setSelectedDate(addYears(startOfMonth(selectedDate), 1));
        };
        handleNextYear();
    }

    const toLastYear = () => {
        const handleLastYear = () => {
            setSelectedDate(addYears(startOfMonth(selectedDate), -1));
        };
        handleLastYear();
    }

    const monthClick = (m) => {
        const handleLastMonth = () => {
            setViewDate(new Date(getYear(selectedDate), m));
            close();
            resetLeftData && resetLeftData();
        };
        if (isDirty) {
            handleOpenDirtyCheck({ leaveCallback: handleLastMonth })
            return;
        }
        handleLastMonth()
    }

    const isActive = (m) => {
        return isEqual(new Date(getYear(selectedDate), m), date) ? classes.active: ''
    }

    return (
        <Grid container style={{ width: '200px', color: '#000', padding: 10 }}>
            <Grid item container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <IconButton onClick={toLastYear}>
                        <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                    </IconButton>
                </Grid>
                <Grid item style={{ minWidth: '110px', textAlign: 'center' }}>
                    {getYear(selectedDate)}
                </Grid>
                <Grid item>
                    <IconButton onClick={toNextYear}>
                        <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container alignItems={'center'}>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(0)}`} onClick={() => monthClick(0)}>Jan</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(1)}`} onClick={() => monthClick(1)}>Feb</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(2)}`} onClick={() => monthClick(2)}>Mar</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(3)}`} onClick={() => monthClick(3)}>Apr</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(4)}`} onClick={() => monthClick(4)}>May</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(5)}`} onClick={() => monthClick(5)}>Jun</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(6)}`} onClick={() => monthClick(6)}>Jul</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(7)}`} onClick={() => monthClick(7)}>Aug</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(8)}`} onClick={() => monthClick(8)}>Sep</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(9)}`} onClick={() => monthClick(9)}>Oct</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(10)}`} onClick={() => monthClick(10)}>Nov</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.monthEach} ${isActive(11)}`} onClick={() => monthClick(11)}>Dec</div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SmallCalendar;