import React from 'react';
import {
    format
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import { makeStyles } from '@material-ui/core/styles';
import PointerIcon from 'images/pointer.png'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt } from 'react-router-dom';
import { useMsg } from 'hooks/MsgProvider';
import LoadingScreen from 'components/common/LoadingScreen';
import useSysParam from 'hooks/UseSysParam';

const useStylesCur = makeStyles((theme) => ({
    videoBox: {
        fontSize: 14,
        borderRadius: '5px',
        backgroundColor: 'white'
    }
}));


export default function OnlineInformation(props) {
    const classes = useStyles();
    const curClasses = useStylesCur();
    const { setErrMsg } = useMsg();
    const [isLoading, setIsLoading] = React.useState(false);
    const [videoOutstandingCourses, setVideoOutstandingCourse] = React.useState([]);
    const [videoCompletedCourses, setVideoCompletedCourse] = React.useState([]);
    const { paramTextValue: formLinks } = useSysParam('evaluation_form_links');

    useEffect(() => {
        loadVideos();
    }, [])

    const loadVideos = () => {
        setIsLoading(true);
        axios.get(`/onlineInformation/get-videos`, null).then((res) => {
            const vodeos = res.data;
            if (vodeos && vodeos.length) {
                setVideoOutstandingCourse(vodeos.filter(e => e.isCompleted === false))
                setVideoCompletedCourse(vodeos.filter(e => e.isCompleted === true))
            }
            setIsLoading(false);
        }).catch(err => {
            setErrMsg(err)
            if (err) {
                console.log(err);
            }
            setIsLoading(false);
        });
    }

    function exitFull() {
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msExitFullscreenElement
        ) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    const handleCompletedVideo = (id, isCancelFullScreen = false) => {
        if (isCancelFullScreen)
            exitFull();
        axios.post('/onlineInformation/completed-video', { id: id }).then(res => {
            loadVideos();
        }).catch(err => {
            setErrMsg(err)
            if (err) {
                console.log(err);
            }
        })
    }

    const videoRoleColor = {
        "SELF_RESEARCHER": { backgroundColor: "#67c451" },
        "COL_RESEARCHER": { backgroundColor: "#e14631" },
        "WS_PARTICIPANT": { backgroundColor: "#5b90d0" }
    }

    const renderVideoRole = (role) => {
        return <div className={classes.videoRole} style={{ ...videoRoleColor[role.code] }}>
            {role.desc}
        </div>
    }
    const play = (e) => {
        pauseOtherVideo(e)
    }

    const pauseOtherVideo = (element) => {
        let allVideoElement = document.getElementsByTagName('video');
        if (allVideoElement && allVideoElement.length > 0) {
            for (let i = 0; i < allVideoElement.length; i++) {
                if (allVideoElement[i].id != element.target.id) {
                    allVideoElement[i].pause()
                }
            }
        }
    }

    const renderRoleContainer = (roles) => {
        var containers = roles.map((r, ridx) => {
            if (ridx == 0)
                return <Grid key={ridx} item style={{ marginBottom: 3 }}>
                    {renderVideoRole(r)}
                </Grid>
            else
                return <Grid key={ridx} item style={{ marginLeft: 3, marginBottom: 3 }}>
                    {renderVideoRole(r)}
                </Grid>
        });
        return containers;
    }

    return (
        <Paper className={classes.paper} style={{ minHeight: 'calc(100vh - 94.5px)', boxShadow: 'unset', minWidth: 1000 }}>
            <Grid container direction={'row'} style={{ height: '100%' }}>
                <Grid container justifyContent='space-between' alignItems={'center'}>
                    <Grid item xs={'auto'}>
                        <Typography variant="h5" gutterBottom xs={12}>
                            eCourse
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{
                            textAlign: 'right', backgroundColor: '#fffbdb',
                            fontSize: '13px', padding: '5px 15px', color: '#000'
                        }}>
                        <div>Please complete all courses and fill in the evaluation form <a target={'_blank'} href={formLinks}><u>here</u></a> before you book or access HADCL.</div>
                    </Grid>
                </Grid>
                <Grid container direction='column'>
                    {
                        videoOutstandingCourses && videoOutstandingCourses.length ?
                            <Grid item xs container alignItems='flex-start' className={curClasses.videoBox} style={{ background: '#F9EFEF', marginTop: 10 }}>
                                <Grid item container alignItems='center' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Outstanding Course
                                    </Grid>

                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <Grid container alignItems='center' style={{ overflow: 'hidden' }}>
                                        <Grid item container justifyContent={'flex-start'} style={{ minWidth: 917 }}>
                                            {
                                                videoOutstandingCourses.map(video => {
                                                    return <Grid container item key={video.id} xs={3} style={{ padding: '0 10px 10px 10px', minWidth: 305 }}>
                                                        <Grid item style={{ width: '100%' }}>
                                                            <video id={'video' + video.id} preload="metadata" controls onEnded={() => handleCompletedVideo(video.id, true)} onPlay={play}
                                                                controlsList="nodownload" style={{ height: '100%', width: '100%' }}>
                                                                <source src={video.links} />
                                                                Your browser does not support HTML5 video.
                                                            </video>
                                                        </Grid>
                                                        <Grid item style={{ margin: '5px 0', lineHeight: '18px' }}>
                                                            {video.title}
                                                        </Grid>
                                                        <Grid item container>
                                                            {
                                                                video.roles && renderRoleContainer(video.roles)
                                                                // video.roles && video.roles.map((r, ridx) => {
                                                                //     if (ridx == 0)
                                                                //         return <Grid key={ridx} item style={{ marginBottom: 3 }}>
                                                                //             {renderVideoRole(r)}
                                                                //         </Grid>
                                                                //     else
                                                                //         return <Grid key={ridx} item style={{ marginLeft: 3, marginBottom: 3 }}>
                                                                //             {renderVideoRole(r)}
                                                                //         </Grid>
                                                                // })
                                                            }
                                                        </Grid>
                                                    </Grid>;
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* </Grid> */}
                            </Grid> : null
                    }

                    {
                        videoCompletedCourses && videoCompletedCourses.length ?
                            <Grid item xs container alignItems='flex-start' className={curClasses.videoBox} style={{ background: '#E4E4E4', marginTop: 10 }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Completed Course
                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <Grid container alignItems='center' style={{ overflow: 'hidden' }}>
                                        <Grid item container justifyContent={'flex-start'} style={{ minWidth: 917 }}>
                                            {
                                                videoCompletedCourses.map(video => {
                                                    return <Grid container item key={video.id} xs={3} style={{ padding: '0px 10px 10px 10px', minWidth: 305 }} direction='column'>
                                                        <Grid item style={{ width: '100%' }}>
                                                            <video id={'video' + video.id} preload="metadata" controls onEnded={() => handleCompletedVideo(video.id, true)} onPlay={play}
                                                                controlsList="nodownload" style={{ height: '100%', width: '100%' }}>
                                                                <source src={video.links} />
                                                                Your browser does not support HTML5 video.
                                                            </video>
                                                        </Grid>
                                                        <Grid item style={{ margin: '5px 0', lineHeight: '18px' }}>
                                                            {video.title}
                                                        </Grid>
                                                        <Grid item style={{ color: '#888888', fontSize: 12, margin: '-5px 0px 5px 0px' }}>
                                                            {`Completed on ${format(new Date(video.completedDate), 'dd MMM yyyy')}`}
                                                        </Grid>
                                                        <Grid item container>
                                                            {
                                                                video.roles && renderRoleContainer(video.roles)
                                                                // video.roles && video.roles.map((r, ridx) => {
                                                                //     if (ridx == 0)
                                                                //         return <Grid key={ridx} item style={{ marginBottom: 3 }}>
                                                                //             {renderVideoRole(r)}
                                                                //         </Grid>
                                                                //     else
                                                                //         return <Grid key={ridx} item style={{ marginLeft: 3, marginBottom: 3 }}>
                                                                //             {renderVideoRole(r)}
                                                                //         </Grid>
                                                                // })
                                                            }
                                                        </Grid>
                                                    </Grid>;
                                                })
                                            }
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid> : null
                    }
                </Grid>
            </Grid>
            <LoadingScreen open={isLoading} />
        </Paper>
    );
}
