import { Link, useHistory, Link as DomLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import headerIcon from '../../../images/headerIcon.png';
import HomeIcon from '../../../images/home_mobile.svg';
import ArtboardIcon from '../../../images/Artboard.png';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Height } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
const useStyles = makeStyles((theme) => ({
    navBg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '#fff',
        padding: '0 20px',
        zIndex: 10,
        overflow: 'auto'
    },

}));
function NavigationMobile(props) {
    const { goTop, location, clickMenu, contactEmail, toggleDrawer, doLogout, emails, classes, addresses, getMenus } = props;
    const classes2 = useStyles();

    const [contactOpen, setContactOpen] = React.useState(false);

    return (
        <div className={classes2.navBg}>
            <Divider />
            <List style={{ width: '100%', padding: 0 }}>
                <ListItem button style={{ width: '100%', padding: 0 }}>
                    <NavLink onClick={() => {
                        toggleDrawer();
                        goTop()
                    }} tag={Link} to={'/homepage'}
                        active={location.pathname === '/homepage'} style={{ width: '100%', padding: '5px 0', display: 'flex', alignItems: 'center', color: '#000' }}
                    >

                        <ListItemIcon style={{ minWidth: 'auto', paddingRight: 10 }}><img style={{ width: 20 }} src={HomeIcon} /></ListItemIcon>
                        <ListItemText primary={'Homepage'} />
                        <ChevronRightIcon />
                    </NavLink>
                </ListItem>
                <Divider />
                {
                    getMenus().map((item) => {
                        return (
                            <React.Fragment key={item.code}>
                                <ListItem button key={item.code} style={{ width: '100%', padding: 0, overflow: 'hidden' }}>
                                    {
                                        item.code === 'TM_CONTACT_US' ?
                                            <NavLink
                                                onClick={() => {
                                                    toggleDrawer();
                                                    goTop()
                                                }}
                                                style={{ width: '100%', padding: '5px 0', display: 'flex', alignItems: 'center', color: '#000' }}
                                                active={location.pathname === item.path}
                                            > <a style={{ textDecoration: 'none', color: '#000', width: '100%', padding: '5px 0', display: 'flex', alignItems: 'center' }} href={`mailto:${contactEmail || ''}`}>
                                                    <ListItemIcon style={{ minWidth: 'auto', paddingRight: 10 }}><img style={{ width: 20 }} src={item.icon} /></ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                    <ChevronRightIcon />
                                                </a></NavLink> :
                                            <NavLink
                                                onClick={() => {
                                                    toggleDrawer()
                                                    clickMenu(item.code)
                                                }}
                                                tag={Link}
                                                style={{ width: '100%', padding: '5px 0', display: 'flex', alignItems: 'center', color: '#000' }}
                                                to={item.path}
                                                active={location.pathname === item.path}
                                            >
                                                <ListItemIcon style={{ minWidth: 'auto', paddingRight: 10 }}><img style={{ width: 20 }} src={item.icon} /></ListItemIcon>
                                                <ListItemText primary={item.title} />
                                                <ChevronRightIcon />
                                            </NavLink>
                                    }

                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{ width: "100%", padding: 0 }}
            >

                <ListItem
                    button onClick={() => { setContactOpen(!contactOpen) }}
                    style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0, fontFamily: 'Quicksand-SemiBold' }}
                >
                    <ListItemIcon style={{ minWidth: 'auto', paddingRight: 4 }}><img style={{ width: 30, marginLeft: '-4px', }} src={ArtboardIcon} /></ListItemIcon>

                    <ListItemText primary={'Contact Us'} />
                    {contactOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={contactOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14 }}>
                            <span style={{ lineHeight: 'initial' }}>For HADCL Portal related enquiries,</span>
                        </ListItem>
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14, paddingRight: 0 }}>
                            <EmailIcon style={{ color: '#212529', marginRight: 5 }} className={classes.emailIcon} /> please contact <a style={{ textDecoration: 'underline', marginLeft: 5 }} href={`mailto:${emails.length && emails[0].content}`}>{emails.length && emails[0].content}</a>
                        </ListItem>
                        {contactEmail && <>
                            <ListItem style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14 }}>
                                <span style={{ lineHeight: 'initial' }}>For other enquiries,</span>
                            </ListItem>
                            <ListItem style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14, paddingRight: 0 }}>
                                <EmailIcon style={{ color: '#212529', marginRight: 5 }} className={classes.emailIcon} /> please contact <a style={{ textDecoration: 'underline', marginLeft: 5 }} href={`mailto:${contactEmail}`}>{contactEmail}</a>
                            </ListItem>
                        </>}

                        <ListItem style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14 }}>
                            <LocationOnIcon style={{ color: '#212529', marginRight: 5 }} className={classes.emailIcon} /> <span style={{ lineHeight: 'initial' }}>{addresses.length && addresses[0].content}</span>
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                {/* <ListItem
                    button onClick={() => { setFunctionsOpen(!functionsOpen) }}
                    style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}
                >
                    FUNCTIONS
                    {functionsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={functionsOpen} timeout="auto" unmountOnExit>

                    {
                        sitemaps.map(sm => {
                            return (
                                <ListItem key={sm.code} style={{paddingTop: 0, paddingBottom: 0, fontSize: 14}}>
                                    <ArrowForwardIosIcon style={{ fontSize: '12px'}} />&nbsp;<NavLink  onClick={() => {
                                                toggleDrawer(); 
                                                clickMenu(sm.code)
                                            }} tag={DomLink} style={{color: '#000', paddingTop: 0, paddingBottom: 0, paddingLeft: 0}} to={sm.path}>{sm.title}</NavLink>
                                </ListItem>
                            );
                        })
                    }
                </Collapse>
                <Divider />
                <ListItem
                    button onClick={() => { setUsefulOpen(!usefulOpen) }}
                    style={{ justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0 }}
                >
                    USEFUL LINKS
                    {usefulOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={usefulOpen} timeout="auto" unmountOnExit>

                    {
                        usefulLinks.map(ul => {
                            return (
                                <ListItem key={ul.id} style={{paddingTop: 0, paddingBottom: 0, fontSize: 14}}>
                                    <ArrowForwardIosIcon style={{ fontSize: '12px' }} />&nbsp;<a style={{ textDecoration: 'underline', color: '#000', paddingTop: 0, paddingBottom: 0 }} href={ul.href} target='_blank'>{ul.title}</a>
                                </ListItem>
                            );
                        })
                    }
                </Collapse>
                <Divider /> */}
            </List>
            <div style={{ paddingTop: 20 }}>
                <div>
                    <NavLink onClick={() => {
                        toggleDrawer();
                    }} tag={Link} to={'/change-password'}
                        style={{ padding: '0', color: '#828282' }}
                    >
                        Change Password
                        <ChevronRightIcon />
                    </NavLink>
                </div>
                <div><span onClick={doLogout} style={{ color: '#828282' }}>Logout <ChevronRightIcon /></span></div>
            </div>
        </div >
    );
}


export default NavigationMobile;
