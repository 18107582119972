import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import React from 'react';
import axios from '../../CustomAxios';
import { UserContext } from '../../hooks/UserContext';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import {
    Grid,
    IconButton,
    Divider,
    TextField,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectComponent from './SelectComponent';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 0,
    },
    title: {

        height: 40,
        paddingLeft: 20,
        fontSize: 13,
        color: '#f58220',
        backgroundColor: '#ffefe1'
    },
    content: {
        padding: 8,
        paddingBottom: 15,
        width: 500,
    },
    header: {
        // padding: '10px 0 8px',
        paddingTop: 10,
        fontSize: 13,
        fontWeight: 600,
        color: '#000'
    },
    divider: {
        width: '100%'
    },
    tr: {
        paddingTop: '8px',
        fontSize: 13,
        fontWeight: 600,
        color: '#000'
    },
    td: {
        paddingTop: 8,
        "& p": {
            marginBottom: 0
        }
    },
    c1: {
        width: 185,
        paddingLeft: 12,
        paddingRight: 10
    },
    c2: {
        flex: 1,
        paddingRight: 8,
        overflow: 'hidden',
        wordBreak: 'break-all'
    },
    c3: {
        width: 95
    },
    access: {
        width: 58,
        height: 18,
        backgroundColor: '#5f9053',
        textAlign: 'center',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        flex: 1
    },
    totalBox: {
        marginTop: 12,
        height: 40,
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: '#f1f1f1'
    },
    scrollBox: {
        maxHeight: 200,
        // position: 'absolute',
        width: '100%',
        // height: '100%',
        // top: 0,
        // left: 0,
        // right: 0,
        overflow: 'auto'
    },
    iconOpen: {
        transform: 'rotate(90deg)'
    },
    ResText: {
        backgroundColor: 'white',

        '& .MuiInputBase-root': {
            fontSize: '13px !important'
        },
        '& input': {
            paddingLeft: 5
        },
    },
    witheBackground: {
        backgroundColor: 'white'
    },
    eachNo: {
        '& input': {
            textAlign: 'center',
            width: 40,
            boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
            backgroundColor: '#f3f3f3',
            borderRadius: 3
        },
        '& fieldset': {
            borderColor: 'transparent'
        }
    },
    button: {
        backgroundColor: '#15A9D2 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    cancelButton: {
        backgroundColor: '#646464 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
}));
export default function MorePopup(props) {
    const classes = useStyles();

    const { moreList, moreDate, isEdit, replaceSelectedData, capacityResMapRef, projectMaxQuota } = props;
    const [dataList, setDataList] = React.useState(moreList)
    const [reason, setReason] = React.useState('')
    const [isReject, setIsReject] = React.useState(false)
    const [optionsList, setOptionsList] = React.useState([]);
    const listMapRef = React.useRef(null);
    const divNodeRef = React.useRef(null);

    React.useEffect(() => {
        const params = {}
        axios.get('/bookRequest/get-add-projects', { params }).then(({ data: result }) => {
            setOptionsList(result)
        }).catch(err => {
            console.log(err);
        });
    }, [])

    const getTotal = () => {
        let t = 0;
        const tMap = {}
        dataList.forEach(element => {
            if (isEdit) {
                tMap[element.projectId] = element;
                if (element.status === 'APPROVED') {
                    t = t + element.capacity;
                }
            } else {
                t = t + element.capacity;
            }
        });
        listMapRef.current = tMap;
        let n = capacityResMapRef.current[new Date(moreDate)]?.capacity
        let comNt = n - t < 0 ? 0 : n - t;
        return isEdit ? comNt : t
    }
    React.useEffect(() => {
        if (isEdit && divNodeRef.current) {

            divNodeRef.current.scrollTop = divNodeRef.current.scrollHeight;
        }
    }, [dataList.length])
    const handleAdd = () => {

        const t = [...dataList];
        t.push({
            start: moreDate,
            end: moreDate,
            title: '',
            capacity: 1,
            rejectReason: '',
            isFirst: false,
            status: 'APPROVED',
            attendance: '',
            projectId: '',
            userTypeCode: '',
            remarks: '',
            isCreate: true,
            bookDate: moreDate
            // status: 'A',
            // attendance: item.attendance,
            // projectId: item.projectId,
            // userTypeCode: item.userTypeCode,
        })
        setDataList(t)
    }

    const handleSelectChange = (field, value, index) => {
        const t = [...dataList]
        t[index][field] = value?.projectId || '';
        t[index]['title'] = value?.refNum || '';
        t[index]['refno'] = value?.refNum || '';
        t[index]['userTypeCode'] = value?.userTypeCode || '';
        t[index]['capacity'] = value ? 1 : 0;
        setDataList(t);
    }

    const handleRejectAll = () => {
        let t = [...dataList]
        t = t.map((item) => {
            item.status = 'REJECTED'
            // item.rejectReason = reason
            return item
        })
        setIsReject(true)
        setDataList(t);
    }

    const handleChange = (field, value, index) => {

        const t = [...dataList]
        t[index][field] = value;
        setDataList(t);
    }
    const handleReplaceNo = (field, value, index) => {
        const regexPattern = /^\d+$/;
        let result = regexPattern.test(value);
        if (value && !result) return;
        if (value)
            value = parseInt(value) > projectMaxQuota ? projectMaxQuota : parseInt(value);
        handleChange(field, value, index)
    }

    const onSubmit = () => {
        if (dataList.length > 0) {
            dataList.forEach((item) => {
                if (item.projectId) {
                    if (item.status === 'REJECTED' && reason) {
                        item.rejectReason = reason
                    }
                }
            })
            // console.log(dataList, 'dataList')
            replaceSelectedData(moreDate, dataList);
            props.onClose();
        }
    }

    const renderView = () => {
        return <Grid container direction='column' className={classes.content} style={{ width: 515 }}>
            <Grid container direction='column' className={classes.table}>
                <Grid container className={classes.header}>
                    <Grid item className={classes.c1} >Project/Self-Service no.</Grid>
                    <Grid item className={classes.c2} >Remarks</Grid>
                    <Grid item className={classes.c3} style={{ textAlign: 'center' }}>No. of access</Grid>
                </Grid>
                <Grid container style={{ flex: 1, position: 'relative' }}>
                    <Grid className={classes.scrollBox}>

                        {dataList.map((item, index) => {
                            return <Grid key={index} container className={classes.tr}>
                                <Divider className={classes.divider} />
                                <Grid className={`${classes.c1} ${classes.td}`} item alignItems={'center'} >
                                    {item.title}
                                    {item.isFirst && <StarIcon style={{ width: 15, height: 16, paddingLeft: 2, marginTop: '-2px' }} />}
                                </Grid>
                                <Grid className={`${classes.c2} ${classes.td}`} item alignItems={'center'} ><div title={item.remarks} dangerouslySetInnerHTML={{__html: item.remarks}}></div></Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center' }} alignItems={'center'} className={`${classes.c3} ${classes.td}`}>
                                    <span className={classes.access}>{item.capacity}</span>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Divider style={{ paddingTop: 8 }} className={classes.divider} />
            <Grid container alignItems={'center'} className={classes.totalBox}>
                <Grid item className={classes.c1} >Total Reserved:</Grid>
                <Grid item className={classes.c2} ></Grid>
                <Grid item className={classes.c3} style={{ display: 'flex', justifyContent: 'center' }}><span className={classes.access} style={{ backgroundColor: '#646464' }}>{getTotal()}</span></Grid>
            </Grid>
        </Grid>
    }


    const renderEdit = () => {
        const total = getTotal();
        const nameStyle = { width: 150, paddingLeft: 8, paddingRight: 8, wordBreak: 'break-all' }
        const statusStyle = { width: 108, paddingRight: 8 }
        const reasonStyle = { width: 100 }
        const numStyle = { width: 120, paddingLeft: 8 }
        const options = [{
            value: 'APPROVED',
            name: 'Approved',
        }, {
            value: 'CANCELLED',
            name: 'Cancelled',
        }, {
            value: 'REJECTED',
            name: 'Rejected',
        }]
        const options2 = optionsList.map(item => {
            const temp = { ...item }
            if (listMapRef.current[temp.projectId]) {
                temp.disabled = true;
            }
            return temp
        })
        return <Grid container direction='column' className={classes.content} style={{ width: 505 }}>
            <Grid container direction='column' className={classes.table}>
                <Grid container className={classes.header}>
                    <Grid item style={nameStyle} >Project/Self- <br />Service no.</Grid>
                    <Grid item style={statusStyle} >Status</Grid>
                    <Grid item style={reasonStyle} >Reason</Grid>
                    <Grid item style={{ ...numStyle, textAlign: 'center' }} >No. of access</Grid>
                </Grid>
                <Grid container style={{ flex: 1, position: 'relative' }}>
                    <Grid className={classes.scrollBox} ref={divNodeRef}>

                        {dataList.map((item, index) => {
                            return <Grid key={index} container className={classes.tr} alignItems='center'>
                                <Divider className={classes.divider} />
                                <Grid style={nameStyle} className={` ${classes.td}`} item alignItems={'center'} >
                                    {!item.isCreate && (item.title)}
                                    {item.isCreate && <SelectComponent valueField={'projectId'} nameField={'refNum'} fullWidth value={item.projectId} onChange={(v, valueObj) => { handleSelectChange('projectId', valueObj, index) }} options={options2} IconComponent={ChevronRightIcon}
                                        style={{ fontSize: 13 }}
                                        classes={{
                                            iconOpen: classes.iconOpen,
                                        }} />}
                                </Grid>
                                <Grid style={statusStyle} className={` ${classes.td}`} item alignItems={'center'} >
                                    <SelectComponent displayEmpty={false} fullWidth value={item.status} onChange={(v) => { handleChange('status', v, index) }} options={options} IconComponent={ChevronRightIcon}
                                        style={{ fontSize: 13 }}
                                        classes={{
                                            iconOpen: classes.iconOpen,
                                        }} />
                                </Grid>
                                <Grid item style={reasonStyle} alignItems={'center'} className={` ${classes.td}`}>
                                    {item.status === 'REJECTED' && <TextField
                                        className={classes.ResText}
                                        value={item.rejectReason}
                                        style={{ width: '100%', }}
                                        onChange={(e) => { handleChange('rejectReason', e.target.value, index) }}

                                    />}
                                </Grid>
                                <Grid item container style={numStyle} alignItems={'center'} className={` ${classes.td}`}>
                                    <Grid item xs={3} md={3} lg={3}>
                                        {
                                            <IconButton disabled={item.capacity <= 1} size="small" style={{ padding: '0px' }} onClick={() => { handleChange('capacity', item.capacity - 1, index) }}
                                            >
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                    <Grid item style={{ width: 45 }}>
                                        <TextField label="" variant="outlined"
                                            className={`${classes.witheBackground} ${classes.eachNo}`}
                                            value={item.capacity}
                                            onChange={(e) => { handleReplaceNo('capacity', e.target.value, index) }}

                                            onBlur={(e) => { handleReplaceNo('capacity', ((e.target.value * 1 === 0 || item.userTypeCode === 'SELF_RESEARCHER') ? 1 : e.target.value), index) }}
                                        // disabled={selectedProject?.projectType === 'SELF_RESEARCHER'}
                                        // onBlur={e => calendarValue.replaceNo(date, e.target.value || bookingItem?.capacity || '1')}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        {
                                            <IconButton disabled={(item.userTypeCode === 'SELF_RESEARCHER' && item.capacity >= 1) || item.capacity >= projectMaxQuota} size="small" style={{ padding: '0px' }}
                                                onClick={() => { handleChange('capacity', item.capacity + 1, index) }}
                                            // disabled={}
                                            >
                                                <AddCircleOutlineOutlinedIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Divider style={{ paddingTop: 8 }} className={classes.divider} />
            <Grid container style={{ paddingTop: 8, paddingLeft: 6 }}>
                <Button
                    // variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleAdd}
                    startIcon={<AddIcon fontSize="small" />}
                >
                    New
                </Button>
            </Grid>
            <Grid container alignItems={'center'} className={classes.totalBox}>
                <Grid item style={{ paddingLeft: 14 }}>Remaining capacity of the lab</Grid>
                <Grid item className={classes.c2} ></Grid>
                <Grid item className={classes.c3} ><span className={classes.access} style={{ backgroundColor: '#646464' }}>{total}</span></Grid>
            </Grid>
            <Grid container style={{ paddingTop: 8, paddingLeft: 6 }}>
                <Button
                    // variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleRejectAll}
                >
                    Reject all
                </Button>
            </Grid>
            <Grid container alignItems={'center'} style={{ opacity: isReject ? 1 : 0.3, fontSize: 13, paddingLeft: 15 }}>
                <Grid item xs={6}>Reason of reject (for batch reject):</Grid>
                <Grid item xs={6}>
                    <TextField label="" variant="outlined"
                        onChange={(e) => { setReason(e.target.value) }}
                        value={reason}
                        disabled={!isReject}
                        className={classes.ResText}
                        style={{ paddingLeft: 5 }}
                    // disabled={selectedProject?.projectType === 'SELF_RESEARCHER'}
                    // onBlur={e => calendarValue.replaceNo(date, e.target.value || bookingItem?.capacity || '1')}
                    />
                </Grid>
            </Grid>
            <Divider style={{ paddingTop: 8 }} className={classes.divider} />
            <Grid container justifyContent={'center'} alignItems={'center'} style={{ paddingTop: 8 }}>
                <Grid item>
                    <Button className={classes.button} onClick={onSubmit}>Update</Button>
                </Grid>
                <Grid item style={{ marginLeft: '5px' }}>
                    <Button className={classes.cancelButton} onClick={props.onClose}>Cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    }


    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            // maxWidth="xs"
            className={classes.dialog}
            aria-labelledby="form-dialog-title"
        >
            <Grid container className={classes.title} >
                <Grid item container alignItems={'center'} style={{ flex: 1 }}>
                    {format(new Date(moreDate), 'd MMM yyyy')}&nbsp;({(new Date(moreDate) + '').substring(0, 3)})
                </Grid>
                <Grid item >
                    <IconButton onClick={props.onClose}>
                        <CloseIcon style={{ color: '#000' }} fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            {isEdit ? renderEdit() : renderView()}
        </Dialog>
    )
}
