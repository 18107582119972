
import React, { useState, useEffect, createContext } from 'react';
import axios from 'CustomAxios';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useUser from 'hooks/UseFindUser';
import RenderPcOrMobile from '../RenderPcOrMobile';
import NotSignOutPC from 'view/pc/accessQrCode/NotSignOut.pc';
import NotSignOutMobile from 'view/mobile/accessQrCode/NotSignOut.mobile';
import { useMsg } from 'hooks/MsgProvider';
export const NotSignOutViewContext = createContext({});
const hourOption = [{
    value: '09',
    name: '09'
}, {
    value: '10',
    name: '10'
}, {
    value: '11',
    name: '11'
}, {
    value: '12',
    name: '12'
}, {
    value: '13',
    name: '13'
}, {
    value: '14',
    name: '14'
}, {
    value: '15',
    name: '15'
}, {
    value: '16',
    name: '16'
}, {
    value: '17',
    name: '17'
}, {
    value: '18',
    name: '18'
}, {
    value: '19',
    name: '19'
}, {
    value: '20',
    name: '20'
}]

export default function NotSignOut(props) {
    const { setCanQrCode } = props;
    const { user } = useUser();
    const [open, setOpen] = useState(false);
    const { setSuccessMsg } = useMsg();
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');

    const [isCorrect, setIsCorrect] = useState(false);
    const [isComply, setIsComply] = useState(false);
    const [startDate, setStartDate] = useState('')

    const [id, setId] = useState(null);



    const [isError, setIsError] = useState(false);
    const [isLeavingTimeError, setIsLeavingTimeError] = useState(false);

    const renderMinuteOption = () => {
        const res = [];
        if (hour === '20') {
            res.push({ value: '00', name: '00' });
            return res;
        }
        for (let index = 0; index < 60; index++) {
            let v = index < 10 ? '0' + index : index
            res.push({
                value: v,
                name: v
            })
        }
        return res;
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }



    const getLeakage = () => {
        axios.get('/attendance/get-leakage-attendance', {}).then(({ data: result }) => {
            const { endTime, startTime } = result
            if (result && !endTime) {
                setId(result.id)
                setOpen(true)
                setStartDate(startTime)
            } else {
                setCanQrCode(true)
                setOpen(false)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        // console.log(user)
        if (user) {
            if (user?.roles.some((role) => role.toUpperCase().includes("COL_RESEARCHER") || role.toUpperCase().includes("SELF_RESEARCHER"))) {
                getLeakage()

            }
        }
    }, [user])




    const submitValidate = () => {
        setIsLeavingTimeError(false)
        if (!hour || !minute || !isCorrect || !isComply) {
            setIsError(true);
            return false;
        }
        if (isAfter(new Date(startDate), new Date(format(new Date(startDate), 'yyyy/MM/dd') + ' ' + hour + ':' + minute))) {
            setIsError(false);
            setIsLeavingTimeError(true)
            return false
        }
        return true
    }

    const submit = () => {
        if (!submitValidate()) {
            return;
        }
        setIsError(false);
        setIsLeavingTimeError(false);
        const params = {
            id,
            leavingTime: format(new Date(startDate), 'yyyy-MM-dd') + 'T' + hour + ':' + minute
        }
        axios.post('/attendance/submit-leakage-attendance', params).then(({ data: result }) => {
            if (result) {
                setSuccessMsg('Submitted Successfully!');
                getLeakage()
                setMinute('')
                setHour('')
                setIsCorrect(false)
                setIsComply(false)
            }
        }).catch(err => {
            console.log(err);
        });
    }



    const fDate = startDate && format(new Date(startDate), 'dd/MM/yyyy');

    return <NotSignOutViewContext.Provider value={{
        open, minute, setMinute, hour, setHour, isCorrect, setIsCorrect
        , isComply, setIsComply
        , isError, isLeavingTimeError
        , renderMinuteOption, checkIcon, submit
        , fDate, hourOption
    }}>
        <RenderPcOrMobile pc={<NotSignOutPC />} mobile={<NotSignOutMobile />} />
    </NotSignOutViewContext.Provider>
}
