import React, { useEffect } from 'react';
import {
    format
    , startOfDay
    , isAfter,
    isBefore,
    addDays,
    addYears
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField,
    Select,
    Input,
    RadioGroup,
    MenuItem,
    ListItemText,
    FormControl
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DatePicker from '@material-ui/lab/DatePicker';
import { useStyles } from './styles';
import isValid from 'date-fns/isValid';
import { useLocation, Prompt, useHistory } from 'react-router-dom';
import { getSearchParams } from 'components/common/utils';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import LoadingScreen from 'components/common/LoadingScreen';
import useModal from "hooks/useModal";
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { warning, confirmReportDeadline, confirmReportDeadline_8, success } from 'components/common/Constants';
import SelectComponent from 'components/common/CommonSelect';
import ReportDraftEditor from 'components/progressReports/researcher/detail/ReportDraftEditor';
import CheckIcon from '@material-ui/icons/Check';
import EditUser from 'components/userManagement/editUser.dialog';
import useUser from 'hooks/UseFindUser';

export default function ProjectDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const uLocation = useLocation()
    const showModal = useModal();
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState({});
    const [errorMsg, setErrorMsg] = React.useState('')
    const [nextPath, setNextPath] = React.useState(null);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editId, setEditId] = React.useState(null);
    const [projectId, setProjectId] = React.useState(null);
    const draftEditorRef = React.useRef({})
    const { user } = useUser();
    const [state, setBaseState] = React.useState({
        projectObjectives: [],
        piUsers: [],
        statusCode: ''
    })
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const { setUser } = React.useContext(UserContext);
    const [isCreate, setIsCreate] = React.useState(true)
    const isNoBack = React.useRef(null);
    const [isChangedStatus, setIsChangedStatus] = React.useState(false);
    const [projectLists, setProjectLists] = React.useState([])

    function getProjectLists() {
        axios.get('/user/get-colProject-list')
            .then(({ data }) => {
                setProjectLists(data);
            })
    }

    React.useEffect(function effectFunction() {
        getProjectLists();
    }, []);

    useEffect(() => {
        // console.log(state.accessEndDate)
        if (!isChangedStatus && isDirty) {
            const newState = {}
            if (state.accessStartDate && isBefore(new Date(), new Date(state.accessStartDate))) {
                newState.statusCode = 'PENDING'
            } else if (state.accessStartDate && state.accessEndDate && isAfter(new Date(), new Date(state.accessStartDate)) && isBefore(new Date(), new Date(state.accessEndDate))) {
                newState.statusCode = 'ACTIVE'
            } else if (state.accessEndDate && isAfter(new Date(), new Date(state.accessEndDate))) {
                newState.statusCode = 'ACCESS_COMPLETED'
            }
            if (state.portalAccessEndDate && isAfter(new Date(), new Date(state.portalAccessEndDate))) {
                newState.statusCode = 'INACTIVE'
            }
            setBaseState((oldState) => ({
                ...oldState,
                ...newState
            }))
        }
    }, [state.accessEndDate, state.accessStartDate, state.portalAccessEndDate, isDirty])
    useEffect(() => {
        if (isDirty) {
            const newState = {}
            if (state.studyCompletionDate && state.accessEndDate) {
                if (isAfter(new Date(state.accessEndDate), new Date(state.studyCompletionDate))) {
                    newState.portalAccessEndDate = addYears(new Date(state.accessEndDate), 2)
                } else {
                    newState.portalAccessEndDate = addYears(new Date(state.studyCompletionDate), 2)
                }
            } else if (state.accessEndDate) {
                newState.portalAccessEndDate = addYears(new Date(state.accessEndDate), 2)
            } else if (state.studyCompletionDate) {
                newState.portalAccessEndDate = addYears(new Date(state.studyCompletionDate), 2)
            }
            setBaseState((oldState) => ({
                ...oldState,
                ...newState
            }))
        }

    }, [state.accessEndDate, state.studyCompletionDate])

    const changeRolePermission = [
        {
            role: 'HADCL_OFFICER',
            targetRoles: ["isHADCL", "isDutyOfficer", "isSelfService", "isCollaborate", "isWSParticipant"]
        },
        {
            role: 'DUTY_OFFICER',
            targetRoles: ["isDutyOfficer", "isSelfService", "isCollaborate", "isWSParticipant"]
        },
        {
            role: 'SYSTEM_ADMIN',
            targetRoles: ["isSystemAM", "isHADCL", "isDutyOfficer", "isPMOMember", "isSelfService", "isCollaborate", "isWSParticipant"]
        }
    ];

    const roleDisabled = (roleChb) => {
        for (let i = 0; i < changeRolePermission.length; i++) {
            if (user.roles.some(e => e === changeRolePermission[i].role)) {
                if (changeRolePermission[i].targetRoles.some(f => f === roleChb))
                    return false;
            }
        }
        return true;
    }

    const handleSetBaseState = (newState) => {
        setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    // console.log(isDirty, 'isDirty---------------')
    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {
            // timer = setTimeout(() => {
            //     goTop()
            // }, 200)
            history.push(nextPath);
        }

    }, [history, isDirty, nextPath])

    useEffect(() => {
        const pathname = uLocation.pathname;
        const temp = pathname.split('/');
        if (temp[temp.length - 1] == 'create') {
            setIsCreate(true)
        } else {
            setIsCreate(false)
            getDetail(temp[temp.length - 1])
            setProjectId(temp[temp.length - 1])
        }
    }, [uLocation])

    const getDetail = (id) => {
        setIsLoading(true);
        axios.get('/project/get-project-detail', { params: { projectId: id } }).then((res) => {
            // res.data.internalRemarks = window.htmlDecode(res.data.internalRemarks)
            setIsLoading(false);
            draftEditorRef.current = {
                internalRemarks: res.data.internalRemarks,
            };
            setBaseState(res.data)
        })
    }

    const goTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty) {
            return true;
        }

        setDirtyCheckMsgObj({
            btn1Action: () => {

                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave()
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setTimeout(() => {
                    goTop()
                }, 20)
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div style={{ minWidth: 300 }}>Your project is not yet saved. </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }

    const handleAddObjective = () => {
        const newObjectives = [...state.projectObjectives]
        const newObj = {
            projectObj: ""
        }
        newObjectives.push(newObj)
        setError((s) => ({ ...s, projectObjectives: false }))
        handleSetBaseState({ projectObjectives: newObjectives })
    }
    const handleChangeObjective = (value, filedName, index) => {
        const newObjectives = [...state.projectObjectives]
        newObjectives[index][filedName] = value
        setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({ projectObjectives: newObjectives })

    }
    const onDeleteObjective = (index) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    const newObjectives = [...state.projectObjectives]

                    newObjectives.splice(index, 1)
                    setIsDirty(true)
                    handleSetBaseState({ projectObjectives: newObjectives })
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the objective?</div>
            </SystemConfirmDialog>
        ));

    }

    const handleFirstCheck = (payload, errorTemp, callback) => {
        let isError = false;
        if (!payload.refNum) {
            errorTemp.refNum = true;
            isError = true
        }
        if (!payload.cohort) {
            errorTemp.cohort = true;
            isError = true
        }
        if (!payload.projectTitle) {
            errorTemp.projectTitle = true;
            isError = true
        }
        if (!payload.statusCode) {
            errorTemp.statusCode = true;
            isError = true
        }
        if (!payload.accessStartDate) {
            errorTemp.accessStartDate = true;
            isError = true
        }
        if (!payload.accessEndDate) {
            errorTemp.accessEndDate = true;
            isError = true
        }
        if (!payload.expectedAccessEndDate) {
            errorTemp.expectedAccessEndDate = true;
            isError = true
        }
        if (!payload.portalAccessEndDate) {
            errorTemp.portalAccessEndDate = true;
            isError = true
        }
        isError && callback()
    }
    const handleCheck = (payload) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.AllMandatory;

        handleFirstCheck(payload, errorTemp, () => {
            isError = true;
        })
        if (payload.projectObjectives.length > 0) {
            payload.projectObjectives.forEach(item => {
                if (!item.projectObj) {
                    errorTemp.objectives = true;
                    isError = true

                }
            });
        } else {
            errorTemp.projectObjectives = true;
            !isError && (msg = warning.ObjectivesAdd)
            isError = true
        }
        if (payload.internalRemarks) {
            let cleanCode = payload.internalRemarks.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
            // console.log(cleanCode, cleanCode.length)
            if (cleanCode.length > 2000) {
                errorTemp.internalRemarks = true;
                !isError && (msg = warning.RichLength)
                isError = true
            }
        }
        // con

        isNoBack.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }
    const onSubmit = (payload) => {
        const requests = {
            ...payload,
            // projectProgress: payload.projectProgress * 1,
            // status
        }
        setIsLoading(true);
        axios.post('/project/create-update-project', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.Saved

                    setIsDirty(false)
                    setIsLoading(false);
                    setErrorMsg(m)

                    // draftEditorLengthErrorRef.current = {}

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }

    const handleSave = () => {
        const payload = {
            ...state,
            accessStartDate: state.accessStartDate ? format(startOfDay(new Date(state.accessStartDate)), "yyyy-MM-dd") : state.accessStartDate,
            accessEndDate: state.accessEndDate ? format(startOfDay(new Date(state.accessEndDate)), "yyyy-MM-dd") : state.accessEndDate,
            expectedAccessEndDate: state.expectedAccessEndDate ? format(startOfDay(new Date(state.expectedAccessEndDate)), "yyyy-MM-dd") : state.expectedAccessEndDate,
            studyCompletionDate: state.studyCompletionDate ? format(startOfDay(new Date(state.studyCompletionDate)), "yyyy-MM-dd") : state.studyCompletionDate,
            portalAccessEndDate: state.portalAccessEndDate ? format(startOfDay(new Date(state.portalAccessEndDate)), "yyyy-MM-dd") : state.portalAccessEndDate,
            internalRemarks: draftEditorRef.current.internalRemarks
        }
        if (handleCheck(payload)) {
            return
        }
        payload.internalRemarks = window.htmlEncode(payload.internalRemarks)
        onSubmit(payload)
    }
    const handleCancel = () => {

        if (!isDirty) {
            goTop()
        }
        history.push(`/project-management`);

    }



    const handleChangeDate = (newValue, filedName) => {
        // console.log(newValue)
        if (error[filedName]) {
            setError((s) => ({ ...s, [filedName]: false }))
        }
        const newState = {
            [filedName]: newValue ? startOfDay(newValue) : ''
        }
        handleSetBaseState({ ...newState })

    }
    const formatDateValue = (date) => {
        if (typeof date === 'string') {
            return startOfDay(new Date(date))
        }
        return startOfDay(date)
    }
    const handleChangeTextArea = (value, fileName) => {

        let isError = false
        if (error[fileName]) {
            isError = false
        }


        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        setIsDirty(true)
        // handleSetBaseState({[fileName]: value})
    }
    const handleCloseDialog = () => {
        if (!isDirty && !isNoBack.current) {
            goTop()
            setTimeout(() => {
                handleCancel();
            }, 20)
        }
        setErrorMsg('')
    }

    const handleChangeInput = (value, field) => {
        let isError = false
        if (error[field]) {
            isError = false
        }
        if (isError !== error[field]) {
            setError((s) => ({ ...s, [field]: isError }))
        }
        handleSetBaseState({ [field]: value })
    }
    const handleEditClose = () => {
        setEditOpen(false);
        getDetail(projectId)
        setEditId(null);
    };

    const getRateNumber = (e) => {
        e.target.value = (e.target.value * 1).toFixed()
        let v = e.target.value * 1

        return v
    }

    const handleDelete = () => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    setIsLoading(true);
                    isNoBack.current = true
                    axios.post('/project/delete-project', { id: projectId * 1 })
                        .then((res) => {
                            if (res.data.code == 0) {
                                let m = success.Deleted
                                setIsDirty(false)
                                setIsLoading(false);
                                setErrorMsg(m)
                                isNoBack.current = false;
                                // draftEditorLengthErrorRef.current = {}

                            } else if (res.data.code == 1) {
                                setIsLoading(false);
                                setErrorMsg(res.data.data)
                            }

                        }).catch(err => {
                            let errMsg = err.response?.data?.message;
                            setErrorMsg(errMsg ? errMsg : err.message)
                            setIsLoading(false);
                        });
                }}
                okText="Yes"
            >
                <div style={{ padding: '0 10px' }}>
                    Are you sure to delete this project?
                </div>
                <div style={{ padding: '0 10px' }}>
                    If so, please update the access booking and delete project
                </div>
                <div style={{ padding: '0 10px' }}>
                    from researchers’ accounts accordingly.
                </div>
            </SystemConfirmDialog>
        ));
    }
    const handleOpenUserEdit = (item) => {
        setEditOpen(true);
        setEditId(item.userId);
    }
    // console.log(state.statusCode)
    const titleText = () => {
        return isCreate ? 'Create' : 'Edit'
    }
    const showErrorBorder = (isShow) => {
        return isShow ? { border: '1px solid #ff0000' } : {}
    }
    return (
        <Paper className={classes.paper} style={{ boxShadow: 'unset' }}>
            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        {titleText()} Project
                    </Typography>
                </Grid>
                <Grid container alignItems='center' style={{ paddingBottom: 10 }}>
                    <Grid item>* Mandatory fields</Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Project No. *</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <TextField fullWidth InputProps={{
                                style: {
                                    paddingLeft: 10,
                                    width: "100%",
                                    ...(showErrorBorder(error.refNum))
                                },
                            }} value={state.refNum}
                                onChange={(e) => {
                                    handleChangeInput(e.target.value, 'refNum')
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Cohort *</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <TextField type='number' fullWidth
                                InputProps={{
                                    style: {
                                        paddingLeft: 10,
                                        width: "100%",
                                        ...(showErrorBorder(error.cohort))
                                    },
                                }}
                                value={state.cohort}
                                onBlur={(e) => {
                                    if (e.target.value.length > 4) return
                                    const cohort = getRateNumber(e);
                                    if (!state.accessEndDate && cohort > 1800) {
                                        const newState = {}
                                        newState.accessEndDate = addYears(new Date(cohort + '-03-31'), 1)
                                        if (!state.expectedAccessEndDate) {

                                            newState.expectedAccessEndDate = addYears(new Date(cohort + '-03-31'), 1)
                                        }
                                        handleSetBaseState({ ...newState })
                                    }
                                    handleChangeInput(cohort, 'cohort')
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length > 4) return
                                    handleChangeInput(getRateNumber(e), 'cohort')
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Project Title*</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <TextField fullWidth
                                inputProps={{
                                    maxLength: 500
                                }}
                                InputProps={{
                                    style: {
                                        paddingLeft: 10,
                                        width: "100%",
                                        ...(showErrorBorder(error.projectTitle))
                                    },
                                }} value={state.projectTitle}
                                onChange={(e) => {
                                    handleChangeInput(e.target.value, 'projectTitle')

                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Status*</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <Select
                                value={state.statusCode}
                                onChange={(e) => {
                                    // console.log(e, o)
                                    setIsChangedStatus(true)
                                    handleChangeInput(e.target.value, 'statusCode')
                                    // handleSetBaseState({ status: e.target.value })
                                }}
                                input={<Input />}
                                style={{
                                    width: 200, paddingLeft: 10,
                                    ...(showErrorBorder(error.statusCode))
                                }}

                            >
                                <MenuItem name={'Pending'} value={'PENDING'} style={{ backgroundColor: '#fff' }} >
                                    <ListItemText primary={'Pending'} style={{ margin: '0', color: '#000', }} />
                                    {state.statusCode == 'PENDING' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                </MenuItem>
                                <MenuItem name={'Active'} value={'ACTIVE'} style={{ backgroundColor: '#fff' }} >
                                    <ListItemText primary={'Active'} style={{ margin: '0', color: '#6ad157', }} />
                                    {state.statusCode == 'ACTIVE' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                </MenuItem>
                                <MenuItem name={'Access Completed'} value={'ACCESS_COMPLETED'} style={{ backgroundColor: '#fff' }} >
                                    <ListItemText primary={'Access Completed'} style={{ margin: '0', color: '#000', }} />
                                    {state.statusCode == 'ACCESS_COMPLETED' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                </MenuItem>
                                <MenuItem name={'Inactive'} value={'INACTIVE'} style={{ backgroundColor: '#fff' }} >
                                    <ListItemText primary={'Inactive'} style={{ margin: '0', color: '#000', }} />
                                    {state.statusCode == 'INACTIVE' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                </MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>PI</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <div>
                                {
                                    state.piUsers.map((item) => {
                                        return <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                            handleOpenUserEdit(item)
                                        }}>
                                            {item.userName}
                                        </div>
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Researcher</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <div>
                                {
                                    state.researcherUsers && state.researcherUsers.map((item) => {
                                        return <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                            handleOpenUserEdit(item)
                                        }}>{item.userName}
                                        </div>
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>HA Collaborator</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <div>
                                {
                                    state.haCollaboratorUsers && state.haCollaboratorUsers.map((item) => {
                                        return <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                            handleOpenUserEdit(item)
                                        }}>{item.userName} </div>
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Access Start Date*</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={formatDateValue(state.accessStartDate || '')}
                                onChange={(newValue) => {
                                    if (isValid(newValue)) {
                                        handleChangeDate(newValue, 'accessStartDate')
                                    }
                                }}
                                // minDate={new Date()}
                                maxDate={state.accessEndDate}
                                // maxDate={state.projectEndDate}
                                views={['year', 'month', 'date']}
                                renderInput={(params) => {
                                    params.inputProps.placeholder = "DD/MM/YYYY";
                                    return <TextField  {...params} helperText={null} className={classes.DateText} error={error.accessStartDate} />
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Access End Date*</Grid>
                        <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={formatDateValue(state.accessEndDate || '')}
                                onChange={(newValue) => {
                                    if (isValid(newValue)) {
                                        handleChangeDate(newValue, 'accessEndDate')
                                        const newState = {}
                                        newState.expectedAccessEndDate = newValue
                                        handleSetBaseState({ ...newState })
                                    }
                                }}
                                minDate={state.accessStartDate}
                                maxDate={state.portalAccessEndDate}
                                views={['year', 'month', 'date']}
                                renderInput={(params) => {
                                    params.inputProps.placeholder = "DD/MM/YYYY";
                                    return <TextField  {...params} helperText={null} className={classes.DateText} error={error.accessEndDate} />
                                }}
                            />
                        </Grid>
                        <Grid item container xs={3} className={classes.label}>Expected Access End Date*</Grid>
                        <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={formatDateValue(state.expectedAccessEndDate || '')}
                                onChange={(newValue) => {
                                    if (isValid(newValue)) {
                                        // setIsChangedExpectedDate(true)
                                        handleChangeDate(newValue, 'expectedAccessEndDate')
                                    }
                                }}
                                // minDate={new Date()}
                                // maxDate={state.projectEndDate}
                                views={['year', 'month', 'date']}
                                renderInput={(params) => {
                                    params.inputProps.placeholder = "DD/MM/YYYY";
                                    return <TextField  {...params} helperText={null} className={classes.DateText} error={error.expectedAccessEndDate} />
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Study Completion Date</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={formatDateValue(state.studyCompletionDate || '')}
                                onChange={(newValue) => {
                                    if (isValid(newValue)) {
                                        handleChangeDate(newValue, 'studyCompletionDate')
                                    }
                                }}
                                // minDate={new Date()}
                                // maxDate={state.projectEndDate}
                                views={['year', 'month', 'date']}
                                renderInput={(params) => {
                                    params.inputProps.placeholder = "DD/MM/YYYY";
                                    return <TextField  {...params} helperText={null} className={classes.DateText} error={error.studyCompletionDate} />
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Portal Access End Date*</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={formatDateValue(state.portalAccessEndDate || '')}
                                onChange={(newValue) => {
                                    if (isValid(newValue)) {
                                        // setIsChangedPortalDate(true)
                                        handleChangeDate(newValue, 'portalAccessEndDate')
                                    }
                                }}
                                minDate={state.accessEndDate}
                                // maxDate={state.projectEndDate}
                                views={['year', 'month', 'date']}
                                renderInput={(params) => {
                                    params.inputProps.placeholder = "DD/MM/YYYY";
                                    return <TextField  {...params} helperText={null} className={classes.DateText} error={error.portalAccessEndDate} />
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Project Objectives*</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            {
                                state.projectObjectives.map((item, index) => {
                                    return <Grid container key={index} style={{ padding: '5px 0' }}>
                                        <Grid item>{index + 1}.&nbsp;</Grid>
                                        <Grid item style={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                id="filled-multiline-static"
                                                label=""
                                                multiline
                                                rows={3}
                                                inputProps={{ maxLength: 2000 }}
                                                className={classes.textareaBox}
                                                placeholder="<2000 characters"
                                                variant="outlined"
                                                value={item.projectObj}
                                                InputProps={{
                                                    style: {

                                                        ...(error.objectives && !item.projectObj ? { border: '1px solid #ff0000' } : {})
                                                    },
                                                }}
                                                onChange={(e) => handleChangeObjective(e.target.value, 'projectObj', index)}
                                            />

                                        </Grid>
                                        <Grid item>
                                            <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => { onDeleteObjective(index) }} />
                                        </Grid>
                                    </Grid>
                                })
                            }
                            <Grid container >
                                <Button style={{
                                    backgroundColor: '#15a9d2 ',
                                    color: '#ffff ',
                                    marginRight: 10,
                                    textTransform: 'none'
                                }} className={classes.activeButton} onClick={handleAddObjective}>+Add</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lineBottom} >
                        <Grid item container xs={3} className={classes.label}>Internal Remarks</Grid>
                        <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                            <ReportDraftEditor placeholder={'<2000 characters'} isError={error.internalRemarks} value={state.internalRemarks} onChange={(e) => handleChangeTextArea(e, 'internalRemarks')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave()}>Save</Button>
                    <Button style={{
                        backgroundColor: '#646464 ',
                        color: '#ffff ',
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={handleCancel}>{'Cancel'}</Button>
                    {!isCreate && <Grid container style={{ paddingTop: 10 }}>
                        <Button style={{
                            backgroundColor: '#ff0000 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleDelete}>{'Delete Project'}</Button>
                    </Grid>}
                </Grid>
            </Grid>
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
            <Prompt
                when={true}
                message={(location) => {
                    if (location.pathname != '/project-management') {
                        localStorage.setItem('selectedCohort', '')
                    }
                }}
            />
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            <LoadingScreen open={isLoading} />
            <EditUser _id={0} open={editOpen} userId={editId} handleClose={() => handleEditClose()} roleDisabled={roleDisabled}
                projectLists={projectLists} disableBackdropClick disableEscapeKeyDown />
        </Paper>
    )
}