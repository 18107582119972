import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay,
    addDays
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField,
    Radio,
    FormControlLabel,
    RadioGroup
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ReportDraftEditor from '../../researcher/detail/ReportDraftEditor';
import DatePicker from '@material-ui/lab/DatePicker';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';
import isValid from 'date-fns/isValid';
import { useLocation, Prompt, useHistory } from 'react-router-dom';
import { getSearchParams } from 'components/common/utils';
import { dateMonthMap } from '../index'
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import LoadingScreen from 'components/common/LoadingScreen';
import useModal from "hooks/useModal";
import SelectComponent from 'components/common/CommonSelect';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { warning, confirmReportDeadline, confirmReportDeadline_8, success } from 'components/common/Constants';
import RenderToolDate from '../RenderToolDate';

const milestoneOption = {
    DATA_RC: 'Data Requirement / Clarification',
    DATA_SPE: 'Data Specification',
    DATA_EXT: 'Data Extraction',
    DATA_REL: 'Data Release',
    DATA_PRE: 'Data Pre-processing',
    DESC_ANA: 'Descriptive Analysis',
    CODE_MOD: 'Coding & Modelling',
    MODE_VAL: 'Model Validation',
}
export default function ReportDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const uLocation = useLocation()
    const [projectId, setProjectId] = React.useState('')
    const [yearMonth, setYearMonth] = React.useState(new Date())
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('')
    const { setUser } = React.useContext(UserContext);
    const [error, setError] = React.useState({});
    const showModal = useModal();
    const [reload, setReload] = React.useState(false);
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();

    const [state, setBaseState] = React.useState({ projectProgress: '', supplementaries: [] })
    const [nextPath, setNextPath] = React.useState(null);
    const timerRef = React.useRef(null);
    const isErrorRef = React.useRef(null);

    const draftEditorRef = React.useRef({})
    const draftEditorLengthErrorRef = React.useRef({})
    React.useEffect(() => {
        // setHaveOutstandingReport(localStorage.getItem('haveOutstandingReport'))
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])
    const disableSubmit = state.statusDB == 'SAVED' || state.statusDB == 'REVIEWED' || state.statusDB == null
    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    React.useEffect(() => { //callback of leave Anyway
        let timer = null
        if (!isDirty && nextPath) {
            // timer = setTimeout(() => {
            //     goTop()
            // }, 200)
            history.push(nextPath);
        }
        return () => {
            // clearTimeout(timer)
        }
    }, [history, isDirty, nextPath])
    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/progress-reports/form')) {
            return true;
        }
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave('REVIEWED')
            },
            btn1Label: 'Reviewed',
            btn2Action: () => {
                setTimeout(() => {
                    goTop()
                    if (location && location.pathname) {
                        if (location.pathname === '/login') {
                            localStorage.clear();
                            axios.defaults.headers.common['Authorization'] = "";
                            setUser(null);
                        }

                        setNextPath(location.pathname + location.search);

                    }
                }, 20)
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>You have not yet clicked reviewed before you leave. </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);

        return false;
    }

    const getFormDetail = (y, _isCopy) => {
        setIsLoading(true);
        axios.get('/progressReport/get-progress-report-detail', { params: { projectId: projectId, yearMonth: format(y, 'yyyy-MM-dd') } }).then((res) => {
            if (res.data) {

                let data = res.data;
                res.data.estimatedAccessEndDate = res.data.estimatedAccessEndDate || res.data.projectEndDate;

                draftEditorRef.current = {
                    dataCommentHADCL: data.dataCommentHADCL,
                    dataCommentFor: data.dataCommentFor,
                    platformCommentHADCL: data.platformCommentHADCL,
                    platformCommentFor: data.platformCommentFor,
                    physicalCommentHADCL: data.physicalCommentHADCL,
                    physicalCommentFor: data.physicalCommentFor,
                    meetingCommentHADCL: data.meetingCommentHADCL,
                    meetingCommentFor: data.meetingCommentFor,
                    othersCommentHADCL: data.othersCommentHADCL,
                    othersCommentFor: data.othersCommentFor,
                    supplementaries: data.supplementaries,
                    remarks: data.remarks,
                    issueRisks: data.issueRisks,
                };
                setBaseState(data)
                setIsLoading(false);
                setReload(!reload)
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    React.useEffect(() => {
        if (projectId && yearMonth) {
            getFormDetail(yearMonth);
        }
    }, [yearMonth, projectId])

    React.useEffect(() => {
        const params = getSearchParams(uLocation.search);
        setProjectId(params.projectId)
        // console.log(params.yearMonth)
        setYearMonth(startOfMonth(new Date(params.yearMonth)))

    }, [uLocation.search])
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const handleChangeInput = (e, fileName) => {
        if (disableSubmit) return
        let value = e.target.value;

        if (error[fileName]) {
            setError((s) => ({ ...s, [fileName]: false }))
        }
        handleSetBaseState({ [fileName]: value })
    }


    const handleChangeTextArea = (value, fileName, maxLength) => {
        let cleanCode = value.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
        let isError = false
        if (error[fileName]) {
            isError = false
        }
        if (cleanCode.length > maxLength) {
            draftEditorLengthErrorRef.current[fileName] = maxLength
            // setError((s) => ({...s,[fileName]: false}))
        } else {
            draftEditorLengthErrorRef.current[fileName] = false
        }

        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
        // handleSetBaseState({[fileName]: value})
    }
    const formatDateValue = (date) => {
        if (typeof date === 'string') {
            return new Date(date)
        }
        return date
    }



    const handleCheck = (payload) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.reportRiskSeverity;
        if (!payload.overallRisk) {
            errorTemp.overallRisk = true;
            isError = true
        }
        // console.log(draftEditorLengthErrorRef)
        Object.keys(draftEditorRef.current).forEach((key) => {
            if (draftEditorLengthErrorRef.current[key]) {
                errorTemp[key] = true;
                isError = true
                msg = warning.reportRichLength
            }
        })
        isErrorRef.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }
    const renderStatusText = (status) => {
        return `Do you confirm to ${status == 'REVIEWED' ? 'reviewed' : 'Return for Revision'} the report?`
    }
    const handleSave = (status) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    const issueRisks = []
                    state.issueRisks && state.issueRisks.map((item) => {
                        if (item.issues || item.severity || item.owner || item.targetResolutionDate != null) {
                            item.targetResolutionDate = item.targetResolutionDate ? format(startOfDay(new Date(item.targetResolutionDate)), "yyyy-MM-dd") : null
                            issueRisks.push(item)
                        }
                    })
                    const payload = {
                        projectId: state.projectId,
                        reportingMonth: state.reportingMonth,
                        dataCommentHADCL: draftEditorRef.current.dataCommentHADCL,
                        dataCommentFor: state.dataCommentFor,
                        platformCommentHADCL: draftEditorRef.current.platformCommentHADCL,
                        platformCommentFor: state.platformCommentFor,
                        physicalCommentHADCL: draftEditorRef.current.physicalCommentHADCL,
                        physicalCommentFor: state.physicalCommentFor,
                        meetingCommentHADCL: draftEditorRef.current.meetingCommentHADCL,
                        meetingCommentFor: state.meetingCommentFor,
                        othersCommentHADCL: draftEditorRef.current.othersCommentHADCL,
                        othersCommentFor: state.othersCommentFor,
                        // supplementaries: draftEditorRef.current.supplementaries,
                        issueRisks: issueRisks,
                        overallRisk: state.overallRisk,
                        remarks: draftEditorRef.current.remarks,
                    };
                    // console.log(payload)
                    if (handleCheck(payload)) {
                        return
                    }
                    payload.dataCommentHADCL = window.htmlEncode(payload.dataCommentHADCL)
                    payload.platformCommentHADCL = window.htmlEncode(payload.platformCommentHADCL)
                    payload.physicalCommentHADCL = window.htmlEncode(payload.physicalCommentHADCL)
                    payload.meetingCommentHADCL = window.htmlEncode(payload.meetingCommentHADCL)
                    payload.othersCommentHADCL = window.htmlEncode(payload.othersCommentHADCL)
                    payload.remarks = window.htmlEncode(payload.remarks)
                    onSubmit(payload, status)
                }}
            >
                <div style={{ padding: '0 10px' }}>{renderStatusText(status)}</div>
            </SystemConfirmDialog>
        ));
        // timerRef.current = setTimeout(() => { 


        // }, 200)
    }

    const onSubmit = (payload, status) => {
        const requests = {
            ...payload,
            projectProgress: payload.projectProgress * 1,
            status
        }
        setIsLoading(true);
        axios.post('/progressReport/update-progress-report', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.ReportReviewed
                    if (status == 'REVISED') {
                        m = success.ReportRevised
                    }
                    setIsDirty(false)
                    setIsLoading(false);
                    setErrorMsg(m)
                    draftEditorLengthErrorRef.current = {}
                    // draftEditorLengthErrorRef.current = {}

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
            });
    }

    const onChangeRadio = (value, fileName) => {
        if (disableSubmit) return
        handleSetBaseState({ [fileName]: value })
    }

    const handleAddRick = () => {
        const newObj = {
            "issues": "",
            "severity": "",
            "owner": "",
            "targetResolutionDate": null
        }
        const newIssueRisks = [...state.issueRisks]
        newIssueRisks.push(newObj)
        handleSetBaseState({ issueRisks: newIssueRisks })
        draftEditorRef.current.issueRisks = newIssueRisks
    }

    const handleChangeIssueRisks = (value, filedName, index) => {
        const newIssueRisks = [...state.issueRisks]
        newIssueRisks[index][filedName] = value
        !disableSubmit && setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({ issueRisks: newIssueRisks })
        draftEditorRef.current.issueRisks = newIssueRisks
    }

    const onDeleteIssueRisks = (index) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    const newIssueRisks = [...state.issueRisks]

                    newIssueRisks.splice(index, 1)
                    !disableSubmit && setIsDirty(true)
                    handleSetBaseState({ issueRisks: newIssueRisks })
                    draftEditorRef.current.issueRisks = newIssueRisks
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the risk?</div>
            </SystemConfirmDialog>
        ));

    }
    const goTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    const handleCancel = () => {

        if (!isDirty) {
            goTop()
        }
        history.push(`/progress-reports-management`);

    }

    const handleCloseDialog = () => {
        if (!isDirty && !isErrorRef.current) {
            goTop()
            setTimeout(() => {
                handleCancel();
            }, 20)
        }
        setErrorMsg('')
    }

    const handleChangeReportMonth = (reportMonth) => {
        history.push(`/progress-reports-management/form?projectId=${projectId}&yearMonth=${format(reportMonth, 'yyyy-MM-dd')}`);
    }
    const showYesOrNo = (data) => {
        return data ? 'Yes' : 'No'
    }
    return (
        <Paper className={classes.paper} style={{ boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Progress Report
                    </Typography>
                </Grid>

                <Grid container>
                    <Grid container className={classes.title}>
                        HADCL Progress Report
                    </Grid>
                    <Grid container>
                        <Grid container style={{ height: 45, paddingTop: 7 }} alignContent='center'>
                            <Grid item container xs={6} style={{ paddingLeft: 10 }}>
                                <RenderToolDate
                                    date={yearMonth}
                                    label={format(yearMonth, 'MMMM yyyy')}
                                    isSelect={true}
                                    labelStyle={{ paddingRight: 8 }}
                                    endDate={state.projectEndDate}
                                    setViewDate={(e) => { handleChangeReportMonth(e) }}
                                />

                            </Grid>
                            <Grid item container xs={6} alignContent="center">Completion Status: {state.status}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent} >{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Cohort</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.cohort}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} className={classes.label}>Project Title</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent} style={{ wordBreak: 'break-all' }}>{state.projectTitle}</Grid>
                            <Grid item container xs={3} className={classes.label}>Reporting Month</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.reportingMonth && `${dateMonthMap[format(new Date(state.reportingMonth), 'MMM')]} ${format(new Date(state.reportingMonth), 'yyyy')}`}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container alignContent='center' xs={3} className={classes.label}>Project Progress*</Grid>
                            <Grid item container alignItems='center' xs={3} className={classes.rightContent}>
                                {/* <TextField type='number' disabled={true} inputProps={{ min: 0, max: 100 }} InputProps={{
                                    style: {
                                        paddingLeft: 10,
                                        width: 100,
                                        ...(error.projectProgress ? { border: '1px solid #ff0000' } : {})
                                    },
                                }} value={state.projectProgress||''} /> */}
                                {state.projectProgress || ''}%
                                <Grid container>(Expected Progress: {state.expectedProgress}%)</Grid>
                            </Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project Milestone*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {(state.projectMilestone || []).map((item) => milestoneOption[item]).join(', ')}

                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} className={classes.label}>Complete Progress This Month*</Grid>
                            <Grid item container xs={9} className={classes.rightContent}>
                                <div style={{
                                    overflowY: 'auto',
                                    padding: '0 5px 0',
                                    height: 120,
                                    fontWeight: 'bold',
                                    wordBreak: 'break-all'
                                }} dangerouslySetInnerHTML={{ __html: state.completeProgress }}></div>

                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} className={classes.label}>Planned Progress For Next Month*</Grid>
                            <Grid item container xs={9} className={classes.rightContent}>
                                <div style={{
                                    overflowY: 'auto',
                                    padding: '0 5px 0',
                                    height: 120,
                                    fontWeight: 'bold',
                                    wordBreak: 'break-all'
                                }} dangerouslySetInnerHTML={{ __html: state.plannedProgress }}></div>

                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Estimated Access End Date Of HADCL*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {state.estimatedAccessEndDate && format(new Date(state.estimatedAccessEndDate), 'dd/MM/yyyy')}

                            </Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Estimated Study Completion Date*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {state.estimatedStudyCompletionDate && format(new Date(state.estimatedStudyCompletionDate), 'dd/MM/yyyy')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Project Outcomes */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Project Outcomes
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={5} className={classes.projectTh} justifyContent='center' >Project Objectives/Deliverables (as stated in proposal)</Grid>
                        <Grid item container xs={7}>
                            <Grid item xs={4} className={classes.projectTh}>Achieved</Grid>
                            <Grid item xs={4} className={classes.projectTh}>Partially Achieved</Grid>
                            <Grid item xs={4} className={classes.projectTh} style={{ border: 0 }}>Not Yet Achieved</Grid>
                        </Grid>
                    </Grid>
                    {
                        state.outcomes && state.outcomes.map((item, index) => {
                            return <Grid key={index} container className={`${classes.projectTr} ${classes.lineBottom}`}>
                                <Grid item container xs={5} style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: '#f3f3f3', lineHeight: '32px' }} alignItems='baseline' className={classes.projectTd} >
                                    {index + 1}. &nbsp;
                                    <Grid item style={{ wordBreak: 'break-all', flex: 1 }}>{item.projectObj}&nbsp; *</Grid>
                                </Grid>
                                <Grid item container xs={7}>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            startIcon={checkIcon(item.isAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}

                                        >

                                        </Button>
                                    </Grid>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            startIcon={checkIcon(item.isPartiallyAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}


                                        >
                                        </Button>
                                    </Grid>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            startIcon={checkIcon(item.isNotYetAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}


                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })
                    }


                </Grid>
                {/* Expected Deliverables Upon Completion */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Expected Deliverables Upon Completion
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >Publication Of Research Papers*</Grid>
                        <Grid item container xs={8}>
                            <Button

                                startIcon={checkIcon(true)}
                                // className={classes.button}
                                style={{
                                    textTransform: 'none',
                                    lineHeight: '1.5',
                                    fontSize: '13px'
                                }}

                            >
                                <div className={classes.buttonLabel}>{showYesOrNo(state.isPublication)}</div>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >AI Model/Algorithm*</Grid>
                        <Grid item container xs={8}>
                            <Button

                                startIcon={checkIcon(true)}
                                // className={classes.button}
                                style={{
                                    textTransform: 'none',
                                    lineHeight: '1.5',
                                    fontSize: '13px'
                                }}

                            >
                                <div className={classes.buttonLabel}>{showYesOrNo(state.isAiModelAlg)}</div>
                            </Button>

                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >Provision Of Image Labelling*</Grid>
                        <Grid item container xs={8}>
                            <Button

                                startIcon={checkIcon(true)}
                                // className={classes.button}
                                style={{
                                    textTransform: 'none',
                                    lineHeight: '1.5',
                                    fontSize: '13px'
                                }}

                            >
                                <div className={classes.buttonLabel}>{showYesOrNo(state.isProvision)}</div>
                            </Button>

                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Others, please specify (e.g. abstract submission/ presentation to conferences)</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 120,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.specifyContent }}></div>

                        </Grid>
                    </Grid>
                </Grid>
                {/* Plan For Integration */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Plan For Integration
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Plan to integrate HA clinical system*
                                <div>(if yes, please specify the target systems)</div>
                            </div>
                        </Grid>
                        <Grid item container xs={8}>
                            <Button

                                startIcon={checkIcon(true)}

                                style={{
                                    textTransform: 'none',
                                    lineHeight: '1.5',
                                    fontSize: '13px'
                                }}

                            >
                                <div className={classes.buttonLabel}>{showYesOrNo(state.isPlanToIntegrate)}</div>
                            </Button>

                            <Grid item container xs={12} className={classes.rightContent}>(Please specify the target systems: )</Grid>
                            <Grid item container xs={12} className={classes.rightContent}>
                                <div style={{
                                    overflowY: 'auto',
                                    padding: '0 5px 0',
                                    height: 120,
                                    fontWeight: 'bold',
                                    wordBreak: 'break-all'
                                }} dangerouslySetInnerHTML={{ __html: state.targetSystems }}></div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Related COCs/CC/Cluster Involved</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 120,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.relatedCOCInvolved }}></div>

                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Related HA Collaborators Involved</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 120,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.relatedHAInvolved }}></div>

                        </Grid>
                    </Grid>
                </Grid>
                {/* Additional Requests And Comments */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Additional Requests And Comments
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' > </Grid>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Comments from Researcher</Grid>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' style={{ border: 0 }}>Reply from HADCL Office </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Data
                                <Grid container>e.g. requirement and justification  </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 130,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.dataCommentResearcher }}></div>

                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} reload={reload} height={80} placeholder={'≤5000 characters'} isError={error.dataCommentHADCL} value={state.dataCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'dataCommentHADCL', 5000)} />

                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.dataCommentFor == 1)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(1, 'dataCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Allow researchers to view</div>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.dataCommentFor != 1)}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(0, 'dataCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Only for internal view</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Platform And Tool
                                <Grid container>e.g. hardware and software </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 130,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.platformCommentResearcher }}></div>
                            {/* <ReportDraftEditor height={130} placeholder={'≤2000 characters'} value={state.platformCommentResearcher} isError={error.platformCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'platformCommentResearcher',2000)}/> */}
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} reload={reload} height={80} placeholder={'≤5000 characters'} value={state.platformCommentHADCL} isError={error.platformCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'platformCommentHADCL', 5000)} />

                            {/* <div style={{
                                    overflowY: 'auto',
                                    padding: '0 5px 0',
                                height: 80,
                                fontWeight: 'bold'
                                }} dangerouslySetInnerHTML={{__html: state.platformCommentHADCL}}></div> */}
                            {/* <ReportDraftEditor placeholder={'≤5000 characters'} disabled={true} isError={error.platformCommentHADCL} value={state.platformCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'platformCommentHADCL', 5000)} /> */}
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.platformCommentFor == 1)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(1, 'platformCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Allow researchers to view</div>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.platformCommentFor != 1)}

                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(0, 'platformCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Only for internal view</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Physical Site And Administration Support
                                <Grid container>e.g. booking, access control </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 130,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.physicalCommentResearcher }}></div>
                            {/* <ReportDraftEditor height={130} placeholder={'≤2000 characters'} value={state.physicalCommentResearcher} isError={error.physicalCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'physicalCommentResearcher',2000)}/> */}
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} reload={reload} height={80} placeholder={'≤5000 characters'} value={state.physicalCommentHADCL} isError={error.physicalCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'physicalCommentHADCL', 5000)} />

                            {/* <ReportDraftEditor placeholder={'≤5000 characters'} disabled={true} isError={error.physicalCommentHADCL} value={state.physicalCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'physicalCommentHADCL', 5000)} /> */}
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.physicalCommentFor == 1)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(1, 'physicalCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Allow researchers to view</div>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.physicalCommentFor != 1)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(0, 'physicalCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Only for internal view</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Meeting With HADCL Office
                                <Grid container>Please specify purpose and tentative meeting schedule</Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 130,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.meetingCommentResearcher }}></div>
                            {/* <ReportDraftEditor height={130} placeholder={'≤2000 characters'} isError={error.meetingCommentResearcher} value={state.meetingCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'meetingCommentResearcher',2000)}/> */}
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} reload={reload} height={80} placeholder={'≤5000 characters'} isError={error.meetingCommentHADCL} value={state.meetingCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'meetingCommentHADCL', 5000)} />

                            {/* <ReportDraftEditor placeholder={'≤5000 characters'} isError={error.meetingCommentHADCL} value={state.meetingCommentHADCL} disabled={true} onChange={(e) => handleChangeTextArea(e, 'meetingCommentHADCL', 5000)} /> */}
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.meetingCommentFor == 1)}

                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(1, 'meetingCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Allow researchers to view</div>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.meetingCommentFor != 1)}

                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(0, 'meetingCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Only for internal view</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Others
                                <Grid container>Please specify</Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <div style={{
                                overflowY: 'auto',
                                padding: '0 5px 0',
                                height: 130,
                                fontWeight: 'bold',
                                wordBreak: 'break-all'
                            }} dangerouslySetInnerHTML={{ __html: state.othersCommentResearcher }}></div>
                            {/* <ReportDraftEditor height={130} placeholder={'≤2000 characters'} isError={error.othersCommentResearcher} value={state.othersCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'othersCommentResearcher',2000)}/> */}
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} reload={reload} height={80} placeholder={'≤5000 characters'} isError={error.othersCommentHADCL} value={state.othersCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'othersCommentHADCL', 5000)} />
                            {/* <ReportDraftEditor placeholder={'≤5000 characters'} isError={error.othersCommentHADCL} value={state.othersCommentHADCL} disabled={true} onChange={(e) => handleChangeTextArea(e, 'othersCommentHADCL', 5000)} /> */}
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.othersCommentFor == 1)}

                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(1, 'othersCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Allow researchers to view</div>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Button

                                    startIcon={checkIcon(state.othersCommentFor != 1)}

                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => { onChangeRadio(0, 'othersCommentFor') }}

                                >
                                    <div className={classes.buttonLabel}>Only for internal view</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Supplementary Information */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Supplementary Information
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Document name</Grid>
                        <Grid item container xs={8} className={classes.projectTh} justifyContent='center' >{`Description & Link *`}</Grid>

                    </Grid>
                    {
                        state.supplementaries && state.supplementaries.map((item, index) => {
                            return <Grid container key={index} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                <Grid item container xs={4} style={{ paddingLeft: 10 }} className={classes.projectTd} >
                                    <Grid item>{index + 1}.&nbsp;&nbsp;</Grid>
                                    <Grid item xs style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces', paddingLeft: 5, paddingRight: 10, backgroundColor: '#f3f3f3' }}>
                                        {item.documentName}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={8} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                                    {/* <ReportDraftEditor height={130} placeholder={'≤200 characters'} isError={error.dataCommentResearcher} value={item.reply} onChange={(e) => handleChangeSupplementariesText(e, 'content', index, 200)} /> */}
                                    <div style={{
                                        overflowY: 'auto',
                                        padding: '0 5px 0',
                                        height: 130,
                                        fontWeight: 'bold',
                                        wordBreak: 'break-all'
                                    }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </Grid>
                                {/* <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                                    
                                        <ReportDraftEditor height={80} placeholder={'≤200 characters'} isError={error.dataCommentResearcher}  value={item.reply} onChange={(e) => handleChangeSupplementariesText(e, 'reply', index ,200)}/>
                                    
                                    <Grid container>
                                        <Button
                                            
                                            startIcon={checkIcon(item.replyFor == 1)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}
                                            onClick={() => { handleChangeSupplementaries(1, 'replyFor', index)}}
                                            
                                            >
                                            <div className={classes.buttonLabel}>Allow researchers to view</div>
                                        </Button>
                                    </Grid>
                                    <Grid container>
                                        <Button
                                            
                                            startIcon={checkIcon(item.replyFor != 1)}
                                            
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}
                                            onClick={() => { handleChangeSupplementaries(0, 'replyFor', index)}}
                                           
                                            >
                                            <div className={classes.buttonLabel}>Only for internal view</div>
                                        </Button>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        })
                    }
                </Grid>
                {/* Remarks */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Comments from HADCL Office
                    </Grid>
                    <Grid container>
                        <ReportDraftEditor disabled={disableSubmit} reload={reload} placeholder={'≤2000 characters'} value={state.remarks} onChange={(e) => handleChangeTextArea(e, 'remarks', 2000)} isError={error.remarks} />

                    </Grid>
                </Grid>
                {/* Issue/Risk Management  */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Issue/Risk Management
                    </Grid>
                    <Grid container style={{ paddingTop: 5 }}>
                        <Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            marginRight: 10,
                            textTransform: 'none'
                        }} disabled={disableSubmit} className={classes.activeButton} onClick={handleAddRick}>+Add</Button>
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Issues</Grid>
                        <Grid item container xs={3} className={classes.projectTh} justifyContent='center' >{`Severity`}</Grid>
                        <Grid item container xs={2} className={classes.projectTh} justifyContent='center' >{`Owner`}</Grid>
                        <Grid item container xs={2} justifyContent='center' >{`Target Resolution Date`}</Grid>
                        <Grid item container xs={1} justifyContent='center' ></Grid>

                    </Grid>
                    {
                        state.issueRisks && state.issueRisks.map((item, index) => {
                            return <Grid container key={index}>
                                <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10, wordBreak: 'break-word', whiteSpace: 'break-spaces', lineHeight: 1.2 }} className={classes.projectTd}  >
                                    {disableSubmit ? item.issues : <TextField inputProps={{ maxLength: 2000 }} fullWidth InputProps={{
                                        style: {
                                            paddingLeft: 10,
                                            width: "100%",
                                        },
                                    }} value={item.issues || ''}
                                        onChange={(e) => handleChangeIssueRisks(e.target.value, 'issues', index)}
                                    />}
                                </Grid>
                                <Grid item container xs={3} style={{ paddingLeft: 10, paddingRight: 10, lineHeight: '28px' }} className={classes.projectTd} justifyContent='center' >
                                    <div>
                                        <SelectComponent disabled={disableSubmit} displayEmpty={false} value={item.severity} onChange={(v) => { handleChangeIssueRisks(v, 'severity', index) }} style={{
                                            textTransform: 'none',
                                            fontSize: '13px',
                                            width: 200,
                                            height: 32
                                        }} options={[{
                                            value: 'LOW',
                                            name: 'Low'
                                        }, {
                                            value: 'MEDIUM',
                                            name: 'Medium'
                                        }, {
                                            value: 'HIGH',
                                            name: 'High'
                                        }]} />
                                    </div>
                                </Grid>
                                <Grid item container xs={2} style={{ paddingLeft: 10, paddingRight: 10, lineHeight: '28px' }} className={classes.projectTd} justifyContent='center' >
                                    <TextField inputProps={{ maxLength: 200 }} InputProps={{
                                        style: {
                                            paddingLeft: 10,
                                            // width: 100,
                                        },
                                    }} disabled={disableSubmit} value={item.owner || ''}
                                        onChange={(e) => handleChangeIssueRisks(e.target.value, 'owner', index)}
                                    />
                                </Grid>
                                <Grid item container xs={2} style={{ paddingLeft: 10, paddingRight: 10, lineHeight: '28px', position: 'relative' }} className={classes.projectTd} justifyContent='center' >
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={formatDateValue(item.targetResolutionDate || '')}
                                        onChange={(newValue) => {
                                            if (isValid(newValue)) {
                                                handleChangeIssueRisks(newValue, 'targetResolutionDate', index)
                                                // handleChangeDate(newValue, 'estimatedStudyCompletionDate')
                                            }
                                        }}
                                        disabled={disableSubmit}
                                        views={['year', 'month', 'date']}
                                        // open={dateOpen}
                                        // allowSameDateSelection={true}
                                        // onAccept={(o) => { console.log(o);  return false}}
                                        // onOpen={handleOpenDate}
                                        // onClose={handleCloseDate}
                                        style={{}}
                                        renderInput={(params) => {
                                            params.inputProps.placeholder = "DD/MM/YYYY";
                                            return <TextField  {...params} helperText={null} className={classes.DateText} error={error.issueRisks && error.issueRisks[index] && error.issueRisks[index]['targetResolutionDate']} />
                                        }}
                                    />

                                    {/* <Grid item container style={{width: 50}} justifyContent='center' alignItems={'center'}>
                                        <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => {onDeleteIssueRisks(index) }} />
                                    </Grid> */}
                                </Grid>
                                <Grid item container xs={1} style={{ paddingLeft: 10, paddingRight: 10, lineHeight: '28px', position: 'relative' }} className={classes.projectTd} justifyContent='center' >


                                    {!disableSubmit && <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => { onDeleteIssueRisks(index) }} />}

                                </Grid>

                            </Grid>
                        })
                    }

                </Grid>

                <Grid container style={{ borderTop: '1px solid #dcdcdc', paddingTop: 10 }}>
                    <Grid item className={classes.riskLabel}>Overall risk severity *</Grid>
                    <RadioGroup aria-label="overallRisk" name="gender1" value={state.overallRisk || ''} onChange={(e) => { handleChangeInput(e, 'overallRisk') }} style={{ flexDirection: 'initial', paddingLeft: 25 }}>
                        <FormControlLabel value="LOW" control={<Radio
                            classes={{
                                root: classes.lowColor,
                                checked: classes.lowColor,
                            }}
                        />} label="Low" />
                        <FormControlLabel value="MEDIUM" control={<Radio
                            classes={{
                                root: classes.mediumColor,
                                checked: classes.mediumColor,
                            }}
                        />} label="Medium" />
                        <FormControlLabel value="HIGH" control={<Radio
                            classes={{
                                root: classes.hightColor,
                                checked: classes.hightColor,
                            }}
                        />} label="High" />
                    </RadioGroup>


                </Grid>
                <Grid container>
                    {!disableSubmit && <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave('REVIEWED')}>Reviewed</Button>}
                    {state.statusDB == 'SUBMITTED' && <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave('REVISED')}>Return for Revision</Button>}
                    <Button style={{
                        backgroundColor: '#646464 ',
                        color: '#ffff ',
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={handleCancel}>{disableSubmit ? 'Back' : 'Cancel'}</Button>
                </Grid>
            </Grid>
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}

            <Prompt
                when={true}
                message={(location) => {
                    if (location.pathname != '/progress-reports-management' && location.pathname != '/progress-reports-management/form') {
                        localStorage.setItem('viewDate', '')
                        localStorage.setItem('isViewDate', '')
                        localStorage.setItem('selectedProject', '')
                    }
                }}
            />
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            <LoadingScreen open={isLoading} />

        </Paper>
    );
}
