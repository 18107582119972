import React from 'react';
import Typography from '@material-ui/core/Typography';

const envDesc = process.env.REACT_APP_ENV_DESC || ""

export default function SiteHeader(props) {
    return (
        <Typography variant="h6" noWrap component="div">
            {`${props.showWelcome ? "Welcome to " : ""}HA Data Sharing Portal${envDesc}`}
        </Typography>
    );
}