import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './Styles';
import SelectComponent from './SelectComponent';
import DatePicker from '@material-ui/lab/DatePicker';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LeftViewComponent from './ManagementComponent/LeftViewComponent';
import LeftEditComponent from './ManagementComponent/LeftEditComponent';
import LeftSetupEditComponent from './ManagementComponent/LeftSetupEditComponent';
const AMViewLeft = ({
    isEdit,
    enableUpdateBooking,
    selectDateClick,
    selectAvailableDateClick,
    setResNoClick,
    updateCalendarNo,
    submitClick,
    cancelClick,
    allAvailableChecked,
    selectDateChecked,
    selectedDateText,
    resNo,
    selectedDateList,
    resNoInvalidMsg,
    isDirty,
    selfServiceList,
    projectList,
    allRecordChecked,
    setAllRecordChecked,
    selectionChecked,
    setSelectionChecked,
    selectionChoose,
    setSelectionChoose,
    setProject,
    project,
    selfService,
    setSelfService,
    setIsBatch,
    isBatch,
    forProject,
    setForProject,
    forSelfService,
    setForSelfService,
    isEditManageRequest,
    availableSpace,
    setAvailableSpace,
    updateCalendarAvailable,
    isReleaseNow, setIsReleaseNow,
    releaseDate, setReleaseDate,
    newReleaseTime, setNewReleaseTime,
    setResNo,
    rejectReason,
    setRejectReason,
    setUpRemarks,
    setSetUpRemarks,
    releaseTimeInvalidMsg,
    bookingAllProject,
    bookingAllSelfService,
    setSelectedDateList,
    handleOpenDirtyCheck,
    setIsShowFirstTime,
    isShowFirstTime,
    firstTimeRef,
    bookingsList,
    viewDate
}) => {
    const [remarks, setRemarks] = useState(setUpRemarks);

    useEffect(() => {
        remarks !== setUpRemarks && setRemarks(setUpRemarks)
    }, [setUpRemarks])


    // useEffect(() => {
    //     if (!forProject && !forSelfService) {
    //         setAvailableSpace('')
    //     }
    // }, [forProject, forSelfService])

    const renderEdit = () => {
        return !isEditManageRequest ? <LeftSetupEditComponent
            enableUpdateBooking={enableUpdateBooking}
            selectDateClick={selectDateClick}
            selectAvailableDateClick={selectAvailableDateClick}
            submitClick={submitClick}
            cancelClick={cancelClick}
            allAvailableChecked={allAvailableChecked}
            selectDateChecked={selectDateChecked}
            selectedDateText={selectedDateText}
            selectedDateList={selectedDateList}
            forProject={forProject}
            setForProject={setForProject}
            forSelfService={forSelfService}
            setForSelfService={setForSelfService}
            availableSpace={availableSpace}
            setAvailableSpace={setAvailableSpace}
            updateCalendarAvailable={updateCalendarAvailable}
            isReleaseNow={isReleaseNow} setIsReleaseNow={setIsReleaseNow}
            releaseDate={releaseDate} setReleaseDate={setReleaseDate}
            newReleaseTime={newReleaseTime} setNewReleaseTime={setNewReleaseTime}
            setUpRemarks={setUpRemarks}
            setSetUpRemarks={setSetUpRemarks}
            releaseTimeInvalidMsg={releaseTimeInvalidMsg}
            viewDate={viewDate}
        /> : <LeftEditComponent
            isEdit={isEdit}
            enableUpdateBooking={enableUpdateBooking}
            selectDateClick={selectDateClick}
            selectAvailableDateClick={selectAvailableDateClick}
            setResNoClick={setResNoClick}
            updateCalendarNo={updateCalendarNo}
            submitClick={submitClick}
            cancelClick={cancelClick}
            allAvailableChecked={allAvailableChecked}
            selectDateChecked={selectDateChecked}
            selectedDateText={selectedDateText}
            resNo={resNo}
            selectedDateList={selectedDateList}
            resNoInvalidMsg={resNoInvalidMsg}
            isDirty={isDirty}
            selfServiceList={selfServiceList}
            projectList={projectList}
            selectionChoose={selectionChoose}
            setSelectionChoose={setSelectionChoose}
            setProject={setProject}
            project={project}
            selfService={selfService}
            setSelfService={setSelfService}
            setIsBatch={setIsBatch}
            isBatch={isBatch}
            setResNo={setResNo}
            rejectReason={rejectReason}
            setRejectReason={setRejectReason}
            setUpRemarks={setUpRemarks}
            bookingAllProject={bookingAllProject}
            bookingAllSelfService={bookingAllSelfService}
            setSelectedDateList={setSelectedDateList}
            handleOpenDirtyCheck={handleOpenDirtyCheck}
            bookingsList={bookingsList}
        />
    }
    return (<>
        {isEdit ? renderEdit() : <LeftViewComponent
            selfServiceList={selfServiceList}
            projectList={projectList}
            allRecordChecked={allRecordChecked}
            setAllRecordChecked={setAllRecordChecked}
            selectionChecked={selectionChecked}
            setSelectionChecked={setSelectionChecked}
            setProject={setProject}
            project={project}
            selfService={selfService}
            setSelfService={setSelfService}
            isBatch={isBatch}
            setIsShowFirstTime={setIsShowFirstTime}
            isShowFirstTime={isShowFirstTime}
            firstTimeRef={firstTimeRef}
        />}
    </>)
}

export default AMViewLeft;