import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useStyles } from "Styles";
import Badge from '@material-ui/core/Badge';
import { Link, useHistory, Link as DomLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as React from 'react';
import SessionExtendDialog from 'components/common/SessionExtendDialog';
import TimeOutDialog from 'components/common/TimeoutDialog';

import headerIcon from '../../../images/headerIcon.png';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '../../../images/home.svg';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import useSysParam from 'hooks/UseSysParam';
import useNavFixed from 'hooks/useNavFixed';
import AccessQrCode from 'components/accessQrCode';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PushPinIcon from '../../../images/PushPinIcon.svg';
import PushPinIconActiveIcon from '../../../images/PushPinIconActive.svg';
import { HomeLayoutViewContext } from 'components/HomeLayout';
import format from 'date-fns/format';


function HomeLayoutPC(props) {
    const {
        classes, user, sessionExtendOpen, timeoutOpen, emails,
        addresses, usefulLinks, sitemaps, logoLink, contactEmail,
        location, openQrCode, navRef, isFixed, notificationAnchorEl, setOpenQrCode,
        setNotificationAnchorEl, getUserEmail, promptLogout,
        onSessionExtend, closeSessionExtend, closeTimeout, doLogout, getExpTime, lastLoginTime, goTop, clickMenu, clickNotification,
        notifications, handlePinned, notificationRedirect, handleDeleteNotification, showNtfRedirect, showNtfClose,
        loadNotifications
    } = React.useContext(HomeLayoutViewContext)

    const renderNotificationItem = (ntf) => {
        return <Grid container style={{
            padding: '10px',
            borderBottom: '1px solid #dcdcdc',
            fontSize: 13,
            position: 'relative',
            background: ntf.isRead ? 'unset' : '#DEECF3',
        }} alignItems='start'>
            <Grid container item style={{ flex: 1, color: ntf.isRed ? 'red' : 'unset' }} dangerouslySetInnerHTML={{ __html: ntf.title }}></Grid>
            <Grid container item style={{ width: 66 }} justifyContent={'flex-end'}>
                <IconButton aria-label="PushPinIcon" style={{ padding: 2, height: 22, width: 22 }} onClick={() => handlePinned(ntf)} >
                    <img style={{ width: 18 }} src={ntf.isPinned ? PushPinIconActiveIcon : PushPinIcon} />
                </IconButton>
                {
                    showNtfRedirect(ntf) &&
                    <IconButton aria-label="KeyboardArrowRightIcon" style={{ padding: 2, height: 22, width: 22 }} onClick={() => notificationRedirect(ntf)} >
                        <KeyboardArrowRightIcon style={{ fontSize: 20, color: '#f58220' }} size='small' />
                    </IconButton>
                }
                {
                    showNtfClose(ntf) && <IconButton aria-label="close" style={{ padding: 2, height: 22, width: 22 }} >
                        <CloseIcon style={{ fontSize: 20, color: '#000' }} size='small' onClick={() => handleDeleteNotification(ntf)} />
                    </IconButton>
                }
            </Grid>
            <Grid container style={{ color: '#9a9a9a' }}>{format(new Date(ntf.ntfDate), 'd MMM yyyy')}</Grid>
        </Grid>
    }

    return (
        <div style={{ height: '100vh', width: '100%', fontFamily: 'Quicksand-SemiBold', minWidth: 1000 }}>
            <CssBaseline />
            <Grid container direction='column' style={{ height: '94.5px' }} flexWrap={'nowrap'} >
                <Grid container ref={navRef} className={isFixed ? classes.fixedTop : ''}>
                    <Grid item container>
                        <Grid container justifyContent='space-between' style={{ padding: '5px', backgroundColor: '#fff' }} alignItems='center'>
                            <Grid item>
                                <a href={logoLink} target={'_blank'}><img src={headerIcon} /></a>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <Grid item >
                                        <Grid container direction='column' style={{ fontSize: '13px' }}>
                                            <Grid item container alignItems='center' justifyContent='flex-end'>
                                                <Grid item style={{ fontWeight: 'bold' }}>
                                                    {getUserEmail()}
                                                </Grid>
                                                <Grid item className={classes.logoutButton}
                                                    onClick={() => { doLogout() }}>
                                                    Logout
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                {lastLoginTime(localStorage.getItem('lastLoginTime'))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <IconButton className={classes.bellButton} onClick={clickNotification}>
                                            {/* badgeContent={3} */}
                                            <Badge color="secondary" badgeContent={notifications.length} >
                                                <Avatar sx={{ bgcolor: '#15A9D2', width: 30, height: 30 }}>
                                                    <NotificationsIcon sx={{ color: 'white' }} fontSize="medium" />
                                                </Avatar>
                                            </Badge>
                                        </IconButton>
                                        {notificationAnchorEl && <Popover
                                            id={'notification-popover'}
                                            open={true}
                                            anchorEl={notificationAnchorEl}
                                            onClose={() => {
                                                setNotificationAnchorEl(null);
                                                loadNotifications();
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Grid container style={{ width: 365 }}>
                                                <Grid container style={{
                                                    padding: '10px',backgroundColor: '#ffefe1'
                                                    , height: 40, position: 'fixed', zIndex: 1, width: 365
                                                }}>
                                                    <Grid item style={{ fontSize: 13, color: '#f58220' }}>Notification</Grid>
                                                    <IconButton aria-label="close" style={{ position: 'absolute', top: 5, right: 5, padding: 5 }} onClick={() => { setNotificationAnchorEl(null) }}>
                                                        <CloseIcon style={{ fontSize: 20, color: '#000' }} size='small' />
                                                    </IconButton>
                                                </Grid>
                                                <Grid container style={{ color: '#000', marginTop: 40 }}>
                                                    {
                                                        notifications && notifications.map((ntf) => {
                                                            return renderNotificationItem(ntf)
                                                        })
                                                    }
                                                    {/* <Grid container style={{ padding: '10px', borderBottom: '1px solid #dcdcdc', fontSize: 13 }} alignItems='center'>
                                                        <Grid container item style={{ flex: 1 }}>Urgent Close at 13:00 on 11 Mar 2022</Grid>
                                                        <Grid container item style={{ width: 44 }}>
                                                            <IconButton aria-label="PushPinIcon" style={{ padding: 2, height: 22, width: 22 }} >
                                                                <img style={{ width: 18 }} src={PushPinIcon} />
                                                            </IconButton>
                                                            <IconButton aria-label="KeyboardArrowRightIcon" style={{ padding: 2, height: 22, width: 22 }} >
                                                                <KeyboardArrowRightIcon style={{ fontSize: 20, color: '#f58220' }} size='small' />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid container style={{ color: '#9a9a9a' }}>31 Mar 2022</Grid>
                                                    </Grid>
                                                    <Grid container style={{ padding: '10px', borderBottom: '1px solid #dcdcdc', fontSize: 13 }} alignItems='center'>
                                                        <Grid container item style={{ flex: 1 }}>Below researcher has not signed-out:</Grid>
                                                        <Grid container item style={{ width: 44 }}>
                                                            <IconButton aria-label="PushPinIcon" style={{ padding: 2, height: 22, width: 22 }} >
                                                                <img style={{ width: 18 }} src={PushPinIconActiveIcon} />
                                                            </IconButton>
                                                            <IconButton aria-label="KeyboardArrowRightIcon" style={{ padding: 2, height: 22, width: 22 }} >
                                                                <KeyboardArrowRightIcon style={{ fontSize: 20, color: '#f58220' }} size='small' />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid container >Axxxx WONG T** M**</Grid>
                                                        <Grid container >[1 Apr 2022]</Grid>
                                                        <Grid container style={{ color: '#9a9a9a' }}>31 Mar 2022</Grid>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Popover>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.topMenu}>
                        <Grid item xs={'auto'} style={{ width: '60px', height: '40px' }}>
                            <NavLink onClick={goTop} tag={Link} className="text-white" to={'/homepage'}
                                active={location.pathname === '/homepage'} style={{ padding: 'auto' }}
                            ><img src={HomeIcon} /></NavLink>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        {
                            user?.menus?.topMenus?.map((item) => {
                                return (
                                    <React.Fragment key={item.code}>
                                        <Grid item xs>
                                            {
                                                item.code === 'TM_CONTACT_US' ? <NavLink
                                                    onClick={goTop} className="text-white"
                                                    active={location.pathname === item.path}
                                                > <a className="text-white" style={{ textDecoration: 'none' }} href={`mailto:${contactEmail || ''}`}>{item.title}</a></NavLink> : <NavLink onClick={() => clickMenu(item.code)} tag={Link} className="text-white" to={item.path}
                                                    active={location.pathname === item.path}
                                                >{item.title}</NavLink>
                                            }

                                        </Grid>
                                        <Divider orientation="vertical" flexItem />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ margin: '0px', width: '100%' }} >
                {props.children}
            </div>
            <Grid container direction='column' style={{ backgroundColor: '#333' }}>
                <Grid item xs='auto' container className={classes.bottomBox}>
                    <Grid item container>
                        <Grid item xs={4} container>
                            <List>
                                {/* <ListItem>
                                    <NavLink tag={DomLink} className="text-white" to={'#'}>HADCL WEBSITE</NavLink>
                                </ListItem> */}
                                <ListItem>
                                    Contact Us
                                </ListItem>
                                <ListItem>
                                    <span style={{ lineHeight: 'initial' }}>For HADCL Portal related enquiries,</span>
                                </ListItem>
                                <ListItem>
                                    <EmailIcon className={classes.emailIcon} />&nbsp;
                                    please contact &nbsp;<a style={{ textDecoration: 'underline' }} href={`mailto:${emails.length && emails[0].content}`}>{emails.length && emails[0].content}</a>
                                </ListItem>
                                {contactEmail && <>
                                    <ListItem>
                                        <span style={{ lineHeight: 'initial' }}>For other enquiries,</span>
                                    </ListItem>
                                    <ListItem>
                                        <EmailIcon className={classes.emailIcon} />&nbsp;
                                        please contact &nbsp;<a style={{ textDecoration: 'underline' }} href={`mailto:${contactEmail}`}>{contactEmail}</a>
                                    </ListItem>
                                </>}

                                <ListItem>
                                    <LocationOnIcon className={classes.emailIcon} />&nbsp;
                                    <span style={{ lineHeight: 'initial' }}>{addresses.length && addresses[0].content}</span>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={4}>
                            <List>
                                <ListItem>
                                    FUNCTIONS
                                </ListItem>
                                {
                                    sitemaps.map(sm => {
                                        return (
                                            <ListItem key={sm.code}>
                                                <ArrowForwardIosIcon style={{ fontSize: '12px' }} />&nbsp;<NavLink onClick={() => { clickMenu(sm.code) }} tag={DomLink} className="text-white" to={sm.path}>{sm.title}</NavLink>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Grid>
                        <Grid item container xs={4}>
                            <List>
                                <ListItem>
                                    USEFUL LINKS
                                </ListItem>
                                {
                                    usefulLinks.map(ul => {
                                        return (
                                            <ListItem key={ul.id}>
                                                <ArrowForwardIosIcon style={{ fontSize: '12px' }} />&nbsp;<a style={{ textDecoration: 'underline' }} href={ul.href} target='_blank'>{ul.title}</a>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container style={{ color: '#ffff', fontSize: '13px', padding: '8px 50px', height: '37px' }}>
                    <Grid item>
                        Copyright © {new Date().getFullYear()} Hospital Authority. All rights reserved.
                    </Grid>
                </Grid>
            </Grid>
            {/* </main> */}
            <SessionExtendDialog open={sessionExtendOpen} getExpTime={getExpTime} onClose={closeSessionExtend} onExtend={onSessionExtend} onOpenLogOut={promptLogout} />
            <TimeOutDialog open={timeoutOpen} onClose={closeTimeout} />
            {openQrCode && <AccessQrCode onClose={() => { setOpenQrCode(false) }} />}
        </div >
    );
}


export default HomeLayoutPC;
