import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./Styles";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt, useLocation } from 'react-router-dom'; 
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import useModal from "hooks/useModal";
import useUser from 'hooks/UseFindUser';
import { warning, success } from 'components/common/Constants';
import ReportDraftEditor from 'components/progressReports/researcher/detail/ReportDraftEditor';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import { OutputManagementContext } from './index';
import ExamplePart3 from '../researcher/ExamplePart3'
import ExamplePart2 from '../researcher/ExamplePart2'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { statusColor } from '../researcher/OutputRequestList';
import isValid from 'date-fns/isValid';
import DatePicker from '@material-ui/lab/DatePicker';
export default function OutputManagementDetail(props) {
    const classes = useStyles();
    const {
        isCreate, handleBack,
        currentDetail, setIsLoading, setReload, reload, selectedProject,isOutputRequest, selectedProjectObj, user
    } = React.useContext(OutputManagementContext)
    const { setUser } = React.useContext(UserContext);
    const history = useHistory();
    
    const showModal = useModal();
    const [state, setBaseState] = React.useState({ models: [], files: [] })
    const [nextPath, setNextPath] = React.useState(null);
    const [error, setError] = React.useState({});
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const draftEditorRef = React.useRef({})
    const [openExample3, setOpenExample3] = React.useState(false)
    const [openExample2, setOpenExample2] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [wishOutputModel, setWishOutputModel] = React.useState(false)
    const isNoBack = React.useRef(null);
    const [status, setStatus] = React.useState("")

   
    const disableSubmit = (state.statusCode == "INCOMPLETED") ? true : false;
    
   

    useEffect(() => {
        if (!isCreate && currentDetail) {
            loadDetail()
        } else {
            setBaseState((oldState) => ({
                ...oldState,
                requestByName: user.user.email,  
                projectId: selectedProject,  
                requestType: isOutputRequest ? "OUTPUT" : "PUBLICATION",
                refNum: selectedProjectObj.refNum
            }))
        }
    }, [currentDetail, isCreate])

    

    
    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    const handleChangeInput = (value, field) => {
        let isError = false
        if (error[field]) {
            isError = false
        }
        if (isError !== error[field]) {
            setError((s) => ({ ...s, [field]: isError }))
        }
        handleSetBaseState({ [field]: value })
    }
    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {

            history.push(nextPath);
        }

    }, [history, isDirty, nextPath])

    const loadDetail = () => {
        setIsLoading(true);
        axios.get('/outputRequest/get-output-request-detail', { params: { outputId: currentDetail.id, projectId: currentDetail.projectId } }).then((res) => {
            const data = res.data;
            if (data) {
                draftEditorRef.current = {
                    remarksRsp: data.remarksRsp,
                };
                if (data.statusCode == "UNDER_REVIEW" || data.statusCode == "REVISION" || data.statusCode == "APPROVED" || data.statusCode == "REJECTED") {
                    setStatus(data.statusCode)
                }
                if (!res.data.models || res.data.models.length == 0) {
                    setWishOutputModel(false)
                } else {
                    setWishOutputModel(true)
                }
                setBaseState(data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/output-request-management'))
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                handleSave()
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your Output Request is not yet saved. </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        
        return false;
    }

   
    const handleCheck = (payload) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.AllMandatory;
        if (!payload.status) {
            errorTemp.status = true;
            isError = true
        }

        
        
        if (payload.remarksRsp) {
            let cleanCode = payload.remarksRsp.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
            
            if (cleanCode.length > 2000) {
                errorTemp.remarksRsp = true;
                !isError && (msg = warning.RichLength)
                isError = true
            }
        }
        if (status && status == "APPROVED") {
            if (!payload.filePassword ) {
                errorTemp.filePassword = true;
                isError = true
                msg = warning.AdmApproved
            }
            if (!payload.retentionDate) {
                errorTemp.retentionDate = true;
                isError = true
                msg = warning.AdmApproved
            }
        }
        isNoBack.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }

    const handleEmailGenerated = (payload) => {
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request', payload)
        .then((res) => {
            if (res.data.code == 0) {
                let m = success.EmailGenerated
                setErrorMsg(m)
                setIsLoading(false);
            } else if (res.data.code == 1) {
                setIsLoading(false);
                setErrorMsg(res.data.data)
                isNoBack.current = true
            }
        }).catch(err => {
            let tempErrorMsg = err.response?.data?.message;
            setErrorMsg(tempErrorMsg ? tempErrorMsg : err.message)
            setIsLoading(false);
            isNoBack.current = true
        });
    }
    const onSubmit = (payload) => {
        const requests = {
            ...payload,
        }
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    setIsDirty(false)
                    setIsLoading(false);
                    if (payload.status == "REVISION" || payload.status == "APPROVED" || payload.status == "REJECTED") { 
                        showModal((pros) => (
                            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                                {...pros}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    payload.isSend = true;
                                    handleEmailGenerated(payload)
                                }}
                                onCancel={() => {
                                    
                                    setIsDirty(false)
                                    setReload(!reload)
                                    handleBack()
                                }}
                            >
                                <div>{success.Saved}</div>
                                <div style={{ padding: '0 10px' }}>Generate email to respective researcher?</div>
                            </SystemConfirmDialog>
                        ));
                    } else {
                        let m = success.Saved
                        setErrorMsg(m)
                    }
                    
                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }

            }).catch(err => {
                let tempMsg = err.response?.data?.message;
                setErrorMsg(tempMsg ? tempMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }
    
    const handleSave = () => {
        const payload = {
            id: state.id,
            status: status,
            filePassword: state.filePassword,
            retentionDate: state.retentionDate,
            resOfficer: state.resOfficer,
            isSend: false,
            remarksRsp: draftEditorRef.current.remarksRsp
        }
        
        if (handleCheck(payload)) {
            return
        }
        if (payload.retentionDate) {
            payload.retentionDate = format(startOfDay(new Date(payload.retentionDate)), "yyyy-MM-dd")
        }
        payload.remarksRsp = window.htmlEncode(payload.remarksRsp)
        
        onSubmit(payload)
    }

    const handleChangeTextArea = (value, fileName) => {
       
        
        let isError = false
        if (error[fileName]) {
            isError = false
        }


        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
        
    }

    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    
    const handleChangeModel = (value, filedName, index) => {
        const newModels = [...state.models]
        newModels[index][filedName] = value
        
        handleSetBaseState({ models: newModels })

    }
    
    const handleBackList = () => {
        if (isDirty && !disableSubmit) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    handleBack()
                },
            })
        } else {
            setIsDirty(false)
            handleBack()
        }
        
    }
    const handleCloseDialog = () => {
        if (!isDirty && !isNoBack.current) {
            handleBackList()
            setReload(!reload)
        }
        setErrorMsg('')
    }

    const handleRenderInput = ( value) => {
        return <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', maxHeight: 250, whiteSpace: 'break-spaces', display: 'flex' }}>
            <div title={value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', color: '#c1c1c1' }} dangerouslySetInnerHTML={{ __html: value }}>
            </div>

        </div>
        
    }
    const formatDateValue = (date) => {
        if (typeof date === 'string') {
            return new Date(date)
        }
        return date
    }
    return (
        <>
            <Grid container style={{ height: '100%' }} >
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Output Request
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container className={classes.title}>
                        Part I&nbsp;&nbsp;&nbsp;Requestor Information
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Request No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestNo}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Status</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{
                                <div title={state.status} style={{ width: '150px',height: 29, lineHeight:'29px', color: "#fff", borderRadius: 5, fontSize:13, textAlign:'center', ...(statusColor[state.statusCode])}} dangerouslySetInnerHTML={{__html: state.status}}>
                                </div>
                                
                            }</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Requested by </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestByName}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>
                                <div>
                                    <div>First Submission Date</div>
                                    <div>Last Submission Date</div>
                                </div>
                            </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <div>
                                    <div>{state.firstSubmissionDate && format(new Date(state.firstSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                    <div>{state.lastSubmissionDate && format(new Date(state.lastSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project / Self-Service No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Organisation</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(state.organisation)}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Reason of Request<span className={classes.red}>*</span> </Grid>
                            <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(state.reasonOfRequest)}

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                {/*  */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part II&nbsp;&nbsp;&nbsp;Requested File List <span className={classes.red}>*</span>&nbsp;<span style={{ cursor: 'pointer', color: '#15a9d2', textDecoration: 'underline' }} onMouseEnter={() => { setOpenExample2(true) }}>(e.g.)</span> &nbsp;(For export of models, please fill in details in part III)
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.projectHeader}>
                        <Grid container item style={{ width: 50, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >No.</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Name</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Path</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Description of the file(s)</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.projectTh} alignItems='center' >Reason of exporting the file(s)</Grid>
                        <Grid item style={{ width: 50 }} className={classes.projectTh} alignItems='center' ></Grid>
                    </Grid>
                    {
                        state.files.map((item, index) => {
                            return <Grid container>
                                <Grid container item style={{ width: 50, padding: '5px 10px' }} className={classes.projectTd}  >{index + 1} </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(item.name)}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(item.path)}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(item.desc)}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.projectTd} >
                                    {handleRenderInput(item.reason)}

                                </Grid> 
                            </Grid>
                        })
                    } 
                </Grid>
                {/* Part III Model list  (Please specify in the below details for different model(s))*/}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part III&nbsp;&nbsp;&nbsp;Model List &nbsp;<span style={{ cursor: 'pointer', color: '#15a9d2', textDecoration: 'underline' }} onMouseEnter={() => { setOpenExample3(true) }}>(e.g.)</span> &nbsp;(Please fill in this part if any model(s) is/are requested in part II)
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>I wish to output model <span className={classes.red}>*</span></Grid>
                            <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                                <Grid item style={{ paddingRight: 30 }}>
                                    <Button
                                        disabled={true}
                                        startIcon={checkIcon(wishOutputModel)}
                                        // className={classes.button}
                                        style={{
                                            textTransform: 'none',
                                            lineHeight: '1.5',
                                            fontSize: '13px'
                                        }}
                                       
                                    >
                                        <div className={classes.buttonLabel}>Yes</div>
                                    </Button>
                                </Grid>
                                <Button
                                    disabled={true}
                                    startIcon={checkIcon(!wishOutputModel)}
                                    
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    
                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        wishOutputModel && state.models.map((item, index) => {
                            return <Grid container style={{ paddingBottom: 20 }}>
                                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                    <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >
                                        <Grid item>
                                            {
                                                item.isClose ? <ArrowDropDownIcon style={{ color: '#15a9d2', cursor: 'pointer', marginTop: '-3px' }} onClick={() => { handleChangeModel(false, 'isClose', index) }} /> : <ArrowDropUpIcon style={{ color: '#15a9d2', cursor: 'pointer', marginTop: '-3px' }} onClick={() => { handleChangeModel(true, 'isClose', index) }} />
                                            }
                                        </Grid>
                                        <Grid item xs>
                                            Model:
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                        <Grid item xs>
                                            {handleRenderInput(item.model)}

                                        </Grid>

                                    </Grid>
                                </Grid>
                                {!item.isClose && <>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >File name:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.fileName)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Full file path:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.fullFilePath)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Algorithm:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.algorithm)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Mechanism of the model:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.mechanism)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Programming Language:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.language)}

                                        </Grid>
                                    </Grid>

                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Sample script file:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.sampleScript)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Script to load the model and run the prediction:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.modelScript)}

                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={'center'} style={{ fontWeight: '600', minHeight: 40, paddingLeft: 10 }} className={`${classes.lineBottom}`}>
                                        Input &nbsp;<i style={{ fontSize: 13, fontWeight: 'normal', lineHeight: '18px' }}>(Please provide details of the input parameters of the above model.)</i>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Input value, type and description:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.inputInfo)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Supplementary information:(optional)</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.inputSupp)}

                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={'center'} style={{ fontWeight: '600', minHeight: 40, paddingLeft: 10 }} className={`${classes.lineBottom}`}>
                                        Output &nbsp;<i style={{ fontSize: 13, fontWeight: 'normal', lineHeight: '18px' }}>(Please provide details of the result or output of the above model.)</i>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Output value and type:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.outputInfo)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Description/ Explanation of output:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.outputDesc)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Usage of output:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.outputUsage)}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Supplementary information:(optional)</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(item.outputSupp)}

                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        })

                    }
                </Grid>

                {/* Part IV  Comments from Researchers */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part IV&nbsp;&nbsp;&nbsp;Remarks from Researcher
                    </Grid>
                    <Grid container>
                        
                        <ReportDraftEditor disabled={true} placeholder={'≤2000 characters'}  value={state.remarksReq} />

                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part V&nbsp;&nbsp;&nbsp;Comments from HADCL Office
                    </Grid>
                    <Grid container>
                        
                        <ReportDraftEditor disabled={disableSubmit}  placeholder={'≤2000 characters'} isError={error.remarksRsp} value={state.remarksRsp} onChange={(e) => handleChangeTextArea(e, 'remarksRsp')} />
                        
                    </Grid>
                </Grid>
                
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} alignContent='center' className={classes.label}>Status After Review by HADCL <span className={classes.red}> *</span>:</Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <SelectComponent
                            disabled={disableSubmit}
                            displayEmpty={false}
                            value={status}
                            onChange={(v) => {
                                if (v != 'APPROVED') {
                                    handleChangeInput("", 'filePassword')
                                    handleChangeInput(null, 'retentionDate')
                                }
                                setStatus(v)
                                setIsDirty(true);
                            }}
                            style={{
                                textTransform: 'none',
                                fontSize: '13px',
                                width: '200px',
                                ...(error.status ? {border: '1px solid #ff0000'} : {})
                            }}
                            options={[
                                {
                                    value: 'UNDER_REVIEW',
                                    name: 'Under Review'
                                },
                                {
                                    value: 'REVISION',
                                    name: 'Revision Required'
                                },
                                {
                                    value: 'APPROVED',
                                    name: 'Approved'
                                },
                                {
                                    value: 'REJECTED',
                                    name: 'Rejected'
                                }
                        ]} />
                    </Grid>
                </Grid>
                {status == "APPROVED" && <><Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} alignContent='center' className={classes.label}>Password:</Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <TextField
                            fullWidth
                            id="filled-filePassword"
                            label=""
                            multiline
                            disabled={disableSubmit}
                            className={classes.textareaBox}
                            variant="outlined"
                            value={state.filePassword}
                            inputProps={{ maxLength: 64 }}
                            InputProps={{
                                style: {
                                    width: 200,
                                    ...(error.filePassword ? { border: '1px solid #ff0000' } : {})
                                }
                            }}
                            onChange={(e) => handleChangeInput(e.target.value, 'filePassword')}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} alignContent='center' className={classes.label}>Output Retention Date: </Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <DatePicker
                            disabled={disableSubmit}
                            inputFormat="dd/MM/yyyy"
                            value={formatDateValue(state.retentionDate || '')}
                            onChange={(newValue) => {
                                if (isValid(newValue)) {
                                    handleChangeInput(newValue, 'retentionDate')
                                }
                            }}
                            views={['year', 'month', 'date']}
                            renderInput={(params) => {
                                params.inputProps.placeholder = "DD/MM/YYYY";
                                return <TextField  {...params} helperText={null} className={classes.DateText} error={error.retentionDate} />
                            }}
                        />
                    </Grid>
                </Grid>
                </>}
                
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} alignContent='center' className={classes.label}>Responsible officer:</Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <TextField
                            fullWidth
                            id="filled-resOfficer"
                            label=""
                            multiline
                            disabled={disableSubmit}
                            inputProps={{ maxLength: 20 }}
                            className={classes.textareaBox}
                            placeholder="≤20 characters"
                            variant="outlined"
                            value={state.resOfficer}
                            InputProps={{
                                style: {
                                    width: 200
                                }
                            }}
                            onChange={(e) => handleChangeInput(e.target.value, 'resOfficer')}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <div className={classes.mandatory}>*=Mandatory</div>
                </Grid>
                <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs>
                        {!disableSubmit && <Button style={{
                                backgroundColor: '#15a9d2 ',
                                color: '#ffff ',
                                marginRight: 10,
                                textTransform: 'none'
                        }} className={classes.activeButton}  onClick={() => handleSave()}>Save</Button>}
                        <Button style={{
                            backgroundColor: '#646464 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleBackList}>{disableSubmit ? "Back to Summary" : "Cancel"}</Button>
                    </Grid>
                    
                </Grid>
                {state.lastUpdatedName && <div style={{ paddingTop: 10 }}>Last updated by {state.lastUpdatedName} on {format(new Date(state.lastUpdatedDate), 'dd MMM yyyy HH:mm')} </div>}
            </Grid>
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            {openExample3 && <ExamplePart3 open={openExample3} onClose={() => { setOpenExample3(false) }} />}
            {openExample2 && <ExamplePart2 open={openExample2} onClose={() => { setOpenExample2(false) }} />}
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </>
    );
}
