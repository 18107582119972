import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HadclCalendar from '../common/HadclCalendar';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { DateNoTime } from '../common/HadclFormatter'
import React from 'react';
import endOfMonth from 'date-fns/endOfMonth';
import StartOfToday from 'date-fns/startOfToday';
import addMonths from 'date-fns/addMonths';
import { useStyles } from "Styles";
import axios from '../../CustomAxios';

const { forwardRef } = React;

const ColStatus= forwardRef((props, ref) => {
    const [selectedDate, setSelectedDate] = React.useState(StartOfToday);
    const maxDate = endOfMonth(addMonths(new Date(), 1));
    const [quota, setQuota] = React.useState(null);

    const classes = useStyles();

    const changeDate = (date) => {
        setSelectedDate(date);
    }

    const availableQuota = async () => {
        await axios.get('bookSetup/available-quota', {
            params: {
                startDate: selectedDate,
                endDate: selectedDate
            }
        }).then(res => {
            // console.log(selectedDate)
            setQuota(res.data[0].availability);
        }).catch(err => {
            console.log(err);
        })
    }

    React.useEffect(function effectFunction() {
        availableQuota();
    }, [selectedDate]);

    return (
        <>
        <Typography variant="h5" gutterBottom xs={12}>
            Lab Availability
        </Typography>
        <Grid container>
            <Grid item xs={12} md={5}>
                <HadclCalendar
                    value={selectedDate}
                    onChange={(newValue) => {
                        changeDate(newValue);
                    }}
                    onMonthChange={(d) => {
                        changeDate(d)
                    }}
                    maxDate={maxDate}
                />
            </Grid>
            <Grid item md={1}> </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth className={classes.input}>
                    <TextField
                        id="selectedDate"
                        type="text"
                        label="Selected Date"
                        value={DateNoTime(selectedDate)}
                        defaultValue=""
                        disabled
                    />
                </FormControl>
                <FormControl fullWidth className={classes.input}>
                    <TextField
                        name="capacity"
                        type="number"
                        label="No. of Access Available"
                        disabled
                        value={quota}
                        defaultValue=""
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                <Typography variant="body1" gutterBottom>
                    Note: All access requests after the cut-off date will be treated as ad-hoc requests. Please send your ad-hoc access requests to HADCL Office at hadcl.service@ha.org.hk at least 3 working days in advance.
                </Typography>
                <br /><br /><br />
            </Grid>

        </Grid>
        </>
    );
}
)
export default ColStatus;
