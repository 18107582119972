import React from 'react';
import DirtyCheckContext from './DirtyCheckContext';
import SystemMessageDialog from 'components/common/SystemMessageDialog';

const { Provider, Consumer: DirtyCheckConsumer } = DirtyCheckContext;
const DirtyCheckProvider = props => {
    const [openDirtyCheckDialog, setOpenDirtyCheckDialog] = React.useState(false);
    const [dirtyCheckMsgObj, setDirtyCheckMsgObj] = React.useState({});
    const [isDirty, setIsDirty] = React.useState(false);

    React.useEffect(() => {
        if (!openDirtyCheckDialog && Object.keys(dirtyCheckMsgObj).length > 0)
            setDirtyCheckMsgObj({});
    }, [dirtyCheckMsgObj, openDirtyCheckDialog])

    const onClose = () => {
        setOpenDirtyCheckDialog(false)
    }

    return (
        <Provider value={{
            openDirtyCheckDialog,
            setOpenDirtyCheckDialog,
            dirtyCheckMsgObj,
            setDirtyCheckMsgObj,
            isDirty,
            setIsDirty,
        }} {...props}>
            {props.children}
            {Object.keys(dirtyCheckMsgObj).length > 0 &&
                <SystemMessageDialog
                    open={openDirtyCheckDialog}
                    onClose={onClose}
                    isShowCloseButton={true}
                    {...dirtyCheckMsgObj}
                >
                    <dirtyCheckMsgObj.MsgContent />
                </ SystemMessageDialog>
            }
        </Provider>
    )
}

const useDirtyCheck = () => {
    const context = React.useContext(DirtyCheckContext);
    if (context === undefined) {
        throw new Error('useDirtyCheck must be used within a DirtyCheckContext');
    }

    return context;
}

export { DirtyCheckProvider, useDirtyCheck, DirtyCheckConsumer };