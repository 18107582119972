import { useState, useEffect } from 'react';
import axios from '../CustomAxios';

export default function useInformation(type) {
    const [informations, setInformations] = useState([]);

    const getInformations = async () => {
        return axios.get(`/information/get-by-type`, {
            params: {
                type
            }
        }).then(async (res) => {
            setInformations(res.data);
        });
    }

    useEffect(() => {
        if (type)
            getInformations();
    }, [type]);

    return {
        informations,
    }
}