import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Divider, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoginViewContext } from 'components/Login.page';
import LoginIcon from 'images/loginIcon.png';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    Grid,
} from '@material-ui/core';
import LoadingScreen from 'components/common/LoadingScreen';
import LockPromptDialog from 'components/common/LockPromptDialog';
import { Controller, useForm } from "react-hook-form";
import { useStyles } from './Login.page.style';
import login_bg_mobile from 'images/login_bg_mobile.png';
import NoticeDialog from './NoticeDialog';
const LoginMobile = () => {
    const {
        isLoading, theme,  iptNotice, usefulInfo,  logoLink,
        handleSubmit, onSubmit, control, email, setEmail, errors, password, setPassword,
        handleClickShowPassword, handleMouseDownPassword, showPassword, showLockTip,
        setShowLockTip, lockTipContent, setErrMsg, isError, loginRemainTime
    } = useContext(LoginViewContext)

    const classes = useStyles();

    const [noticeOpen, setNoticeOpen] = React.useState(false)

    React.useEffect(() => {
        if (Array.isArray(iptNotice) && iptNotice.length > 0) {
            setNoticeOpen(true)
        }
    }, [iptNotice])
    React.useEffect(() => {
        if (errors.email?.message) {
            setErrMsg(errors.email?.message)
        }
    }, [errors.email])
    React.useEffect(() => {
        if (errors.password?.message) {
            setErrMsg(errors.password?.message)
        }
    }, [errors.password])

    return <div style={{ height: '100%', overflow: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <div style={{ height: '100%', overflowY: 'auto' }}>
            <LoadingScreen open={isLoading} />
            <ThemeProvider theme={theme}>
                <Grid container className={classes.fullHeight} flexWrap={'nowrap'}>
                    <Grid container item >
                        <span className={classes.blueRound} />
                        <span className={classes.yellowRound} />
                        <Grid container direction='column'>
                            <Grid container>
                                <div>
                                    <img width="75%" src={login_bg_mobile} />
                                </div>
                            </Grid>
                            {/* <Grid container>
                            </Grid> */}
                            <div>
                                <a href={logoLink} target={'_blank'}>
                                    <img style={{ maxWidth: '100%' }} src={LoginIcon} alt={'icon'} className={classes.loginIcon} />
                                </a>
                            </div>
                            {/* <Grid container className={classes.iconDivider}></Grid> */}
                            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <Grid container direction={'column'} className={classes.loginFormContainer}>
                                    <Grid item style={{ lineHeight: 1.1 }}>
                                        <div className={classes.welcomeFont}>Welcome to Hospital Authority</div>
                                        <div className={classes.welcomeFont}>Data Collaboration Laboratory Portal{process.env.REACT_APP_ENV_DESC || ""}</div>
                                    </Grid>
                                    <Grid item container direction={'column'} className={classes.loginInputBox}>
                                        <Grid item className={classes.loginFont}>
                                            LOGIN
                                        </Grid>
                                        <Grid item className={classes.LoginInputWrapper}>
                                            <FormControl fullWidth>
                                                <FormLabel htmlFor="email" className={classes.blackColor}>Email</FormLabel>
                                                <Controller
                                                    as={TextField}
                                                    id={'email'}
                                                    name="email"
                                                    control={control}
                                                    required
                                                    fullWidth={true}
                                                    value={email}
                                                    onInput={e => setEmail(e.target.value)}
                                                    error={errors.email && true}
                                                    // helperText={errors.email?.message}
                                                    variant="standard"
                                                    InputProps={{
                                                        className: classes.inputStyle,
                                                    }}
                                                    style={{ color: 'red' }}
                                                    inputProps={{
                                                        maxLength: 128,
                                                        style: {
                                                            color: (isError || errors.email) && 'red'
                                                        }
                                                    }}
                                                // {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.loginPwdInputWrapper}>
                                            <FormControl fullWidth>
                                                <FormLabel htmlFor="password" className={classes.blackColor}>Password</FormLabel>
                                                <Controller
                                                    as={TextField}
                                                    control={control}
                                                    required
                                                    id='password'
                                                    name='password'
                                                    value={password}
                                                    onInput={e => setPassword(e.target.value)}
                                                    type={showPassword? 'text' : 'password'}
                                                    autoComplete='off'
                                                    error={errors.password && true}
                                                    // helperText={errors.password?.message}
                                                    style={{ height: '30px' }}
                                                    variant="standard"
                                                    InputProps={{
                                                        className: classes.inputStyle,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    style={{ color: 'black' }}
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        maxLength: 64,
                                                        style: {
                                                            fontFamily: showPassword || 'text-security-disc',
                                                            color: (isError || errors.email) && 'red'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item className={classes.forgetPwdWrapper}>
                                            <Link
                                                href={'#'}
                                                className={classes.forgetPwd}
                                            >
                                                Forget Password?
                                            </Link>
                                        </Grid> */}
                                        <Grid item className={classes.LoginInputWrapper}>
                                            <FormControl>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        textTransform: 'none',
                                                        fontFamily: 'Quicksand-SemiBold',
                                                        fontSize: 16
                                                    }}
                                                    disabled={loginRemainTime > 0}
                                                >
                                                    Login
                                                    {loginRemainTime > 0 ? <CircularProgress size={16} /> : null}
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid container style={{ flex: 1, minHeight: 90, position:'relative' }}>
                                <div className={classes.InfoWrapper} style={{position: 'absolute', top: 0,left: 0, height: '100%'}}>

                                    <Typography color={'primary'} className={classes.titleFont}>USEFUL INFORMATION</Typography>
                                    <Divider className={classes.InfoDivider} />
                                    <div className={classes.InfoContainer}>
                                        {Array.isArray(usefulInfo) && usefulInfo.length > 0 &&
                                            usefulInfo.map((item, index) => (
                                                <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <NoticeDialog open={noticeOpen} onClose={() => setNoticeOpen(false)} content={<div className={classes.InfoContainer} style={{ maxHeight: 300 }}>
                {Array.isArray(iptNotice) && iptNotice.length > 0 &&
                    iptNotice.map((item, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    ))
                }
            </div>} />
            <LockPromptDialog open={showLockTip} onClose={() => setShowLockTip(false)} content={lockTipContent} />
        </div>
    </div>
}

export default LoginMobile;
