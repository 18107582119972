
import Grid from '@material-ui/core/Grid';
import { useStyles } from "Styles";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from '../../CustomAxios';
import React, { useCallback, useEffect } from 'react';
import { Link as DomLink, useHistory } from 'react-router-dom';
import useUser from '../../hooks/UseFindUser';
import RenderPcOrMobile, { isMobilePhone } from '../RenderPcOrMobile';
import HomePagePC from 'view/pc/homepage/Homepage.pc';
import HomepageMobile from 'view/mobile/homepage/Homepage.mobile';
import { useMsg } from 'hooks/MsgProvider';

export const HomepageViewContext = React.createContext({});
export default function Homepage(props) {
    const classes = useStyles();
    const { user } = useUser();
    let history = useHistory();
    
    const middleMenus = user && user.menus && user.menus.middleMenus || [];

    const [activeStep, setActiveStep] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(4);

    const [openQrCode, setOpenQrCode] = React.useState(false);
    const [canQrCode, setCanQrCode] = React.useState(false);

    const [defaultSize] = React.useState(isMobilePhone() ? 6 : 4)

    const onResize = useCallback(() => {
        const cw = document.documentElement.clientWidth;
        if (cw <= 1024) {
            setPageSize(defaultSize);
            return;
        }
        setPageSize(parseInt((cw - 60) / 240));
    }, [])

    useEffect(() => {
        setActiveStep(0);
    }, [pageSize])

    useEffect(() => {
        axios.get(`/information/get-news`, {
            params: {
                pageIndex: 1,
                pageSize: 10
            }
        })

        window.addEventListener('resize', onResize)
        onResize();
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])
    const getSize = middleMenus.length % pageSize > 0 ? parseInt(middleMenus.length / pageSize) + 1 : parseInt(middleMenus.length / pageSize)
    const maxSteps = middleMenus.length ? getSize : 0;


    const activeMenus = middleMenus.slice(activeStep * pageSize, activeStep * pageSize + pageSize);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isPageClass = () => {
        return middleMenus.length > pageSize ? null : classes.visibilityHidden;
    }

    const midMenuClick = (path, code) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        history.push(path);
        if (code && code === 'MM_ACCESS_QR_CODE') {
            setOpenQrCode(true)
        }
    }

    return <HomepageViewContext.Provider value={{
        classes, activeStep, openQrCode, setOpenQrCode, maxSteps,
        activeMenus, handleNext, handleBack, isPageClass, midMenuClick, middleMenus, canQrCode,
        setCanQrCode
    }}>
        <RenderPcOrMobile pc={<HomePagePC {...props} />} mobile={<HomepageMobile {...props} />} />
    </HomepageViewContext.Provider>
}
