import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: "#fff",
        color: '#000',
        boxShadow: '0px 2px 0px -8px rgb(0 0 0 / 20%), 0px -1px 6px 1px rgb(0 0 0 / 14%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: 4,
        minWidth: 100,
        maxWidth: 300,
        marginTop: 8
    },
    arrow: {
        color: '#fff'
    }
}));
const CustomTooltip = ({ title, ...props }) => {
    const classes = useStyles();
    const renderTitle = () => {
        if (typeof title === 'string') {
            return <>
                <div dangerouslySetInnerHTML={{__html: title}}/>
            </>
        }
        return title
    }
    return <Tooltip {...props} title={renderTitle()} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} />
}

export default CustomTooltip;