import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Countdown from 'react-countdown';
import { confirmDialog } from './Constants'
import CloseBtnIcon from 'images/closeBtn.png';

export default function SystemMessageDialog(props) {
    const {  onClose,  open,  btn1Action, btn1Label, btn2Action, btn2Label, isShowButton = true, isShowCloseButton = false } = props;


    const handleOk = () => {
        onClose();
    };

   

    return (
        <Dialog
            open={open}
            // disableBackdropClick
            disableEscapeKeyDown
            // maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
        // style={{ maxWidth: 'auto' }}
        >
            <DialogTitle id="alert-dialog-confirm"
                style={{ textAlign: 'center', padding: '10px', position: 'relative' }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'System Message'}
                </Typography>
                    {isShowCloseButton && <IconButton aria-label="close" style={{position: 'absolute', top: 5, right: 5, padding: 5}} onClick={handleOk}>
                    <img src={CloseBtnIcon} width={40}/>
                </IconButton>}
            </DialogTitle>
            <DialogContent style={{ padding: '0 24px', color: '#000', fontSize: '16px', textAlign: 'center', fontFamily: 'Quicksand-SemiBold' }}>
                {props.children}
                {/* <DialogContentText id="alert-dialog-description" style={{  }}>
                    

                </DialogContentText> */}
            </DialogContent>
            <DialogActions style={{ paddingBottom: '16px', marginTop: 20 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item>
                        {isShowButton && <Button onClick={btn1Action && typeof btn1Action === 'function' ? btn1Action : handleOk} color="primary"
                            style={{ backgroundColor: '#f58220', padding: '10 24px', color: '#fff', textTransform: 'none', }}>
                            {btn1Label || 'OK'}
                        </Button>}
                        {btn2Action && typeof btn2Action === 'function' &&
                            <Button onClick={btn2Action} color="primary"
                                style={{ backgroundColor: '#646464', padding: '10 24px', color: '#fff', marginLeft: 10, textTransform: 'none', }}>
                                {btn2Label || 'Cancel'}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
