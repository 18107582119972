import axios from 'axios';
const CustomAxios = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT ||'api',
    headers: {
        Pragma: 'no-cache',
    },
});

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';


export default CustomAxios;