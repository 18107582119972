
import React, { useState, useEffect, createContext } from 'react';
import axios from 'CustomAxios'; 
import useUser from 'hooks/UseFindUser'; 
import bwipjs from 'bwip-js';
import RenderPcOrMobile from '../RenderPcOrMobile';
import AccessQrCodePC from 'view/pc/accessQrCode/accessQrCode.pc';
import AccessQrCodeMobile from 'view/mobile/accessQrCode/accessQrCode.mobile';

export const AccessQrCodeViewContext = createContext({});


export default function AccessQrCode(props) {
    const { onClose } = props; 
    const { user } = useUser();
    const [open, setOpen] = useState(false);
    const [occupiedOpen, setOccupiedOpen] = useState(false);
    const [updateAlertOpen, setUpdateAlertOpen] = React.useState(false);
    const [isReload, setIsReload] = React.useState(0);
    const [isLocked, setIsLocked] = React.useState(false);

    const [state, setState] = useState({
        isQrCode: false,
        project: '',
        refNum: '',
        projectList: [],
        remarks: '',
        token: '',
    });
    const { project, projectList, remarks, token, refNum } = state;

    const reportRef = React.useRef(null)

    const getReportStatus = (projectId) => {
        reportRef.current && reportRef.current.getReportStatus(projectId,  (res)=> { 
            if (res) {
                setIsLocked(true)
            } else {
                setIsLocked(false)
            }
        })
    }

    const handleSetState = (newState) => {
        setState(s => ({ ...s, ...newState }))
    }

    const getUserProject = async () => {
        await axios.get('/attendance/get-attendance-qrs', null).then(res => {
            // handleSetState({  })
            const projectQRs = res.data?.projectQRs || []
            if (projectQRs.length <= 0) {
                setUpdateAlertOpen(true)
                return
            }
            let projectTemp = projectQRs[0] || {};
            if (project) {
                projectQRs.forEach(element => {
                    if (element.projectId === project) {
                        projectTemp = element;
                    }
                });
            }

            setOpen(true)
            if (projectTemp?.isOccupied) {
                setOccupiedOpen(true)
            }
            // console.log(22112211)
            
            handleSetState({ isQrCode: true, projectList: projectQRs, project: projectTemp?.projectId, refNum: projectTemp?.refNum, token: projectTemp?.token, remarks: res.data?.remarks })
        }).catch(err => {
            console.log(err);
        })

    }

    useEffect(() => {
        if (user) {
            if (user?.roles.some((role) => role.toUpperCase().includes("COL_RESEARCHER") || role.toUpperCase().includes("SELF_RESEARCHER"))) {
                getUserProject()
            } else {
                onClose()
            }
        }
    }, [user])

    const createQrCode = () => {
        try {
            bwipjs.toCanvas('qrcode_canvas', {
                bcid: 'qrcode',       // Barcode type
                text: token,    // Text to encode
                barcolor: '0056AC',
                width: 50,
                height: 50,
            });
            // console.log(canvas)
        } catch (error) {
            console.error(error)
        }

    }

    useEffect(() => {
        if (token) {
            createQrCode();
            getReportStatus(state?.project)
        }
    }, [token])

    useEffect(() => {
        isReload && getUserProject();
    }, [isReload])

   

   
    const handleSelect = (v, o) => {
        handleSetState({ project: v })
        setOccupiedOpen(o?.isOccupied)
        setIsReload(isReload + 1)
    }
    const optionsClick = (v, o) => {
        if (project === v) {
            getUserProject()
        }
    }

    // console.log(isQrCode)
    return <AccessQrCodeViewContext.Provider value={{
        onClose,  open,  occupiedOpen, setOccupiedOpen,  updateAlertOpen, setUpdateAlertOpen
        , project, projectList, remarks, token, refNum
        , handleSelect, optionsClick, reportRef,isLocked
    }}>
        <RenderPcOrMobile pc={<AccessQrCodePC />} mobile={<AccessQrCodeMobile />} />
    </AccessQrCodeViewContext.Provider>
}
