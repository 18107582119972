import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        fontSize: 13
    },
    
    textareaBox: {
        width: '100%',
        textIndex: 10,
        '& textarea': {
            paddingLeft: 10,
            fontSize: 13
        }
        // paddingLeft: 10
    },
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    label: {
        minHeight: 32,
        padding: '4px 10px 4px 20px',
        lineHeight: '32px',
        backgroundColor: '#f3f3f3',
    },
    rightContent: {
        padding: '4px 10px 4px 20px',
        // lineHeight: '32px',
    },
    lineBottom: {
        // minHeight: 40,
        borderBottom: '1px solid #dcdcdc'
    },
    
    
    DateText: {
        backgroundColor: 'white',
        width: '130px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important',
            paddingRight: 0
        },
        '& .MuiButtonBase-root': {
            margin: 0,
            padding: 0,
            paddingRight: 2
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 2,
        },
        '& input': {
            padding: '5px 0 5px 10px',
            // paddingTop: 5,
        },
        '& input::-webkit-input-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
        '& input::-moz-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
    },
    
}));