import { FormControl, FormControlLabel, Typography, Card, CardContent, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import axios from '../../CustomAxios';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useStyles } from './Styles';
import LoadingScreen from '../common/LoadingScreen';
import { warning, error } from '../common/Constants';
import HadclDatePicker from '../common/HadclDatePicker';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SelectComponent from './SelectComponent';
import {
    startOfMonth
    , startOfDay
    , format
} from 'date-fns';


const schema = yup.object().shape({
    bookDate: yup.date().required().typeError(warning.ApprovalBookDate)
});


export default function GenerateEmailDialog(props) {
    const { open, viewDate } = props;
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(false);
    const { watch, handleSubmit, control, errors, setError, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { bookDate: startOfMonth(viewDate) },
    });

    const [options, setOptions] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const getList = (bookDate) => {
        const params = {
            bookMonth: startOfDay(bookDate)
        }
        axios.get('/bookRequest/get-booked-active-projects', { params }).then(({ data: result }) => {
            if (result) {
                setOptions(result)
                setSelected([])
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const watchBookDate = watch('bookDate');
    useEffect(() => {
        clearErrors("bookDate")
        const timeout = setTimeout(() => {
            if (watchBookDate && watchBookDate.toString().indexOf("Invalid Date") < 0) {
                getList(watchBookDate)
            } else {
                watchBookDate && setError("bookDate", { message: error.ApprovalEmail })
                setOptions([])
            }
        }, 400);

        return () => { timeout && window.clearTimeout(timeout) }
    }, [watchBookDate])

    const handleClose = () => {
        props.handleClose();
    }
    const onSubmit = (data = {}) => {
        const { bookDate } = data;
        if ((!sendAll && selected.length === 0) || !bookDate) {
            return;
        }
        let projects = selected;
        if (sendAll) {
            projects = options.map((item) => item.projectId)
        }
        const requests = {
            month: format(startOfDay(bookDate), "yyyy-MM-dd"),
            projects: projects
        }
        setIsLoading(true);
        axios.post('/bookRequest/generate-email', requests)
            .then(({ data: result }) => {
                handleClose()
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            });
    }


    const [sendAll, setSendAll] = React.useState(false);
    const handleSendAllChange = () => {
        setSendAll(!sendAll)
    }


    return (
        <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Typography className={`${classes.mainColorTitle}`} variant="h5" align="center" xs={12}>
                    Generate Booking Confirmation Email
                </Typography>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent style={{ overflow: "hidden" }}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormControl fullWidth>
                                <Grid item>
                                    Select Month
                                </Grid>
                                <Controller
                                    name="bookDate"
                                    control={control}
                                    render={({ ref, ...rest }) => (
                                        <HadclDatePicker
                                            openTo="month"
                                            views={['year', 'month']}
                                            inputFormat={"MMM yyyy"}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.outlinedDatePicker}
                                                    margin="normal"
                                                    error={errors.bookDate && true}
                                                    helperText={errors.bookDate?.message}
                                                    InputProps={params.InputProps}
                                                />
                                            )}
                                            {...rest}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Grid item>
                                <FormControlLabel
                                    label="Send individually"
                                    className={classes.controlLabel}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={!sendAll}
                                            checkedIcon={<CheckCircleIcon />}
                                            icon={<RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                                            color="primary"
                                            onChange={handleSendAllChange}
                                        />
                                    }
                                />
                                <SelectComponent
                                    disabled={sendAll}
                                    multiple={true}
                                    value={selected}
                                    onChange={(v) => { setSelected(v) }}
                                    options={options}
                                    valueField={'projectId'}
                                    nameField={'refNum'}
                                />

                            </Grid>

                            <FormControlLabel
                                label="Send to ALL"
                                className={classes.controlLabel}
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={sendAll}
                                        checkedIcon={<CheckCircleIcon />}
                                        icon={<RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                                        color="primary"
                                        onChange={handleSendAllChange}
                                    />
                                }
                            />
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions style={{ padding: 16, justifyContent: "center" }} >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.notTextTransform}
                    // onClick={handleGenerateEmail}
                    >
                        Generate
                    </Button>

                    <Button
                        className={classes.cancelButton}
                        type="button"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
                <LoadingScreen open={isLoading} />
            </form>
        </Dialog>)
}
