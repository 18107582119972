import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import { Link, useHistory, Link as DomLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as React from 'react';
import SessionExtendDialog from 'components/common/SessionExtendDialog';
import TimeOutDialog from 'components/common/TimeoutDialog';

import headerIcon from '../../../images/headerIcon.png';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '../../../images/home_mobile.svg';
import MoreIcon from 'images/et-more.svg';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import AccessQrCode from 'components/accessQrCode';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PushPinIcon from '../../../images/PushPinIcon.svg';
import PushPinIconActiveIcon from '../../../images/PushPinIconActive.svg';
import { HomeLayoutViewContext } from 'components/HomeLayout';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NavigationMobile from './NavigationMobile'
import SystemMessageDialog from 'components/common/SystemMessageDialog';

const useStyles = makeStyles((theme) => ({
    logoutBtn: {
        backgroundColor: '#f58220'
    },
    root: {
        position: 'fixed',
        bottom: 80,
        right: theme.spacing(2),
        backgroundColor: '#f58220',
        color: '#fff',
        width: 30,
        height: 30,
        borderRadius: '50%',
        textAlign: 'center'
    },
}));
function HomeLayoutMobile(props) {
    const {
        classes, user, sessionExtendOpen, timeoutOpen, emails,
        addresses, usefulLinks, sitemaps, logoLink, contactEmail,
        location, openQrCode, navRef, isFixed, setOpenQrCode,
        getUserEmail, promptLogout,
        onSessionExtend, closeSessionExtend, closeTimeout, doLogout, getExpTime, lastLoginTime, clickMenu,
        onlyOnPCDialog, setOnlyOnPCDialog
    } = React.useContext(HomeLayoutViewContext)
    const classes2 = useStyles();

    const [open, setOpen] = React.useState(false)
    const [openTop, setOpenTop] = React.useState(false)

    const mainRef = React.useRef(null)
    const toggleDrawer = (_event) => {
        setOpen(!open);
    };

    const isActive = (patchName) => {
        if (!open && location.pathname === patchName) {
            return true
        }
        return false;
    }

    const goTop = () => {
        mainRef.current.scrollTop = 0;
    }
    const handleScroll = () => {
        if (mainRef.current.scrollTop > 100) {
            if (!openTop) {
                setOpenTop(true)
            }
        } else {
            setOpenTop(false)

        }
    }

    React.useEffect(() => {
        mainRef.current.addEventListener('scroll', handleScroll, true)
        return () => {
            mainRef.current.removeEventListener('scroll', handleScroll, false)
        }
    }, [mainRef.current])

    const getMenus = React.useCallback(() => {
        let res = [];
        const resMap = {
            'CHANGE_PASSWORD': true,
            'CONTACT_US': true,
            // 'PROGRESS_REPORT': true,
            // 'MANAGE_BOOKINGS': true,
            // 'ONLINE_INFO': true,
            // 'OUTPUT_REQUEST': true,
            // 'OUTPUT_REQUEST_MANAGEMENT': true
        };
        // console.log(user?.menus?.topMenus)
        let topMenus = user?.menus?.topMenus || []
        let middleMenus = user?.menus?.middleMenus || [];

        middleMenus.forEach(element => {
            let code = element.code.slice(3)
            if (!resMap[code]) {
                resMap[code] = true;
                res.push(element)
            }
        })
        topMenus.forEach(element => {
            let c = element.code.slice(3)
            if (!resMap[c]) {
                resMap[c] = true;
                res.push(element)
            }
        })
        sitemaps.forEach(element => {
            let d = element.code.slice(3)
            if (!resMap[d]) {
                resMap[d] = true;
                res.push(element)
            }
        })
        res.forEach(element => {
            let code = element.code.slice(3)
            if (code === 'PROGRESS_REPORT' || code === 'PROGRESS_REPORT_ADM' ||
                code === 'OUTPUT_REQUEST' || code === 'OUTPUT_REQUEST_MANAGEMENT' ||
                code === 'ONLINE_INFO' || code === 'ONLINE_INFO_MANAGEMENT') {
                element.path = '#';
            }
        })
        
        return res;
    }, [user?.menus])


    return (
        <div style={{ height: '100%', overflow: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', fontFamily: 'Quicksand-SemiBold', }}>

            <CssBaseline />
            <Grid container direction='column' style={{ height: '100%' }}>

                <Grid container direction='column' style={{ overflow: 'hidden' }} flexWrap={'nowrap'} >
                    <Grid container ref={navRef} className={isFixed ? classes.fixedTop : ''}>
                        <Grid item container>
                            <Grid container justifyContent='space-between' style={{ padding: '5px', backgroundColor: '#fff' }} alignItems='center'>
                                <Grid container justifyContent={'center'}>
                                    <a href={logoLink} target={'_blank'}><img src={headerIcon} /></a>
                                </Grid>
                                <Grid container style={{ paddingTop: 8 }}>
                                    <Grid container alignItems='center' justifyContent={'space-between'} >
                                        <Grid item >
                                            <Grid container direction='column' style={{ fontSize: '13px' }}>
                                                <Grid item container alignItems='center'>
                                                    <Grid item style={{ color: '#f58220', fontSize: 18 }}>
                                                        {getUserEmail()}
                                                    </Grid>
                                                    {/* <Grid item className={classes.logoutButton}
                                                        onClick={() => { doLogout() }}>
                                                        Logout
                                                    </Grid> */}
                                                </Grid>
                                                <Grid item>
                                                    {lastLoginTime(localStorage.getItem('lastLoginTime'))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {/* <IconButton className={classes.bellButton}  >
                                                <Badge color="secondary" badgeContent={0} >
                                                    <Avatar sx={{ bgcolor: '#15A9D2', width: 30, height: 30 }}>
                                                        <NotificationsIcon sx={{ color: 'white' }} fontSize="medium" />
                                                    </Avatar>
                                                </Badge>
                                            </IconButton> */}
                                            <IconButton onClick={() => { doLogout() }} className={` ${classes2.logoutBtn}`} style={{ backgroundColor: '#f58220', padding: 5 }}>
                                                {/* badgeContent={3} */}
                                                <ExitToAppIcon sx={{ color: 'white' }} style={{ fontSize: 21 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item container className={classes.topMenu}>
                            <Grid item xs={'auto'} style={{ width: '60px', height: '40px' }}>
                                <NavLink onClick={goTop} tag={Link} className="text-white" to={'/homepage'}
                                    active={location.pathname === '/homepage'} style={{ padding: 'auto' }}
                                ><img src={HomeIcon} /></NavLink>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            {
                                user?.menus?.topMenus?.map((item, idx) => {
                                    return (
                                        <React.Fragment key={item.code}>
                                            <Grid item xs>
                                                {
                                                    item.code === 'TM_CONTACT_US' ? <NavLink
                                                        onClick={goTop} className="text-white"
                                                        active={location.pathname === item.path}
                                                    > <a className="text-white" style={{ textDecoration: 'none' }} href={`mailto:${contactEmail || ''}`}>{item.title}</a></NavLink> : <NavLink onClick={() => clickMenu(item.code)} tag={Link} className="text-white" to={item.path}
                                                        active={location.pathname === item.path}
                                                    >{item.title}</NavLink>
                                                }

                                            </Grid>
                                            <Divider orientation="vertical" flexItem />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid container style={{ margin: '0px', width: '100%', flex: 1, position: 'relative' }} >
                    <Grid ref={mainRef} container style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} >
                        <Grid container style={{ margin: '0px', width: '100%', minHeight: 'calc(100% - 37px)', overflowX: 'auto' }}>

                            {props.children}

                        </Grid>
                        <Grid container style={{ backgroundColor: '#000', flex: 1 }} alignContent='end'>

                            <Grid item container justifyContent={'center'} style={{ color: '#ffff', fontSize: '13px', padding: '8px 0', height: '37px', backgroundColor: '#333' }}>
                                <Grid item>
                                    Copyright © {new Date().getFullYear()} Hospital Authority. All rights reserved.
                                </Grid>
                            </Grid>
                        </Grid>

                        {openTop && <div onClick={goTop} role="presentation" className={classes2.root}>
                            <KeyboardArrowUpIcon />
                        </div>}
                    </Grid>
                    {
                        open && <NavigationMobile user={user} Link={Link} goTop={goTop} location={location} contactEmail={contactEmail} clickMenu={clickMenu} toggleDrawer={toggleDrawer} doLogout={doLogout} emails={emails} classes={classes} usefulLinks={usefulLinks} addresses={addresses} sitemaps={sitemaps} getMenus={getMenus} />
                    }
                </Grid>
                <Grid container justifyContent={'space-around'} style={{ height: 70, lineHeight: 1.1, textAlign: 'center', fontSize: 12, boxShadow: `0 -5px 10px 0 ${open ? 'rgba(205, 205, 205, 0.5)' : 'rgba(8, 8, 8, 0.5)'}`, zIndex: 10 }}>
                    <Grid item style={{ width: '20%' }}>
                        <NavLink onClick={() => {
                            setOpen(false);
                            goTop()
                        }} tag={Link} to={'/homepage'}
                            active={isActive('/homepage')} style={{ color: '#f58220', padding: '10px 0 0', textAlign: 'center', filter: `grayscale(${isActive('/homepage') ? 0 : 1})` }}
                        >
                            <img style={{ width: 20, marginBottom: 5 }} src={HomeIcon} />
                            <div>Homepage</div>
                        </NavLink>
                    </Grid>
                    {
                        getMenus().slice(0, 2).map((item, _index) => {
                            return <Grid item key={item.code} style={{ maxWidth: '22%', minWidth: '20%' }}>
                                <NavLink
                                    onClick={() => {
                                        setOpen(false);
                                        clickMenu(item.code)
                                    }}
                                    tag={Link} to={item.path}
                                    active={isActive(item.path)}
                                    style={{ color: '#f58220', padding: '10px 0 0', textAlign: 'center', wordBreak: 'break-word', filter: `grayscale(${isActive(item.path) ? 0 : 1})` }}
                                >
                                    <img style={{ width: 20, marginBottom: 5 }} src={item.icon} />
                                    <div>{item.title}</div>
                                </NavLink>
                            </Grid>
                        })
                    }
                    <Grid item style={{ flex: 1, maxWidth: '20%', padding: '10px 0 0', color: '#f58220', textAlign: 'center', filter: `grayscale(${open ? 0 : 1})` }} onClick={toggleDrawer}>
                        <img style={{ width: 20, marginBottom: 5 }} src={MoreIcon} />
                        <div>More</div>
                    </Grid>
                </Grid>
            </Grid>

            <SystemMessageDialog open={onlyOnPCDialog} onClose={() => { setOnlyOnPCDialog(false); }}>
                <div>Function only available on PC.</div>
            </SystemMessageDialog>
            <SessionExtendDialog open={sessionExtendOpen} getExpTime={getExpTime} onClose={closeSessionExtend} onExtend={onSessionExtend} onOpenLogOut={promptLogout} />
            <TimeOutDialog open={timeoutOpen} onClose={closeTimeout} />
            {openQrCode && <AccessQrCode onClose={() => { setOpenQrCode(false) }} />}
        </div >
    );
}


export default HomeLayoutMobile;
