import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: '100%'
    },
    rightWrapper: {
        flex: 1,
        // backgroundImage: `url(${login_bg_mobile})`,
        // // backgroundPosition: 'center center',
        // backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden'
    },
    blueRound: {
        backgroundColor: '#7092be',
        width: 100,
        height: 100,
        borderRadius: '50%',
        position: 'absolute',
        bottom: '-52px',
        right: '-26px'
    },
    yellowRound: {
        backgroundColor: '#ffff80',
        width: 65,
        height: 65,
        borderRadius: '50%',
        position: 'absolute',
        bottom: '-40px',
        right: '50px'
    },
    titleFont: {
        fontSize: '13px !important',
        fontFamily: 'Quicksand-SemiBold !important'
    },
    stepperRoot: {
        backgroundColor: 'unset !important',
        paddingLeft: 15
    },
    stepperDotRoot: {
        width: '15px !important',
        height: '15px !important',
        backgroundColor: '#848484'
    },
    dotActiveRoot: {
        backgroundColor: theme.palette.primary.main
    },
    LoginInputWrapper: {
        paddingTop: 14
    },
    loginPwdInputWrapper: {
        paddingTop: 17
    },
    blackColor: {
        color: '#000000 !important',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: '16px !important',
        marginBottom: 5
    },
    // RightPanelWrapper: {
    //     minWidth: '300px',
    //     [theme.breakpoints.down('lg')]: {
    //         width: "300px !important"
    //     },
    //     maxWidth: '500px',
    //     height: '450px',
    // },
    InfoWrapper: {
        // height: '125px',
        marginBottom: '10px',
        padding: '12px 20px ',
        opacity: 0.80,
        display: 'flex',
        flexDirection: 'column'
    },
    InfoDivider: {
        borderColor: `${theme.palette.primary.main} !important`,
        width: '90%'
    },
    InfoContainer: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: 13,
        color: '#000000',
        flex: 1
    },
    loginIcon: {
        paddingLeft: '9px',
    },
    iconDivider: {
        height: 5,
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
    welcomeFont: {
        fontSize: 22,
        fontFamily: 'Quicksand-SemiBold',
        color: '#000000',
        // [theme.breakpoints.up('md')]: {
        //     fontSize: 17,
        // },
        // [theme.breakpoints.up('lg')]: {
        //     fontSize: 22,
        // },
        // [theme.breakpoints.up('xl')]: {
        //     fontSize: 27,
        // },
    },
    loginFont: {
        fontSize: 30,
        fontFamily: 'Quicksand-SemiBold',
        color: theme.palette.primary.main,
    },
    loginFormContainer: {
        padding: '0px 20px 0 20px'
    },
    forgetPwdWrapper: {
        paddingTop: 17
    },
    forgetPwd: {
        fontSize: 16,
        fontFamily: 'Quicksand-SemiBold',
    },
    inputStyle: {
        height: '30px',
        fontFamily: 'Quicksand-SemiBold !important',
        fontSize: '16px !important',
        '& input': {
            padding: '0 !important'
        }
    },
    loginInputBox: {
        padding: '15px 20px ',
        borderRadius: '30px',
        backgroundColor: '#ffefe1',
        marginTop: '10px !important'
    }
    // InfoContainer: {
    //     paddingTop: '8px',
    //     height: 'calc(100% - 25px)',
    //     width: '100%',
    //     overflow: 'auto',
    //     '&::-webkit-scrollbar': {
    //         width: 5,
    //         height: 1
    //     },
    //     '&::-webkit-scrollbar-thumb': {
    //         borderRadius: 5,
    //         background: '#D8D8D8'
    //     },
    //     '&::-webkit-scrollbar-track': {
    //         borderRadius: 5,
    //         background: 'unset',
    //     },
    //     scrollbarColor: '#D8D8D8 white',
    //     scrollbarWidth: 'thin',
    // },
}));