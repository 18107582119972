import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    summaryTitle: {
        marginBottom: 6
    },
    projectTitle: {
        height: 30,
        backgroundColor: '#ff751e',
        paddingLeft: 10,
        color: '#fff',
        lineHeight: '30px'
    },
    projectContent: {
        height: 120,
        padding: 5,
        overflowY: 'auto',
        marginBottom: 5
    },
    selfServiceTitle: {
        height: 30,
        backgroundColor: '#2eb9f5',
        paddingLeft: 10,
        color: '#fff',
        lineHeight: '30px'
    },
    selfServiceContent: {
        height: 120,
        padding: 5,
        overflowY: 'auto'
    },
    fullBookingButton: {
        height: 40,
        width: 180,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: 600
    },
    attendanceMainTop: {
        fontSize: 13,
        height: 376,
        marginBottom: 20
    },
    attendanceMainTopLeft: {

    },
    attendanceMainTopRight: {

    },
    totalBookedTitle: {
        height: 54,
        backgroundColor: '#cfecd7',
        fontWeight: 600,
        padding: '0 10px'
    },
    seatsTitle: {
        height: 54,
        backgroundColor: '#b1e0e7',
        fontWeight: 600,
        padding: '0 10px'
    },
    labUtilizationTitle: {
        height: 54,
        backgroundColor: '#ded5f2',
        fontWeight: 600,
        padding: '0 10px'
    },
    totalAttendanceTitle: {
        height: 54,
        backgroundColor: '#ffd2f6',
        fontWeight: 600,
        padding: '0 10px'
    },
    detailedAttendanceTitle: {
        height: 54,
        backgroundColor: '#f6cdd4',
        fontWeight: 600,
        padding: '0 10px'
    },
    num: {
        flex: 1,
        fontWeight: 600,
        fontSize: 35,
        height: 146
    },
    remarks: {
        height: 110,
        backgroundColor: '#eef5ff',
        fontWeight: 600,
        color: '#0064e7',
        padding: '7px 0 13px 14px'
    },
    labCapacity: {
        backgroundColor: '#646464',
        color: '#fff',
        width: 50,
        height: 20,
        textAlign: 'center'
    },
    dateList: {
        display: 'inline-block',
        backgroundColor: '#e8e8e8',
        width: 105,
        height: 30,
        lineHeight: '30px',
        textAlign: 'center',
        borderRadius: 3,
        marginRight: 10
    },
    DateText: {
        backgroundColor: 'white',
        width: '115px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important',
            paddingRight: 0
        },
        '& .MuiButtonBase-root': {
            margin: 0,
            padding: 0,
            paddingRight: 2
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 2,
        },
        '& input': {
            paddingLeft: 10,
            paddingTop: 5, 
            paddingRight: 5
        },
        '& input::-webkit-input-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
        '& input::-moz-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
    },
    timeField: {
        '& .MuiInputBase-root': {
            fontSize: '13px !important',
            paddingRight: 0
        },
        '& .MuiButtonBase-root': {
            margin: 0,
            padding: 0,
            paddingRight: 2
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 2,
        },
        '& input': {
            paddingLeft: 10,
            paddingTop: 5,
            paddingRight: 5
        },

    },
    timeTextField: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'rgba(0, 0, 0, 0.23)'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)'
        },
        '& .MuiInputBase-input': {
            padding: 5
        }
    },
    exportButton: {
        backgroundColor: '#15a9d2 !important',
        color: '#fff !important',
        textTransform: 'none !important',
        fontSize: '15px !important',
        height: '30px !important'
    }
}));