import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import axios from '../../CustomAxios';
import EditRecord from './Scanner.dialog';
import React, { useMemo, useCallback, useEffect, createContext, forwardRef, useImperativeHandle, useRef } from 'react';
import { DateNoTime, Time, GridColDef } from '../common/HadclFormatter';
import { useStyles } from "./styles";
import SelectComponent from 'components/common/CommonSelect';
import { useStyles as useCommonStyles } from "Styles";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import format from 'date-fns/format';
import AttendanceTimePicker from './AttendanceTimePicker';
import AttendanceSelectCom from './AttendanceSelectCom';
import LoadingScreen from 'components/common/LoadingScreen';
import SystemMessageDialog from '../common/SystemMessageDialog';
import useModal from "hooks/useModal";
import SystemConfirmDialog from "../common/SystemConfirmDialog";
import { HomeLayoutViewContext } from 'components/HomeLayout';
import isAfter from 'date-fns/isAfter';
export const AttendanceGridContext = createContext({});


const AttendanceGridTable = forwardRef(({ selectedDate, setIsDirty, handleOpenDirtyCheck, isDirty, projectListRef, projectList, fetchData, fetchDashboardRate }, ref) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [remarksAnchorEl, setRemarksAnchorEl] = React.useState(null);
    const [editBookId, setEditBookId] = React.useState(null);

    const [occupiedError, setOccupiedError] = React.useState(null);

    const [selectedProject, setSelectedProject] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');
    const [rowData, setRowData] = React.useState([]);
    const gridApi = React.useRef();
    const isEditRef = React.useRef(false);
    const editRowIndexRef = React.useRef(null);
    const editDetailRef = React.useRef(null);
    const signInTimeRef = React.useRef('');
    const signInTimeComRef = React.useRef(null);
    const signOutTimeRef = React.useRef('');
    const signOutTimeComRef = React.useRef(null);
    const editStatusRef = React.useRef({});
    const editProjectComRef = React.useRef({});
    const editResearcherComRef = React.useRef({});

    const researcherListRef = React.useRef([]);
    const selectedDateRef = React.useRef(null);
    const rowDataRef = React.useRef([]);
    const selectedProjectRef = React.useRef('');
    const showModal = useModal();

    const {
        loadNotifications
    } = React.useContext(HomeLayoutViewContext)

    // const [{

    // }, setEditState] = React.useState({});
    React.useEffect(() => {
        gridApi.current && gridApi.current.api.redrawRows()
    }, [rowData.length])
    useEffect(() => {
        fetchData(selectedDate)
        selectedDateRef.current = selectedDate;
        setSelectedProject('');
        handleEditClose();
    }, [selectedDate])
    useEffect(() => {
        selectedProjectRef.current = selectedProject;
    }, [selectedProject])
    useEffect(() => {
        refreshData()
    }, [selectedDate, selectedProject])



    const closeEdit = () => {
        let newDate = JSON.parse(JSON.stringify(rowDataRef.current));
        if (!editDetailRef.current && (editRowIndexRef.current || editRowIndexRef.current === 0)) {
            newDate.splice(0, 1)
        }
        editDetailRef.current = null;
        editRowIndexRef.current = null;
        isEditRef.current = false;
        researcherListRef.current = []
        setRowData(newDate)
        rowDataRef.current = JSON.parse(JSON.stringify(newDate));
        setIsDirty(false)

    }
    const handleCheck = () => {
        let res = false;
        if (!editStatusRef.current.projectId) {
            setErrorMsg('Please select Project no./SDP no.');
            return true;
        }
        if (!editStatusRef.current.userId) {
            setErrorMsg('Please select Researcher');
            return true;
        }
        if (!editStatusRef.current.signinTime) {
            setErrorMsg('Please input Sign-in time.');
            return true;
        }
        // console.log(editStatusRef.current, isAfter(new Date(editStatusRef.current.signoutTime), new Date()))
        if (editStatusRef.current.timeOverError && (isAfter(new Date(editStatusRef.current.signinTime), new Date()) || isAfter(new Date(editStatusRef.current.signoutTime), new Date()))) {
            editStatusRef.current.timeOverErrorMsg && setErrorMsg(editStatusRef.current.timeOverErrorMsg);
            return true
        }

        if (editStatusRef.current.isError) {
            editStatusRef.current.errorMsg && setErrorMsg(editStatusRef.current.errorMsg);
            return true
        }
        if (editStatusRef.current.isSignInError) {
            editStatusRef.current.signInErrorMsg && setErrorMsg(editStatusRef.current.signInErrorMsg);
            return true
        }
        if (editStatusRef.current.isSignOutError) {
            editStatusRef.current.signOutErrorMsg && setErrorMsg(editStatusRef.current.signOutErrorMsg);
            return true
        }

        return res;
    }
    const handleSave = () => {
        if (handleCheck()) return;
        let singinTime = format(new Date(selectedDateRef.current), 'yyyy-MM-dd') + 'T' + format(new Date(editStatusRef.current.signinTime), 'HH:mm')
        let singOutTime = editStatusRef.current.signoutTime ? format(new Date(selectedDateRef.current), 'yyyy-MM-dd') + 'T' + format(new Date(editStatusRef.current.signoutTime), 'HH:mm') : null
        const requests = {
            "attendanceId": editStatusRef.current.attendanceId || 0,
            "date": format(selectedDateRef.current, 'yyyy-MM-dd'),
            "projectId": editStatusRef.current.projectId,
            "userId": editStatusRef.current.userId,
            "signinTime": singinTime,
            "signoutTime": singOutTime,
            workstationNo: editStatusRef.current.workstationNo,
            lockerNo: editStatusRef.current.lockerNo,
            remarks: editStatusRef.current.remarks,
        }

        // return
        setIsLoading(true);
        axios.post('/attendance/update-attendance', requests).then(res => {
            if (res.data.code == 0) {
                refreshData();
                fetchDashboardRate();
                handleEditClose()
                loadNotifications();
                setErrorMsg('Saved successfully!');
            } else if (res.data.code == 1) {
                setIsLoading(false);
                setOccupiedError(res.data.data)
            }

        }).catch(err => {
            setIsLoading(false);
            var reseErorMsg = err.response?.data?.message;
            setErrorMsg(reseErorMsg ? reseErorMsg : err.message);
            if (err) {
                console.log(err);
            }
        })

    }

    const handleEditClose = () => {
        setEditOpen(false);
        setEditBookId(null);
        closeEdit()
    };



    const refreshData = () => {
        const params = {
            date: format(selectedDateRef.current, 'yyyy-MM-dd'),
        }
        if (selectedProjectRef.current) {
            params.projectId = selectedProjectRef.current
        }
        axios.get('/attendance/get-attendance-list', {
            params
        }).then(res => {
            setRowData(res.data);
            rowDataRef.current = JSON.parse(JSON.stringify(res.data));
            setIsLoading(false);
            gridApi.current.api.redrawRows()
        })
    }




    useImperativeHandle(ref, () => ({

        submitClick() {
            handleSave()
        },
        refreshList() {
            refreshData()
        },
        editClose() {
            handleEditClose()
        },

    }));

    return <AttendanceGridTableMain
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        editBookId={editBookId}
        setEditBookId={setEditBookId}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        rowData={rowData}
        setRowData={setRowData}
        gridApi={gridApi}
        isEditRef={isEditRef}
        editRowIndexRef={editRowIndexRef}
        editDetailRef={editDetailRef}
        signInTimeRef={signInTimeRef}
        signInTimeComRef={signInTimeComRef}
        signOutTimeRef={signOutTimeRef}
        signOutTimeComRef={signOutTimeComRef}
        editStatusRef={editStatusRef}
        editProjectComRef={editProjectComRef}
        editResearcherComRef={editResearcherComRef}
        researcherListRef={researcherListRef}
        selectedDateRef={selectedDateRef}
        rowDataRef={rowDataRef}
        selectedProjectRef={selectedProjectRef}
        showModal={showModal}
        handleOpenDirtyCheck={handleOpenDirtyCheck}
        isDirty={isDirty}
        projectListRef={projectListRef}
        projectList={projectList}
        fetchData={fetchData}
        closeEdit={closeEdit}
        handleSave={handleSave}
        handleEditClose={handleEditClose}
        refreshData={refreshData}
        selectedDate={selectedDate}
        setIsDirty={setIsDirty}
        fetchDashboardRate={fetchDashboardRate}
        occupiedError={occupiedError}
        setOccupiedError={setOccupiedError}
        setRemarksAnchorEl={setRemarksAnchorEl}
        remarksAnchorEl={remarksAnchorEl}
    />

})

const AttendanceGridTableMain = ({
    isLoading,
    setIsLoading,
    occupiedError,
    editOpen,
    setOccupiedError,
    editBookId,
    selectedProject,
    setSelectedProject,
    errorMsg,
    setErrorMsg,
    rowData,
    setRowData,
    gridApi,
    isEditRef,
    editRowIndexRef,
    editDetailRef,
    signInTimeRef,
    signOutTimeRef,
    editStatusRef,
    editProjectComRef,
    editResearcherComRef,
    researcherListRef,
    selectedDateRef,
    rowDataRef,
    showModal,
    handleOpenDirtyCheck,
    isDirty,
    projectListRef,
    projectList,
    closeEdit,
    handleSave,
    handleEditClose,
    refreshData,
    setIsDirty,
    selectedDate,
    fetchDashboardRate,
    remarksAnchorEl,
    setRemarksAnchorEl
}) => {
    const classes = useStyles();
    const classesCommon = useCommonStyles();
    const isDirtyRef = useRef(null);
    const {
        loadNotifications
    } = React.useContext(HomeLayoutViewContext)

    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty])
    const handleEditOpen = (pros) => {
        isEditRef.current = true;
        editDetailRef.current = pros;
        editRowIndexRef.current = pros.rowIndex;
        editStatusRef.current = pros.data
        signInTimeRef.current = new Date(pros.data.signinTime)
        signOutTimeRef.current = pros.data.signoutTime ? new Date(pros.data.signoutTime) : '';
        fetchResearcherData(pros.data.projectId);
        var firstRow = gridApi.current.api.getDisplayedRowAtIndex(pros.rowIndex);
        if (firstRow) {
            var firstRowData = firstRow.data;
            firstRowData.isEdit = true;
            gridApi.current.api.applyTransaction({ update: [firstRowData] });
            gridApi.current.api.redrawRows()
        }
        setIsDirty(true)
    };
    const handleRowClose = (rowIndex) => {
        closeEdit();
        var firstRow = gridApi.current.api.getDisplayedRowAtIndex(rowIndex);
        if (firstRow && rowDataRef.current.length !== 0) {
            var firstRowData = rowDataRef.current[rowIndex];
            gridApi.current.api.applyTransaction({ update: [firstRowData] });
            gridApi.current.api.redrawRows()
        }

    }

    const defaultColDef = useMemo(() => {
        return {
            //   flex: 1,
            editable: false,
        };
    }, []);
    const fetchResearcherData = (id) => {
        axios.get('/attendance/get-attendance-users', {
            params: {
                date: format(selectedDateRef.current, 'yyyy-MM-dd'),
                projectId: id
            }
        }).then(res => {
            // setProjectList(res?.data || [])
            researcherListRef.current = res?.data || [];
            editResearcherComRef.current && editResearcherComRef.current.updateOptions(res?.data || [])
            gridApi.current.api.redrawRows()
        }).catch(err => {
            if (err) {
                console.log(err);
            }
        })
    }

    const renderDate = (pros) => {
        return (
            <div>
                {pros.value && format(new Date(pros.value), 'dd/MM/yyyy')}
            </div>
        );
    }

    const handleSignInTime = (newValue) => {

        signInTimeRef.current = newValue;
        editStatusRef.current.signinTime = newValue;


        setIsDirty(true)
    }

    const handleSignOutTime = (newValue) => {

        signOutTimeRef.current = newValue;
        editStatusRef.current.signoutTime = newValue;

        setIsDirty(true)

    }

    const redrawTopRows = useCallback(() => {
        var rows = [];
        var row = gridApi.current.api.getDisplayedRowAtIndex(editRowIndexRef.current);
        rows.push(row);
        gridApi.current.api.redrawRows({ suppressFlash: false, rowNodes: rows });
    }, []);
    const handleChangeProject = (v, obj) => {
        editStatusRef.current.projectId = v;
        editStatusRef.current.refNum = obj.refNum;
        editStatusRef.current.userId = '';
        editStatusRef.current.university = '';
        editStatusRef.current.researcherName = '';
        fetchResearcherData(v)
        redrawTopRows()
        setIsDirty(true)
    }
    const handleChangeResearcher = (v, obj) => {
        editStatusRef.current.userId = obj.userId;
        editStatusRef.current.university = obj.university;
        editStatusRef.current.researcherName = obj.researcherName;
        editStatusRef.current.expiryDate = obj.expiryDate;
        // gridApi.current.api.redrawRows()
        redrawTopRows()
        setIsDirty(true)
    }
    const handleCloseNew = (rowIndex) => {
        const func = () => {
            handleRowClose(rowIndex)
        }
        if (isDirtyRef.current) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    func()
                },
            })
            return;
        }
        func();
    }

    const handleChangeRemarks = (e) => {

        const value = e.target.value.slice(0, 100)
        editStatusRef.current.remarks = value;


        setIsDirty(true)
    }

    const columnDefs = [

        GridColDef({
            headerName: 'Date',
            field: 'date',
            width: '140px',
            cellRendererFramework: renderDate
        }),
        GridColDef({
            headerName: 'Project no./SDP no.',
            field: 'refNum',
            flex: 1,
            // minWidth: 160,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <AttendanceSelectCom valueField={'projectId'} nameField={'refNum'} ref={editProjectComRef} value={editStatusRef.current.projectId} onChange={handleChangeProject} options={projectListRef.current} />
                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value}
                    </div>
                );
            }
        }),
        GridColDef({
            headerName: 'Researcher name',
            field: 'researcherName',
            flex: 1,
            // minWidth: 160,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <AttendanceSelectCom valueField={'userId'} nameField={'researcherName'} ref={editResearcherComRef} value={editStatusRef.current.userId} onChange={handleChangeResearcher} options={researcherListRef.current} />

                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value}
                    </div>
                );
            }
        }),
        GridColDef({
            headerName: 'University',
            field: 'university',
            editable: false,
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {editStatusRef.current.university}
                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value}
                    </div>
                );
            }
            // width: '100px',
        }),
        GridColDef({
            headerName: 'ID',
            field: 'userId',
            // flex: 1,
            width: '70px',
            minWidth: 70,
            editable: false,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {editStatusRef.current.userId}
                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value}
                    </div>
                );
            }
        }),
        GridColDef({
            headerName: 'Expiry date',
            field: 'expiryDate',
            width: '150px',
            editable: false,
            cellRendererFramework: renderDate

        }),
        GridColDef({
            headerName: '1st time visit',
            field: 'isFirstTime',
            // width: '150px',
            flex: 1,
            cellRendererFramework: (pros) => {
                const t = {
                    'false': 'NO',
                    'true': 'YES',
                }
                return (
                    <div>
                        {t[pros.value + '']}
                    </div>
                );
            }
        }),
        GridColDef({
            headerName: 'Sign-in time',
            field: 'signinTime',
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div >
                        <Grid container alignContent={'center'} style={{ height: 40 }}>

                            <AttendanceTimePicker
                                id='signinTime'
                                value={signInTimeRef.current}
                                onChange={handleSignInTime}
                            />
                        </Grid>

                    </div>
                }
                return (
                    <div>
                        {pros.value && format(new Date(pros.value), 'HH:mm')}
                    </div>
                );
            }

        }),
        GridColDef({
            headerName: 'Sign-out time',
            field: 'signoutTime',
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <Grid container alignContent={'center'} style={{ height: 40 }}>
                            <AttendanceTimePicker
                                id='signoutTime'
                                value={signOutTimeRef.current}
                                onChange={handleSignOutTime}
                            />

                        </Grid>
                    </div>
                }
                return (
                    <div>
                        {pros.value ? format(new Date(pros.value), 'HH:mm') : ''}
                    </div>
                );
            }

        }),

        GridColDef({
            headerName: 'Update source',
            field: 'updateSource',
            editable: false,
            flex: 1,

        }),
        GridColDef({
            headerName: 'Workstation No.',
            field: 'workstationNo',
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <Grid container alignContent={'center'} style={{ height: 40 }}>
                            <TextField defaultValue={pros.value} className={classes.timeField} inputProps={{ maxLength: 20 }} helperText='' onChange={(e) => { editStatusRef.current.workstationNo = e.target.value }} />

                        </Grid>


                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value ? pros.value : ''}
                    </div>
                );
            }

        }),
        GridColDef({
            headerName: 'Locker No.',
            field: 'lockerNo',
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <Grid container alignContent={'center'} style={{ height: 40 }}>
                            <TextField defaultValue={pros.value} className={classes.timeField} helperText='' inputProps={{ maxLength: 20 }} onChange={(e) => { editStatusRef.current.lockerNo = e.target.value }} />

                        </Grid>


                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value ? pros.value : ''}
                    </div>
                );
            }

        }),
        GridColDef({
            headerName: 'Remarks',
            field: 'remarks',
            flex: 1,
            cellRendererFramework: (pros) => {
                let edit = isEditRef.current && pros?.data?.isEdit
                if (edit) {
                    return <div>
                        <Grid container alignContent={'center'} style={{ height: 40 }}>

                            <div style={{
                                color: '#000',
                                height: 28,
                                lineHeight: '28px',
                                border: '1px solid rgba(0,0,0,0.23)',
                                borderRadius: '4px',

                                padding: '0 5px',
                                width: '100%'
                            }} className={classes.timeField} onClick={(e) => {
                                console.log(e)
                                setRemarksAnchorEl(e.currentTarget)
                            }}>
                                <div style={{
                                    overflow: 'hidden',
                                    width: '100%'
                                }}>{pros.value}</div>
                            </div>

                        </Grid>


                    </div>
                }
                return (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {pros.value ? pros.value : ''}
                    </div>
                );
            }

        }),
        GridColDef({
            headerName: '',
            width: '80px',
            minWidth: 80,
            sortable: false,
            filter: false,
            editable: false,
            cellRendererFramework: (pros) => {

                if (isEditRef.current) {
                    if (pros?.data?.isEdit) {
                        editRowIndexRef.current = pros.rowIndex;
                        return <div style={{ lineHeight: '35px' }}>
                            <CheckIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => handleSave()}></CheckIcon>
                            <CloseIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => handleCloseNew(pros.rowIndex)}></CloseIcon>
                        </div>
                    } else {
                        return <></>
                    }

                }

                return (
                    <div style={{ lineHeight: '35px' }}>
                        <EditIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => handleEditOpen(pros)}></EditIcon>
                        <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => onDelete(pros.data.attendanceId)} />
                    </div>
                );
            }
        }),
    ];

    const onGridReady = (params) => {
        gridApi.current = params;
    }

    const handleNew = () => {
        const newList = [...rowData]
        const singinTime = new Date()
        const data = {
            attendanceId: 0,
            date: selectedDate,
            signoutTime: '',
            signinTime: singinTime,
            isEdit: true,
            expiryDate: '',
            id: '',
            isFirstTime: '',
            refNum: '',
            researcherName: '',
            university: '',
            userId: '',
            updateSource: '',
            remarks: '',
        }
        if (selectedProject) {
            data.projectId = selectedProject;
            fetchResearcherData(selectedProject)
        }
        newList.unshift(data)
        // setEditRowIndex(newList.length - 1)
        editRowIndexRef.current = 0;
        isEditRef.current = true;
        signInTimeRef.current = singinTime
        signOutTimeRef.current = ''
        editStatusRef.current = data;
        setRowData(newList)

        rowDataRef.current = JSON.parse(JSON.stringify(newList));
        redrawTopRows()
    }
    const onSortChanged = () => {
        if (editRowIndexRef.current || editRowIndexRef.current === 0) {
            handleRowClose(editRowIndexRef.current)
        }
    }
    const handleSelect = (v) => {
        const func = () => {
            setSelectedProject(v);
            handleEditClose();
        }
        if (isDirty) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    func()
                },
            })
            return;
        }
        func()
    }

    const onDelete = id => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    setIsLoading(true);
                    axios.post('/attendance/delete-attendance', { "attendanceId": id }).then(res => {
                        console.log(res)
                        refreshData();
                        fetchDashboardRate();
                        loadNotifications();
                    }).catch(err => {
                        var resErrorMsg = err.response?.data?.message;
                        setErrorMsg(resErrorMsg ? resErrorMsg : err.message);
                        if (err) {
                            console.log(err);
                        }
                    })
                    setIsLoading(false);
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the attendance?</div>
            </SystemConfirmDialog>
        ));
    }
    const isDisableNew = isEditRef.current || isAfter(selectedDateRef.current, new Date())
    return (
        <AttendanceGridContext.Provider value={{
            // isReload,
            editStatusRef
        }}>

            <Paper className={classes.paper} style={{ paddingTop: 8 }}>
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <Grid container alignItems={'center'} style={{ marginBottom: 8 }}>
                        <Grid item container alignItems={'center'} style={{ flex: 1 }}>
                            <Grid item>Date <span className={classes.dateList}>{format(new Date(selectedDate), 'dd/MM/yyyy')}</span></Grid>
                            <Grid item>
                                Project no./SDP no. &nbsp;&nbsp;
                                <SelectComponent valueField={'projectId'} nameField={'refNum'} value={selectedProject} onChange={handleSelect} style={{
                                    textTransform: 'none',
                                    fontSize: '13px',
                                    width: 130,
                                }} options={projectList} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button disabled={isDisableNew} style={isDisableNew ? { backgroundColor: '#dfdfdf', color: 'rgba(0, 0, 0, 0.26)', textTransform: 'none', fontSize: 15, width: 70, height: 30 } : { backgroundColor: '#15a9d2', color: '#fff', textTransform: 'none', fontSize: 15, width: 70, height: 30 }} className={classes.fullBookingButton} onClick={handleNew}>+New</Button>
                        </Grid>
                    </Grid>
                    <div className={`ag-theme-alpine ${classesCommon.nhadclAgGrid}`}
                        style={{ position: 'relative', zIndex: 0, height: '100%' }}>

                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            editType={'fullRow'}
                            onSortChanged={onSortChanged}
                        >
                        </AgGridReact>
                    </div>

                </div>
                <br />
                <EditRecord bookId={editBookId} open={editOpen} handleClose={handleEditClose} />
                <LoadingScreen open={isLoading} />
                {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                    <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
                </SystemMessageDialog>}
                {occupiedError && <SystemMessageDialog open={!!occupiedError} onClose={() => setOccupiedError(null)} isShowCloseButton={true}>
                    <div style={{ lineHeight: 1.3 }}>
                        <div>Record cannot be submitted due to insufficient </div>
                        <div>capacity.</div>
                        <div style={{ backgroundColor: '#efefef', padding: '3px 0' }}> {occupiedError.refNum}:&nbsp;{occupiedError.seats}&nbsp;seat(s) all occupied</div>
                        <div>Please check again. </div>
                    </div>
                </SystemMessageDialog>}
            </Paper>
            {remarksAnchorEl && <Popover
                id={remarksAnchorEl ? 'remarks-popover' : null}
                open={true}
                anchorEl={remarksAnchorEl}
                onClose={() => {
                    setRemarksAnchorEl(null);
                    redrawTopRows()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container style={{ padding: 10, minWidth: 200 }}>

                    <TextareaAutosize minRows={3} maxLength={100} defaultValue={editStatusRef.current.remarks} placeholder="" onChange={handleChangeRemarks} />
                </Grid>
            </Popover>}
        </AttendanceGridContext.Provider>
    )
}
export default AttendanceGridTable;