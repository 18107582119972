import React from 'react';
import {
    format
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./Styles";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt, useLocation } from 'react-router-dom';
import LoadingScreen from 'components/common/LoadingScreen'; 
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import useModal from "hooks/useModal";
import useUser from 'hooks/UseFindUser';
import OutputRequestList from './OutputRequestList'
import PublicationReviewList from './PublicationReviewList'
import { warning, success } from 'components/common/Constants';
import ReportDraftEditor from 'components/progressReports/researcher/detail/ReportDraftEditor';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import { OutputContext } from './index';
import ExamplePart3 from './ExamplePart3'
import ExamplePart2 from './ExamplePart2'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { statusColor } from './OutputRequestList';

export default function OutputDetail(props) {
    const classes = useStyles();
    const {
        isCreate, handleBack,
        currentDetail, setIsLoading, setReload, reload, selectedProject,isOutputRequest, selectedProjectObj, user
    } = React.useContext(OutputContext)
    const { setUser } = React.useContext(UserContext);
    const history = useHistory();
    
    const showModal = useModal();
    const [state, setBaseState] = React.useState({ models: [], files: [] })
    const [nextPath, setNextPath] = React.useState(null);
    const [error, setError] = React.useState({});
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const draftEditorRef = React.useRef({})
    const [openExample3, setOpenExample3] = React.useState(false)
    const [openExample2, setOpenExample2] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [wishOutputModel, setWishOutputModel] = React.useState(false)
    const isNoBack = React.useRef(null);

    const isProjectMember = user.user.id != state.requester && !isCreate
    const disableSubmit = (isCreate || state.statusCode == "INCOMPLETED" || state.statusCode == "REVISION") && !isProjectMember ? false : true;
    // console.log(isDirty, currentDetail)

    useEffect(() => {
        if (!isCreate && currentDetail) {
            loadDetail()
        } else {
            setBaseState((oldState) => ({
                ...oldState,
                requestByName: user.user.email,  
                projectId: selectedProject,  
                requestType: isOutputRequest ? "OUTPUT" : "PUBLICATION",
                refNum: selectedProjectObj.refNum
            }))
        }
    }, [currentDetail, isCreate])

    

    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    const handleChangeInput = (value, field) => {
        let isError = false
        if (error[field]) {
            isError = false
        }
        if (isError !== error[field]) {
            setError((s) => ({ ...s, [field]: isError }))
        }
        handleSetBaseState({ [field]: value })
    }
    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {

            history.push(nextPath);
        }

    }, [history, isDirty, nextPath])

    const loadDetail = () => {
        setIsLoading(true);
        axios.get('/outputRequest/get-output-request-detail', { params: { outputId: currentDetail.id, projectId: currentDetail.projectId } }).then((res) => {
            if (res.data) {
                draftEditorRef.current = {
                    remarksReq: res.data.remarksReq,
                    // supplementaries:res.data.supplementaries,
                };
                if (!res.data.models || res.data.models.length == 0) {
                    setWishOutputModel(false)
                } else {
                    setWishOutputModel(true)
                }
                setBaseState(res.data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/output-request'))
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave('INCOMPLETED')
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your Output Request is not yet saved/ submitted </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        
        return false;
    }

    const checkModel = (models) => {
        let res = false
        models.forEach(item => {
            if (!item.algorithm || !item.fileName || !item.fullFilePath || !item.inputInfo  || !item.language || !item.mechanism || !item.model || !item.modelScript || !item.outputDesc || !item.outputInfo  || !item.outputUsage || !item.sampleScript) {
                res = true;
            }
        });
        return res
    }
    const handleCheckSubmit = (payload, errorTemp, callback) => {
        let isError = false;
        let msg = "";
        if (!payload.reasonOfRequest) {
            errorTemp.reasonOfRequest = true;
            isError = true
        }
        if (payload.files.length > 0) {
            payload.files.forEach(item => {
                if (!item.name || !item.path || !item.reason || !item.desc) {
                    errorTemp.files = true;
                    isError = true
                }
            });
        } else {
            errorTemp.files = true;
            !isError && (msg = warning.filesAdd)
            isError = true
        }
        if (payload.models.length > 0) {
            if (checkModel(payload.models)) {
                errorTemp.models = true;
                isError = true
            }
        } else if(wishOutputModel) {
            errorTemp.model = true;
            !isError && (msg = warning.modelAdd)
            isError = true
        }
        if (isError) {
            callback(msg)
        }
    }
    const handleCheck = (payload, status) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.AllMandatory;
        if (status != 'INCOMPLETED') {
            handleCheckSubmit(payload, errorTemp, function (m) { 
                isError = true;
                if (m) {
                    msg =m
                }
            })
        }
        if (payload.remarksReq) {
            let cleanCode = payload.remarksReq.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
            // console.log(cleanCode, cleanCode.length)
            if (cleanCode.length > 2000) {
                errorTemp.remarksReq = true;
                !isError && (msg = warning.RichLength)
                isError = true
            }
        }
        isNoBack.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }
    const onSubmit = (payload, status) => {
        const requests = {
            ...payload,
            // projectProgress: payload.projectProgress * 1,
            status
        }
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request-res', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.Saved
                    if (status == 'SUBMITTED') {
                        m = success.Submitted
                    }
                    if (status == 'WITHDRAWN') {
                        m = success.Withdraw
                    }

                    setIsDirty(false)
                    setIsLoading(false);
                    setErrorMsg(m)

                    // draftEditorLengthErrorRef.current = {}

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }
    const handleSave = (status) => {
        const payload = {
            projectId: state.projectId,
            requestType: state.requestType,
            organisation: state.organisation,
            reasonOfRequest: state.reasonOfRequest,
            files: state.files,
            models: state.models,
            remarksReq: draftEditorRef.current.remarksReq
        }
        if (!wishOutputModel) {
            payload.models = []
        }
        if (state.id) {
            payload.id = state.id
        }
        if (status !== "WITHDRAWN" && handleCheck(payload, status)) {
            return
        }
        payload.remarksReq = window.htmlEncode(payload.remarksReq)
        onSubmit(payload, status)
    }

    const handleChangeTextArea = (value, fileName) => {
        
        let isError = false
        if (error[fileName]) {
            isError = false
        }


        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
        // handleSetBaseState({[fileName]: value})
    }

    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const handleAddFileList = () => {
        const newFiles = [...state.files]
        const newObj = {
            name: "",
            path: "",
            reason: "",
            desc: "",
        }
        newFiles.push(newObj)
        setError((s) => ({ ...s, files: false }))
        handleSetBaseState({ files: newFiles })
    }
    const handleChangeFile = (value, filedName, index) => {
        const newFiles = [...state.files]
        newFiles[index][filedName] = value
        !disableSubmit && setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({ files: newFiles })

    }
    const onDeleteFile = (item, index) => {
        if (item.name && item.path && item.reason && item.desc) {
            showModal((pros) => (
                <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    onConfirm={() => {
                        const newFiles = [...state.files]
                        newFiles.splice(index, 1)
                        !disableSubmit && setIsDirty(true)
                        handleSetBaseState({ files: newFiles })
                    }}
                >
                    <div style={{ padding: '0 10px' }}>Do you confirm to remove the file?</div>
                </SystemConfirmDialog>
            ));
        } else {
            const newFiles = [...state.files]
            newFiles.splice(index, 1)
            // setIsDirty(true)
            handleSetBaseState({ files: newFiles })
        }

    }
    const handleAddModelList = () => {
        const newModels = [...state.models]
        const newObj = {
            algorithm: "",
            fileName: "",
            fullFilePath: "",
            inputInfo: "",
            inputSupp: "",
            language: "",
            mechanism: "",
            model: "",
            modelScript: "",
            outputDesc: "",
            outputInfo: "",
            outputSupp: "",
            outputUsage: "",
            sampleScript: "",
        }
        newModels.push(newObj)
        setError((s) => ({ ...s, models: false }))
        handleSetBaseState({ models: newModels })
    }
    
    const handleChangeModel = (value, filedName, index) => {
        const newModels = [...state.models]
        newModels[index][filedName] = value
        filedName != 'isOpen' && !disableSubmit &&  setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({ models: newModels })

    }
    const onDeleteModel = (item, index) => {
        if (item.algorithm && item.fileName && item.fullFilePath && item.inputInfo && item.inputSupp && item.language && item.mechanism && item.model && item.modelScript && item.outputDesc && item.outputInfo && item.outputSupp && item.outputUsage && item.sampleScript) {
            showModal((pros) => (
                <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    onConfirm={() => {
                        const newModels = [...state.models]
                        newModels.splice(index, 1)
                        !disableSubmit && setIsDirty(true)
                        handleSetBaseState({ models: newModels })
                    }}
                >
                    <div style={{ padding: '0 10px' }}>Do you confirm to remove the model?</div>
                </SystemConfirmDialog>
            ));
        } else {
            const newModels = [...state.models]
            newModels.splice(index, 1)
            // setIsDirty(true)
            handleSetBaseState({ models: newModels })
        }

    }
    const handleBackList = () => {
        if (isDirty && !disableSubmit) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    handleBack()
                },
            })
        } else {
            setIsDirty(false)
            handleBack()
        }
        
    }
    const handleCloseDialog = () => {
        if (!isDirty && !isNoBack.current) {
            handleBackList()
            setReload(!reload)
        }
        setErrorMsg('')
    }

    const handleWithdraw = () => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    handleSave('WITHDRAWN')
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to withdraw this Application?</div>
            </SystemConfirmDialog>
        ));
    }

    const handleRenderInput = (component, value) => {
        if (disableSubmit) {
            return <div style={{ padding: '5px 0', width: '100%', height: '100%', maxHeight: 250, wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex' }}>
                <div title={value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', color: '#c1c1c1' }} dangerouslySetInnerHTML={{ __html: value }}>
                </div>

            </div>
        }
        return component
    }
    const showRemarksForAdm = () => {
        return state.remarksRsp && <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
        <Grid container className={classes.title}>
            Part V&nbsp;&nbsp;&nbsp;Comments from HADCL Office
        </Grid>
        <Grid container>
            {/* <ReportDraftEditor placeholder={'<2000 characters'} value={''} /> */}
            <ReportDraftEditor disabled={true} value={state.remarksRsp}  />
        </Grid>
    </Grid>
    }
    const showWithdrawButton = () => {
        if ((state.statusCode == "INCOMPLETED" || state.statusCode == "REVISION") && !isProjectMember) {
            return <Button style={{
                backgroundColor: '#f00 ',
                color: '#ffff ',
                marginRight: 10,
                textTransform: 'none'
            }} className={classes.activeButton} onClick={handleWithdraw}>Withdraw this Application</Button>
        }
        return ""
    }
    const isShowModelErrorBorder = (value) => {
        return error.models && !value ? {border: '1px solid #ff0000'} : {}
    }
    // console.log(error)
    return (
        <>
            <Grid container style={{ height: '100%' }} >
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Output Request
                    </Typography>
                    <Typography variant="h8" gutterBottom xs={12}>
                        <span style={{ color: 'red' }}>Maximum quota: 12 per Data Collaboration Project; 3 per Self-service Data Platform Application.</span>
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container className={classes.title}>
                        Part I&nbsp;&nbsp;&nbsp;Requestor Information
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Request No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestNo}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Status</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{
                                <div title={state.status} style={{  width: '150px',height: 29, lineHeight:'29px', color: "#fff", borderRadius: 5, fontSize:13, textAlign:'center', ...(statusColor[state.statusCode])}} dangerouslySetInnerHTML={{__html: state.status}}>
                                </div>
                                
                            }</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Requested by </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestByName}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>
                                <div>
                                    <div>First Submission Date</div>
                                    <div>Last Submission Date</div>
                                </div>
                            </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <div>
                                    <div>{state.firstSubmissionDate && format(new Date(state.firstSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                    <div>{state.lastSubmissionDate && format(new Date(state.lastSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project / Self-Service No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Organisation</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-Organisation"
                                        label=""
                                        multiline
                                        // rows={1}
                                        inputProps={{ maxLength: 100 }}
                                        className={classes.textareaBox}
                                        placeholder="≤100 characters"
                                        variant="outlined"
                                        value={state.organisation}
                                        InputProps={{
                                            // style: {
                                            //     ...(error.reasonOfRequest && !state.reasonOfRequest ? { border: '1px solid #ff0000' } : {})
                                            // },
                                        }}
                                        onChange={(e) => handleChangeInput(e.target.value, 'organisation')}
                                    />,
                                    state.organisation
                                )}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Reason of Request<span className={classes.red}>*</span> </Grid>
                            <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-reasonOfRequest"
                                        label=""
                                        multiline
                                        rows={3}
                                        inputProps={{ maxLength: 500 }}
                                        className={classes.textareaBox}
                                        placeholder="≤500 characters"
                                        variant="outlined"
                                        value={state.reasonOfRequest}
                                        InputProps={{
                                            style: {
                                                ...(error.reasonOfRequest && !state.reasonOfRequest ? { border: '1px solid #ff0000' } : {})
                                            },
                                        }}
                                        onChange={(e) => handleChangeInput(e.target.value, 'reasonOfRequest')}
                                    />,
                                    state.reasonOfRequest
                                )}

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                {/*  */}
                <Grid item xs={'auto'}>
                    <Typography variant="h8" gutterBottom xs={12}>
                        <br />
                        <u><b>Output Request Requirements:</b></u> <br/>
                        <table border="0">
                            <tr>
                                <td>1.</td>
                                <td>Each request <u>MUST NOT</u> exceed <b>30 output files.</b></td>
                            </tr>
                            <tr>
                                <td>2. </td>
                                <td>Each output file <u>MUST NOT</u> exceed <b>1,000 rows OR 30 columns.</b></td>
                            </tr>
                        </table>
                    </Typography>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part II&nbsp;&nbsp;&nbsp;Requested File List <span className={classes.red}>*</span>&nbsp;<span style={{ cursor: 'pointer', color: '#15a9d2', textDecoration: 'underline' }} onMouseEnter={() => { setOpenExample2(true) }}>(e.g.)</span> &nbsp;(For export of models, please fill in details in part III)
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.projectHeader}>
                        <Grid container item style={{ width: 50, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >No.</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Name</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Path</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Description of the file(s)</Grid>
                        <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.projectTh} alignItems='center' >Reason of exporting the file(s)</Grid>
                        <Grid item style={{ width: 50 }} className={classes.projectTh} alignItems='center' ></Grid>
                    </Grid>
                    {
                        state.files.map((item, index) => {
                            return <Grid container>
                                <Grid container item style={{ width: 50, padding: '5px 10px' }} className={classes.projectTd}  >{index + 1} </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(
                                        <TextField
                                            fullWidth
                                            id="filled-file-name"
                                            label=""
                                            multiline
                                            rows={2}
                                            inputProps={{ maxLength: 200 }}
                                            className={classes.textareaBox}
                                            placeholder="≤200 characters"
                                            variant="outlined"
                                            value={item.name}
                                            InputProps={{
                                                style: {
                                                    ...(error.files && !item.name ? { border: '1px solid #ff0000' } : {})
                                                },
                                            }}
                                            onChange={(e) => handleChangeFile(e.target.value, 'name', index)}
                                        />,
                                        item.name
                                    )}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(
                                        <TextField
                                            fullWidth
                                            id="filled-file-path"
                                            label=""
                                            multiline
                                            rows={2}
                                            inputProps={{ maxLength: 200 }}
                                            className={classes.textareaBox}
                                            placeholder="≤200 characters"
                                            variant="outlined"
                                            value={item.path}
                                            InputProps={{
                                                style: {
                                                    ...(error.files && !item.path ? { border: '1px solid #ff0000' } : {})
                                                },
                                            }}
                                            onChange={(e) => handleChangeFile(e.target.value, 'path', index)}
                                        />,
                                        item.path
                                    )}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd}  >
                                    {handleRenderInput(
                                        <TextField
                                            fullWidth
                                            id="filled-file-desc"
                                            label=""
                                            multiline
                                            rows={3}
                                            inputProps={{ maxLength: 2000 }}
                                            className={classes.textareaBox}
                                            placeholder="≤2000 characters"
                                            variant="outlined"
                                            value={item.desc}
                                            InputProps={{
                                                style: {
                                                    ...(error.files && !item.desc ? { border: '1px solid #ff0000' } : {})
                                                },
                                            }}
                                            onChange={(e) => handleChangeFile(e.target.value, 'desc', index)}
                                        />,
                                        item.desc
                                    )}

                                </Grid>
                                <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.projectTd} >
                                    {handleRenderInput(
                                        <TextField
                                            fullWidth
                                            id="filled-file-Reason"
                                            label=""
                                            multiline
                                            rows={3}
                                            inputProps={{ maxLength: 2000 }}
                                            className={classes.textareaBox}
                                            placeholder="≤2000 characters"
                                            variant="outlined"
                                            value={item.reason}
                                            InputProps={{
                                                style: {
                                                    ...(error.files && !item.reason ? { border: '1px solid #ff0000' } : {})
                                                },
                                            }}
                                            onChange={(e) => handleChangeFile(e.target.value, 'reason', index)}
                                        />,
                                        item.reason
                                    )}

                                </Grid>
                                <Grid item style={{ width: 50 }} className={classes.projectTd} alignItems='center' justifyContent={'center'} >
                                    <span>
                                        {
                                            !disableSubmit && <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => { onDeleteFile(item, index) }} />
                                        }

                                    </span>
                                </Grid>
                            </Grid>
                        })
                    }
                    <Grid container style={{ padding: "10px 0" }}>
                        {!disableSubmit && <Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            textTransform: 'none',
                            // lineHeight: 1.2
                        }} className={classes.activeButton} onClick={handleAddFileList}>+Add</Button>}
                    </Grid>
                </Grid>
               
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part III&nbsp;&nbsp;&nbsp;Model List &nbsp;<span style={{ cursor: 'pointer', color: '#15a9d2',textDecoration: 'underline' }} onMouseEnter={() => { setOpenExample3(true) }}>(e.g.)</span> &nbsp;(Please fill in this part if any model(s) is/are requested in part II)
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>I wish to output model <span className={classes.red}>*</span></Grid>
                            <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                                <Grid item style={{ paddingRight: 30 }}>
                                    <Button
                                        disabled={disableSubmit}
                                        startIcon={checkIcon(wishOutputModel)}
                                        // className={classes.button}
                                        style={{
                                            textTransform: 'none',
                                            lineHeight: '1.5',
                                            fontSize: '13px'
                                        }}
                                        // disabled={project.length === 0 && selfService.length === 0}
                                        onClick={() => {
                                            if (state.models.length == 0) {
                                                handleAddModelList()
                                            }
                                            setWishOutputModel(true)
                                        }}
                                    >
                                        <div className={classes.buttonLabel}>Yes</div>
                                    </Button>
                                </Grid>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(!wishOutputModel)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => setWishOutputModel(false)}
                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        wishOutputModel && state.models.map((item, index) => {
                            return <Grid container style={{ paddingBottom: 20 }}>
                                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                    <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >
                                        <Grid item>
                                            {
                                                item.isClose ? <ArrowDropDownIcon style={{ color: '#15a9d2', cursor: 'pointer', marginTop: '-3px' }} onClick={() => { handleChangeModel(false, 'isClose', index) }} /> : <ArrowDropUpIcon style={{ color: '#15a9d2', cursor: 'pointer', marginTop: '-3px' }} onClick={() => { handleChangeModel(true, 'isClose', index) }} />
                                            }
                                        </Grid>
                                        <Grid item xs>
                                            Model:
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                        <Grid item xs>
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-model-File-name"
                                                    label=""
                                                    multiline
                                                    rows={2}
                                                    inputProps={{ maxLength: 100 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤100 characters"
                                                    variant="outlined"
                                                    value={item.model}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.model))
                                                            // ...(error.model && !item.model ? { border: '1px solid #ff0000' } : {})
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'model', index)}
                                                />,
                                                item.model
                                            )}

                                        </Grid>
                                        <Grid item>
                                            {!disableSubmit && <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => { onDeleteModel(item, index) }} />}
                                        </Grid>

                                    </Grid>
                                </Grid>
                                {!item.isClose && <>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >File name:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.fileName}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.fileName))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'fileName', index)}
                                                />,
                                                item.fileName
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Full file path:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.fullFilePath}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.fullFilePath))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'fullFilePath', index)}
                                                />,
                                                item.fullFilePath
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Algorithm:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.algorithm}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.algorithm))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'algorithm', index)}
                                                />,
                                                item.algorithm
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Mechanism of the model:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.mechanism}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.mechanism))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'mechanism', index)}
                                                />,
                                                item.mechanism
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Programming Language:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.language}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.language))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'language', index)}
                                                />,
                                                item.language
                                            )}

                                        </Grid>
                                    </Grid>

                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Sample script file:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-model-sampleScript"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.sampleScript}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.sampleScript))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'sampleScript', index)}
                                                />,
                                                item.sampleScript
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Script to load the model and run the prediction:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-multiline-File-name"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.modelScript}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.modelScript))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'modelScript', index)}
                                                />,
                                                item.modelScript
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={'center'} style={{ fontWeight: '600', minHeight: 40, paddingLeft: 10 }} className={`${classes.lineBottom}`}>
                                        Input &nbsp;<i style={{ fontSize: 13, fontWeight: 'normal', lineHeight: '18px' }}>(Please provide details of the input parameters of the above model.)</i>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Input value, type and description:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-inputInfo"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.inputInfo}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.inputInfo))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'inputInfo', index)}
                                                />,
                                                item.inputInfo
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Supplementary information:(optional)</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-inputSupp"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.inputSupp}
                                                    // InputProps={{
                                                    //     style: {
                                                    //         ...(isShowModelErrorBorder(item.inputSupp))
                                                    //     },
                                                    // }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'inputSupp', index)}
                                                />,
                                                item.inputSupp
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={'center'} style={{ fontWeight: '600', minHeight: 40, paddingLeft: 10 }} className={`${classes.lineBottom}`}>
                                        Output &nbsp;<i style={{ fontSize: 13, fontWeight: 'normal', lineHeight: '18px' }}>(Please provide details of the result or output of the above model.)</i>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Output value and type:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-outputInfo"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.outputInfo}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.outputInfo))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'outputInfo', index)}
                                                />,
                                                item.outputInfo
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Description/ Explanation of output:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-outputDesc"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.outputDesc}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.outputDesc))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'outputDesc', index)}
                                                />,
                                                item.outputDesc
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Usage of output:</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-outputUsage"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.outputUsage}
                                                    InputProps={{
                                                        style: {
                                                            ...(isShowModelErrorBorder(item.outputUsage))
                                                        },
                                                    }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'outputUsage', index)}
                                                />,
                                                item.outputUsage
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Supplementary information:(optional)</Grid>
                                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                            {handleRenderInput(
                                                <TextField
                                                    fullWidth
                                                    id="filled-outputSupp"
                                                    label=""
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ maxLength: 500 }}
                                                    className={classes.textareaBox}
                                                    placeholder="≤500 characters"
                                                    variant="outlined"
                                                    value={item.outputSupp}
                                                    // InputProps={{
                                                    //     style: {
                                                    //         ...(isShowModelErrorBorder(item.outputSupp))
                                                    //     },
                                                    // }}
                                                    onChange={(e) => handleChangeModel(e.target.value, 'outputSupp', index)}
                                                />,
                                                item.outputSupp
                                            )}

                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        })

                    }

                    <Grid container style={{ padding: "10px 0" }}>
                        {!disableSubmit && <Button disabled={!wishOutputModel} style={{
                            ...(!wishOutputModel ? {
                                backgroundColor: '#dfdfdf ',
                                color: 'rgba(0, 0, 0, 0.26) ',
                            } : {
                                backgroundColor: '#15a9d2 ',
                                color: '#ffff '
                            }),
                            textTransform: 'none',
                            // lineHeight: 1.2
                        }} className={classes.activeButton} onClick={handleAddModelList}>+Add</Button>}
                    </Grid>
                </Grid>

                {/* Part IV  Comments from Researchers */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part IV&nbsp;&nbsp;&nbsp;Remarks from Researcher
                    </Grid>
                    <Grid container>
                        
                        <ReportDraftEditor disabled={disableSubmit} placeholder={'≤2000 characters'} isError={error.remarksReq} value={state.remarksReq} onChange={(e) => handleChangeTextArea(e, 'remarksReq')} />

                    </Grid>
                </Grid>
                {showRemarksForAdm()}
                <Grid container style={{ paddingTop: 10 }}>
                    <div className={classes.mandatory}>*=Mandatory</div>
                </Grid>
                <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs>
                        {!disableSubmit && <><Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            marginRight: 10,
                            textTransform: 'none'
                        }}className={classes.activeButton} onClick={() => handleSave('SUBMITTED')}>Submit</Button>
                            <Button style={{
                                backgroundColor: '#15a9d2 ',
                                color: '#ffff ',
                                marginRight: 10,
                                textTransform: 'none'
                            }}  className={classes.activeButton} onClick={() => handleSave('INCOMPLETED')}>Save</Button></>}
                        <Button style={{
                            backgroundColor: '#646464 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleBackList}>{disableSubmit ? "Back to Summary" : 'Cancel'}</Button>
                    </Grid>
                    <Grid item >
                        { showWithdrawButton() }
                    </Grid>
                </Grid>
                {state.lastUpdatedName && <div style={{ paddingTop: 10 }}>Last updated by {state.lastUpdatedName} on {format(new Date(state.lastUpdatedDate), 'dd MMM yyyy HH:mm')} </div>}
            </Grid>
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            {<ExamplePart3 open={openExample3} onClose={() => { setOpenExample3(false) }} />}
            {<ExamplePart2 open={openExample2} onClose={() => { setOpenExample2(false) }} />}
            {<SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </>
    );
}
