import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import { useStyles } from "Styles";
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useMsg } from '../../hooks/MsgProvider';
import axios from '../../CustomAxios';
import LoadingScreen from '../common/LoadingScreen';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import HadclDatePicker from '../common/HadclDatePicker';
import { warning, error, projectRepeatedMsg } from '../common/Constants';
import { getInitProjectInfo } from './editUser.dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
    format,
    startOfDay,
    addMonths
} from 'date-fns';
function trimTime(value, originalvalue) {
    if (value == null)
        return null;

    return startOfDay(value);
}

export default function CreateUser(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const { setSuccessMsg, setErrMsg } = useMsg();
    const isIERef = React.useRef();
    const projectMapRef = React.useRef({})

    const getProjectDateSchema = () => {
        const res = {};
        for (let index = 0; index < 8; index++) {
            if (index === 0) {
                res.expiryDateCollaborate = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
                res.accessStartDateCollaborate = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
                res.portalAccessEndDateCollaborate = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
            } else {
                res[`accessStartDateCollaborate${index + 1}`] = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
                res[`expiryDateCollaborate${index + 1}`] = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
                res[`portalAccessEndDateCollaborate${index + 1}`] = yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate)
            }
        }
        return res
    }

    const schema = yup.object().shape({
        email: yup.string().email(),
        accessLocation: yup.string().default('0'),
        effectiveDate: yup.date().transform(trimTime).nullable().min("2018/01/01", warning.UserEffectiveDateValidation).typeError(warning.UserEffectiveDate),
        accessStartDate: yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate),
        idValidity: yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate),

        portalAccessEndDate: yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate),
        expiryDateSelfService: yup.date().transform(trimTime).nullable().typeError(warning.UserExpiryDate),
        ...(getProjectDateSchema())
    });

    const { watch, setValue, handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            'effectiveDate': null,
            'idValidity': null,
            'expiryDateSelfService': null,
            'accessStartDate': null,
            'portalAccessEndDate': null,
            'status': 'A',
            'lockStatus': 'UL',
            'typeOfID': 'STAFF',
            accountCreationDate: '',
            ...(getInitProjectInfo())
        },
    });


    const watchIsSelf = watch("isSelfService");
    const watchIsCol = watch("isCollaborate");
    const watchExpiryDateSelfService = watch("expiryDateSelfService");
    const watchIdValidity = watch("idValidity");

    const handleClose = () => {
        props.handleClose();
    }

    function handleIsSelfServiceChange(isChecked) {
        if (!isChecked) {
            setValue('appNumSelfService', '');
            setValue('appNoSelfService', '');
            setValue('dateQuota', '');
            setValue('accessStartDate', null);
            setValue('expiryDateSelfService', null);
            setValue('portalAccessEndDate', null);
            setValue('accessLocation', "0");
            setValue('is_UND_FORM_SIG', false);
            setValue('is_WAT_VIDEO', false);
            setValue('is_HD_FORM_COM', false);
            setValue('is_FRS_SIG', false);
        }
    }

    function handleIsCollaborate(isChecked) {
        if (!isChecked) {
            for (let index = 0; index < 8; index++) {
                if (index === 0) {
                    setValue('projectId', '');
                    setValue('accessStartDateCollaborate', null);
                    setValue('expiryDateCollaborate', null);
                    setValue('portalAccessEndDateCollaborate', null);
                    setValue('isPI', false);
                    setValue('isHA', false);
                    setValue('is_UND_FORM_SIG_P', false);
                    setValue('is_WAT_VIDEO_P', false);
                    setValue('is_HD_FORM_COM_P', false);
                    setValue('is_FRS_SIG_P', false);

                } else {
                    setValue('projectId' + (index + 1), '');
                    setValue('accessStartDateCollaborate' + (index + 1), null);
                    setValue('expiryDateCollaborate' + (index + 1), null);
                    setValue('portalAccessEndDateCollaborate' + (index + 1), null);
                    setValue('isPI' + (index + 1), false);
                    setValue('isHA' + (index + 1), false);
                    setValue('is_UND_FORM_SIG_P' + (index + 1), false);
                    setValue('is_WAT_VIDEO_P' + (index + 1), false);
                    setValue('is_HD_FORM_COM_P' + (index + 1), false);
                    setValue('is_FRS_SIG_P' + (index + 1), false);
                }
            }
        }
    }

    function handleCancelForm(e) {
        e.preventDefault();
        handleClose();
    }

    function IsInteger(x) {

        let regexPattern = /^-?[0-9]+$/;

        // check if the passed number is integer or float
        let result = regexPattern.test(x);

        if (result) {
            return true;
        }
        else {
            return false;
        }

    }
    const baseValidation = (data) => {
        if (!data.lastName) {
            setErrMsg(error.UserLastName);
            return false;
        }
        if (!data.firstName) {
            setErrMsg(error.UserFirstName);
            return false;
        }
        if (!data.email) {
            setErrMsg(error.UserEmail);
            return false;
        }
        if (!data.effectiveDate) {
            setErrMsg(error.UserAccountEffectiveDate);
            return false;
        }
        return true
    }
    const validation = data => {
        if (!baseValidation(data)) {
            return false
        }

        if (data.dateQuota !== null && data.dateQuota !== '' && !IsInteger(data.dateQuota)) {
            setErrMsg(error.UserQuota);
            return false;
        }
        else if (parseInt(data.dateQuota, 10) < 1 || parseInt(data.dateQuota, 10) > 5) {
            setErrMsg(error.UserQuotaValidation);
            return false;
        }

        if (!(data.isSystemAM || data.isSelfService || data.isCollaborate || data.isHADCL || data.isPMOMember || data.isPMOChair || data.isDutyOfficer || data.isWSParticipant)) {
            setErrMsg(error.UserRole);
            return false;
        }

        if (data.isSystemAM && (data.isSelfService || data.isCollaborate || data.isHADCL || data.isPMOMember || data.isPMOChair || data.isDutyOfficer || data.isWSParticipant)) {
            setErrMsg(error.UserRoleValidaiton);
            return false;
        }

        if (!data.isSystemAM && (data.isSelfService || data.isCollaborate || data.isWSParticipant) && (data.isHADCL || data.isPMOMember || data.isPMOChair || data.isDutyOffice)) {
            setErrMsg(error.UserResearcherValidation);
            return false;
        }
        return true;
    }

    const existProject = (projects, num) => {
        return projects && projects.some(e => e.projectId && e.projectId === num);
    }
    const genAdminTasks = (data, index, f = "") => {
        const res = [];

        if (data[`is_UND_FORM_SIG${f}${index}`]) {
            res.push({
                code: 'UND_FORM_SIG'
            })
        }

        if (data[`is_WAT_VIDEO${f}${index}`]) {
            res.push({
                code: 'WAT_VIDEO'
            })
        }
        if (data[`is_HD_FORM_COM${f}${index}`]) {
            res.push({
                code: 'HD_FORM_COM'
            })
        }
        if (data[`is_FRS_SIG${f}${index}`]) {
            res.push({
                code: 'FRS_SIG'
            })
        }
        return res;
    }
    const formatSubmitDate = (date) => {
        return date ? format(startOfDay(new Date(date)), 'yyyy-MM-dd') : null
    }
    const checkProjectError = (data, projects) => {
        for (let index = 0; index < 8; index++) {
            let num = "";
            if (index != 0) {
                num = index + 1;
            }
            if (data[`projectId${num}`]) {
                if (existProject(projects, data[`projectId${num}`])) {
                    setIsLoading(false);
                    setErrMsg(projectRepeatedMsg(projectMapRef.current[data[`projectId${num}`]]));
                    return true;
                }
                const adminTasks = genAdminTasks(data, num, '_P')
                projects.push({
                    "projectId": data[`projectId${num}`],
                    "expiryDateCollaborate": formatSubmitDate(data[`expiryDateCollaborate${num}`]),
                    "accessStartDate": formatSubmitDate(data[`accessStartDateCollaborate${num}`]),
                    "portalAccessEndDate": formatSubmitDate(data[`portalAccessEndDateCollaborate${num}`]),
                    isPI: data[`isPI${num}`],
                    isHA: data[`isHA${num}`],
                    adminTasks: adminTasks
                });
            }
        }

        return false
    }

    const onSubmit = data => {
        setIsLoading(true);

        if (!validation(data)) {
            setIsLoading(false);
            return;
        }

        const p_dateQuota = (data.dateQuota == null) ? null : parseInt(data.dateQuota);

        let projects = [];

        if (checkProjectError(data, projects)) {
            return
        }

        axios.post('/user/create-user', {
            "email": data.email,
            "salutation": data.salutation,
            "lastName": data.lastName,
            "firstName": data.firstName,
            "appNumSelfService": data.appNumSelfService,
            "appNoSelfService": data.appNoSelfService,
            "typeOfID": data.typeOfID,
            "dateQuota": p_dateQuota,
            "effectiveDate": formatSubmitDate(data.effectiveDate),
            "idValidity": formatSubmitDate(data.idValidity),
            "expiryDateSelfService": formatSubmitDate(data.expiryDateSelfService),
            "accessStartDate": formatSubmitDate(data.accessStartDate),
            "portalAccessEndDate": formatSubmitDate(data.portalAccessEndDate),
            "isPermanent": data.isPermanent,
            "idNo": data.idNo,
            "accessLocation": parseInt(data.accessLocation),
            "isSelfService": data.isSelfService,
            "isCollaborate": data.isCollaborate,
            "isHADCL": data.isHADCL,
            "isPMOMember": data.isPMOMember,
            "isPMOChair": data.isPMOChair,
            "isSystemAM": data.isSystemAM,
            "isDutyOfficer": data.isDutyOfficer,
            "isWSParticipant": data.isWSParticipant,
            "appNumCollaborate": data.appNumCollaborate,
            "expiryDateCollaborate": formatSubmitDate(data.expiryDateCollaborate),
            "status": data.status,
            "lockStatus": data.lockStatus === 'L' ? data.lockStatus : null,
            "projects": projects,
            "adminTasks": genAdminTasks(data, ""),
        }).then(async (res) => {
            setIsLoading(false);
            setSuccessMsg(res.data.message);
            handleClose();
        }).catch((err) => {
            setIsLoading(false);
            setErrMsg(err);
        });
    }

    React.useEffect(() => {
        isIERef.current = !(document.documentMode == undefined); //document.documentMode is a IE only property. If this is defined, it means user is using IE
    }, [])

    const renderCheckBox = (pros, disabled) => {
        return <Checkbox
            color="primary"
            onChange={(e) => { pros.onChange(e.target.checked); }}
            checked={pros.value}
            disabled={disabled}
        />
    }

    const handleSelectProject = (project, index) => {
        if (!project) {
            setValue('accessStartDateCollaborate' + index, null);
            setValue('expiryDateCollaborate' + index, null);
            setValue('portalAccessEndDateCollaborate' + index, null);
            return
        }
        projectMapRef.current[project.projectId] = project.refNum
        setValue('accessStartDateCollaborate' + index, project.accessStartDate);
        setValue('expiryDateCollaborate' + index, project.accessEndDate);
        setValue('portalAccessEndDateCollaborate' + index, project.portalAccessEndDate);
    }

    React.useEffect(() => {
        if (watchExpiryDateSelfService) {
            setValue('portalAccessEndDate', addMonths(new Date(watchExpiryDateSelfService), 3));
        }
    }, [watchExpiryDateSelfService])

    const handleSetPermanent = (e) => {
        if (e) {
            setValue('isPermanent', false);
        }
    }

    React.useEffect(() => {
        if (watchIdValidity) {
            handleSetPermanent(watchIdValidity);
        }
    }, [watchIdValidity])

    const handleSetIDvalidity = (isPermanent) => {
        if (isPermanent) {
            setValue('idValidity', null);
        }
    }

    const handleProjectRole = (num = "") => {
        return <div className={classes.divBound}>
            <FormControl variant="outlined" fullWidth className={classes.input}>
                <Controller
                    name={`projectId${num}`}
                    control={control}
                    render={({ _ref, ...rest }) => (
                        <Select
                            labelId="salutation-label"
                            id={`projectId${num}`}
                            // label=""
                            displayEmpty={true}
                            style={{ marginLeft: 8 }}
                            {...rest}
                            disabled={!watchIsCol}
                            onChange={(e, o) => {
                                // console.log(e, o)
                                rest.onChange(e.target.value);
                                handleSelectProject(o.props._project, num)
                            }}
                            MenuProps={{
                                PopoverClasses: { root: classes.modSelectedPopover },
                            }}
                        >
                            <MenuItem _project={null} value={""}>Please select</MenuItem>
                            {props.projectLists.map((item, index) => {
                                return <MenuItem _project={item} key={item.projectId + '_' + index} value={item.projectId}>{item.refNum}</MenuItem>
                            })}
                        </Select>
                    )}
                />

            </FormControl>

            <Grid container >
                <Grid item xs={6} style={{ paddingRight: 10 }}>
                    <Controller
                        name={`accessStartDateCollaborate${num}`}
                        control={control}
                        render={({ _ref, ...rest }) => (
                            <HadclDatePicker
                                label="Access Start Date"
                                disablePast
                                disabled={true}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.noMarginTop}
                                        margin="normal"
                                        error={false}
                                        helperText={errors[`accessStartDateCollaborate${num}`]?.message}
                                        variant="standard"
                                        InputProps={params.InputProps}
                                        required
                                    />
                                )}
                                {...rest}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <Controller
                        name={`expiryDateCollaborate${num}`}
                        control={control}
                        render={({ _ref, ...rest }) => (
                            <HadclDatePicker
                                label="Access End Date"
                                disablePast
                                disabled={true}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.noMarginTop}
                                        margin="normal"
                                        error={false}
                                        helperText={errors[`expiryDateCollaborate${num}`]?.message}
                                        variant="standard"
                                        InputProps={params.InputProps}
                                        required
                                    />
                                )}
                                {...rest}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 10 }}>
                    <Controller
                        name={`portalAccessEndDateCollaborate${num}`}
                        control={control}
                        render={({ _ref, ...rest }) => (
                            <HadclDatePicker
                                label="Portal Access End Date"
                                disablePast
                                disabled={true}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.noMarginTop}
                                        margin="normal"
                                        error={false}
                                        helperText={errors[`portalAccessEndDateCollaborate${num}`]?.message}
                                        variant="standard"
                                        InputProps={params.InputProps}
                                        required
                                    />
                                )}
                                {...rest}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <FormControlLabel
                control={
                    <Controller
                        name={`isPI${num}`}
                        control={control}
                        render={(pros) => (
                            <Checkbox
                                color="primary"
                                disabled={!watchIsCol}
                                onChange={(e) => {
                                    pros.onChange(e.target.checked);
                                }}
                                checked={pros.value}
                            />
                        )}
                    />
                }
                name={`isPI${num}`}
                label="PI"
            />
            <FormControlLabel
                control={
                    <Controller
                        name={`isHA${num}`}
                        control={control}
                        render={(_pros) => (
                            <Checkbox
                                color="primary"
                                disabled={!watchIsCol}
                                onChange={(e) => { _pros.onChange(e.target.checked); }}
                                checked={_pros.value}
                            />
                        )}
                    />
                }
                name={`isHA${num}`}
                label="HA Collaborator"
            />
            <Grid container >
                <Grid item container xs={4} style={{ paddingTop: 5 }}>Admin Tasks</Grid>
                <Grid item container xs={8}>
                    <Grid container>

                        <FormControlLabel
                            control={
                                <Controller
                                    name={`is_UND_FORM_SIG_P${num}`}
                                    control={control}
                                    render={(p) => renderCheckBox(p, !watchIsCol)}
                                />
                            }
                            style={{ margin: 0 }}
                            name={`is_UND_FORM_SIG_P${num}`}
                            label="Undertaking Form"
                        />
                    </Grid>
                    <Grid container>

                        <FormControlLabel
                            control={
                                <Controller
                                    name={`is_WAT_VIDEO_P${num}`}
                                    control={control}
                                    render={(p) => renderCheckBox(p, !watchIsCol)}
                                />
                            }
                            style={{ margin: 0 }}
                            name={`is_WAT_VIDEO_P${num}`}
                            label="eCourse"
                        />
                    </Grid>
                    <Grid container>

                        <FormControlLabel
                            control={
                                <Controller
                                    name={`is_HD_FORM_COM_P${num}`}
                                    control={control}
                                    render={(p) => renderCheckBox(p, !watchIsCol)}
                                />
                            }
                            style={{ margin: 0 }}
                            name={`is_HD_FORM_COM_P${num}`}
                            label="Health Declaration Form"
                        />
                    </Grid>
                    <FormControlLabel
                        control={
                            <Controller
                                name={`is_FRS_SIG_P${num}`}
                                control={control}
                                render={(p) => renderCheckBox(p, !watchIsCol)}
                            />
                        }
                        style={{ margin: 0 }}
                        name={`is_FRS_SIG_P${num}`}
                        label="FRS"
                    />
                </Grid>
            </Grid>
        </div>
    }

    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            disableEnforceFocus={!!isIERef.current} //disableEnforceFocus is required because "Desktop pickers don't work inside modals" for IE11
            aria-labelledby="form-dialog-title"
        >
            <LoadingScreen open={isLoading} />
            <form className={classes.userEditForm} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">Create User</DialogTitle>
                <DialogContent>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={3}>Salutation</Grid>
                        <Grid item container xs={9}>
                            <FormControl variant="outlined" fullWidth className={classes.input}>
                                <Controller
                                    name="salutation"
                                    control={control}
                                    defaultValue={''}
                                    render={({ ref, ...rest }) => (
                                        <Select
                                            labelId="salutation-label"
                                            id="salutation"
                                            // label=""
                                            style={{ marginLeft: 8 }}
                                            {...rest}
                                        >
                                            <MenuItem value={'Mr'}>Mr</MenuItem>
                                            <MenuItem value={'Ms'}>Ms</MenuItem>
                                            <MenuItem value={'Miss'}>Miss</MenuItem>
                                            <MenuItem value={'Dr'}>Dr</MenuItem>
                                            <MenuItem value={'Prof'}>Prof</MenuItem>
                                        </Select>
                                    )}
                                />

                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={3}>Last Name *</Grid>
                        <Grid item container xs={9}>
                            <FormControl fullWidth className={classes.input}>
                                <Controller
                                    as={TextField}
                                    name="lastName"
                                    control={control}
                                    required
                                    label=""
                                    fullWidth={true}
                                    error={errors.lastName && true}
                                    helperText={errors.lastName?.message}
                                    inputProps={{ maxLength: 100 }}
                                    defaultValue=""
                                    {...props}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'baseline'}>
                        <Grid item container xs={3}>First Name *</Grid>
                        <Grid item container xs={9}>
                            <FormControl fullWidth className={classes.input}>
                                <Controller
                                    as={TextField}
                                    name="firstName"
                                    control={control}
                                    required
                                    label=""
                                    fullWidth={true}
                                    error={errors.firstName && true}
                                    helperText={errors.firstName?.message}
                                    inputProps={{ maxLength: 100 }}
                                    defaultValue=""
                                    {...props}
                                />
                                <div style={{ fontSize: 13, paddingLeft: 10 }}>(Please input Initials + *)</div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl fullWidth className={classes.input}>
                        <Controller
                            as={TextField}
                            name="email"
                            control={control}
                            required
                            label="Email"
                            fullWidth={true}
                            error={errors.email && true}
                            helperText={errors.email?.message}
                            inputProps={{ maxLength: 100 }}
                            defaultValue={''}
                            {...props}
                        />
                    </FormControl>

                    <Grid container alignItems={'center'}>
                        <Grid item container xs={5}>Account Creation Date</Grid>
                        {/* <Grid item container xs={7} alignItems="center" style={{height: 48, paddingLeft: 10}}>
                            
                        </Grid> */}
                    </Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={5}>Account Effective Date *</Grid>
                        <Grid item container xs={7}>
                            <Controller
                                name="effectiveDate"
                                control={control}
                                render={({ ref, ...rest }) => (
                                    <HadclDatePicker
                                        label=""
                                        inputFormat="dd-MM-yyyy"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={classes.noMarginTop}
                                                margin="normal"
                                                error={errors.effectiveDate && true}
                                                helperText={errors.effectiveDate?.message}

                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        )}
                                        {...rest}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={5}>Type of ID</Grid>
                        <Grid item container xs={7}>
                            <Controller
                                as={
                                    <RadioGroup row aria-label="typeOfID">
                                        <FormControlLabel style={{ margin: 0 }} value="STAFF" control={<Radio color="primary" />} label="Staff ID" />
                                        <FormControlLabel style={{ margin: 0 }} value="STUDENT" control={<Radio color="primary" />} label="Student ID" />
                                    </RadioGroup>
                                }
                                name="typeOfID"
                                control={control}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={5}>ID validity</Grid>
                        <Grid item container xs={7} alignItems={'center'}>
                            <Grid item xs style={{ paddingRight: 20 }}>
                                <Controller
                                    name="idValidity"
                                    control={control}
                                    render={({ ref, ...rest }) => (
                                        <HadclDatePicker
                                            label=""
                                            inputFormat="dd-MM-yyyy"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.noMarginTop}
                                                    margin="normal"
                                                    error={errors.idValidity && true}
                                                    helperText={errors.idValidity?.message}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )}
                                            {...rest}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item style={{ paddingLeft: 5 }}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="isPermanent"
                                            control={control}
                                            defaultValue={false}
                                            render={(pros) => (
                                                <Radio
                                                    color="primary"
                                                    onChange={(e) => { pros.onChange(e.target.checked); handleSetIDvalidity(e.target.checked) }}
                                                    checked={pros.value}
                                                    style={{ padding: 0 }}
                                                />
                                            )}
                                        />
                                    }
                                    style={{ margin: 0 }}
                                    name="isPermanent"
                                    label="Permanent"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item container xs={5}>ID no.</Grid>
                        <Grid item container xs={7}>
                            <FormControl fullWidth className={classes.input}>
                                <Controller
                                    as={TextField}
                                    name="idNo"
                                    control={control}
                                    label=""
                                    fullWidth={true}
                                    error={errors.idNo && true}
                                    helperText={errors.idNo?.message}
                                    inputProps={{ maxLength: 20 }}
                                    defaultValue=""
                                    {...props}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <div className={classes.margin}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Role</FormLabel>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="isSelfService"
                                            control={control}
                                            defaultValue={false}
                                            render={(pros) => (
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => { pros.onChange(e.target.checked); handleIsSelfServiceChange(e.target.checked); }}
                                                    checked={pros.value}
                                                    disabled={props.roleDisabled('isSelfService')}
                                                />
                                            )}
                                        />
                                    }
                                    name="isSelfService"
                                    label="Self-Service Researchers"
                                />
                                <br />
                                <div className={classes.divBound}>
                                    <table>
                                        <tr>
                                            <td style={{ width: 180 }}>
                                                <FormControl fullWidth className={classes.input}>
                                                    <Controller
                                                        as={TextField}
                                                        name="appNumSelfService"
                                                        control={control}
                                                        label="Ref. No."
                                                        error={errors.appNumSelfService && true}
                                                        helperText={errors.appNumSelfService?.message}
                                                        inputProps={{ maxLength: 60 }}
                                                        disabled={!watchIsSelf}
                                                        defaultValue=""
                                                        required
                                                        {...props}
                                                    />
                                                </FormControl>
                                            </td>
                                            <td style={{ width: 80 }}>
                                                <FormControl fullWidth className={classes.input}>
                                                    <Controller
                                                        as={TextField}
                                                        name="appNoSelfService"
                                                        control={control}
                                                        label="App. No."
                                                        error={errors.appNoSelfService && true}
                                                        helperText={errors.appNoSelfService?.message}
                                                        inputProps={{ maxLength: 10 }}
                                                        disabled={!watchIsSelf}
                                                        defaultValue=""
                                                        // required
                                                        {...props}
                                                    />
                                                </FormControl>
                                            </td>
                                            <td style={{ width: 250 }}>
                                                <FormControl fullWidth className={classes.input}>
                                                    <Controller
                                                        as={TextField}
                                                        name="dateQuota"
                                                        // type="number"
                                                        control={control}
                                                        label="No. of Access Day requested"
                                                        error={errors.dateQuota && true}
                                                        helperText={errors.dateQuota?.message}
                                                        inputProps={{ maxLength: 5 }}
                                                        disabled={!watchIsSelf}
                                                        defaultValue=""
                                                        required
                                                        {...props}
                                                    />
                                                </FormControl>
                                            </td>
                                        </tr>
                                    </table>

                                    <Grid container >
                                        <Grid item xs={6} style={{ paddingRight: 10 }}>
                                            <Controller
                                                name={`accessStartDate`}
                                                control={control}
                                                render={({ ref, ...rest }) => (
                                                    <HadclDatePicker
                                                        label="Access Start Date"
                                                        // disablePast
                                                        disabled={!watchIsSelf}
                                                        inputFormat="dd-MM-yyyy"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className={classes.noMarginTop}
                                                                margin="normal"
                                                                error={errors[`accessStartDate`] && true}
                                                                helperText={errors[`accessStartDate`]?.message}
                                                                variant="standard"
                                                                InputProps={params.InputProps}
                                                                required
                                                            />
                                                        )}
                                                        {...rest}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingLeft: 10 }}>
                                            <Controller
                                                name={`expiryDateSelfService`}
                                                control={control}
                                                render={({ ref, ...rest }) => (
                                                    <HadclDatePicker
                                                        label="Access End Date"
                                                        // disablePast
                                                        inputFormat="dd-MM-yyyy"
                                                        disabled={!watchIsSelf}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className={classes.noMarginTop}
                                                                margin="normal"
                                                                error={errors[`expiryDateSelfService`] && true}
                                                                helperText={errors[`expiryDateSelfService`]?.message}
                                                                variant="standard"
                                                                InputProps={params.InputProps}
                                                                required
                                                            />
                                                        )}
                                                        {...rest}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingRight: 10 }}>
                                            <Controller
                                                name={`portalAccessEndDate`}
                                                control={control}
                                                render={({ ref, ...rest }) => (
                                                    <HadclDatePicker
                                                        label="Portal Access End Date"
                                                        // disablePast
                                                        inputFormat="dd-MM-yyyy"
                                                        disabled={!watchIsSelf}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className={classes.noMarginTop}
                                                                margin="normal"
                                                                error={errors[`portalAccessEndDate`] && true}
                                                                helperText={errors[`portalAccessEndDate`]?.message}
                                                                variant="standard"
                                                                InputProps={params.InputProps}
                                                                required
                                                            />
                                                        )}
                                                        {...rest}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems={'center'}>
                                        <Grid item container xs={4}>Access Location</Grid>
                                        <Grid item container xs={8}>
                                            <Controller
                                                as={
                                                    <RadioGroup row aria-label="accessLocation">
                                                        <FormControlLabel style={{ margin: 0 }} value={"0"} control={<Radio color="primary" disabled={!watchIsSelf} />} label="On-site" />
                                                        <FormControlLabel style={{ margin: 0 }} value={"1"} control={<Radio color="primary" disabled={!watchIsSelf} />} label="Remote Access" />
                                                    </RadioGroup>
                                                }
                                                name="accessLocation"
                                                control={control}
                                                defaultValue={"0"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid item container xs={4} style={{ paddingTop: 5 }}>Admin Tasks</Grid>
                                        <Grid item container xs={8}>
                                            <Grid container>

                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="is_UND_FORM_SIG"
                                                            control={control}
                                                            render={(p) => renderCheckBox(p, !watchIsSelf)}
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    style={{ margin: 0 }}
                                                    name="is_UND_FORM_SIG"
                                                    label="Undertaking Form"
                                                />
                                            </Grid>
                                            <Grid container>

                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="is_WAT_VIDEO"
                                                            control={control}
                                                            render={(p) => renderCheckBox(p, !watchIsSelf)}
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    style={{ margin: 0 }}
                                                    name="is_WAT_VIDEO"
                                                    label="eCourse"
                                                />
                                            </Grid>
                                            <Grid container>

                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="is_HD_FORM_COM"
                                                            control={control}
                                                            render={(p) => renderCheckBox(p, !watchIsSelf)}
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    style={{ margin: 0 }}
                                                    name="is_HD_FORM_COM"
                                                    label="Health Declaration Form"
                                                />
                                            </Grid>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name="is_FRS_SIG"
                                                        control={control}
                                                        render={(p) => renderCheckBox(p, !watchIsSelf)}
                                                        defaultValue={false}
                                                    />
                                                }
                                                style={{ margin: 0 }}
                                                name="is_FRS_SIG"
                                                label="FRS"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="isCollaborate"
                                            control={control}
                                            defaultValue={false}
                                            render={(pros) => (
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => { pros.onChange(e.target.checked); handleIsCollaborate(e.target.checked); }}
                                                    checked={pros.value}
                                                    disabled={props.roleDisabled('isCollaborate')}
                                                />
                                            )}
                                        />
                                    }
                                    name="isCollaborate"
                                    label="Project Researchers"
                                />

                                <br />
                                {handleProjectRole()}
                                {handleProjectRole(2)}
                                {handleProjectRole(3)}
                                {handleProjectRole(4)}
                                {handleProjectRole(5)}
                                {handleProjectRole(6)}
                                {handleProjectRole(7)}
                                {handleProjectRole(8)}

                                <Grid container>
                                    {
                                        [
                                            { name: "isHADCL", label: "HADCL Officer" },
                                            { name: "isPMOMember", label: "PMO Member" },
                                            // { name: "isPMOChair", label: "PMO Chairman" },
                                            { name: "isSystemAM", label: "System Admin" },
                                            { name: "isDutyOfficer", label: "Duty Officer" },
                                            { name: "isWSParticipant", label: "Workshop Participant" }
                                        ].map(r => (
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={r.name}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={(pros) => (
                                                                <Checkbox
                                                                    color="primary"
                                                                    onChange={(e) => { pros.onChange(e.target.checked); }}
                                                                    checked={pros.value}
                                                                    disabled={props.roleDisabled(r.name)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    name={r.name}
                                                    label={r.label}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div>
                        </FormControl>
                    </div>

                    <div className={classes.margin}>
                        <Grid container>
                            <Grid item xs={5} md={5} lg={5}>
                                <FormLabel component="legend">Status</FormLabel>
                                <div className={classes.marginRadio}>
                                    <Controller
                                        as={
                                            <RadioGroup aria-label="Status">
                                                <FormControlLabel value="A" control={<Radio color="primary" />} label="Active" />
                                                <FormControlLabel value="I" control={<Radio color="primary" />} label="Inactive" />
                                            </RadioGroup>
                                        }
                                        name="status"
                                        control={control}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                                <FormLabel component="legend">Locked</FormLabel>
                                <div className={classes.marginRadio}>
                                    <Controller
                                        as={
                                            <RadioGroup aria-label="Lock">
                                                <FormControlLabel value="L" disabled control={<Radio color="primary" />} label="Yes" />
                                                <FormControlLabel value={'UL'} disabled control={<Radio color="primary" />} label="No" />
                                            </RadioGroup>
                                        }
                                        name="lockStatus"
                                        control={control}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.margin}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Save
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleCancelForm}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    );
}
