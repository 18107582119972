import { useState, useEffect } from 'react';

function usePwdValidation(watchNewPassword, email) {
    const [atLeast8Valid, setAtLeast8Valid] = useState(false);
    const [atLeast1Num, setAtLeast1Num] = useState(false);
    const [atLeast1LowAndUp, setAtLeast1LowAndUp] = useState(false);
    const [atLeast1Special, setAtLeast1Special] = useState(false);
    const [notContainEmail, setNotContainEmail] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        setAtLeast8Valid(watchNewPassword.length >= 8);
        setAtLeast1Num(/\d/.test(watchNewPassword));
        setAtLeast1LowAndUp(/(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]/.test(watchNewPassword));
        setAtLeast1Special(/.*[^A-Za-z0-9]+.*/.test(watchNewPassword));
        setNotContainEmail(watchNewPassword && email && !watchNewPassword.toUpperCase().includes(email.toUpperCase().substr(0, email.indexOf('@'))));
        setSaveEnabled(atLeast8Valid && atLeast1Num && atLeast1LowAndUp && atLeast1Special && notContainEmail);
    }, [atLeast1LowAndUp, atLeast1Num, atLeast1Special, atLeast8Valid, email, notContainEmail, watchNewPassword]);

    return { atLeast8Valid, atLeast1Num, atLeast1LowAndUp, atLeast1Special, notContainEmail, saveEnabled };
}

export default usePwdValidation;