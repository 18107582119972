import { format, parseISO, subDays } from 'date-fns'

export function GridDateNoTime(params) {
    try {
        var d = (typeof params.value.getMonth === 'function') ? params.value : parseISO(params.value);
        return format(d, 'yyyy/MM/dd');
    } catch (err) {
        return "";
    }
}

export function DateNoTime(date) {
    try {
        var d = (typeof date.getMonth === 'function') ? date : parseISO(date);
        return format(d, 'yyyy/MM/dd');
    } catch (err) {
        return "";
    }
}

export function DateWithTime(date) {
    try {
        var d = parseISO(date);
        return format(d, 'yyyy/MM/dd HH:mm:ss');
    } catch (err) {
        return "";
    }
}

export function DisplayCutOffDate(cutOffDate) {
    //as researcher should also be able to book ON the 'cut-off day'
    //display cut-off day as DB setting date minus 1 day 
    return DateNoTime(subDays(cutOffDate, 1));
}

export function MonthYear(date) {
    try {
        var d = (typeof date.getMonth === 'function') ? date : parseISO(date);
        return format(d, 'MMM-yyyy');
    } catch (err) {
        return "";
    }
}


export function GridTime(params) {
    try {
        var d = (typeof params.value.getTime === 'function') ? params.value : parseISO(params.value);
        return format(d, 'HH:mm:ss');
    } catch (err) {
        return "";
    }
}

export function Time(date) {
    try {
        var d = (typeof date.getTime === 'function') ? date : parseISO(date);
        return format(d, 'HH:mm:ss');
    } catch (err) {
        return "";
    }
}


function hasValue(val) {
    if (val === null || typeof val === 'undefined') {
        return false;
    }
    return true;
}

const customComparator = (valueA, valueB) => {
    // console.log(valueA)
    if (valueA === null) {
        valueA = ''
    }
    if (valueB === null) {
        valueB = ''
    }
    if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA?.toLowerCase()?.localeCompare(valueB?.toLowerCase());
    }
    if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA-valueB;
    }
};

export function GridColDef(obj) {
    const {
        headerName,
        field,
        headerTooltip,
        minWidth,
        resizable,
        wrapText,
        autoHeight,
        sortable,
        filter,
        headerCheckboxSelection,
        checkboxSelection,
        tooltipField,
        comparator,
        ...other } = obj;

    return {
        headerName: hasValue(headerName) ? headerName : null,
        field: hasValue(obj.field) ? obj.field : null,
        headerTooltip: hasValue(headerTooltip) ? headerTooltip : headerName, //tool tip should be the same as header by default
        minWidth: hasValue(minWidth) ? minWidth : 100,
        resizable: hasValue(resizable) ? resizable : true,
        wrapText: hasValue(wrapText) ? wrapText : false,
        autoHeight: hasValue(autoHeight) ? autoHeight : true,
        sortable: hasValue(sortable) ? sortable : true,
        filter: hasValue(filter) ? filter : true,
        headerCheckboxSelection: hasValue(headerCheckboxSelection) ? headerCheckboxSelection : false,
        checkboxSelection: hasValue(checkboxSelection) ? checkboxSelection : false,
        tooltipField: hasValue(tooltipField) ? tooltipField : field,
        comparator: hasValue(comparator) ? comparator : customComparator,
        ...other
    };
}


