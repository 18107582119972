import React, { useEffect } from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import axios from '../../../CustomAxios';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./Styles";
import { useHistory, Prompt, useLocation } from 'react-router-dom';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import useModal from "hooks/useModal";
import { warning, success } from 'components/common/Constants';
import ReportDraftEditor from 'components/progressReports/researcher/detail/ReportDraftEditor';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import { OutputContext } from './index';
import isValid from 'date-fns/isValid';
import { statusColor } from './OutputRequestList';
import DatePicker from '@material-ui/lab/DatePicker';
const publicationTypeMap = {
    ABS: 'Abstract',
    SUM: 'Summary',
    RES: 'Research Paper',
    MAN: 'Manuscript',
    
}
const jouOrConMap = {
    JOU: 'Journal',
    CON: 'Conference',
    
}
export default function PublicationDetail(props) {
    const classes = useStyles();
    const {
        isCreate, handleBack,
        currentDetail, setIsLoading, setReload, reload, selectedProject,isOutputRequest, selectedProjectObj, user
    } = React.useContext(OutputContext)
    const { setUser } = React.useContext(UserContext);
    const history = useHistory();
    const showModal = useModal();
    const [state, setBaseState] = React.useState({ supplementaries: [], publication: {} })
    const [nextPath, setNextPath] = React.useState(null);
    const [error, setError] = React.useState({});
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const draftEditorRef = React.useRef({}) 
    const [errorMsg, setErrorMsg] = React.useState('')
    const isNoBack = React.useRef(null);
    const draftEditorLengthErrorRef = React.useRef({ supplementaries: {} })
    const isProjectMember = user.user.id != state.requester&& !isCreate
    const disableSubmit = (isCreate || state.statusCode == "INCOMPLETED" || state.statusCode == "REVISION") && !isProjectMember ? false : true;

    useEffect(() => {
        if (!isCreate && currentDetail) {
            loadDetail()
        } else {
            setBaseState((oldState) => ({
                ...oldState,
                requestByName: user.user.email,  
                projectId: selectedProject,  
                requestType: isOutputRequest ? "OUTPUT" : "PUBLICATION",
                refNum: selectedProjectObj.refNum
            }))
            draftEditorRef.current.supplementaries = []
        }
    }, [currentDetail, isCreate])

    

    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    
    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {

            history.push(nextPath);
        }

    }, [history, isDirty, nextPath])

    const loadDetail = () => {
        setIsLoading(true);
        axios.get('/outputRequest/get-output-request-detail', { params: { outputId: currentDetail.id, projectId: currentDetail.projectId } }).then((res) => {
            if (res.data) {
                draftEditorRef.current = {
                    remarksReq: res.data.remarksReq,
                    supplementaries: res.data.supplementaries,
                };
                
                setBaseState(res.data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/output-request'))
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave('INCOMPLETED')
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your Publication is not yet saved/ submitted </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
       
        return false;
    }

    const checkSupplementaries = (payload, errorTemp) => {
        let isError = false;
        payload.supplementaries.map((item, index) => {
            if (!errorTemp.supplementaries) {
                errorTemp.supplementaries = {}
            }
            if (!item.content) {
                if (errorTemp.supplementaries[index]) {
                    errorTemp.supplementaries[index]['content'] = true;
                } else {
                    errorTemp.supplementaries[index] = {content: true}
                }
                isError = true
            }
            if (!item.documentName) {
                if (errorTemp.supplementaries[index]) {
                    errorTemp.supplementaries[index]['documentName'] = true;
                } else {
                    errorTemp.supplementaries[index] = {'documentName': true}
                }
                isError = true
            }
            return item
        })
        return isError
    }
    const handleCheckSubmit = (payload, errorTemp, callback) => {
        let isError = false;
        if (!payload.publication.publicationType||!payload.publication.publicationTitle||!payload.publication.jouOrCon) { 
            errorTemp.publication = true;
            isError = true
        }
        if (payload.publication.jouOrCon == "CON") { 
            if (!payload.publication.nameOfConference || !payload.publication.conferenceOrganizer || !payload.publication.expectedPreDate) { 
                errorTemp.publication = true;
                isError = true
            }
        }
        if (payload.publication.jouOrCon == "JOU") { 
            if (!payload.publication.nameOfJournal || !payload.publication.expectedPubDate) { 
                errorTemp.publication = true;
                isError = true
            }
        }
        if (checkSupplementaries(payload, errorTemp)) {
            isError = true
        }
        if (isError) {
            callback()
        }
    }
    const handleCheck = (payload, status) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.AllMandatory;
        if (status != 'INCOMPLETED') {
            handleCheckSubmit(payload, errorTemp, function () { 
                isError = true;
            })
           
        }
        Object.keys(draftEditorRef.current).forEach((key) => {
            if ('supplementaries' === key) {
                Object.keys(draftEditorLengthErrorRef.current.supplementaries).forEach((k) => {
                    if (draftEditorLengthErrorRef.current.supplementaries[k]) {
                        errorTemp['supplementaries'][k] ={content: true};
                        isError = true
                        msg = warning.reportRichLength
                    }
                    // return;
                })
            } 
            // return key
        })
        if (payload.remarksReq) {
            let cleanCode = payload.remarksReq.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
            // console.log(cleanCode, cleanCode.length)
            if (cleanCode.length > 2000) {
                errorTemp.remarksReq = true;
                !isError && (msg = warning.RichLength)
                isError = true
            }
        }
        isNoBack.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }
    const onSubmit = (payload, status) => {
        const requests = {
            ...payload,
            // projectProgress: payload.projectProgress * 1,
            status
        }
        // return
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request-res', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.Saved
                    if (status == 'SUBMITTED') {
                        m = success.Submitted
                    }
                   

                    setIsDirty(false)
                    setIsLoading(false);
                    setErrorMsg(m)

                    // draftEditorLengthErrorRef.current = {}

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }
    const handleSave = (status) => {
        const payload = {
            projectId: state.projectId,
            requestType: state.requestType,
            organisation: state.organisation,
            publication: state.publication,
            
            supplementaries: JSON.parse(JSON.stringify(draftEditorRef.current.supplementaries)),
            remarksReq: draftEditorRef.current.remarksReq
        }
       
        if (state.id) {
            payload.id = state.id
        }
        if (status !== "WITHDRAWN" && handleCheck(payload, status)) {
            return
        }
        if (payload.publication.expectedPreDate) {
            payload.publication.expectedPreDate = format(startOfDay(new Date(payload.publication.expectedPreDate)), "yyyy-MM-dd")
        }
        if (payload.publication.expectedPubDate) {
            payload.publication.expectedPubDate = format(startOfDay(new Date(payload.publication.expectedPubDate)), "yyyy-MM-dd")
        }
        payload.remarksReq = window.htmlEncode(payload.remarksReq)
        payload.supplementaries.map((item) => {
            item.content = window.htmlEncode(item.content)
        })
        // return 
        onSubmit(payload, status)
    }

    const handleChangeTextArea = (value, fileName) => {
        
        let isError = false
        if (error[fileName]) {
            isError = false
        }


        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
    }

    

    const handleBackList = () => {
        if (isDirty && !disableSubmit) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    handleBack()
                },
            })
        } else {
            setIsDirty(false)
            handleBack()
        }
        
    }
    const handleCloseDialog = () => {
        if (!isDirty && !isNoBack.current) {
            handleBackList()
            setReload(!reload)
        }
        setErrorMsg('')
    }

   
    const handleRenderInput = (component, value) => {
        if (disableSubmit) {
            return <div style={{ padding: '5px 0', width: '100%', height: '100%', maxHeight: 250, wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex'}}>
                <div title={value} style={{ lineHeight: "1.2", width: '100%', maxHeight: '100%', overflowY: 'auto', color: '#c1c1c1' }} dangerouslySetInnerHTML={{ __html: value }}>
                </div>

            </div>
        }
        return component
    }

    const handleAddAttendance = () => {
        if(disableSubmit) return
        const newObj = {
            "content": "",
            "documentName": "",
        }
        const newSupplementaries = [...state.supplementaries]
        newSupplementaries.push(newObj)
        handleSetBaseState({supplementaries: newSupplementaries})
        draftEditorRef.current.supplementaries = newSupplementaries
    }
    const handleChangeSupplementaries = (value, filedName, index, maxLength) => {
        const newSupplementaries = [...state.supplementaries]
        let cleanCode = value.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
        let isError = false
        let supplementariesError = { ...(error.supplementaries || {}) }
        supplementariesError[index] = { ...(supplementariesError[index] || {})}
       
        if (cleanCode.length > maxLength) {
            draftEditorLengthErrorRef.current['supplementaries'][index] = maxLength
            // setError((s) => ({...s,[fileName]: false}))
        } else {
            draftEditorLengthErrorRef.current['supplementaries'][index] = false
        }
        if (isError !== supplementariesError[index]['content']) {
            supplementariesError[index]['content'] = isError
            setError((s) => ({...s,['supplementaries']: supplementariesError}))
        }
        newSupplementaries[index][filedName] = value
        !disableSubmit&& setIsDirty(true)
        
        draftEditorRef.current.supplementaries = newSupplementaries
    }
    const handleChangeSupplementariesName = (value, filedName, index) => {
        const newSupplementaries = [...state.supplementaries]
        
        let isError = false
        let supplementariesError = { ...(error.supplementaries || {}) }
        supplementariesError[index] = { ...(supplementariesError[index] || {})}
        

        if (isError !== supplementariesError[index]) {
            
            supplementariesError[index]['documentName'] = isError
            // console.log(supplementariesError,1111)
            setError((s) => ({...s,['supplementaries']: supplementariesError}))
        }
        newSupplementaries[index][filedName] = value
        !disableSubmit&& setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({supplementaries: newSupplementaries})
        draftEditorRef.current.supplementaries = newSupplementaries
    }
    const onDeleteSupplementaries = (index) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    const newSupplementaries = [...state.supplementaries]
                    let isError = false
                    let supplementariesError = { ...(error.supplementaries || {}) }
                    if (error['supplementaries'] && supplementariesError[index]) {
                        // isError = false
                        draftEditorLengthErrorRef.current['supplementaries'][index] = false
                    }
                    if (isError !== supplementariesError[index]) {
                        supplementariesError[index] = {}
                        setError((s) => ({ ...s, ['supplementaries']: {} }))
                    }
                    newSupplementaries.splice(index, 1)
                    !disableSubmit && setIsDirty(true)
                    handleSetBaseState({supplementaries: newSupplementaries})
                    draftEditorRef.current.supplementaries = newSupplementaries
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the document?</div>
            </SystemConfirmDialog>
        ));
        
    }
    const genOption = (obj) => {
         
        return Object.keys(obj).map((k) => {
            return {
                value: k,
                name: obj[k]
            }
        })
    }
    const handlePublication = (value, fileName) => {
        const newPublication = {...state.publication}
        newPublication[fileName] = value
        handleSetBaseState({
            publication: newPublication
        })
    }
    const handleChangeInput = (value, field) => {
        let isError = false
        if (error[field]) {
            isError = false
        }
        if (isError !== error[field]) {
            setError((s) => ({ ...s, [field]: isError }))
        }
        handleSetBaseState({ [field]: value })
    }
    const formatDateValue = (date = "") => {
        if (typeof date === 'string') {
            return new Date(date)
        }
        return date
    }

    const handleChangeConference = (v, fileName) => {
        const newPublication = {...state.publication}
        if (v == "CON") {
            newPublication.nameOfJournal = "";
            newPublication.expectedPubDate = null
        } else {
            newPublication.nameOfConference = "";
            newPublication.conferenceOrganizer = "";
            newPublication.expectedPreDate = null
        }
        newPublication[fileName] = v
        handleSetBaseState({
            publication: newPublication
        })
    }
    // console.log(state.publication)
    const isShowPublicationErrorBorder = (filedName) => {
        return error.publication && !state.publication[filedName] ? {border: '1px solid #ff0000'} : {}
    }
    const showRemarksForAdm = () => {
        return state.remarksRsp && <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
        <Grid container className={classes.title}>
            Part V&nbsp;&nbsp;&nbsp;Comments from HADCL Office
        </Grid>
        <Grid container>
            {/* <ReportDraftEditor placeholder={'<2000 characters'} value={''} /> */}
            <ReportDraftEditor disabled={true} value={state.remarksRsp}  />
        </Grid>
    </Grid>
    }
    return (
        <>
            <Grid container style={{ height: '100%' }} >
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Publication Review
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container className={classes.title}>
                        Part I&nbsp;&nbsp;&nbsp;Requestor Information
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Request No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestNo}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Status</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{
                                <div title={state.status} style={{ width: '150px',height: 29, lineHeight:'29px', color: "#fff", borderRadius: 5, fontSize:13, textAlign:'center', ...(statusColor[state.statusCode])}} dangerouslySetInnerHTML={{__html: state.status}}>
                                </div>
                                
                            }</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Requested by</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestByName}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>
                                <div>
                                    <div>First Submission Date</div>
                                    <div>Last Submission Date</div>
                                </div>
                            </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <div>
                                    <div>{state.firstSubmissionDate && format(new Date(state.firstSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                    <div>{state.lastSubmissionDate && format(new Date(state.lastSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project / Self-Service No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Organisation</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-Organisation"
                                        label=""
                                        multiline
                                        // rows={3}
                                        inputProps={{ maxLength: 100 }}
                                        className={classes.textareaBox}
                                        placeholder="≤100 characters"
                                        variant="outlined"
                                        value={state.organisation}
                                        InputProps={{
                                            // style: {
                                            //     ...(error.reasonOfRequest && !state.reasonOfRequest ? { border: '1px solid #ff0000' } : {})
                                            // },
                                        }}
                                        onChange={(e) => handleChangeInput(e.target.value, 'organisation')}
                                    />,
                                    state.organisation
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*  */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part II&nbsp;&nbsp;&nbsp;Publication Details
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Publication Type <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(
                                <SelectComponent disabled={disableSubmit} displayEmpty={false} value={state.publication.publicationType } onChange={(v) => { handlePublication(v, 'publicationType') }} style={{
                                    textTransform: 'none',
                                    fontSize: '13px',
                                    width: '200px',
                                    ...(isShowPublicationErrorBorder("publicationType"))
                                }} options={genOption(publicationTypeMap)} />,
                                publicationTypeMap[state.publication.publicationType]
                            )}
                        </Grid>
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Publication Title <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(
                                <TextField
                                    fullWidth
                                    id="filled-publicationTitle"
                                    label=""
                                    multiline
                                    rows={2}
                                    inputProps={{ maxLength: 200 }}
                                    className={classes.textareaBox}
                                    placeholder="≤200 characters"
                                    variant="outlined"
                                    value={state.publication.publicationTitle}
                                    InputProps={{
                                        style: {
                                            ...(isShowPublicationErrorBorder("publicationTitle"))
                                        },
                                    }}
                                    onChange={(e) => handlePublication(e.target.value, 'publicationTitle')}
                                />,
                                state.publication.publicationTitle
                            )}
                        </Grid>
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Journal or Conference <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(
                                <SelectComponent disabled={disableSubmit} displayEmpty={false} value={state.publication.jouOrCon } onChange={(v) => { handleChangeConference(v, 'jouOrCon') }} style={{
                                    textTransform: 'none',
                                    fontSize: '13px',
                                    width: '200px',
                                    ...(isShowPublicationErrorBorder("jouOrCon")) 
                                }} options={genOption(jouOrConMap)} />,
                                jouOrConMap[state.publication.jouOrCon]
                            )}
                            
                        </Grid>
                    </Grid>
                    {state.publication.jouOrCon == "CON" && <>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >
                                Name of Conference<span className={classes.red}>*</span>
                            </Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-nameOfConference"
                                        label=""
                                        multiline
                                        rows={2}
                                        inputProps={{ maxLength: 200 }}
                                        className={classes.textareaBox}
                                        placeholder="≤200 characters"
                                        variant="outlined"
                                        value={state.publication.nameOfConference}
                                        InputProps={{
                                            style: {
                                                ...(isShowPublicationErrorBorder("nameOfConference"))
                                            },
                                        }}
                                        onChange={(e) => handlePublication(e.target.value, 'nameOfConference')}
                                    />,
                                    state.publication.nameOfConference
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Conference Organizer<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-conferenceOrganizer"
                                        label=""
                                        multiline
                                        rows={2}
                                        inputProps={{ maxLength: 200 }}
                                        className={classes.textareaBox}
                                        placeholder="≤200 characters"
                                        variant="outlined"
                                        value={state.publication.conferenceOrganizer}
                                        InputProps={{
                                            style: {
                                                ...(isShowPublicationErrorBorder("conferenceOrganizer"))
                                            },
                                        }}
                                        onChange={(e) => handlePublication(e.target.value, 'conferenceOrganizer')}
                                    />,
                                    state.publication.conferenceOrganizer
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Expected Presentation Date<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                <DatePicker
                                    disabled={disableSubmit}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.publication.expectedPreDate)}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handlePublication(newValue, 'expectedPreDate')
                                        }
                                    }}
                                    views={['year', 'month', 'date']}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.publication && !state.publication.expectedPreDate} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>}
                    {state.publication.jouOrCon == "JOU" && <>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Name of Journal<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(
                                    <TextField
                                        fullWidth
                                        id="filled-nameOfJournal"
                                        label=""
                                        multiline
                                        rows={2}
                                        inputProps={{ maxLength: 200 }}
                                        className={classes.textareaBox}
                                        placeholder="≤200 characters"
                                        variant="outlined"
                                        value={state.publication.nameOfJournal}
                                        InputProps={{
                                            style: {
                                                ...(isShowPublicationErrorBorder("nameOfJournal"))
                                            },
                                        }}
                                        onChange={(e) => handlePublication(e.target.value, 'nameOfJournal')}
                                    />,
                                    state.publication.nameOfJournal
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Expected Publication Date<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                <DatePicker
                                    disabled={disableSubmit}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.publication.expectedPubDate)}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handlePublication(newValue, 'expectedPubDate')
                                        }
                                    }}
                                    views={['year', 'month', 'date']}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.publication && !state.publication.expectedPubDate} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>}
                    
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.label} >Reference&nbsp;(optional)</Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(
                                <TextField
                                    fullWidth
                                    id="filled-multiline-File-name"
                                    label=""
                                    multiline
                                    rows={3}
                                    inputProps={{ maxLength: 1000 }}
                                    className={classes.textareaBox}
                                    placeholder="≤1000 characters"
                                    variant="outlined"
                                    value={state.publication.reference}
                                    
                                    onChange={(e) => handlePublication(e.target.value, 'reference')}
                                />,
                                state.publication.reference
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                    
                {/* Part III Model list  (Please specify in the below details for different model(s))*/}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part III&nbsp;&nbsp;&nbsp;Supplementary Information
                    </Grid>
                    <Grid container style={{padding: '5px 10px', fontSize: 13}}>
                        Please provide us the details or document links of the supplementary information below.
                    </Grid>
                    <Grid container >
                        <Button style={{
                            ...(disableSubmit ? {backgroundColor: '#dfdfdf ',
                            color: 'rgba(0, 0, 0, 0.26) ',} : {backgroundColor: '#15a9d2 ',
                            color: '#ffff '}),
                            marginRight: 10,
                            textTransform: 'none'
                        }} disabled={disableSubmit} className={classes.activeButton} onClick={handleAddAttendance}>+Add Document</Button>
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Document name</Grid>
                        <Grid item container xs={8} className={classes.projectTh} justifyContent='center' >{`Description & Link *`}</Grid>
                    </Grid>
                    {
                        state.supplementaries && state.supplementaries.map((item, index) => {
                            return <Grid container key={index}>
                                <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10}} className={classes.projectTd}  >
                                    <span style={{paddingTop: 2}}>{index + 1}.&nbsp;&nbsp;</span> 
                                    <Grid item container xs>
                                        {handleRenderInput(
                                            <TextField inputProps={{ maxLength: 500 }} InputProps={{
                                                style: {
                                                    paddingLeft: 10,
                                                    // width: 100,
                                                    ...(error.supplementaries && error.supplementaries[index] && error.supplementaries[index]['documentName'] ? { border: '1px solid #ff0000' } : {})
                                                },
                                            }} value={item.documentName||''} onChange={(e) => handleChangeSupplementariesName(e.target.value, 'documentName', index)} />,
                                            item.documentName
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={8} style={{ paddingLeft: 10, paddingRight: 10,}} className={classes.projectTd} justifyContent='center' >
                                    <Grid item container style={{flex: 1, position: 'relative', height: 80 }}>
                                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
                                            {handleRenderInput(
                                                <ReportDraftEditor disabled={disableSubmit} placeholder={'≤200 characters'} isError={error.supplementaries && error.supplementaries[index] && error.supplementaries[index]['content']} value={item.content} height={80} onChange={(e) => handleChangeSupplementaries(e, 'content', index, 200)} />,
                                                item.content
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item container style={{width: 50}} justifyContent='center' alignItems={'center'}>
                                        {!disableSubmit && <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => {onDeleteSupplementaries(index) }} />}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        })
                    }
                </Grid>

                {/* Part IV  Comments from Researchers */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part IV&nbsp;&nbsp;&nbsp;Remarks from Researcher
                    </Grid>
                    <Grid container>
                        {/* <ReportDraftEditor placeholder={'<2000 characters'} value={''} /> */}
                        <ReportDraftEditor disabled={disableSubmit} placeholder={'≤2000 characters'} isError={error.remarksReq} value={state.remarksReq} onChange={(e) => handleChangeTextArea(e, 'remarksReq')} />

                    </Grid>
                </Grid>
                {showRemarksForAdm()}
                <Grid container style={{ paddingTop: 10 }}>
                    <div className={classes.mandatory}>*=Mandatory</div>
                </Grid>
                <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs>
                        {!disableSubmit && <><Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            marginRight: 10,
                            textTransform: 'none'
                        }}  className={classes.activeButton} onClick={() => handleSave('SUBMITTED')}>Submit</Button>
                            <Button style={{
                                backgroundColor: '#15a9d2 ',
                                color: '#ffff ',
                                marginRight: 10,
                                textTransform: 'none'
                            }}  className={classes.activeButton} onClick={() => handleSave('INCOMPLETED')}>Save</Button></>}
                        <Button style={{
                            backgroundColor: '#646464 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleBackList}>{disableSubmit ? "Back to Summary" : 'Cancel'}</Button>
                    </Grid>
                    
                </Grid>
                {state.lastUpdatedName && <div style={{ paddingTop: 10 }}>Last updated by {state.lastUpdatedName} on {format(new Date(state.lastUpdatedDate), 'dd MMM yyyy HH:mm')} </div>}
            </Grid>
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
           
            {<SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </>
    );
}
