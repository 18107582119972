import React, { useState } from 'react';
import {
    Grid
    , Button
} from '@material-ui/core';
import { useStyles } from './Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import { useTheme } from '@material-ui/core/styles';
import axios from '../../CustomAxios';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import GoReportDialog from 'components/common/GoReportDialog';

const ProjectItem = ({ item, classes, theme, itemClick, value, index }) => {
    const bgColor = item.disableBooking ? {
        backgroundColor: "#dddddd",
    } : {
        backgroundColor: item.checked ? `${theme.palette.primary.main}` : 'white',
    }
    return (
        <div style={{ marginTop: '4px' }} key={index}>
            <Button
                disabled={item.disableBooking}
                endIcon={item.checked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                // color={item.checked ? "primary" : "inherit"}
                className={classes.projectButton}
                style={{
                    ...(bgColor),
                    color: item.checked ? 'white' : 'black',
                    textTransform: 'none'
                }}
                onClick={() => itemClick(item, value)}
            >
                <div className={classes.projectLabel}>{item.projectCode}</div>
            </Button>
        </div >
    )
};

const ProjectSelector = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [projectList, setProjectList] = useState([]);
    const ColProjectList = projectList.filter(e => e.projectType === "COL_RESEARCHER");
    const SelfProjectList = projectList.filter(e => e.projectType === "SELF_RESEARCHER");
    const { value, setIsLocked } = props;
    const { isDirty } = useDirtyCheck();

    const reportRef = React.useRef(null)
    const getReportStatus = (projectId) => {
        reportRef.current.getReportStatus(projectId, (res) => {
            if (res) {
                setIsLocked(true)
            } else {
                setIsLocked(false)
            }
        })
    }

    const fetchData = async (fvalue, callback) => {
        await axios.get('bookrequest/get-user-projects', null).then(res => {
            let projects = res.data;
            const colProject = projects.find(e => e.projectType === "COL_RESEARCHER");
            const selfService = projects.find(e => e.projectType === "SELF_RESEARCHER");

            if (fvalue.selectedProject) {
                const existPro = projects.find(e => e.projectId === fvalue.selectedProject.projectId);
                if (!existPro.disableBooking) {
                    existPro.checked = true;
                    fvalue.setSelectedProject(existPro);
                }
            } else {
                if (colProject && !colProject.disableBooking) {
                    colProject.checked = true;
                    fvalue.setSelectedProject(colProject);
                    getReportStatus(colProject.projectId)
                }
                else if (selfService && !selfService.disableBooking) {
                    selfService.checked = true;
                    fvalue.setSelectedProject(selfService);
                    getReportStatus(selfService.projectId)
                }
            }
            setProjectList(res.data);
            callback && callback(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    React.useEffect(() => {
        fetchData(value);
    }, [])

    const itemClick = (item, ivalue) => {
        const handleChangeProject = (pl) => {
            let tmpProjectList = pl ? pl : projectList;
            if (tmpProjectList) {
                tmpProjectList.forEach(e => e.checked = e.projectId === item.projectId);
                setProjectList(tmpProjectList);
                getReportStatus(item.projectId);
                ivalue.setSelectedProject(tmpProjectList.find(sitem => sitem.checked));
            }
        }
        const fetchDateCallback = () => {
            fetchData(value, handleChangeProject)
        }
        if (isDirty && item.projectId !== ivalue.selectedProject.projectId) {
            ivalue.handleOpenDirtyCheck({ leaveCallback: fetchDateCallback });
            return;
        }
        fetchDateCallback();
        // fetchData(value, handleChangeProject)
    }

    return (
        <>

            <Grid container justifyContent={'center'}>
                <Grid item>
                    {
                        ColProjectList.length ?
                            <>
                                <div className={classes.projectTitle}>Project No.</div>
                                {ColProjectList.map((item, index) => (
                                    <ProjectItem key={index + '_Col'} index={index + '_Col'} item={item} classes={classes} theme={theme} itemClick={itemClick} value={value} />
                                ))}
                            </> : null
                    }
                    {
                        SelfProjectList.length ?
                            <>
                                <div className={classes.projectTitle}>Self-Service No.</div>
                                {SelfProjectList.map((item, index) => (
                                    <ProjectItem key={index + '_Self'} index={index + '_Self'} item={item} classes={classes} theme={theme} itemClick={itemClick} value={value} />
                                ))}
                            </> : null
                    }
                </Grid>
            </Grid>
            <GoReportDialog ref={reportRef} />
        </>
    );
}

export default ProjectSelector;