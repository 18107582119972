import React from 'react';
import {
    Divider,
    Grid,
    Typography,
    Button,
    TextField,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import { useStyles } from './Styles';
import ProjectSelector from './ProjectSelector';
import CalendarComponent from 'components/common/Calendar/CalendarComponent';
import BookingHistoryComponent from 'components/common/BookingHistory/BookingHistoryComponent';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/core/styles';
import DragImage from 'images/dragImage.png';
import {
    format
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , addDays
    , addMonths
    , isAfter
    , addHours
} from 'date-fns';
import LoadingScreen from '../common/LoadingScreen';
import SystemMessageDialog from '../common/SystemMessageDialog';
import axios from '../../CustomAxios';
import TickIcon from '../../images/tick.svg';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import useSysParam from 'hooks/UseSysParam';
import Checkbox from '@material-ui/core/Checkbox';
import { HomeLayoutViewContext } from 'components/HomeLayout';
export const { Provider, Consumer } = React.createContext();

const ManageBookings = (props) => {
    const classes = useStyles();
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [remarks, setRemarks] = React.useState([]);
    const [currentModal, setCurrentModal] = React.useState('CView');
    const [isEdit, setIsEdit] = React.useState(false);
    const [selectedDateList, setSelectedDateList] = React.useState([]);
    const capacityList = React.useRef([]);
    const [bookingsList, setBookingsList] = React.useState([]);
    const [viewDate, setViewDate] = React.useState(startOfMonth(new Date()));
    const initWeekCheckeds = [{ checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }];
    const [weekCheckedList, setWeekCheckedList] = React.useState(initWeekCheckeds);
    const initWeekRdoDps = [{ display: false }, { display: false }, { display: false }, { display: false }, { display: false }, { display: false }, { display: false }];
    const [weekRdoDisplays, setWeekRdoDisplays] = React.useState(initWeekRdoDps);
    const [, setNoValid] = React.useState(true);
    const [resNo, setResNo] = React.useState('');
    const [resNoInvalidMsg, setResNoInvalidMsg] = React.useState('');
    const [allAvailableChecked, setAllAvailableChecked] = React.useState(false);
    const [selectDateChecked, setSelectDateChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const theme = useTheme();
    const [dateList, setDateList] = React.useState([]);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [updateAlertOpen, setUpdateAlertOpen] = React.useState(false);
    const [seatsInvalidOpen, setSeatsInvalidOpen] = React.useState(false);
    const [maxQuotaAlertOpen, setMaxQuotaAlertOpen] = React.useState(false);
    const [twoDayErrorOpen, setTwoDayErrorOpen] = React.useState(false);
    const [submitResult, setSubmitResult] = React.useState();
    const regexPattern = /^\d+$/;
    const [releaseTimes, setReleaseTimes] = React.useState([]);
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const [nextPath, setNextPath] = React.useState(null);
    const history = useHistory();
    const { setUser } = React.useContext(UserContext);
    const { paramIntValue: projectMaxQuota } = useSysParam('project_user_max_quota_per_book');

    const [isDragOpen, setIsDragOpen] = React.useState(false);
    const [isShowAgain, setIsShowAgain] = React.useState(false);

    const [reloadFlag, setReloadFlag] = React.useState(false);

    const [isLocked, setIsLocked] = React.useState(false);

    const {
        loadNotifications
    } = React.useContext(HomeLayoutViewContext)

    const uLocation = useLocation();
    React.useEffect(() => {
        if (uLocation.state && uLocation.state.date)
            setViewDate(new Date(uLocation.state.date));
    }, [uLocation.state])

    const isPreviousDate2 = (date) => {
        return isAfter(addDays(startOfDay(new Date()), 2), date);
    }

    React.useEffect(() => {
        if (selectedProject?.projectType === 'SELF_RESEARCHER')
            setResNo(1);
    })

    const fetchProjectData = () => {
        axios.get('bookrequest/get-user-projects', null).then(res => {
            let projects = res.data;
            if (selectedProject) {
                const existPro = projects.find(e => e.projectId === selectedProject.projectId);
                setSelectedProject(existPro);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    React.useEffect(() => {
        axios.post('/bookSetup/get-release-time', [format(startOfDay(viewDate), "yyyy-MM-dd"), format(addMonths(startOfDay(viewDate), 1), "yyyy-MM-dd")])
            .then(({ data: result }) => {
                setReleaseTimes(result);
            }).catch(err => {
                console.log(err)
            });

        fetchProjectData();
    }, [viewDate])

    React.useEffect(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let weekRdoDisplaysTmp = [...initWeekRdoDps]
        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            const weekIdx = d.getDay();
            const dateItem = capacityList.current.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(d)));
            const isClose = dateItem && !dateItem.available;
            const todayBooking = todayHasBooking(d);
            const isFull = dateItem && dateItem.quota >= dateItem.capacity && !todayBooking;
            const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(d));
            const notExpired = selectedProject && startOfDay(new Date(selectedProject.effectiveEndDate)) >= startOfDay(d);
            const isReject = isRejectDate(d);

            if (dateItem && !isClose && !isFull && !isPreviousDate && isRelease(dateItem?.releaseTime) && notExpired && !isReject) {
                weekRdoDisplaysTmp[weekIdx].display = true;
            }
            setWeekRdoDisplays(weekRdoDisplaysTmp);
        }
    }, [capacityList.current, bookingsList])

    React.useEffect(() => {
        setIsDirty(isUpdated());
        updateWeekChecked();
        if (!capacityList.current.length || !selectedDateList.length) return;
        const allCheckCount = capacityList.current.filter(item =>
            item.available &&
            (item.capacity > item.quota || todayHasBooking(new Date(item.bookingDate))) &&
            !isAfter(startOfDay(new Date()), startOfDay(new Date(item.bookingDate)))
            // !isPreviousDate(new Date(item.bookingDate))
            && startOfDay(new Date(item.bookingDate)) <= startOfDay(new Date(selectedProject.effectiveEndDate)) &&
            !isRejectDate(new Date(item.bookingDate))
        ).length;
        if (allCheckCount === selectedDateList.length && selectedProject?.projectType === 'COL_RESEARCHER') {
            setAllAvailableChecked(true);
            setSelectDateChecked(false);
        } else {
            setAllAvailableChecked(false);
            setSelectDateChecked(true);
        }
    }, [selectedDateList])

    React.useEffect(() => {
        setAllAvailableChecked(false);
        setSelectDateChecked(false);
        setSelectedDateList([]);
        setResNoInvalidMsg('');
        if (selectedProject?.projectType === 'COL_RESEARCHER')
            setResNo('');
    }, [selectedProject])

    const updateWeekChecked = () => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let selListTem = [...selectedDateList];
        let weekCheckedObj = [[], [], [], [], [], [], []];

        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            if (selectedProject && isAfter(startOfDay(d), startOfDay(new Date(selectedProject.effectiveEndDate)))) continue;
            if (isRejectDate(d)) continue;
            const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(d));
            const weekIdx = d.getDay();
            const hasChecked = selListTem.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)));
            const capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));
            const canBook = capItem && capItem.available && isRelease(capItem?.releaseTime) && (capItem.capacity > capItem.quota || todayHasBooking(d)) && !isPreviousDate;

            if (hasChecked)
                weekCheckedObj[weekIdx].push(true);
            else if (canBook)
                weekCheckedObj[weekIdx].push(false);
        }

        let weekCheckedListTmp = [...weekCheckedList];
        weekCheckedListTmp.forEach((item, idx) => {
            const trueChecked = weekCheckedObj[idx].filter(e => e === true);
            const falseChecked = weekCheckedObj[idx].filter(e => e === false);
            item.checked = trueChecked.length > 0 && falseChecked.length === 0;
        });
        setWeekCheckedList(weekCheckedListTmp);
    }

    const updateCalendarNo = () => {
        setResNoInvalidMsg('');
        let result = regexPattern.test(resNo);
        // setNoValid(result)
        if (!result)
            setResNoInvalidMsg('No. invalid');
        else if (parseInt(resNo) > projectMaxQuota) {
            setResNoInvalidMsg(`Max ${projectMaxQuota}`);
            return;
        }
        if (result && selectedDateList.length) {
            let dateListTem = [...selectedDateList];
            dateListTem.forEach(item => item.no = parseInt(resNo));
            setSelectedDateList(dateListTem);
            setUpdateAlertOpen(true);
        }
    }

    const enableUpdateBooking = () => {
        return (selectedProject?.projectType === 'COL_RESEARCHER' && resNo && selectedDateList.length) || false;
    }

    const setViewDateState = (date) => {
        setViewDate(date);
        setSelectedDateList([]);
        setWeekCheckedList(initWeekCheckeds);
        setAllAvailableChecked(false);
        setSelectDateChecked(false);
    }

    const selectAvailableDateClick = () => {
        const allAChecked = !allAvailableChecked;
        setAllAvailableChecked(allAChecked);
        allAChecked && setSelectDateChecked(false);
        if (allAChecked) {
            let selListTem = [...selectedDateList];
            capacityList.current.filter(
                item => item.available &&
                    // !isPreviousDate(new Date(item.bookingDate))  //startOfDay(new Date(item.bookingDate)) >= startOfDay(new Date())
                    startOfDay(new Date(item.bookingDate)) >= startOfDay(new Date())
                    &&
                    startOfDay(new Date(item.bookingDate)) <= startOfDay(new Date(selectedProject.effectiveEndDate))
            ).forEach(item => {
                const bookDate = new Date(item.bookingDate);
                const hasChecked = selListTem.some(sl => isEqual(startOfDay(new Date(sl.date)), startOfDay(bookDate)));
                if (isRelease(item.releaseTime) && !hasChecked && !isRejectDate(bookDate) && (item.capacity > item.quota || todayHasBooking(bookDate))) {
                    const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(bookDate)));
                    selListTem.push({ date: bookDate, no: bookinged ? bookinged.capacity : 1 });
                    removeInvalidDate(bookDate);
                }
            });
            setSelectedDateList(selListTem);
        } else {
            setSelectedDateList([]);
        }
    }

    const selectDateClick = () => {

        setSelectDateChecked(!selectDateChecked);
        if (!selectDateChecked) { setAllAvailableChecked(false); console.log('selectDateChecked', false); }
        setSelectedDateList([]);
    }

    const todayHasBooking = (date) => {
        return bookingsList.some(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(date)));
    }

    const handleCheckFunc = (week, d, wkListTmp, selListTem) => {
        const hasChecked = selListTem.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)))
        const capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));
        const canBook = capItem && !!capItem.available && capItem.capacity > capItem.quota;
        if (wkListTmp[week].checked && !hasChecked && (canBook || todayHasBooking(d))) {
            const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(d)));
            selListTem.push({ date: d, no: bookinged ? bookinged.capacity : 1 });
            removeInvalidDate(d);
        } else if (!wkListTmp[week].checked) {
            let curIdx = selListTem.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)));
            if (curIdx > -1) {
                selListTem.splice(curIdx, 1);
            }
        }
    }

    const setWeekChecked = (week) => {
        let wkListTmp = [...weekCheckedList];
        wkListTmp[week].checked = !wkListTmp[week].checked;
        setWeekCheckedList(wkListTmp);

        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let selListTem = [...selectedDateList];
        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            if (d.getDay() !== week) continue;
            const isPre = isAfter(startOfDay(new Date()), startOfDay(d));
            const dateItem = dateList.find(item => startOfDay(new Date(item.bookingDate)).valueOf() == startOfDay(d).valueOf())
            if (isPre || !isRelease(dateItem?.releaseTime) || isRejectDate(d)) continue;
            if (isAfter(startOfDay(d), startOfDay(new Date(selectedProject.effectiveEndDate)))) return false;


            handleCheckFunc(week, d, wkListTmp, selListTem);
            setSelectedDateList(selListTem);
        }
    }

    const setCapacityList = React.useCallback((list) => {
        capacityList.current = list;
    }, [])

    const setBookingsListState = React.useCallback((list) => {
        setBookingsList(list);
    }, [])

    const checkedDate = (date) => {
        let dateListTem = [...selectedDateList];
        let curIdx = dateListTem.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(date)));
        if (curIdx > -1) {
            dateListTem.splice(curIdx, 1);
        } else {
            dateListTem.push({ date: date, no: bookinged ? bookinged.capacity : 1 });
            removeInvalidDate(date);
        }

        setSelectedDateList(dateListTem);
    }

    const addNo = (date) => {
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        replaceNo(date, curDate ? curDate.no + 1 : 1);
    }

    const removeNo = (date) => {
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        replaceNo(date, curDate.no - 1);
    }

    const replaceNo = (date, no) => {
        let result = regexPattern.test(no);
        if (no && !result) return;
        if (no)
            no = parseInt(no);
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        if (!curDate) {
            curDate = { date: date, no: 1 };
            dateListTem.push(curDate);
            removeInvalidDate(date);
        }
        curDate.no = no;

        setSelectedDateList(dateListTem);
    }

    const selectedDateText = () => {
        return selectDateChecked ? selectedDateList.sort((a, b) => a.date - b.date).map(e => format(startOfDay(e.date), 'd MMM yyyy')).join("\r\n")
            : '';
    }

    const cancelClick = () => {
        setIsEdit(false);
        setSelectedDateList([]);
        setAllAvailableChecked(false);
        setSelectDateChecked(false);
        setResNo('');
        setReloadFlag(!reloadFlag)
    }

    const submitValidate = () => {
        const existMaxRecore = selectedDateList.some(e => e.no > projectMaxQuota);
        if (existMaxRecore)
            return false;
        return true;
    }

    const submitClick = () => {
        let submitSuccess = false;
        if (!selectedDateList.length) return false;

        if (!submitValidate()) {
            setMaxQuotaAlertOpen(true);
            return false;
        }
        let isError = false;
        let tempArr = [];
        selectedDateList.forEach((item) => {
            if (item.no === 0) {
                if (isPreviousDate2(item.date)) {
                    isError = true;
                }
            }
        })

        if (isError) {
            setTwoDayErrorOpen(true);
            return false
        }

        setIsLoading(true);
        const requests = selectedDateList.map(item => ({
            bookDate: format(startOfDay(item.date), "yyyy-MM-dd"),
            quota: item.no,
            projectId: selectedProject.projectId,
        }))

        axios.post('/bookRequest/booking_submit', requests)
            .then(({ data: result }) => {
                setSubmitResult(result);
                if (result.isSuccess) {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setIsSubmitted(true);
                    setResNo('');
                    setNoValid(true);
                    setResNoInvalidMsg('');
                    setSelectedDateList([]);
                    submitSuccess = true;
                }
                else {
                    setSeatsInvalidOpen(true);
                }
                loadNotifications();
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
            });
        return submitSuccess;
    }

    const viewBookingResult = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setIsSubmitted(false);
        setIsEdit(false);
    }

    const setResNoClick = (e) => {
        const no = e.target.value;
        let result = regexPattern.test(no);
        if (no && !result) return;
        setResNo(no);
    }

    const removeInvalidDate = (date) => {
        let submitResultTmp = { ...submitResult };
        if (submitResult && submitResult.invalidList) {
            let curIdx = submitResult.invalidList.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
            if (curIdx > -1) {
                submitResult.invalidList.splice(curIdx, 1);
            }
        }
        setSubmitResult(submitResultTmp);
    }

    const isUpdated = () => {
        if (isEdit) {
            for (let i = 0; i < selectedDateList.length; i++) {
                const curBooking = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(new Date(selectedDateList[i].date))));
                if (!curBooking && selectedDateList[i].no > 0) return true;
                if (curBooking && curBooking.capacity !== selectedDateList[i].no)
                    return true;
            }
        }
        return false;
    }

    const isRelease = (releaseTime) => {
        return !releaseTime || isAfter(new Date(), new Date(releaseTime));
    }

    const isRejectDate = (date) => {
        const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(date)) &&
            e.status === 'REJECTED');
        return !!bookinged;
    }
    const handleCloseDragDialog = () => {
        if (isShowAgain) {
            axios.post('/information/update-user-drag')
                .then(() => {
                    setIsShowAgain(false)
                }).catch(err => {
                    console.log(err)
                });
        }
        setIsDragOpen(false)
    }

    const editClick = () => {
        setIsEdit(true)
        setCurrentModal('CView');
        setReloadFlag(!reloadFlag);
        axios.get('/information/get-user-drag')
            .then(({ data: result }) => {
                if (!result.dragFlag) {
                    setIsDragOpen(true)
                }
            }).catch(err => {
                console.log(err)
            });
        fetchProjectData();
    }

    const handleSelectDate = (e) => {
        if (e.action === 'click' || e.action === 'doubleClick') return;
        let selListTem = [...selectedDateList];
        let slots = e.slots;
        // slots = slots.filter(
        //     item => !isPreviousDate(new Date(item))  
        // )
        for (let i = 0; i < slots.length; i++) {
            const capItem = capacityList.current.find(
                item => item.available
                    && isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(slots[i]))
                    && startOfDay(new Date(item.bookingDate)) >= startOfDay(new Date())
                    && startOfDay(new Date(item.bookingDate)) <= startOfDay(new Date(selectedProject.effectiveEndDate))
                    && (item.capacity > item.quota || todayHasBooking(new Date(item.bookingDate)))
            )

            if (!capItem) continue;
            if (isRejectDate(slots[i])) continue;

            const bookinged = bookingsList.find(f => isEqual(startOfDay(new Date(f.bookDate)), startOfDay(slots[i])));
            const hasChecked = selListTem.some(sl => isEqual(startOfDay(new Date(sl.date)), startOfDay(slots[i])));
            if (!hasChecked && isRelease(capItem?.releaseTime))
                selListTem.push({ date: slots[i], no: bookinged ? bookinged.capacity : 1 });
        }

        setSelectedDateList(selListTem);
        return;
    }

    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {
            history.push(nextPath);
        }
    }, [history, isDirty, nextPath])

    const handleOpenDirtyCheck = ({ location, leaveCallback }) => {
        if (location && location.pathname === '/manage-bookings')
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {
                if (submitClick())
                    setIsDirty(false);
                setOpenDirtyCheckDialog(false);
            },
            btn1Label: 'Submit Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);
                }
                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your booking request(s) has(have) not been submitted.</div>
                    <div>Please be reminded to submit before you leave the page.</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }

    const renderColRes = () => {
        if (submitResult?.invalidList && submitResult?.invalidList.length && submitResult?.invalidList[0].date) {
            return submitResult?.invalidList?.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, inIdx) => {
                return (
                    <div key={inIdx}>{format(startOfDay(new Date(item.date)), 'd MMM')} : Remaining seat(s) = {item.remainingSeats}</div>
                )
            })
        }
        return submitResult?.invalidList?.map((item, inIdx) => {
            return (
                <div key={inIdx}>Remaining day(s) = {item.remainingSeats}</div>
            )
        })
    }

    const renderSelfRes = () => {
        return selectedProject?.projectType === 'SELF_RESEARCHER' &&
            <Grid item
                style={{
                    textAlign: 'right', position: 'absolute', right: '0', top: '0', backgroundColor: '#fffbdb',
                    fontSize: '13px', padding: '5px 15px', color: '#000'
                }}>
                <div>Application Access Period: {format(new Date(selectedProject?.effectiveStartDate), 'dd/MMM/yyyy')} to {format(new Date(selectedProject?.effectiveEndDate), 'dd/MMM/yyyy')}</div>
                <div>Remaining Access days: {selectedProject?.remainDays} day(s)</div>
            </Grid>
    }


    const renderSelfResText = () => {
        return <>
            <div style={{ marginTop: '10px' }}>Your booking request has been successfully submitted. A confirmation email will be delivered to you soon. </div>
            {
                selectedProject?.projectType === 'SELF_RESEARCHER' &&
                <div style={{ marginTop: '10px' }}>
                    Please be reminded to attend all booking session.
                    Any absence without prior cancellation will be counted as 1 access.
                </div>
            }
            {
                selectedProject?.projectType === 'SELF_RESEARCHER' ?
                    <div style={{ marginTop: '10px' }}>Please also check your booking records in the HADCL Portal before your visit.</div>
                    : <div style={{ marginTop: '10px' }}>Please be reminded to check your booking records in the HADCL Portal before your visit. </div>
            }
            <div style={{ marginTop: '10px' }}>See You!</div>
            {
                selectedProject?.projectType === 'SELF_RESEARCHER' &&
                <div style={{ marginTop: '10px' }}>Remaining access for {selectedProject?.projectCode}: {submitResult?.remainingAccess} day(s)</div>
            }
        </>
    }

    const renderEditNode = () => {
        return <><div style={{ marginTop: '10px' }}>
            {
                selectedProject?.projectType === 'COL_RESEARCHER' &&
                <Button
                    startIcon={allAvailableChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                    className={classes.selDateButton}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => selectAvailableDateClick()}
                >
                    <div className={classes.selDateLabel}>Select all available dates</div>
                </Button>
            }
        </div >
            <div>
                <Button
                    startIcon={selectDateChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                    className={classes.selDateButton}
                    style={{
                        color: `${theme.palette.primary.main}`,
                        textTransform: 'none',
                        fontSize: '13px'
                    }}
                    onClick={() => selectDateClick()}
                >
                    <div className={classes.selDateLabel}>Select date</div>
                </Button>
            </div >
            <div style={{ textAlign: 'center', marginTop: '8px' }}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue={selectedDateText()}
                    style={{ backgroundColor: 'white' }}
                    className={classes.selDateText}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>

            <div style={{ marginTop: '10px', marginLeft: '5px' }}>
                <div>No. of researchers</div>
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <TextField
                            className={classes.ResNoText}
                            value={resNo}
                            onChange={(e) => setResNoClick(e)}
                            disabled={(selectedProject?.projectType === 'SELF_RESEARCHER' || !selectedDateList.length)}
                        />
                    </Grid>
                    <Grid item>
                        <span style={{ lineHeight: '1', color: 'red', marginLeft: '5px' }}>{resNoInvalidMsg}</span>
                    </Grid>
                </Grid>


                <div style={{ marginTop: '8px' }}>
                    <Button className={enableUpdateBooking() ? classes.activeButton : classes.inActiveButton} onClick={() => updateCalendarNo()}
                        disabled={!enableUpdateBooking()}>Update Booking</Button>
                </div>
            </div>
        </>
    }

    return (
        <Provider value={{ selectedProject, setSelectedProject, remarks, setRemarks, setDateList, dateList, handleOpenDirtyCheck }}>
            <Grid container style={{ minWidth: 1000 }}>

                <LoadingScreen open={isLoading} />
                <Prompt
                    when={isDirty}
                    message={(location) => handleOpenDirtyCheck({ location })}
                />
                <SystemMessageDialog isShowButton={false} open={isDragOpen} onClose={handleCloseDragDialog} isShowCloseButton={true}>
                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>You can drag the dates to make booking. <img src={DragImage} width={50} /></div>
                    <div style={{ textAlign: 'left' }}>
                        <Checkbox
                            color="primary"
                            onChange={(e) => { setIsShowAgain(e.target.checked); }}
                            checked={isShowAgain}
                        />Do not show again
                    </div>
                </SystemMessageDialog>
                <SystemMessageDialog open={updateAlertOpen} onClose={() => setUpdateAlertOpen(false)}>
                    <div>Your booking is updated.</div>
                    <div>Please be reminded to SUBMIT before you leave the page.</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={seatsInvalidOpen} onClose={() => setSeatsInvalidOpen(false)}>
                    <div>Booking cannot be submitted due to insufficient capacity.</div>
                    <div style={{
                        borderRadius: '3px', backgroundColor: '#f3f3f3', maxHeight: '200px', overflow: 'auto', margin: '10px auto'
                    }}>
                        {
                            renderColRes()
                        }
                    </div>
                    <div>Please select alternative date(s).</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={maxQuotaAlertOpen} onClose={() => setMaxQuotaAlertOpen(false)}>
                    <div>Quota needs to be between 1 and {projectMaxQuota}</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={twoDayErrorOpen} onClose={() => setTwoDayErrorOpen(false)}>
                    <div>Revision cannot be saved.</div>
                    <div>Cancellation has to be made 2 days in advance.</div>
                </SystemMessageDialog>

                {
                    !isSubmitted ?
                        <Grid container style={{ height: 'calc(100vh - 94.5px)', minHeight: '650.5px' }}>
                            <Grid item className={classes.leftWrapper}>
                                <Consumer>
                                    {value =>
                                        <>
                                            <ProjectSelector value={value} setIsLocked={setIsLocked} />

                                            {isEdit && renderEditNode()}

                                            <Grid container justifyContent={'center'}>
                                                <Grid item container style={{ width: '170px' }}>
                                                    <Grid item xs={12} lg={12} md={12} sm={12} style={{ padding: '10px 0' }}>
                                                        <Divider />
                                                    </Grid>
                                                    {isEdit && <div>
                                                        <div style={{ textDecoration: 'underline' }}>Reminder</div>
                                                        {
                                                            selectedProject?.projectType === 'COL_RESEARCHER' ?
                                                                <>
                                                                    <div>Please submit after updating booking.</div>
                                                                    <div>Seats will be reserved on a first-come-first-served basis.</div>
                                                                </> :
                                                                <div>
                                                                    *Please be reminded to attend all booked Session. Any booking Session without cancellation will be counted as 1 time access.
                                                                    <br></br>
                                                                    *Seats will be reserved on a first-come-first-served basis.
                                                                </div>
                                                        }
                                                    </div>
                                                    }

                                                    {
                                                        remarks &&
                                                        <>

                                                            {
                                                                remarks.filter(e => selectedDateList.some(f => isEqual(new Date(f.date), new Date(e.bookingDate)))).sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate)).map((remarkItem, index) => (
                                                                    <>
                                                                        {index === 0 ? <Grid item xs={12} lg={12} md={12} sm={12} style={{ color: '#0064E7' }}>Remarks:</Grid> : null}
                                                                        <Grid key={index} item xs={12} lg={12} md={12} sm={12} style={{ color: '#0064E7' }}>
                                                                            {`${format(new Date(remarkItem.bookingDate), 'd MMM')}: ${remarkItem.remarks}`}
                                                                        </Grid>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                            {isEdit && <Grid container justifyContent={'center'} style={{ marginTop: '8px' }}>
                                                <Grid item container style={{ width: '200px', marginLeft: '5px' }}>
                                                    <Grid item>
                                                        <Button disabled={!isDirty} className={isDirty ? classes.activeButton : classes.inActiveButton} onClick={() => submitClick()}>Submit</Button>
                                                    </Grid>
                                                    <Grid item style={{ marginLeft: '5px' }}>
                                                        <Button className={classes.cancelButton} onClick={() => cancelClick()}>Cancel</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            }
                                        </>
                                    }
                                </Consumer>
                            </Grid>
                            <Grid item className={classes.rightWrapper}>
                                <Grid container style={{ height: '100%' }} direction='column'>
                                    <Grid container style={{ padding: '10px', position: 'relative' }}>
                                        <Grid item xs className={classes.titleWrapper}>
                                            <Typography component={'div'} className={classes.titleFontRoot}>Manage Booking Requests</Typography>
                                        </Grid>
                                        {
                                            renderSelfRes()
                                        }
                                        {
                                            !isEdit && <Grid item xs={'auto'} className={classes.titleWrapper}
                                                style={
                                                    // selectedProject?.projectType === 'COL_RESEARCHER' ? { display: 'flex' } :
                                                    { display: 'flex', position: 'absolute', right: '10px', top: '50px' }
                                                }
                                            >
                                                <Select
                                                    value={currentModal}
                                                    className={classes.bookingModSelected}
                                                    onChange={(e) => setCurrentModal(e.target.value)}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null,
                                                        PopoverClasses: { root: classes.modSelectedPopover },
                                                        MenuListProps: {

                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={'CView'} style={{ backgroundColor: '#fff' }}>
                                                        <ListItemText primary={'Calendar View'} style={{ margin: '0', color: '#000', }} />
                                                        {currentModal === 'CView' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                                    </MenuItem>
                                                    <Divider flexItem style={{ pointerEvents: 'none' }} />
                                                    <MenuItem value={'History'} style={{ backgroundColor: '#fff' }}>
                                                        <ListItemText primary={'Booking History'} style={{ margin: '0', color: '#000' }} />
                                                        {currentModal === 'History' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                                                    </MenuItem>
                                                </Select>
                                                {selectedProject && !isLocked && <Button className={classes.activeButton} style={{ marginLeft: '5px', width: '100px' }}
                                                    onClick={editClick}
                                                >Edit</Button>}
                                            </Grid>

                                        }
                                    </Grid>
                                    {
                                        currentModal === 'CView' &&
                                        <>
                                            <Grid item className={classes.calendarWrapper}>
                                                {selectedProject && currentModal === 'CView' &&
                                                    <Consumer>
                                                        {
                                                            (selectedProjectValue) =>
                                                                <CalendarComponent
                                                                    selectedProjectValue={selectedProjectValue}
                                                                    currentModal={currentModal}
                                                                    isEdit={isEdit}
                                                                    selectedDateList={selectedDateList}
                                                                    addNo={addNo}
                                                                    removeNo={removeNo}
                                                                    replaceNo={replaceNo}
                                                                    checkedDate={checkedDate}
                                                                    setCapacityList={setCapacityList}
                                                                    setBookingsList={setBookingsListState}
                                                                    weekCheckedList={weekCheckedList}
                                                                    setWeekChecked={(week) => setWeekChecked(week)}
                                                                    setViewDate={(date) => setViewDateState(date)}
                                                                    submitResult={submitResult}
                                                                    releaseTimes={releaseTimes}
                                                                    isRelease={isRelease}
                                                                    todayHasBooking={todayHasBooking}
                                                                    viewDate={viewDate}
                                                                    handleSelectDate={handleSelectDate}
                                                                    setIsLoading={setIsLoading}
                                                                    projectMaxQuota={projectMaxQuota}
                                                                    isRejectDate={isRejectDate}
                                                                    reloadFlag={reloadFlag}
                                                                    weekRdoDisplays={weekRdoDisplays}
                                                                    isPreviousDate={isPreviousDate2}
                                                                />
                                                        }
                                                    </Consumer>
                                                }
                                            </Grid>
                                            <Grid item className={classes.labelWrapper}>
                                                <Grid container alignItems={'center'}>
                                                    <Grid style={{ paddingRight: '8px' }}>Lab Availability</Grid>
                                                    <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#ED3A4E' }}></Grid>
                                                    <Grid>80% full</Grid>
                                                    <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#FFB448' }}></Grid>
                                                    <Grid>60% full</Grid>
                                                    <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#50E4A7' }}></Grid>
                                                    <Grid>Available</Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {selectedProject && currentModal === 'History' &&
                                        <>
                                            <Grid item className={classes.calendarWrapper}>
                                                <Consumer>
                                                    {
                                                        (selectedProjectValue) =>
                                                            <BookingHistoryComponent
                                                                selectedProjectValue={selectedProjectValue}

                                                                setViewDate={(date) => setViewDate(date)}

                                                                isRelease={isRelease}
                                                                viewDate={viewDate}
                                                            />
                                                    }
                                                </Consumer>
                                            </Grid>
                                            <Grid item className={classes.labelWrapper}>
                                                <Grid container alignItems={'center'}>
                                                    <Grid style={{ paddingRight: '8px' }}>
                                                        Please attend all booked session(s).
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid> : null
                }
                {
                    <Grid container style={{ height: 'calc(100vh - 265px)', display: isSubmitted ? '' : 'none' }} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Grid item style={{
                            boxShadow: '3px 3px 15px #888888',
                            width: '600px',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            padding: '40px 15px',
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={TickIcon} />
                            </div>

                            <div style={{ fontSize: '25px', textAlign: 'center', color: '#f58220' }}>Submitted</div>
                            <div style={{ fontSize: '13px', textAlign: 'center', color: '#000' }}>
                                {renderSelfResText()}
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                <Button
                                    onClick={() => viewBookingResult()}
                                    style={{ color: '#fff', backgroundColor: '#f58220', textTransform: 'none', padding: '10px 30px' }}
                                >
                                    <div>View My Booking Results</div>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Provider >
    );
}

export default ManageBookings;