
import Grid from '@material-ui/core/Grid';
import { useStyles } from "Styles";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React, { useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ThreeDVirIcon from 'images/3DVir.png';
import AccessQrCode from 'components/accessQrCode';
import NotSignOut from 'components/accessQrCode/NotSignOut';
import { HomepageViewContext } from 'components/Homepage/Homepage.page';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

export default function HomepageMobile(props) {
    const {
        classes, activeStep, openQrCode, setOpenQrCode, maxSteps,
        activeMenus, handleNext, handleBack, isPageClass, midMenuClick, middleMenus, canQrCode, setCanQrCode
    } = React.useContext(HomepageViewContext)

    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const newActiveMenus = activeMenus;

    const [onlyOnPCDialog, setOnlyOnPCDialog] = React.useState(false)

    const menuClick = (path, code) => {
        if (code === 'MM_PROGRESS_REPORT' || code === 'MM_PROGRESS_REPORT_ADM' ||
            code === 'MM_OUTPUT_REQUEST' || code === 'MM_OUTPUT_REQUEST_MANAGEMENT' ||
            code === 'MM_ONLINE_INFO' || code === 'MM_ONLINE_INFO_MANAGEMENT') {
            setOnlyOnPCDialog(true);
            return;
        }
        midMenuClick(path, code);
    }

    return (
        <>
            <Grid container direction='column' style={{ backgroundColor: '#000', overflow: 'hidden' }}>
                <Grid item container justifyContent='center' direction='column' alignItems='center' className={classes.middleMenuBox} style={{ paddingBottom: 10 }}>
                    <Grid item container alignItems='center' style={{ marginTop: '10px', width: '100%', minHeight: middleMenus.length > 6 ? 465 : 70 }}>
                        <Grid item xs='auto' style={{ position: 'relative' }}>
                            <Button style={{ position: 'absolute', top: '-35px', left: 0, zIndex: 9 }} size="small" onClick={handleBack} disabled={activeStep === 0}
                                className={`${classes.middleMenuArrow} ${isPageClass()}`}
                            >
                                <KeyboardArrowLeft fontSize='large' />
                            </Button>
                        </Grid>
                        <Grid container item xs>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container justifyContent='center' >
                                    {
                                        newActiveMenus.map(menu => {
                                            return <Grid key={menu.code} item className={classes.middleSigle} alignItems='center'
                                                style={{ width: 145, height: 145, margin: '10px 5px 0' }}
                                                onClick={() => menuClick(menu.path, menu.code)}>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        display: 'block',
                                                        width: '70px',
                                                        height: '70px',
                                                        margin: '20px auto 0px auto'
                                                    }}
                                                    src={menu.icon}
                                                />
                                                <Typography className={classes.middleMenuText} style={{ lineHeight: 1.2 }}>
                                                    {menu.title}
                                                </Typography>
                                            </Grid>
                                        })
                                    }
                                    {newActiveMenus.length % 2 === 1 ? <Grid item alignItems='center'
                                        style={{ width: 145, height: 145, margin: '10px 5px 0' }}
                                    /> : ''}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs='auto' style={{ position: 'relative' }}>
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                className={`${classes.middleMenuArrow} ${isPageClass()}`}
                                style={{ position: 'absolute', top: '-35px', right: 0, zIndex: 9 }}
                            >
                                <KeyboardArrowRight fontSize='large' />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems='center' style={{ width: '90%' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ padding: '0px 10px 10px 10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E' }}>
                                        What's New
                                    </Grid>
                                    <Grid item xs='auto' style={{ textAlign: 'right' }}>

                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap' alignItems={'center'} className={classes.newsList}>
                                    <Grid item container alignItems={'center'} justifyContent={'center'} style={{ width: '91%' }} className={classes.newTextBox}>Welcome to the HADCL Portal! More functions will be coming soon. Stay tuned!</Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Grid item container alignItems='center' style={{ width: '90%' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ paddingBottom: '10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Video Corner
                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <Grid container alignItems='center' style={{ overflow: 'hidden' }}>

                                        <Grid item container xs justifyContent={'space-evenly'}>
                                            <Grid item container alignItems={'center'} justifyContent={'center'} className={classes.newTextBox} style={{ height: 180, width: '90%' }}>Welcome to the HADCL Portal! More functions will be coming soon. Stay tuned!</Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid> */}


                    {/* <Grid item container alignItems='center' style={{ width: '90%', marginBottom: '10px' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ paddingBottom: '10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Explore HADCL
                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <a href='https://www.l2vr.co/scene.php?sid=33fQf&type=invisible_mattertag'
                                        target='_blank'
                                        className={classes.threeDImg}
                                    >
                                        <img src={ThreeDVirIcon} alt={'icon'} style={{ width: '100%' }} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid> */}
                </Grid>
            </Grid>
            <SystemMessageDialog open={onlyOnPCDialog} onClose={() => { setOnlyOnPCDialog(false); }}>
                <div>Function only available on PC.</div>
            </SystemMessageDialog>
            {openQrCode && canQrCode && <AccessQrCode onClose={() => { setOpenQrCode(false) }} />}
            <NotSignOut setCanQrCode={setCanQrCode} />
        </>
    )
}
