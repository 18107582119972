import { FormControl, FormControlLabel, FormLabel, Radio } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useStyles } from 'Styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HadclDatePicker from '../common/HadclDatePicker';
import useModal from "hooks/useModal";
import ConfirmDialog from "components/common/ConfirmDialog";
import React from 'react';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../CustomAxios';
import { useMsg } from '../../hooks/MsgProvider'
import { warning, confirmDialog } from '../common/Constants';

const schema = yup.object().shape({
    userId: yup.object().required(),
    bookDate: yup.date().required().typeError(warning.ApprovalBookDate),
    capacity: yup.number().min(1).max(6).typeError(warning.ApprovalCapacity),
    statusCode: yup.string().required(),
    bookTypeCode: yup.string().required(),
});

const defaultValues = {
    userId: '',
    bookDate: null,
    capacity: 1,
    statusCode: 'SUBMITTED',
    bookTypeCode: ''
}

export default function AddEditBooking(props) {
    const classes = useStyles();
    const [users, setUsers] = React.useState([]);
    const showModal = useModal();
    const [currentStatusCode, setCurrentStatusCode] = React.useState(null);
    const [refNoSelfServe, setRefNoSelfServe] = React.useState(null);
    const [refNoProject, setRefNoProject] = React.useState(null);
    const [editRefNoSelfServe, setEditRefNoSelfServe] = React.useState(null);
    const [editRefNoProject, setEditRefNoProject] = React.useState(null);
    const isIERef = React.useRef();
    const { setSuccessMsg, setErrMsg } = useMsg();

    const { watch, setValue, handleSubmit, control, errors, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const dialogTitle = props.bookId ? "Edit Ad-Hoc Access Request" : "Create Ad-Hoc Access Request";

    const disableFlags = {
        userId: !!props.bookId,
        bookDate: false,
        statusCode: false,
        bookTypeCode: !!props.bookId
    };

    const watchBookTypeCode = watch("bookTypeCode");

    const watchEmail = watch('userId');

    React.useEffect(() => {

        const fetchData = (bookId) => {
            axios.get('/bookRequest/' + bookId)
                .then(({ data: book }) => {
                    book.userId = { email: book.refNoProject + ((book.refNoProject && book.refNoSelfServe) ? " , " : "") + book.refNoSelfServe + " - " + book.email };
                    reset(book);
                    setCurrentStatusCode(book.statusCode);
                    setEditRefNoSelfServe(book.refNoSelfServe);
                    setEditRefNoProject(book.refNoProject);
                }
                ).catch((err) => {
                    if (err) {
                        console.log(err);
                    }
                });
        }

        const resetEdit = () => {
            getUserList();
            fetchData(props.bookId);
        }

        const resetCreate = () => {
            getUserList();
            reset(defaultValues);
        }

        const resetAll = props.bookId ? resetEdit : resetCreate;
        resetAll();
    }, [props.bookId]);

    const handleClose = () => {
        props.handleClose();
    }

    const handleCancel = () => {
        props.handleCancel();
    }

    const onSubmitEdit = (data) => {
        if (currentStatusCode == "REJECTED" && data.statusCode == "APPROVED") {
            showModal((pros) => (
                <ConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    title={'WARNING'}
                    description={confirmDialog.EditApproveRejected}
                    onConfirm={() => { updateBookings(data) }}
                    onCancel={() => console.log("Booking not update")}
                />
            ));
        }
        else if (currentStatusCode == "APPROVED" && data.statusCode == "REJECTED") {
            showModal((pros) => (
                <ConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    title={'WARNING'}
                    description={confirmDialog.EditRejectApproved}
                    onConfirm={() => { updateBookings(data) }}
                    onCancel={() => console.log("Booking not update")}
                />
            ));
        }
        else if (currentStatusCode == "APPROVED" && data.statusCode == "CANCELLED") {
            showModal((pros) => (
                <ConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    title={'WARNING'}
                    description={confirmDialog.EditCancelApproved}
                    onConfirm={() => { updateBookings(data) }}
                    onCancel={() => console.log("Booking not update")}
                />
            ));
        }
        else if (currentStatusCode != data.statusCode && data.statusCode == "APPROVED") {
            showModal((pros) => (
                <ConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    description={confirmDialog.EditApprove}
                    onConfirm={() => { updateBookings(data) }}
                    onCancel={() => console.log("Booking not update")}
                />
            ));
        }
        else if (currentStatusCode != data.statusCode && data.statusCode == "REJECTED") {
            showModal((pros) => (
                <ConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                    {...pros}
                    description={confirmDialog.EditReject}
                    onConfirm={() => { updateBookings(data) }}
                    onCancel={() => console.log("Booking not update")}
                />
            ));
        }
        else {
            updateBookings(data);
        }
    }

    const updateBookings = (data) => {
        axios.put('/bookRequest/update', {
            id: props.bookId,
            bookDate: data.bookDate,
            quota: data.capacity,
            statusCode: data.statusCode
        })
            .then(res => {
                setSuccessMsg(res.data.message)
                handleClose();
            }
            ).catch((err) => {
                var errorMsg = err.response?.data?.message;
                setErrMsg(errorMsg ? errorMsg : err.message);
            });
    }

    const onSubmitCreate = (data) => {
        axios.post('/bookRequest/create', {
            UserProfileId: data.userId.id,
            bookDate: data.bookDate,
            quota: data.capacity,
            statusCode: data.statusCode,
            requestTypeCode: data.bookTypeCode
        })
            .then(res => {
                setSuccessMsg(res.data.message)
                handleClose();
            }
            ).catch((err) => {
                var errorMsg = err.response?.data?.message;
                setErrMsg(errorMsg ? errorMsg : err.message);
            });
    }

    const onSubmit = props.bookId ? onSubmitEdit : onSubmitCreate;

    const handleServiceTypeChange = (e) => {
        if (e.target.value === 'SELF_RESEARCHER') {
            setValue('capacity', 1);
        }
    }

    function getUserList() {
        axios.get('/bookRequest/user-list')
            .then(({ data: userList }) => {
                userList = userList.filter(c => c.status.toUpperCase() === 'ACTIVE' && c.roles.toUpperCase().includes("RESEARCHER"))//show only active researchers
                setUsers(userList);
            }
            ).catch((err) => {
                if (err) {
                    if (err.response) {
                        console.log(err.response);
                    }
                }
            });
    }

    function compare(a, b) {
        let res = 0
        if (a.refnocollaborate != "") {
            if (b.refnocollaborate != "") {
                if (a.refnocollaborate.toUpperCase() > b.refnocollaborate.toUpperCase()) {
                    res = 1
                } else if (b.refnocollaborate.toUpperCase() > a.refnocollaborate.toUpperCase()) {
                    res = -1
                }
            } else if (a.refnocollaborate.toUpperCase() > b.refnoselfserve.toUpperCase()) {
                res = 1
            } else if (b.refnoselfserve.toUpperCase() > a.refnocollaborate.toUpperCase()) {
                res = -1
            }
        } else if (b.refnocollaborate != "") {
            if (a.refnoselfserve.toUpperCase() > b.refnocollaborate.toUpperCase()) {
                res = 1
            } else if (b.refnocollaborate.toUpperCase() > a.refnoselfserve.toUpperCase()) {
                res = -1
            }
        } else if (a.refnoselfserve.toUpperCase() > b.refnoselfserve.toUpperCase()) {
            res = 1
        } else if (b.refnoselfserve.toUpperCase() > a.refnoselfserve.toUpperCase()) {
            res = -1
        }
        return res

    }

    React.useEffect(() => {
        isIERef.current = document.documentMode !== undefined; //document.documentMode is a IE only property. If this is defined, it means user is using IE
    }, [])

    const getDisabled = (item) => {
        return disableFlags["bookTypeCode"] || watchEmail == null || watchEmail == "" || item == ""
    }
    const isTitle = dialogTitle == "Create Ad-Hoc Access Request";
    const getLabel = (text, item, edit) => {
        if (watchEmail && isTitle) {
            if (isTitle && item) {
                text = `${text} (${item})`
            } else if (edit) {
                text = `${text} (${edit})`
            }
        }
        return text
    }

    const getOptionLabel = (option) => {
        let res = ''
        if (option.email) {
            if (isTitle) {
                if (option.refnocollaborate) {
                    res = res + option.refnocollaborate
                }
                if (option.refnocollaborate && option.refnoselfserve) {
                    res = res + ' , '
                }
                if (option.refnoselfserve) {
                    res = res + option.refnoselfserve;
                }
                res = res + ' - '
            }
            res = res + option.email
        }
        // option.email ? (() ? ((option.refnocollaborate ? option.refnocollaborate : "") + ((option.refnocollaborate && option.refnoselfserve) ? " , " : "") + (option.refnoselfserve ? (option.refnoselfserve) : "")) + ' - ' : '') + option.email : ''
        return res;
    }

    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            disableEnforceFocus={!!isIERef.current} //disableEnforceFocus is required because "Desktop pickers don't work inside modals" for IE11
            aria-labelledby="Edit Booking"
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth className={classes.inputBookingDialog}>
                        <Controller
                            render={({ onChange, ...pros }) => (
                                <Autocomplete
                                    autoHighlight
                                    options={users.sort(compare)}
                                    getOptionLabel={getOptionLabel}
                                    // getOptionLabel={(option) => option.email ? ((dialogTitle == "Create Ad-Hoc Access Request") ? ((option.refnocollaborate ? option.refnocollaborate : "") + ((option.refnocollaborate && option.refnoselfserve) ? " , " : "") + (option.refnoselfserve ? (option.refnoselfserve) : "")) + ' - ' : '') + option.email : ''}
                                    //renderOption={renderOption}
                                    renderInput={(params) => <TextField {...params} label="Ref. No. - Email" required variant="outlined" />}
                                    onChange={(e, data) => {
                                        console.log(data)
                                        if (data) {
                                            setRefNoSelfServe(data.refnoselfserve)
                                            setRefNoProject(data.refnocollaborate)
                                            setValue("bookTypeCode", (data.refnoselfserve ? "SELF_RESEARCHER" : "COL_RESEARCHER"))

                                            if (data.refnoselfserve) {
                                                setValue('capacity', 1);
                                            }
                                            // setValue("bookTypeCode", (data.refnocollaborate ? "COL_RESEARCHER" : "SELF_RESEARCHER"))
                                        } else {
                                            setRefNoSelfServe(null)
                                            setRefNoProject(null)
                                            reset(defaultValues.bookTypeCode)
                                        }
                                        return onChange(data)
                                    }}
                                    {...pros}
                                    disabled={disableFlags["userId"]}
                                />
                            )}
                            onChange={([, data]) => data}
                            control={control}
                            name="userId"
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.inputBookingDialog}>
                        <Controller
                            name="bookDate"
                            control={control}
                            render={({ ref, ...rest }) => (
                                <HadclDatePicker
                                    label="Access Date"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.noMarginTop}
                                            margin="normal"
                                            error={errors.bookDate}
                                            helperText={errors.bookDate?.message}
                                            variant="standard"
                                            InputProps={params.InputProps}
                                        />
                                    )}
                                    {...rest}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.inputBookingDialog}>
                        <Controller
                            as={TextField}
                            control={control}
                            type="number"
                            name="capacity"
                            disabled={(watchBookTypeCode === 'SELF_RESEARCHER')}
                            label="No. of Access Requested (1-6)"
                            //disabled={!watchIsAva}
                            variant="standard"
                            inputProps={{ min: 1 }}
                            error={errors.capacity}
                            helperText={errors.capacity?.message}
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.inputBookingDialog} onChange={handleServiceTypeChange}>
                        <FormLabel component="legend">Access Type (Ref. Number)</FormLabel>
                        <Controller
                            as={
                                <RadioGroup row aria-label="service type">
                                    {/*<FormControlLabel disabled={disableFlags["bookTypeCode"] || watchEmail == null || watchEmail == "" || refNoProject == ""} className={classes.inputLabel} value={"COL_RESEARCHER"} control={<Radio color="primary" />} label={"Project " + (watchEmail ? ((dialogTitle == "Create Booking Request") ? (refNoProject ? "(" + refNoProject + ")" : "") : (editRefNoProject ? "(" + editRefNoProject + ")" : "")) : "")} />*/}
                                    <FormControlLabel disabled={getDisabled(refNoSelfServe)} className={classes.inputLabel} value={"SELF_RESEARCHER"} control={<Radio color="primary" />} label={getLabel('Self-Service', refNoSelfServe, editRefNoSelfServe)} />
                                    {/* <FormControlLabel disabled={getDisabled(refNoSelfServe)} className={classes.inputLabel} value={"SELF_RESEARCHER"} control={<Radio color="primary" />} label={"Self-Service " + (watchEmail ? ((dialogTitle == "Create Ad-Hoc Access Request") ? (refNoSelfServe ? "(" + refNoSelfServe + ")" : "") : (editRefNoSelfServe ? "(" + editRefNoSelfServe + ")" : "")) : "")} /> */}

                                    <FormControlLabel disabled={getDisabled(refNoProject)} className={classes.inputLabel} value={"COL_RESEARCHER"} control={<Radio color="primary" />} label={getLabel('Project', refNoProject, editRefNoProject)} />
                                    {/* <FormControlLabel disabled={getDisabled(refNoProject)} className={classes.inputLabel} value={"COL_RESEARCHER"} control={<Radio color="primary" />} label={"Project " + (watchEmail ? ((dialogTitle == "Create Ad-Hoc Access Request") ? (refNoProject ? "(" + refNoProject + ")" : "") : (editRefNoProject ? "(" + editRefNoProject + ")" : "")) : "")} /> */}
                                </RadioGroup>
                            }
                            name="bookTypeCode"
                            control={control}
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.inputBookingDialog}>
                        <FormLabel component="legend">Status</FormLabel>
                        <Controller
                            as={
                                <RadioGroup aria-label="status">
                                    <FormControlLabel disabled={disableFlags["statusCode"]} className={classes.inputLabel} value={"SUBMITTED"} control={<Radio color="primary" />} label="Submitted" />
                                    <FormControlLabel disabled={disableFlags["statusCode"]} className={classes.inputLabel} value={"APPROVED"} control={<Radio color="primary" />} label="Approved" />
                                    <FormControlLabel disabled={disableFlags["statusCode"]} className={classes.inputLabel} value={"REJECTED"} control={<Radio color="primary" />} label="Rejected" />
                                    <FormControlLabel disabled={disableFlags["statusCode"]} className={classes.inputLabel} value={"CANCELLED"} control={<Radio color="primary" />} label="Cancelled" />
                                </RadioGroup>
                            }
                            name="statusCode"
                            control={control}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">
                        Save
                    </Button>

                    <Button type="button" onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog >)
}
