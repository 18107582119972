import { useState, useEffect, useRef } from 'react';

export default function useNavFixed(type) {
    const navRef = useRef(null);
    const [isFixed, setIsFixed] = useState(false);
    useEffect(() => {
        if (navRef.current && window.screen.width > 500) { 
            const fixedTop = navRef.current.offsetTop;
            window.onscroll = () => {
                let scrollTop = document.documentElement.scrollTop;
                setIsFixed(scrollTop >= fixedTop) 
            }
        }
    },[])

    return {
        navRef,
        isFixed
    }
}