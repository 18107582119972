import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import CloseBtnIcon from 'images/closeBtn.png';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
export default function GenPSWDialog(props) {
    const { onClose, open, btn1Action,onConfirm } = props;
    
    const [isConfirm, setIsConfirm] = React.useState(false)

    const handleOk = () => {
        onConfirm()
        onClose();
        setIsConfirm(false)
    };

   
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }
    return (
        <Dialog
            open={open}
            // disableBackdropClick
            disableEscapeKeyDown
            // maxWidth="xs"
            aria-labelledby="GenPSWDialog-title"
        // style={{ maxWidth: 'auto' }}
        >
            <DialogTitle id="alert-dialog-confirm"
                style={{  padding: '10px', position: 'relative' }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'Reminder'}
                </Typography>
                    <IconButton aria-label="close" style={{position: 'absolute', top: 5, right: 5, padding: 5}} onClick={() => {
                        setIsConfirm(false)
                        onClose()
                    }}>
                        <img src={CloseBtnIcon} width={40}/>
                    </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '0 24px', color: '#000', fontSize: '16px',  fontFamily: 'Quicksand-SemiBold' }}>
                <Grid container>
                    You should check the output files upon receipt. Acknowledgement of receipt of the output files with no inconsistencies or irregularities will be assumed if you do not report any issues to HADCL Office by the output retention date.
                </Grid>
                <div style={{paddingTop: '10px '}}>
                    {/* <div></div> */}
                    <Button
                        startIcon={checkIcon(isConfirm)}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => {setIsConfirm(!isConfirm) }}
                        >
                        <div style={{ color: '#000' }}>I have read and understand the above reminder.*</div>
                    </Button>
                    <div style={{color: '#f00', paddingLeft: 10}}>*=Mandatory</div>
                </div>
            </DialogContent>
            <DialogActions style={{ paddingBottom: '16px', marginTop: 10, paddingLeft: 20 }}>
                <Grid container  alignItems={'center'}>
                    <Grid item>
                        <Button disabled={!isConfirm} onClick={btn1Action && typeof btn1Action === 'function' ? btn1Action : handleOk} color="primary"
                            style={{
                                ...(isConfirm ? {
                                    backgroundColor: '#f58220',
                                    color: '#fff'
                                }: {
                                    backgroundColor: '#dfdfdf ',
                                    color: '#ffff ',
                                }),
                                padding: '10 24px',
                                textTransform: 'none',
                            }}>
                            Generate Password
                        </Button>
                        
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
