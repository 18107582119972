import React from 'react';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import PickersDay from '@material-ui/lab/PickersDay';

export default function HadclDatePicker(props) {
    let {
        views,
        inputFormat,
        mask,
        ...rest } = props;

    const isIERef = React.useRef(!(document.documentMode == undefined));
    views = views ? views : ['year', 'month', 'date'];
    inputFormat = inputFormat ? inputFormat : "yyyy/MM/dd";
    mask = mask ? mask : "____/__/__";
    return (isIERef
        ? <DesktopDatePicker
            renderDay={(date, _selectedDates, PickersDayComponentProps) => <PickersDay disableMargin {...PickersDayComponentProps} />}
            views={views}
            inputFormat={inputFormat}
            mask={mask}
            {...rest} />
        : <DatePicker
            renderDay={(date, _selectedDates, PickersDayComponentProps) => <PickersDay disableMargin {...PickersDayComponentProps} />}
            views={views}
            inputFormat={inputFormat}
            mask={mask}
            {...rest} />
    );
}