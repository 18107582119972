import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function SystemConfirmDialog(props) {
    const {
        open,
        onClose,
        onCancel,
        onConfirm,
        cancelText,
        okText} = props;

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogTitle id="alert-dialog-confirm"
                style={{ textAlign: 'center', padding: '10px', position: 'relative' }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'System Message'}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ padding: '0 24px', color: '#000', fontSize: '16px', textAlign: 'center', fontFamily: 'Quicksand-SemiBold' }}>
                {props.children}
            </DialogContent>
            <DialogActions style={{ paddingBottom: '16px', marginTop: 25 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item>
                        <Button color="primary"
                            style={{ backgroundColor: '#f58220', padding: '10 24px', color: '#fff', textTransform: 'none', }}
                            onClick={() => {
                                onConfirm && onConfirm();
                                onClose();
                            }}>
                            {okText || 'Confirm'}
                        </Button>
                        <Button color="primary"
                            style={{ backgroundColor: '#646464', padding: '10 24px', color: '#fff', marginLeft: 10, textTransform: 'none', }}
                            onClick={() => {
                                onCancel && onCancel();
                                onClose();
                            }}>
                            {cancelText || 'Cancel'}
                        </Button>

                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
