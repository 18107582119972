import React from 'react';
import {
    Divider,
    Grid,
    Typography,
    Button,
    DialogActions,
    Select,
    MenuItem,
    ListItemText,
    FormControl,
    InputLabel,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { useStyles } from './Styles';
import ProjectSelector from './ProjectSelector';
import CalendarComponent from 'components/common/CalendarAdmin/CalendarComponent';
import SetupCalendarComponent from 'components/common/CalendarAdmin/SetupCalendarComponent';
import BookingHistoryComponentByAdmin from 'components/common/BookingHistory/BookingHistoryComponentByAdmin';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import {
    format
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , addDays
    , addMonths
    , isAfter
    , isBefore
} from 'date-fns';
import LoadingScreen from '../common/LoadingScreen';
import SystemMessageDialog from '../common/SystemMessageDialog';
import SystemConfirmDialog from '../common/SystemConfirmDialog';
import axios from '../../CustomAxios';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import AMViewLeft from './AMViewLeft';
import BookingHistoryViewLeft from './BookingHistoryViewLeft';
import useSysParam from 'hooks/UseSysParam';
import Checkbox from '@material-ui/core/Checkbox';
import StarIcon from '@material-ui/icons/Star';
import useModal from "hooks/useModal";
import GenerateEmailDialog from './GenerateEmail.dialog'
import Enum from '../../enum/Enum'
import { HomeLayoutViewContext } from 'components/HomeLayout';

export const { Provider, Consumer } = React.createContext();

const AccessManagement = (props) => {
    const classes = useStyles();
    const showModal = useModal();
    const {
        loadNotifications
    } = React.useContext(HomeLayoutViewContext)

    const [remarks, setRemarks] = React.useState([]);
    const [currentModal, setCurrentModal] = React.useState('');
    const [isEdit, setIsEdit] = React.useState(false);
    const [selectedDateList, setSelectedDateList] = React.useState([]);
    const capacityList = React.useRef([]);
    const bookingResMapRef = React.useRef({});
    const [bookingsList, setBookingsList] = React.useState([]);
    const [viewDate, setViewDate] = React.useState(startOfMonth(new Date()));
    const initWeekCheckeds = [{ checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }];
    const [weekCheckedList, setWeekCheckedList] = React.useState(initWeekCheckeds);
    const initWeekRdoDps = [{ display: false }, { display: false }, { display: false }, { display: false }, { display: false }, { display: false }, { display: false }];
    const [weekRdoDisplays, setWeekRdoDisplays] = React.useState(initWeekRdoDps);
    const [, setNoValid] = React.useState(true);
    const [resNo, setResNo] = React.useState('');
    const [resNoInvalidMsg, setResNoInvalidMsg] = React.useState('');
    const [allAvailableChecked, setAllAvailableChecked] = React.useState(false);
    const [selectDateChecked, setSelectDateChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dateList, setDateList] = React.useState([]);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [updateAlertOpen, setUpdateAlertOpen] = React.useState(false);
    const [seatsInvalidOpen, setSeatsInvalidOpen] = React.useState(false);
    const [maxQuotaAlertOpen, setMaxQuotaAlertOpen] = React.useState(false);
    const [submitResult, setSubmitResult] = React.useState();
    const regexPattern = /^\d+$/;
    const [releaseTimes, setReleaseTimes] = React.useState([]);
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const [nextPath, setNextPath] = React.useState(null);
    const history = useHistory();
    const { setUser } = React.useContext(UserContext);
    const { paramIntValue: projectMaxQuota } = useSysParam('project_user_max_quota_per_book');


    const [reloadFlag, setReloadFlag] = React.useState(false);
    const [isEditManageRequest, setIsEditManageRequest] = React.useState(false);
    const [isEditSetupLab, setIsEditSetupLab] = React.useState(false);

    const [projectList, setProjectList] = React.useState([]);
    const [selfServiceList, setSelfServiceList] = React.useState([]);
    const [allRecordChecked, setAllRecordChecked] = React.useState(true);
    const [selectionChecked, setSelectionChecked] = React.useState(false);

    const [selectionChoose, setSelectionChoose] = React.useState('');
    const [project, setProject] = React.useState([]);
    const [selfService, setSelfService] = React.useState([]);
    const [isBatch, setIsBatch] = React.useState(true);
    const [forProject, setForProject] = React.useState(false);
    const [forSelfService, setForSelfService] = React.useState(false);

    const [availableSpace, setAvailableSpace] = React.useState('');

    const [isReleaseNow, setIsReleaseNow] = React.useState(false);
    const [releaseDate, setReleaseDate] = React.useState('');
    const [setUpRemarks, setSetUpRemarks] = React.useState('');
    const [newReleaseTime, setNewReleaseTime] = React.useState('');
    const [rejectReason, setRejectReason] = React.useState('');
    const [releaseTimeInvalidMsg, setReleaseTimeInvalidMsg] = React.useState('');
    const projectSelfServiceMapRef = React.useRef(null);

    const [prevRecordOpen, setPrevRecordOpen] = React.useState(false)

    const [bookingHistorys, setBookingHistorys] = React.useState([]);

    const [submittedActionList, setSubmittedActionList] = React.useState([]);

    const [errorMsg, setErrorMsg] = React.useState('');

    const [emailDialogOpen, setEmailDialogOpen] = React.useState(false)

    const [bookingAllProject, setBookingAllProject] = React.useState([])
    const [bookingAllSelfService, setBookingAllSelfService] = React.useState([])
    const [isShowFirstTime, setIsShowFirstTime] = React.useState(false)
    const firstTimeRef = React.useRef({});
    const [updateSetAlertOpen, setUpdateSetAlertOpen] = React.useState(false);

    const filterTypeCode = (result, tempP, tempS, tempObjMap) => {
        result && result.forEach(item => {
            tempObjMap[item.projectId] = item;
            if (item.userTypeCode === Enum.AVAILABLE_BOOKING.COL_RESEARCHER) {
                tempP.push(item);
            } else if (item.userTypeCode === Enum.AVAILABLE_BOOKING.SELF_RESEARCHER) {
                tempS.push(item)
            }
        })
    }

    const getBookedProjects = () => {
        const params = {
            bookMonth: format(startOfDay(viewDate), "yyyy-MM-dd"),
            status: 'APPROVED'
        }
        let url = '/bookRequest/get-booked-projects-by-status'
        if (isEditManageRequest && selectionChoose !== 'REJECTED') {
            params.status = 'APPROVED|REJECTED';
        }
        if (currentModal === 'History') {
            params.status = 'APPROVED|CANCELLED|REJECTED';
        }
        axios.get(url, { params }).then(({ data: result }) => {
            const tempP = [];
            const tempS = [];
            const tempObjMap = {};
            filterTypeCode(result, tempP, tempS, tempObjMap)
            setProjectList(tempP);
            setSelfServiceList(tempS);

        }).catch(err => {

            console.log(err);
        });
    }
    React.useEffect(() => {

        getBookedProjects();

    }, [viewDate, isEditManageRequest, currentModal])

    React.useEffect(() => {
        if (isEdit) {
            let newSelected = []
            let newDateList = [];
            let dateMap = {};
            selectedDateList.forEach((item) => {
                if (!dateMap[item.date]) {
                    newDateList.push(item.date)
                }
                dateMap[item.date] = true
            })
            newDateList.forEach(date => {
                checkedManageRequestDate(newSelected, date)
            })

            if (newSelected.length === 0) {
                selectAvailableDateClick(false);
                selectDateClick(false);
            }
            setSelectedDateList(newSelected)
        }
    }, [project, selfService, project.length, selfService.length, selectionChoose])


    React.useEffect(() => {
        if (selectionChoose !== 'APPROVED') {
            getBookedProjects();
        }

    }, [selectionChoose])
    React.useEffect(() => {
        const params = {
            bookMonth: format(startOfDay(viewDate), "yyyy-MM-dd")
        }


        axios.get('/bookRequest/get-add-projects', { params }).then(({ data: result }) => {
            const tempP = [];
            const tempS = [];
            const tempObjMap = {};
            filterTypeCode(result, tempP, tempS, tempObjMap)
            projectSelfServiceMapRef.current = tempObjMap;
            setBookingAllProject(tempP);
            setBookingAllSelfService(tempS);
        }).catch(err => {
            console.log(err);
        });
    }, [viewDate])

    React.useEffect(() => {
        axios.post('/bookSetup/get-release-time', [format(startOfDay(viewDate), "yyyy-MM-dd"), format(addMonths(startOfDay(viewDate), 1), "yyyy-MM-dd")])
            .then(({ data: result }) => {
                setReleaseTimes(result);
            }).catch(err => {
                console.log(err)
            });
    }, [viewDate])

    React.useEffect(() => {
        setIsDirty(isUpdated());
        updateWeekChecked();

    }, [selectedDateList])


    React.useEffect(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let weekRdoDisplaysTmp = [...initWeekRdoDps]
        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            const weekIdx = d.getDay();
            const dateItem = capacityList.current.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(d)));
            const isClose = dateItem && !dateItem.available;
            const todayBooking = todayHasBooking(d);
            const isFull = dateItem && !todayBooking
                && dateItem.quota >= dateItem.capacity;



            if (dateItem && !isClose && !isFull && isRelease()) {
                weekRdoDisplaysTmp[weekIdx].display = true;
            }
            setWeekRdoDisplays(weekRdoDisplaysTmp);
        }
    }, [capacityList.current, bookingsList])


    React.useEffect(() => {
        if (currentModal == "History") {
            setSelectionChecked(false);
            setAllRecordChecked(true);

            const maxDate = endOfMonth(viewDate);
            axios.get('/bookrequest/get-alluser-bookings-history', { params: { bookMonth: format(maxDate, "yyyy-MM-dd") } }).then((bookingsRes) => {
                if (bookingsRes.data) {
                    setBookingHistorys(bookingsRes.data.sort((a, b) => new Date(a.bookDate) - new Date(b.bookDate)));
                } else {
                    setBookingHistorys([]);
                }
            }).catch(err => {
                console.log(err);
            })

        }
    }, [currentModal, viewDate])

    React.useEffect(() => {
        setSelectionChecked(false);
        setAllRecordChecked(true);
    }, [currentModal])

    const changeManageRequestSelectedLength = (maxDate, allCheckCount) => {
        if (selectionChoose === 'APPROVED') {
            const canBookLength = capacityList.current.filter(item =>
                item.available

            ).length;
            allCheckCount = canBookLength;
        } else {
            let tempMap = {}
            let capacityMap = {}
            capacityList.current.forEach((item) => {

                capacityMap[item.bookingDate] = item;
            })
            filterListFunc(bookingsList).forEach((item) => {
                if (!tempMap[item.bookDate] && capacityMap[item.bookDate].available) {
                    allCheckCount = allCheckCount + 1;
                }
                tempMap[item.bookDate] = true;
            });
        }
        let count = 0;
        let dateMap = {}
        selectedDateList.forEach((item) => {
            if (!dateMap[item.date]) {
                count = count + 1;
            }
            dateMap[item.date] = true
        })
        // console.log(allCheckCount, count)
        if (allCheckCount === count) {
            setAllAvailableChecked(true);
            setSelectDateChecked(false);
        } else {
            setAllAvailableChecked(false);
            setSelectDateChecked(true);
        }
    }

    React.useEffect(() => {
        setIsDirty(isUpdated());
        updateWeekChecked();
        if (!selectedDateList.length) return;
        let allCheckCount = 0;
        const maxDate = format(endOfMonth(viewDate), 'dd');
        if (isEditSetupLab) {
            allCheckCount = format(endOfMonth(viewDate), 'dd') * 1;

            if (allCheckCount === selectedDateList.length) {
                setAllAvailableChecked(true);
                setSelectDateChecked(false);
            } else {
                setAllAvailableChecked(false);
                setSelectDateChecked(true);
            }
        } else {
            changeManageRequestSelectedLength(maxDate, allCheckCount);
        }

    }, [selectedDateList])

    React.useEffect(() => {
        setProject([]);
        setSelfService([]);
    }, [selectionChecked])



    const updateWeekChecked = () => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let selListTem = [...selectedDateList];
        let weekCheckedObj = [[], [], [], [], [], [], []];

        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            // if (selectedProject && isAfter(startOfDay(d), startOfDay(new Date(selectedProject.effectiveEndDate)))) continue;
            // if (isRejectDate(d)) continue;
            const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(d));
            const weekIdx = d.getDay();
            const hasChecked = selListTem.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)));
            const capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));
            let canBook = capItem && todayHasBooking(d) && !isPreviousDate;

            if (hasChecked)
                weekCheckedObj[weekIdx].push(true);
            else if (canBook)
                weekCheckedObj[weekIdx].push(false);
        }

        let weekCheckedListTmp = [...weekCheckedList];
        weekCheckedListTmp.forEach((item, idx) => {
            const trueChecked = weekCheckedObj[idx].filter(e => e === true);
            const falseChecked = weekCheckedObj[idx].filter(e => e === false);
            item.checked = trueChecked.length > 0 && falseChecked.length === 0;
        });
        setWeekCheckedList(weekCheckedListTmp);
    }

    const updateCalendarNoForApproved = () => {
        let dateListTem = [...selectedDateList];
        let tempId = [...project, ...selfService];
        let dateMap = {}
        let dateProjectMap = {}
        const newList = [];
        dateListTem.forEach(item => {
            if (dateMap[new Date(item.date)]) {
                dateMap[new Date(item.date)].push(item);
            } else {
                dateMap[new Date(item.date)] = [item];
            }
            dateProjectMap[new Date(item.date) + item.projectId] = item;

        });
        Object.keys(dateMap).forEach((date) => {
            tempId.forEach((id) => {
                if (dateProjectMap[new Date(date) + id]) {
                    newList.push(dateProjectMap[new Date(date) + id])
                } else {
                    newList.push({
                        date: new Date(date),
                        start: startOfDay(new Date(date)),
                        end: startOfDay(new Date(date)),
                        title: projectSelfServiceMapRef.current[id].refNum,
                        capacity: 1,
                        rejectReason: '',
                        isFirst: false,
                        status: 'APPROVED',
                        attendance: '',
                        projectId: id,
                        userTypeCode: projectSelfServiceMapRef.current[id].userTypeCode,
                        remarks: '',
                        // id: i.id,
                    })
                }

            })
        })
        // console.log(newList, 'newList')
        newList.forEach(item => {

            if (selectionChoose === 'APPROVED' && resNo && item.userTypeCode === Enum.AVAILABLE_BOOKING.COL_RESEARCHER) {
                item.capacity = resNo * 1;
            }

            item.status = selectionChoose;
            return item;
        });
        setSelectedDateList(newList);
        setUpdateAlertOpen(true);
    }
    const updateCalendarNo = () => {
        setResNoInvalidMsg('');
        let result = regexPattern.test(resNo);
        // setNoValid(result)
        if (resNo && !result)
            setResNoInvalidMsg('No. invalid');
        else if (parseInt(resNo) > projectMaxQuota) {
            setResNoInvalidMsg(`Max ${projectMaxQuota}`);
            return;
        }
        if (selectionChoose === 'APPROVED') {
            if (selectedDateList.length) {
                updateCalendarNoForApproved()
            }
        } else {
            if (selectedDateList.length) {
                let dateListTem = [...selectedDateList];
                const newList = []
                dateListTem.forEach(item => {

                    if (selectionChoose === 'REJECTED') {
                        item.rejectReason = rejectReason;
                    }
                    item.status = selectionChoose;
                    newList.push(item)
                    return item;
                });
                setSelectedDateList(newList);
                setUpdateAlertOpen(true);
            }
        }

    }

    const changeCalendarDateList = (item, isPublicHoliday) => {
        if (availableSpace) {
            item.capacity = availableSpace
        } else {
            item.capacity = item.capacity ? item.capacity : 1
        }
        item.remarks = setUpRemarks;

        const t = []
        if (forProject) t.push(Enum.AVAILABLE_BOOKING.COL_RESEARCHER)
        if (forSelfService) t.push(Enum.AVAILABLE_BOOKING.SELF_RESEARCHER)
        item.available = t.join(',')
        if (isPublicHoliday) {
            item.available = '';
            item.capacity = 0
        }
        return item
    }
    const updateCalendarAvailable = () => {
        let result = availableSpace === '' || (regexPattern.test(availableSpace));
        if (!result) {
            setResNoInvalidMsg('Available space invalid');
        }

        if (result && selectedDateList.length) {
            let dateListTem = [...selectedDateList];
            const isPublicHoliday = setUpRemarks.toLowerCase().includes('public holiday')

            dateListTem.forEach(item => {
                item = changeCalendarDateList(item, isPublicHoliday)


                return item;
            });
            if (isPublicHoliday) {
                setForSelfService(false)
                setForProject(false)
                setAvailableSpace(0)
            }
            setSelectedDateList(dateListTem);
            setUpdateSetAlertOpen(true);
        }
    }

    const isBatchUpdateBooking = (res) => {
        let temp = selectedDateList.filter((item) => item.projectId)
        temp.forEach((item) => {
            if (selectionChoose === 'APPROVED' && resNo) {
                res = true
            }
            if (selectionChoose != item.status) {
                res = true
            }
            if (selectionChoose === 'REJECTED' && rejectReason) {
                res = true
            }
        })
        return res
    }
    const checkedIsNotApproved = () => {
        return selectionChoose !== 'APPROVED' && (project || selfService)
    }
    const batchUpdateBooking = () => {
        let res = false;
        if (selectedDateList.length) {
            let haveProject = false;
            if (selectionChoose === 'APPROVED' && (project.length > 0 || selfService.length > 0)) {
                haveProject = true
            }
            if (checkedIsNotApproved()) {
                haveProject = true
            }
            if (selectionChoose && haveProject) {

                res = isBatchUpdateBooking(res);
                if ((project.length <= 0 || selfService.length <= 0) && resNo) {
                    res = true
                }
            }

        }
        return res
    }

    const isEnableUpdateBooking = (res) => {
        selectedDateList.forEach((item) => {
            const t = []
            if (forProject) t.push(Enum.AVAILABLE_BOOKING.COL_RESEARCHER)
            if (forSelfService) t.push(Enum.AVAILABLE_BOOKING.SELF_RESEARCHER)
            if (item.available !== t.join(',')) {
                res = true
            }

            if (availableSpace && availableSpace * 1 !== item.capacity) {
                res = true
            }

            if (setUpRemarks !== item.remarks) {
                res = true
            }
        })
        return res;
    }
    const enableUpdateBooking = () => {
        if (isEditSetupLab) {
            let res = false;
            if (selectedDateList.length) {
                res = isEnableUpdateBooking(res);
            }
            return res || availableSpace;

        } else {
            return batchUpdateBooking();
        }

    }

    const setViewDateState = (date) => {
        setViewDate(date);
        setSelectedDateList([]);
        setWeekCheckedList(initWeekCheckeds);

    }

    const selectAvailableDateClickForSetupLab = (selListTem) => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let newListTem = [];
        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));
            if (bookinged) {
                newListTem.push({
                    date: d,
                    start: bookinged.bookingDate,
                    end: bookinged.bookingDate,
                    capacity: bookinged.capacity,
                    quota: bookinged.quota,
                    remarks: bookinged.remarks,
                    available: bookinged.available,
                    uavailableReason: bookinged.uavailableReason
                });
            } else {
                newListTem.push({
                    date: d,
                    start: d,
                    end: d,
                    capacity: '',
                    quota: '',
                    remarks: '',
                    available: '',
                    uavailableReason: '',
                });
            }

        }
        return newListTem
    }
    const selectAvailableDateClickForApproved = (selListTem) => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let newListTem = [];

        for (let date = minDate; date < maxDate; date = addDays(date, 1)) {

            const bookinged = filterListFunc(bookingsList).filter(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(date)))
            let capItem = ''
            capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(date)));
            const canBook = capItem && !!capItem.available
            if (canBook) {
                if (bookinged.length > 0) {
                    bookinged.forEach((item) => {
                        newListTem.push({
                            date: date,
                            start: item.bookDate,
                            end: item.bookDate,
                            title: item.refno,
                            capacity: item.capacity || 1,
                            rejectReason: item.rejectReason,
                            isFirst: item.isFirst,
                            status: item.status,
                            attendance: item.attendance,
                            projectId: item.projectId,
                            userTypeCode: item.userTypeCode,
                            remarks: item.remarks,
                            id: item.id,
                        });
                    })
                } else {
                    newListTem.push({
                        date: date,
                        start: date,
                        end: date,

                    });
                }
            }

        }
        return newListTem
    }

    const selectAvailableDateClickForCancelAndReject = (selListTem) => {
        capacityList.current.filter(
            item => item.available

        ).forEach(item => {
            const bookDate = new Date(item.bookingDate);
            const hasChecked = selListTem.some(sl => isEqual(startOfDay(new Date(sl.date)), startOfDay(bookDate)));

            if (isRelease() && !hasChecked && (selectionChoose !== 'APPROVED' || (!isRejectDate(bookDate) && item.capacity > item.quota || todayHasBooking(bookDate)))) {

                filterListFunc(bookingsList).forEach((i) => {
                    // bookingsList.forEach((i) => {
                    if (isEqual(startOfDay(new Date(i.bookDate)), startOfDay(bookDate))) {
                        selListTem.push({
                            date: bookDate,
                            start: i.bookDate,
                            end: i.bookDate,
                            title: i.refno,
                            capacity: i.capacity || 1,
                            rejectReason: i.rejectReason,
                            isFirst: i.isFirst,
                            status: i.status,
                            attendance: i.attendance,
                            projectId: i.projectId,
                            userTypeCode: i.userTypeCode,
                            remarks: i.remarks,
                            id: i.id,
                        });
                    }
                })

                removeInvalidDate(bookDate);
                ;

            }
        });
        return selListTem
    }

    const selectAvailableDateClick = (checked = !allAvailableChecked) => {
        const allAChecked = checked;
        setAllAvailableChecked(allAChecked);
        allAChecked && setSelectDateChecked(false);
        if (allAChecked) {
            let selListTem = [...selectedDateList];


            if (isEditSetupLab) {
                selListTem = selectAvailableDateClickForSetupLab(selListTem)
            } else {
                if (selectionChoose === 'APPROVED') {
                    selListTem = selectAvailableDateClickForApproved(selListTem)

                } else {
                    selListTem = selectAvailableDateClickForCancelAndReject(selListTem)

                }
            }
            setSelectedDateList(selListTem);
        } else {
            setSelectedDateList([]);
        }
    }

    const selectDateClick = (checked = !selectDateChecked) => {
        setSelectDateChecked(checked);
        if (checked) { setAllAvailableChecked(false); }
        setSelectedDateList([]);
    }

    const todayHasBooking = (date) => {
        return bookingsList.some(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(date)));
    }

    const handleCheckManageRequestForApproved = (d, selListTem, capItem) => {
        if (capItem && !!capItem.available) {
            const bookinged = filterListFunc(bookingsList).filter(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(d)))
            if (bookinged.length > 0) {
                bookinged.forEach((item) => {
                    selListTem.push({
                        date: d,
                        start: item.bookDate,
                        end: item.bookDate,
                        title: item.refno,
                        capacity: item.capacity || 1,
                        rejectReason: item.rejectReason,
                        isFirst: item.isFirst,
                        status: item.status,
                        attendance: item.attendance,
                        projectId: item.projectId,
                        userTypeCode: item.userTypeCode,
                        remarks: item.remarks,
                        id: item.id,
                    });
                })
            } else {
                selListTem.push({
                    date: d,
                    start: d,
                    end: d,

                });
            }
        }
    }

    const handleCheckManageRequest = (canBook, newDate, selListTem, capItem) => {
        if (selectionChoose === 'APPROVED') {
            handleCheckManageRequestForApproved(newDate, selListTem, capItem);

        } else if (canBook && todayHasBooking(newDate)) {

            const bookinged = filterListFunc(bookingsList).filter(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(newDate)));
            if (selectionChoose === 'APPROVED' || bookinged) {
                bookinged.forEach((item) => {
                    selListTem.push({
                        date: newDate,
                        start: item.bookDate,
                        end: item.bookDate,
                        title: item.refno,
                        capacity: item.capacity || 1,
                        rejectReason: item.rejectReason,
                        isFirst: item.isFirst,
                        status: item.status,
                        attendance: item.attendance,
                        projectId: item.projectId,
                        userTypeCode: item.userTypeCode,
                        remarks: item.remarks,
                        id: item.id,
                    });
                })
            }
        }
    }
    const handleCheckFuncForChecked = (canBook, selListTem, d, capItem) => {
        if (isEditSetupLab) {
            const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));
            if (bookinged) {
                selListTem.push({
                    date: d,
                    start: bookinged.bookingDate,
                    end: bookinged.bookingDate,
                    capacity: bookinged.capacity,
                    quota: bookinged.quota,
                    remarks: bookinged.remarks,
                    available: bookinged.available,
                    uavailableReason: bookinged.uavailableReason
                });
            } else {
                selListTem.push({
                    date: d,
                    start: d,
                    end: d,
                    capacity: '',
                    quota: '',
                    remarks: '',
                    available: '',
                    uavailableReason: '',
                });
            }
        } else {
            handleCheckManageRequest(canBook, d, selListTem, capItem)

        }
    }
    const handleCheckFunc = (week, d, wkListTmp, selListTem) => {
        const hasChecked = selListTem.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)))
        let capItem = ''
        capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(d)));


        const canBook = isEditSetupLab || (capItem && !!capItem.available);
        if (wkListTmp[week].checked && !hasChecked) {

            handleCheckFuncForChecked(canBook, selListTem, d, capItem)
            removeInvalidDate(d);

        } else if (!wkListTmp[week].checked) {

            let isHave = true;
            while (isHave) {
                let curIdx = selListTem.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(d)));
                if (curIdx > -1) {
                    selListTem.splice(curIdx, 1);
                } else {
                    isHave = false
                }
            }
        }

    }

    const setWeekChecked = (week) => {
        let wkListTmp = [...weekCheckedList];
        wkListTmp[week].checked = !wkListTmp[week].checked;
        setWeekCheckedList(wkListTmp);

        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);
        let selListTem = [...selectedDateList];
        for (let d = minDate; d < maxDate; d = addDays(d, 1)) {
            if (d.getDay() !== week) continue;


            handleCheckFunc(week, d, wkListTmp, selListTem);
            setSelectedDateList(selListTem);
        }
    }

    const setCapacityList = React.useCallback((list) => {
        capacityList.current = list;
    }, [])

    const setBookingsListState = React.useCallback((list) => {
        setBookingsList(list);
    }, [])

    const approverCheckedDate = (dateListTem, date) => {
        const bookinged = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(new Date(date))));
        if (!bookinged) {
            dateListTem.push({
                date: date,
                start: date,
                end: date,
                title: '',
                capacity: '',
                rejectReason: '',
                isFirst: '',
                status: '',
            })
        }

    }

    const checkedManageRequestDateForApproved = (newList, date, dateListTem) => {
        let isAdd = true;
        newList.forEach((item) => {
            if (isEqual(startOfDay(new Date(item.bookDate)), startOfDay(new Date(date)))) {

                isAdd = false
                dateListTem.push({
                    date: new Date(date),
                    start: item.bookDate,
                    end: item.bookDate,
                    title: item.refno,
                    capacity: item.capacity || 1,
                    rejectReason: item.rejectReason,
                    isFirst: item.isFirst,
                    status: item.status,
                    attendance: item.attendance,
                    projectId: item.projectId,
                    userTypeCode: item.userTypeCode,
                    remarks: item.remarks,
                    id: item.id,
                });
            }
        })
        isAdd && approverCheckedDate(dateListTem, date)
    }

    const checkedManageRequestDate = (dateListTem, date) => {
        const newList = filterListFunc(bookingsList);
        let capItem = capacityList.current.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(new Date(date))));
        const canBook = capItem && !!capItem.available;
        if (canBook) {
            if (selectionChoose === 'APPROVED') {
                checkedManageRequestDateForApproved(newList, date, dateListTem)

            } else {
                newList.forEach((item) => {
                    if (isEqual(startOfDay(new Date(item.bookDate)), startOfDay(new Date(date)))) {

                        dateListTem.push({
                            date: new Date(date),
                            start: item.bookDate,
                            end: item.bookDate,
                            title: item.refno,
                            capacity: item.capacity || 1,
                            rejectReason: item.rejectReason,
                            isFirst: item.isFirst,
                            status: item.status,
                            attendance: item.attendance,
                            projectId: item.projectId,
                            userTypeCode: item.userTypeCode,
                            remarks: item.remarks,
                            id: item.id,
                        });
                    }
                })
            }
        }
        return dateListTem
    }

    const checkedDate = (date) => {
        let dateListTem = [...selectedDateList];

        if (isEditSetupLab) {
            let curIdx = dateListTem.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
            const bookinged = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(date)));
            if (curIdx > -1) {
                dateListTem.splice(curIdx, 1);
            } else {

                dateListTem.push({
                    date: date,
                    start: bookinged?.bookingDate || startOfDay(date),
                    end: bookinged?.bookingDate || startOfDay(date),
                    // title: item.status,
                    capacity: bookinged?.capacity || 0,
                    quota: bookinged?.quota || 0,
                    remarks: bookinged?.remarks || '',
                    available: bookinged?.available || '',
                    uavailableReason: bookinged?.uavailableReason || ''
                });
                removeInvalidDate(date);
            }
        } else {
            let curIdx = dateListTem.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));

            if (curIdx > -1) {
                dateListTem = dateListTem.filter((item) => !isEqual(startOfDay(new Date(item.date)), startOfDay(date)))
            } else if (isRelease()) {
                dateListTem = checkedManageRequestDate(dateListTem, date)
                removeInvalidDate(date);
            }
        }
        setSelectedDateList(dateListTem);


    }

    const addNo = (date) => {
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        replaceNo(date, curDate ? curDate.no + 1 : 1);
    }

    const removeNo = (date) => {
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        replaceNo(date, curDate.no - 1);
    }

    const replaceNo = (date, no) => {
        let result = regexPattern.test(no);
        if (no && !result) return;
        if (!isRelease()) return;
        if (no)
            no = parseInt(no);
        let dateListTem = [...selectedDateList];
        let curDate = dateListTem.find(e => isEqual(startOfDay(new Date(e.date)), startOfDay(date)));
        if (!curDate) {
            curDate = { date: date, no: 1 };
            dateListTem.push(curDate);
            removeInvalidDate(date);
        }
        curDate.no = no;

        setSelectedDateList(dateListTem);
    }

    const replaceSelectedData = (date, value = []) => {
        let result = true;
        let dateListTem = [...value].filter((item) => item.projectId);
        dateListTem.forEach((item, index) => {
            if (!regexPattern.test(item.capacity + '')) {
                result = false;
            }

            dateListTem[index] = { date: date, ...item };

            removeInvalidDate(date)

        })

        if (!result) return;
        setSelectedDateList(dateListTem);
    }

    const selectedDateText = () => {
        if (selectDateChecked) {
            let tempObj = {};
            let t = [];
            selectedDateList.forEach((item) => {
                if (!tempObj[item.date]) {
                    tempObj[item.date] = true;
                    t.push(item)
                }
            })
            t = t.sort((a, b) => a.date - b.date)
            return t.map(e => format(startOfDay(e.date), 'd MMM yyyy')).join("\r\n")
        }
        return '';

    }

    const resetDate = (batch = isBatch) => {
        setResNo('');
        if (batch) {
            setProject([]);
            setSelfService([]);
        } else {
            setProject('');
            setSelfService('');
        }
        setAllRecordChecked(true)
        setSelectionChecked(false)
        setAllAvailableChecked(false);
        setSelectDateChecked(false);
        setResNoInvalidMsg('')
        setForProject(false)
        setForSelfService(false)
        setIsShowFirstTime(false)
        setNewReleaseTime('')
        setReleaseDate('')
        setReleaseTimeInvalidMsg('')
        firstTimeRef.current = {};
    }
    const resetLeftData = () => {
        resetDate(true)

        setIsBatch(true);

        setAvailableSpace('')
        setIsReleaseNow(false)
        setReleaseDate('')
        setNewReleaseTime('')
        setReleaseTimeInvalidMsg('')
        setRejectReason('')
        setSetUpRemarks('')
        setSelectionChoose('');
        setIsShowFirstTime(false)
        firstTimeRef.current = {};
    }

    const cancelClick = () => {
        resetLeftData();
        setIsEdit(false);
        setIsEditManageRequest(false);
        setIsEditSetupLab(false)
        setSelectedDateList([]);

        setReloadFlag(!reloadFlag)

    }

    const submitValidate = () => {
        const existMaxRecore = selectedDateList.some(e => e.capacity > projectMaxQuota);
        if (existMaxRecore)
            return false;
        return true;
    }

    const handleSelectBatch = (slots) => {
        if (selectionChoose) {
            let selListTem = [...selectedDateList];
            for (let i = 0; i < slots.length; i++) {

                selListTem = checkedManageRequestDate(selListTem, slots[i])

            }
            setSelectedDateList(selListTem);
        }
    }

    const handleSelectDate = (e) => {
        if (e.action === 'click') return;
        let selListTem = [...selectedDateList];
        const slots = e.slots;
        if (isEditSetupLab) {
            for (let i = 0; i < slots.length; i++) {
                const bookinged = bookingsList.find(event => isEqual(startOfDay(new Date(event.bookingDate)), startOfDay(slots[i])));


                selListTem.push({
                    date: slots[i],
                    start: bookinged?.bookingDate || startOfDay(slots[i]),
                    end: bookinged?.bookingDate || startOfDay(slots[i]),
                    capacity: bookinged?.capacity || 0,
                    quota: bookinged?.quota || 0,
                    remarks: bookinged?.remarks || '',
                    available: bookinged?.available || '',
                    uavailableReason: bookinged?.uavailableReason || ''
                });

            }
            setSelectedDateList(selListTem);
            return;
        } else {

            handleSelectBatch(slots)
        }



    }

    const getApprovedAction = (item, haveBooking, capacity, text) => {
        let t = text;
        if (haveBooking?.status === 'APPROVED') {
            let oldCapacity = haveBooking?.capacity || 0
            if (oldCapacity - item.quota < 0) {
                capacity = (oldCapacity - item.quota) * -1;
            } else {
                capacity = oldCapacity - item.quota;
                t = 'Removed'
            }
        } else {
            capacity = item.quota
        }
        return {
            capacity,
            text: t
        }
    }
    const generateActionList = (list) => {
        const temp = {};
        const textMap = {
            'APPROVED': 'Added',
            'CANCELLED': 'Cancelled',
            'REJECTED': 'Rejected',
        }
        list.sort((a, b) => a.bookDate - b.bookDate).forEach((t) => {
            const item = { ...t };
            const haveBooking = bookingResMapRef.current[item.projectId + format(new Date(item.bookDate), 'yyyy-MM-dd')]
            const date = format(new Date(item.bookDate), 'dd MMM');
            let capacity = haveBooking?.capacity || item.quota
            let text = textMap[item.statusCode]
            const updateObj = {
                id: item?.requestId || 0,
                projectId: item.projectId,
                oldQuota: haveBooking?.capacity || 0,
                newQuota: item.quota,
                statusCode: item.statusCode,
                bookDate: item.bookDate
            }
            if (item.statusCode === 'APPROVED') {
                let r = getApprovedAction(item, haveBooking, capacity, text);
                capacity = r.capacity;
                text = r.text;
            } else {
                capacity = item.quota;
                updateObj.newQuota = 0;
            }
            item.isChecked = false;

            if (temp[item.projectId]) {
                temp[item.projectId].action.push(`${text} ${capacity} space(s) on ${date}`)
                temp[item.projectId].updatedList.push(updateObj)
            } else {
                item.action = [`${text} ${capacity} space(s) on ${date}`]
                item.updatedList = [updateObj]
                temp[item.projectId] = {
                    ...item
                }
            }
        })
        setSubmittedActionList(Object.keys(temp).map((key) => temp[key]))
    }

    const handleUpdateManageRequest = (requests) => {

        setIsLoading(true);
        axios.post('/bookRequest/all-booking-submit', requests)
            .then(({ data: result }) => {
                setSubmitResult(result);
                if (result.isSuccess) {

                    setIsDirty(false);
                    generateActionList(requests);
                    setIsSubmitted(true);
                    setResNo('');
                    setNoValid(true);
                    setSelectedDateList([]);
                    requestRef.current = null
                    getBookedProjects()
                }
                else {
                    setSeatsInvalidOpen(true);
                    setErrorMsg(result.message)
                }
                setIsLoading(false);
            }).catch(err => {
                var msg = err.response?.data?.message;
                setErrorMsg(msg ? msg : err.message);
                setIsLoading(false);

            });
    }
    const updateSetup = requests => {
        setIsLoading(true);
        axios.post('/bookSetup/update-lab-availability', requests)
            .then(({ data: result }) => {

                setIsSubmitted(true);
                setResNo('');
                setNoValid(true);
                setIsDirty(false);
                loadNotifications();
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);

            });
    }
    const handleUpdateSetup = (requests) => {
        setIsLoading(true);
        const params = requests.settings.map((item) => ({
            date: format(new Date(item.date), "yyyy-MM-dd"),
            capacity: item.capacity
        }))
        axios.post('/bookSetup/capacity-above-approved', params).then(res => {

            updateSetup(requests);
        }).catch(err => {
            showModal((pros) => (
                <SystemConfirmDialog {...pros}
                    onConfirm={() => { updateSetup(requests); }}
                    onCancel={() => console.log("Deletion cancelled.")}
                    okText={'Yes'}
                >
                    <Grid container justifyContent={'center'}>Are you sure to continue?</Grid>
                    <Grid container justifyContent={'center'}>The set capacity is lower than reserved access no. (Please be reminded to revise access no. in "Manage Booking")</Grid>
                    <Grid container justifyContent={'center'}><div style={{ maxHeight: 200, width: '100%', overflowY: 'auto' }}>{err.response?.data?.message.split(',').map((text) => <div>{text}</div>)}</div></Grid>
                </SystemConfirmDialog>

            ));
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const requestRef = React.useRef(null)

    const submitClickForSetupLab = () => {
        const tempList = [];
        let releaseTimeNow = format(new Date(), 'yyyy-MM-dd') + 'T' + format(new Date(), 'HH:mm:ss');
        if (!isReleaseNow) {
            if (!releaseDate || !newReleaseTime) {
                setReleaseTimeInvalidMsg('Pre-set release time')
                setIsLoading(false);
                return
            }
            releaseTimeNow = format(releaseDate, 'yyyy-MM-dd') + 'T' + newReleaseTime + ":00"
        }
        let isPrevTime = false;
        let settings = [];
        let settingsMap = {};
        selectedDateList.forEach(item => {
            const date = startOfDay(item.date)
            if (!settingsMap[date]) {
                settingsMap[date] = true;
                const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(item.date));
                if (isPreviousDate) {
                    isPrevTime = true;
                }
                settings.push({
                    date: format(startOfDay(item.date), "yyyy-MM-dd"),
                    available: item.available,
                    remarks: item.remarks,
                    capacity: item.available ? item.capacity * 1 : 0,
                })
            }
        })

        const requests = {
            "releaseTime": releaseTimeNow,
            settings
        }

        if (isPrevTime) {
            requestRef.current = requests;
            setPrevRecordOpen(true)
            setIsLoading(false);
            return
        }

        handleUpdateSetup(requests)
    }

    const getManageRequestPayloadForCurBooking = (i, tempList, isPrevTime, curBooking, isPreviousDate) => {
        if (curBooking.capacity !== selectedDateList[i].capacity || curBooking.title !== selectedDateList[i].refno || curBooking.remarks !== selectedDateList[i].remarks || curBooking.status !== selectedDateList[i].status || curBooking.rejectReason !== selectedDateList[i].rejectReason) {
            if (selectedDateList[i].status !== 'REJECTED') {
                selectedDateList[i].rejectReason = ''
            }
            tempList.push(selectedDateList[i]);
            if (isPreviousDate) {
                isPrevTime = true;
            }
        }
        return isPrevTime
    }
    const getManageRequestPayload = (tempList, isPrevTime) => {
        let isPrev = isPrevTime
        for (let i = 0; i < selectedDateList.length; i++) {

            const isPreviousDate = isAfter(startOfDay(new Date()), startOfDay(new Date(selectedDateList[i].date)));
            const curBooking = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(new Date(selectedDateList[i].date))) && e.projectId === selectedDateList[i].projectId);
            if (!curBooking) {
                if (selectedDateList[i].status !== 'REJECTED') {
                    selectedDateList[i].rejectReason = ''
                }
                tempList.push(selectedDateList[i]);
                if (isPreviousDate) {
                    isPrev = true;
                }
            } else {
                isPrev = getManageRequestPayloadForCurBooking(i, tempList, isPrev, curBooking, isPreviousDate)
            }

        }
        return isPrev;
    }
    const submitClick = () => {
        let submitSuccess = false;
        if (!selectedDateList.length) return;
        setIsLoading(true);
        if (isEditSetupLab) {

            submitClickForSetupLab();
        } else {
            if (!submitValidate()) {
                setMaxQuotaAlertOpen(true);
                return false;
            }
            let isPrevTime = false;
            let tempList = [];
            isPrevTime = getManageRequestPayload(tempList, isPrevTime)
            const requests = tempList.map(item => ({
                bookDate: format(startOfDay(new Date(item.date)), "yyyy-MM-dd"),
                quota: item.capacity,
                projectId: item.projectId,
                projectCode: item.title,
                requestId: item.id,
                statusCode: item.status,
                remarks: item.remarks,
                rejectReason: item.rejectReason,
            }))

            if (isPrevTime) {
                requestRef.current = requests;
                setPrevRecordOpen(true)
                setIsLoading(false);
                return
            }

            handleUpdateManageRequest(requests)
        }

    }

    const viewBookingResult = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        resetLeftData()
        setIsSubmitted(false);
        setIsEdit(false);
        setIsEditManageRequest(false);
        setIsEditSetupLab(false)

        setSelectedDateList([]);

    }

    const removeInvalidDate = (date) => {
        let submitResultTmp = { ...submitResult };
        if (submitResult && submitResult.invalidList) {
            let curIdx = submitResult.invalidList.findIndex(e => isEqual(startOfDay(new Date(e.date)), startOfDay(new Date(date))));
            if (curIdx > -1) {
                submitResult.invalidList.splice(curIdx, 1);
            }
        }
        setSubmitResult(submitResultTmp);
    }

    const isUpdatedForSetupLab = () => {
        for (let i = 0; i < selectedDateList.length; i++) {
            const curBooking = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookingDate)), startOfDay(new Date(selectedDateList[i].date))));
            if (!curBooking) return true;
            if (curBooking.available !== selectedDateList[i].available || curBooking.remarks !== selectedDateList[i].remarks || curBooking.capacity !== selectedDateList[i].capacity)
                return true;

            continue;
        }
    }
    const isUpdatedForManageRequest = () => {
        const newList = selectedDateList.filter((item) => item.projectId)
        // console.log(newList)
        for (let i = 0; i < newList.length; i++) {
            const curBooking = bookingsList.find(e => isEqual(startOfDay(new Date(e.bookDate)), startOfDay(new Date(newList[i].date))) && e.projectId === newList[i].projectId);
            if (!curBooking) return true;
            if (curBooking.capacity !== newList[i].capacity || curBooking.title !== newList[i].refno || curBooking.remarks !== newList[i].remarks || curBooking.status !== newList[i].status || curBooking.rejectReason !== newList[i].rejectReason)
                return true;

            continue;
        }
    }
    const isUpdated = () => {
        if (isEdit) {
            if (isEditSetupLab) {
                return isUpdatedForSetupLab();
            } else {
                return isUpdatedForManageRequest();
            }

        }
        return false;
    }
    const isRelease = (month) => {
        return true;

    }

    const filterChooseForCancelAndReject = (newList) => {
        if (selectionChoose === 'CANCELLED') {
            newList = newList.filter((item) => item.status !== 'CANCELLED')
        }
        if (selectionChoose === 'REJECTED') {
            newList = newList.filter((item) => item.status !== 'REJECTED')
        }
        return newList;
    }

    const getFilterNewList = () => {
        let newL = [];
        let newSelfService = [];
        if (Array.isArray(project) && Array.isArray(selfService)) {
            newSelfService = [...selfService]
            let selectedIndex = newSelfService.indexOf('firsttime');
            if (selectedIndex >= 0) {
                newSelfService.splice(selectedIndex, 1)
            }
            newL = [...project, ...newSelfService]
        } else {
            project && newL.push(project)
            selfService && newL.push(selfService)
        }
        return newL
    }
    const filterListFunc = (list) => {

        if (!isEditSetupLab) {
            let newList = list;
            newList = filterChooseForCancelAndReject(newList)
            let newL = getFilterNewList();
            if (isEditManageRequest) {
                if (!selectionChoose || newL.length === 0) {
                    return [...newList]
                }
                return newList.filter((item) => newL.includes(item.projectId))
            }
            if (allRecordChecked) return [...newList]
            if (isShowFirstTime) return newList.filter((item) => newL.includes(item.projectId) || item.isFirst)
            return newList.filter((item) => newL.includes(item.projectId))

        }
    }

    const isRejectDate = (date) => {

        return false;
    }


    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {
            history.push(nextPath);
        }
    }, [history, isDirty, nextPath])

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/manage-bookings'))
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {

                setOpenDirtyCheckDialog(false);
                submitClick()
            },
            btn1Label: 'Submit Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);
                }
                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your booking request(s) has(have) not been submitted.</div>
                    <div>{text ? text : 'Please be reminded to submit before you leave the page.'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }

    const editClick = () => {
        const edit = () => {
            resetLeftData();
            setIsEdit(true)
            setCurrentModal('CView');
            setIsEditSetupLab(true);
            setIsEditManageRequest(false)
            setReloadFlag(!reloadFlag);
            setSelectedDateList([])
        }
        if (isDirty) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    edit()
                },
            })
            return;
        }
        edit()
    }

    const uLocation = useLocation();
    React.useEffect(() => {
        if (uLocation.state && uLocation.state.isSetup) {
            editClick();
        } else {
            setCurrentModal('CView');
        }
        if (uLocation.state && uLocation.state.date) {
            setViewDate(startOfDay(new Date(uLocation.state.date)));
        }
    }, [uLocation.state])

    const editManageRequestClick = () => {
        const edit = () => {
            resetLeftData();
            setIsEdit(true)
            setIsEditManageRequest(true)
            setIsEditSetupLab(false);
            setCurrentModal('CView');
            setReloadFlag(!reloadFlag);
            setAllRecordChecked()
            setSelectedDateList([])
        }
        if (isDirty) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    edit()
                },
            })
            return;
        }
        edit()


    }
    const setResNoClick = (e) => {
        const no = e;
        let result = regexPattern.test(no);
        if (no && !result) return;
        setResNo(no);
    }

    const exportToExcel = async (e) => {
        const maxDate = format(endOfMonth(viewDate), "yyyy-MM-dd");
        const response = await axios.get(`/bookRequest/export-alluser-bookings`, { responseType: "blob", params: { bookMonth: maxDate } })
        if (response.data) {
            let fileName = `Booking-${format(new Date(), 'yyyyMMddHHmmss')}.csv`
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(response.data, fileName)
            } else {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.download = fileName;
                document.body.appendChild(link);
                var evt = document.createEvent("MouseEvents");
                evt.initEvent("click", false, false);
                link.dispatchEvent(evt);
                document.body.removeChild(link);
            }
        }
    }

    const onPrevRecordOk = () => {
        if (isEditSetupLab) {
            handleUpdateSetup(requestRef.current)
        } else {

            handleUpdateManageRequest(requestRef.current)
        }
        setPrevRecordOpen(false)
    }

    const handleAction = (checked, index) => {
        const temp = [...submittedActionList];
        temp[index].isChecked = checked;
        setSubmittedActionList(temp)
    }

    const generateEmailConfirm = () => {
        setIsLoading(true);
        const p = [];
        let updatedList = []
        submittedActionList.forEach((item) => {
            if (item.isChecked) {
                p.push(item.projectId)
                updatedList = [...updatedList, ...item.updatedList]
            }
        })
        if (p.length === 0) {
            viewBookingResult()
            return
        }
        const requests = {
            month: format(startOfDay(viewDate), "yyyy-MM-dd"),
            projects: p,
            updatedList
        }
        // console.log(updatedList)
        setIsSubmitted(false);
        axios.post('/bookRequest/email-notification', requests)
            .then(({ data: result }) => {
                viewBookingResult()

                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false);
                setIsSubmitted(true);
            });
    }

    const renderEmailTable = () => {
        return (<Grid container className={classes.emailTable}>
            <Grid container className={classes.emailTableHead}>
                <Grid item className={classes.emailTableTd1}>Generate email</Grid>
                <Grid item className={classes.emailTableTd2}>Performed Action(s)</Grid>
            </Grid>
            <Grid container className={classes.emailTableBody}>
                {submittedActionList.map((item, index) => <Grid key={index} container className={classes.emailTableTr} style={index === 0 ? { border: 0 } : {}}>

                    <Grid item className={classes.emailTableTd1} style={{ paddingLeft: 5 }}>
                        <Button
                            startIcon={item.isChecked ? <CheckCircleIcon style={{ width: '20px', height: '20px', color: '#f58220', marginRight: 5 }} /> : <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999', marginRight: 5 }} />}
                            className={classes.selTableButton}
                            style={{
                                textTransform: 'none',
                                lineHeight: '1.5',
                                fontSize: '13px',
                                padding: '0 8px',
                                color: '#000',
                                justifyContent: 'flex-start',
                                textAlign: 'left'
                            }}
                            onClick={() => handleAction(!item.isChecked, index)}
                        >
                            <div >{item.projectCode}</div>
                        </Button></Grid>
                    <Grid item className={classes.emailTableTd2}>{item?.action?.join('; ')}</Grid>
                </Grid>)}

            </Grid>
        </Grid>)
    }

    const renderSubmitDialog = () => {
        return isSubmitted && <Dialog
            open={isSubmitted}
            disableBackdropClick
            disableEscapeKeyDown
        >

            <Grid container style={{ padding: '0 20px' }} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid item style={{
                    // boxShadow: '3px 3px 15px #888888',
                    // width: '600px',
                    backgroundColor: '#fff',
                    // borderRadius: '8px',
                    padding: '40px 15px',
                }}>
                    <DialogTitle>
                        <Typography className={`${classes.mainColorTitle}`} variant="h5" align="center" xs={12}>
                            {isEditSetupLab ? "Setup Successfully!" : 'Submitted successfully!'}
                        </Typography>
                    </DialogTitle>
                    {isEditManageRequest && <div style={{ fontSize: '13px', textAlign: 'center', color: '#000' }}>
                        <div style={{ marginTop: '10px' }}>Please click below to generate email to respective researchers.</div>
                    </div>}
                    {isEditManageRequest && renderEmailTable()}
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                        <Grid item>
                            {isEditManageRequest && <Button onClick={generateEmailConfirm} color="primary"
                                style={{ backgroundColor: '#f58220', padding: '10 24px', color: '#fff', textTransform: 'none', }}>
                                Confirm
                            </Button>}
                            {isEditManageRequest && <Button onClick={viewBookingResult} color="primary"
                                style={{ backgroundColor: '#646464', padding: '10 24px', color: '#fff', marginLeft: 10, textTransform: 'none', }}>
                                {isEditManageRequest ? 'Cancel' : 'Ok'}
                            </Button>}
                            {!isEditManageRequest && <Button onClick={viewBookingResult} color="primary"
                                style={{ backgroundColor: '#f58220', padding: '10 24px', color: '#fff', textTransform: 'none', }}>
                                OK
                            </Button>}
                        </Grid>

                    </div>
                </Grid>
            </Grid>
        </Dialog>
    }

    const renderFooter = () => {
        return <Grid container item className={classes.labelWrapper} style={{ height: 45, paddingRight: 8 }} alignItems={'center'} justifyContent='space-between'>
            <Grid item container alignItems={'center'} style={{ flex: 1 }}>
                <Grid style={{ paddingRight: '8px' }}>Lab Availability</Grid>
                <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#ED3A4E' }}></Grid>
                <Grid>80% full</Grid>
                <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#FFB448' }}></Grid>
                <Grid>60% full</Grid>
                <Grid style={{ width: '6px', height: '6px', borderRadius: '50%', margin: '0 8px 0 16px', backgroundColor: '#50E4A7' }}></Grid>
                <Grid>Available</Grid>
                <Grid style={{ margin: '-1px 4px 0 16px', color: '#000' }}><StarIcon style={{ width: 15, height: 15 }} /></Grid>
                <Grid>First time</Grid>
            </Grid>
            <Grid item >
                {!isEdit && <Button className={classes.activeButton} style={{ marginLeft: '5px', width: '150px' }}
                    onClick={exportToExcel}
                >Export to excel</Button>}
                {isEditManageRequest && <Button className={classes.activeButton} style={{ marginLeft: '5px', width: '150px' }}
                    onClick={() => setEmailDialogOpen(true)}
                >Generate email</Button>}
            </Grid>
            {emailDialogOpen && <GenerateEmailDialog
                open={emailDialogOpen} handleClose={() => setEmailDialogOpen(false)}
                viewDate={viewDate}
            />}
        </Grid>
    }

    const renderHeader = () => {
        const t = isEditSetupLab ? 'Setup Lab Availability' : 'Access Management'
        return <Grid container style={{ padding: '10px', position: 'relative' }}>
            <Grid item xs className={classes.titleWrapper}>
                <Typography component={'div'} className={classes.titleFontRoot}>{isEditManageRequest ? "Manage Requests" : t}</Typography>
            </Grid>

            <Grid item xs={'auto'} className={classes.titleWrapper}
                style={
                    // selectedProject?.projectType === 'COL_RESEARCHER' ? { display: 'flex' } :
                    { display: 'flex', position: 'absolute', right: '10px', top: '50px' }
                }
            >
                {!isEdit && <Select
                    value={currentModal}
                    className={classes.bookingModSelected}
                    onChange={(e) => setCurrentModal(e.target.value)}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PopoverClasses: { root: classes.modSelectedPopover },
                        MenuListProps: {

                        }
                    }}
                >
                    <MenuItem value={'CView'} style={{ backgroundColor: '#fff' }}>
                        <ListItemText primary={'Calendar View'} style={{ margin: '0', color: '#000', }} />
                        {currentModal === 'CView' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                    </MenuItem>
                    <Divider flexItem style={{ pointerEvents: 'none' }} />
                    <MenuItem value={'History'} style={{ backgroundColor: '#fff' }}>
                        <ListItemText primary={'Booking History'} style={{ margin: '0', color: '#000' }} />
                        {currentModal === 'History' && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                    </MenuItem>
                </Select>}
                {!isEditSetupLab && <Button className={classes.activeButton} style={{ marginLeft: '5px', width: '180px' }}
                    onClick={editClick}
                >Setup Lab Availability</Button>}
                {!isEditManageRequest && <Button className={classes.activeButton} style={{ marginLeft: '5px', width: '150px' }}
                    onClick={editManageRequestClick}
                >Manage Requests</Button>}
            </Grid>
        </Grid>
    }

    const renderInvalidTitle = (failType) => {
        let text = 'Booking cannot be submitted due to insufficient capacity.';
        if (failType === 1) {
            text = 'The Booking for below account(s) cannot be made due to following reason(s):';
            if (Array.isArray(project) && project.length > 0) {
                text = 'The Booking for below project(s) cannot be made beyond the access end date(s):';
            }
            if (Array.isArray(selfService) && selfService.length > 0) {
                text = 'The Booking for below self-service account(s) cannot be made due to following reason(s):';
            }
        }
        return text;
    }

    const renderInvalidListForFailType1 = (resP, resS, item, inIdx) => {
        if (item.projectType === Enum.AVAILABLE_BOOKING.COL_RESEARCHER) {
            resP.push(
                <Grid container key={inIdx}>
                    <Grid style={{ width: '35%', textAlign: 'right' }} item justifyContent={'right'}>{item.refNum}&nbsp;</Grid>
                    <Grid item style={{ flex: 1, textAlign: 'left' }}>
                        <Grid> - Access end date: <span style={{ color: 'red' }}>{format(startOfDay(new Date(item.accessEndDate)), 'dd MMM yyyy')}</span></Grid>
                    </Grid>
                </Grid>
            )
        }
        if (item.projectType === Enum.AVAILABLE_BOOKING.SELF_RESEARCHER) {
            resS.push(
                <Grid container key={inIdx}>
                    <Grid style={{ width: '35%', textAlign: 'right' }} item justifyContent={'right'}>{item.refNum}&nbsp;</Grid>
                    <Grid item style={{ flex: 1, textAlign: 'left' }}>
                        {item.accessEndDate ? <Grid> - Access end date: <span style={{ color: 'red' }}>{format(startOfDay(new Date(item.accessEndDate)), 'dd MMM yyyy')}</span></Grid> : ''}
                        {(item.remainingAccess || item.remainingAccess === 0) && <Grid> - Remaining access days: <span style={item.remainingAccess <= 0 ? { color: 'red' } : {}}>{item.remainingAccess}</span></Grid>}
                    </Grid>
                </Grid>
            )
        }
    }

    const renderInvalidList = () => {
        let p = [];
        let s = [];
        submitResult?.invalidList && submitResult.invalidList.forEach(item => {
            if (item.projectType === Enum.AVAILABLE_BOOKING.COL_RESEARCHER) {
                p.push(item)
            } else {
                s.push(item)
            }
        });
        p = p.sort((a, b) => new Date(a.date) - new Date(b.date))
        s = s.sort((a, b) => new Date(a.date) - new Date(b.date))
        const newList = [...p, ...s]
        const resP = [];
        const resS = [];
        newList.forEach((item, inIdx) => {
            if (submitResult?.failType === 1) {
                renderInvalidListForFailType1(resP, resS, item, inIdx)
            } else {
                resS.push(
                    <div key={inIdx}>{format(startOfDay(new Date(item.date)), 'd MMM')} : Remaining seat(s) = {item.remainingSeats}</div>
                )
            }
        })
        if (resP.length !== 0 && resS.length !== 0) {
            resP.push(<div style={{ width: '90%', height: 1, backgroundColor: '#ccc', margin: '3px auto' }}></div>)
        }
        return [...resP, ...resS];

    }
    return (
        <Provider value={{ remarks, setRemarks, setDateList, dateList, handleOpenDirtyCheck }}>
            <Grid container style={{ minWidth: 1000 }}>

                <LoadingScreen open={isLoading} />
                <Prompt
                    when={isDirty}
                    message={(location) => handleOpenDirtyCheck({ location })}
                />
                <SystemMessageDialog open={updateAlertOpen} onClose={() => setUpdateAlertOpen(false)}>
                    <div>Your booking is updated.</div>
                    <div>Please be reminded to SUBMIT before you leave the page.</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={prevRecordOpen} btn2Action={() => setPrevRecordOpen(false)} btn1Label={'Yes'} btn1Action={onPrevRecordOk}>
                    <div>Are you sure to edit previous records?</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                    <div>{errorMsg}</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={seatsInvalidOpen} onClose={() => setSeatsInvalidOpen(false)}>
                    <div>{renderInvalidTitle(submitResult?.failType)}</div>
                    <div style={{
                        borderRadius: '3px', backgroundColor: '#f3f3f3', maxHeight: '200px', overflow: 'auto', margin: '10px auto'
                    }}>
                        {
                            renderInvalidList()
                        }
                    </div>
                    <div>Please select alternative date(s).</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={maxQuotaAlertOpen} onClose={() => setMaxQuotaAlertOpen(false)}>
                    <div>Quota needs to be between 1 and {projectMaxQuota}</div>
                </SystemMessageDialog>
                <SystemMessageDialog open={updateSetAlertOpen} onClose={() => setUpdateSetAlertOpen(false)}>
                    <div>Your booking is updated.</div>
                    <div>Please be reminded to confirm the release time <br />before you leave the page.</div>
                </SystemMessageDialog>
                <Grid container style={{ height: 'calc(100vh - 94.5px)' }}>
                    <Grid item className={classes.leftWrapper} style={{ width: '205px' }}>
                        <Consumer>
                            {value =>
                                <>
                                    {
                                        currentModal === 'History' ?
                                            <BookingHistoryViewLeft
                                                selectionChecked={selectionChecked}
                                                setSelectionChecked={setSelectionChecked}
                                                project={project}
                                                setProject={setProject}
                                                projectList={projectList}
                                                selfService={selfService}
                                                setSelfService={setSelfService}
                                                selfServiceList={selfServiceList}
                                            />
                                            : <AMViewLeft
                                                isEdit={isEdit}
                                                value={value}
                                                enableUpdateBooking={enableUpdateBooking}
                                                // selectedProject={selectedProject}
                                                selectDateClick={selectDateClick}
                                                selectAvailableDateClick={selectAvailableDateClick}
                                                setResNoClick={setResNoClick}
                                                updateCalendarNo={updateCalendarNo}
                                                submitClick={submitClick}
                                                cancelClick={cancelClick}
                                                allAvailableChecked={allAvailableChecked}
                                                selectDateChecked={selectDateChecked}
                                                selectedDateText={selectedDateText}
                                                resNo={resNo}
                                                selectedDateList={selectedDateList}
                                                resNoInvalidMsg={resNoInvalidMsg}
                                                isDirty={isDirty}
                                                projectList={projectList}
                                                selfServiceList={selfServiceList}
                                                allRecordChecked={allRecordChecked}
                                                setAllRecordChecked={setAllRecordChecked}
                                                selectionChecked={selectionChecked}
                                                setSelectionChecked={setSelectionChecked}
                                                selectionChoose={selectionChoose}
                                                setSelectionChoose={setSelectionChoose}
                                                project={project}
                                                setProject={setProject}
                                                selfService={selfService}
                                                setSelfService={setSelfService}
                                                setIsBatch={setIsBatch}
                                                isBatch={isBatch}
                                                forProject={forProject}
                                                setForProject={setForProject}
                                                forSelfService={forSelfService}
                                                setForSelfService={setForSelfService}
                                                isEditManageRequest={isEditManageRequest}
                                                availableSpace={availableSpace}
                                                setAvailableSpace={setAvailableSpace}
                                                updateCalendarAvailable={updateCalendarAvailable}
                                                isReleaseNow={isReleaseNow}
                                                releaseDate={releaseDate}
                                                setReleaseDate={setReleaseDate}
                                                newReleaseTime={newReleaseTime}
                                                setNewReleaseTime={setNewReleaseTime}
                                                setIsReleaseNow={setIsReleaseNow}
                                                setResNo={setResNo}
                                                rejectReason={rejectReason}
                                                setRejectReason={setRejectReason}
                                                setUpRemarks={setUpRemarks}
                                                setSetUpRemarks={setSetUpRemarks}
                                                releaseTimeInvalidMsg={releaseTimeInvalidMsg}
                                                bookingAllProject={bookingAllProject}
                                                bookingAllSelfService={bookingAllSelfService}
                                                handleOpenDirtyCheck={handleOpenDirtyCheck}
                                                setSelectedDateList={setSelectedDateList}
                                                setIsShowFirstTime={setIsShowFirstTime}
                                                isShowFirstTime={isShowFirstTime}
                                                firstTimeRef={firstTimeRef}
                                                bookingsList={bookingsList}
                                                viewDate={viewDate}
                                            />
                                    }

                                </>
                            }
                        </Consumer>
                    </Grid>
                    <Grid item className={classes.rightWrapper} style={{ width: 'calc(100% - 205px)', }}>
                        <Grid container style={{ height: '100%' }} direction='column'>
                            <Grid container style={{ height: '100%' }} direction='column'>
                                {renderHeader()}
                                {
                                    currentModal === 'CView' &&
                                    <>
                                        <Grid item className={classes.calendarWrapper}>
                                            {currentModal === 'CView' &&
                                                <Consumer>
                                                    {
                                                        (selectedProjectValue) => {
                                                            return !isEditSetupLab ? <CalendarComponent
                                                                selectedProjectValue={selectedProjectValue}
                                                                currentModal={currentModal}
                                                                isEdit={isEdit}
                                                                selectedDateList={selectedDateList}
                                                                addNo={addNo}
                                                                removeNo={removeNo}
                                                                replaceNo={replaceNo}
                                                                checkedDate={checkedDate}
                                                                setCapacityList={setCapacityList}
                                                                setBookingsList={setBookingsListState}
                                                                weekCheckedList={weekCheckedList}
                                                                setWeekChecked={(week) => setWeekChecked(week)}
                                                                setViewDate={(date) => setViewDateState(date)}
                                                                submitResult={submitResult}
                                                                releaseTimes={releaseTimes}
                                                                isRelease={isRelease}
                                                                todayHasBooking={todayHasBooking}
                                                                viewDate={viewDate}
                                                                handleSelectDate={handleSelectDate}
                                                                setIsLoading={setIsLoading}
                                                                projectMaxQuota={projectMaxQuota}
                                                                isRejectDate={isRejectDate}
                                                                reloadFlag={reloadFlag}
                                                                weekRdoDisplays={weekRdoDisplays}
                                                                isEditManageRequest={isEditManageRequest}
                                                                projectValue={project}
                                                                selfServiceValue={selfService}
                                                                allRecordChecked={allRecordChecked}
                                                                replaceSelectedData={replaceSelectedData}
                                                                projectList={projectList}
                                                                selfServiceList={selfServiceList}
                                                                isEditSetupLab={isEditSetupLab}
                                                                selectionChoose={selectionChoose}
                                                                filterListFunc={filterListFunc}
                                                                resetLeftData={resetDate}
                                                                bookingResMapRef={bookingResMapRef}
                                                                setSelectedDateList={setSelectedDateList}
                                                                removeInvalidDate={removeInvalidDate}
                                                            /> : <SetupCalendarComponent
                                                                selectedProjectValue={selectedProjectValue}
                                                                currentModal={currentModal}
                                                                isEdit={isEdit}
                                                                selectedDateList={selectedDateList}
                                                                addNo={addNo}
                                                                removeNo={removeNo}
                                                                replaceNo={replaceNo}
                                                                checkedDate={checkedDate}
                                                                setCapacityList={setCapacityList}
                                                                setBookingsList={setBookingsListState}
                                                                weekCheckedList={weekCheckedList}
                                                                setWeekChecked={(week) => setWeekChecked(week)}
                                                                setViewDate={(date) => setViewDateState(date)}
                                                                submitResult={submitResult}
                                                                releaseTimes={releaseTimes}
                                                                isRelease={isRelease}
                                                                todayHasBooking={todayHasBooking}
                                                                viewDate={viewDate}
                                                                handleSelectDate={handleSelectDate}
                                                                setIsLoading={setIsLoading}
                                                                projectMaxQuota={projectMaxQuota}
                                                                isRejectDate={isRejectDate}
                                                                reloadFlag={reloadFlag}
                                                                weekRdoDisplays={weekRdoDisplays}
                                                                resetLeftData={resetDate}
                                                            />
                                                        }
                                                    }
                                                </Consumer>
                                            }
                                        </Grid>
                                        {renderFooter()}
                                    </>
                                }
                                {currentModal === 'History' &&
                                    <Grid item className={classes.calendarWrapper}>
                                        <Consumer>
                                            {
                                                (selectedProjectValue) =>
                                                    <BookingHistoryComponentByAdmin
                                                        selectedProjectValue={selectedProjectValue}
                                                        bookingHistorys={bookingHistorys}
                                                        project={project}
                                                        selfService={selfService}
                                                        selectionChecked={selectionChecked}
                                                        setViewDate={(date) => setViewDate(date)}
                                                        isRelease={isRelease}
                                                        viewDate={viewDate}
                                                    />
                                            }
                                        </Consumer>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    renderSubmitDialog()
                }
            </Grid>
        </Provider >
    );
}

export default AccessManagement;