import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        fontSize: 13
    },
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    button: {
        backgroundColor: '#dfdfdf ',
        color: '#000000 ',
        textTransform: 'none '
    },
    title: {
        padding: 10,
        backgroundColor: '#f6c2a4',
        lineHeight: 1.1,
        fontSize: 13,
        borderRadius: 3
    },
    label: {
        minHeight: 32,
        padding: '4px 10px 4px 20px',
        lineHeight: '32px',
        backgroundColor: '#f3f3f3',
    },
    rightContent: {
        padding: '4px 10px 4px 20px',
        // lineHeight: '32px',
    },
    lineBottom: {
        // minHeight: 40,
        borderBottom: '1px solid #dcdcdc'
    },
    textareaBox: {
        width: '100%',
        textIndex: 10,
        '& textarea': {
            paddingLeft: 10,
            fontSize: 13
        }
        // paddingLeft: 10
    },
    textarea: {
        // paddingLeft: 10
    },
    textareaTips: {
        fontSize: 13
    },
    DateText: {
        backgroundColor: 'white',
        width: '130px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important',
            paddingRight: 0
        },
        '& .MuiButtonBase-root': {
            margin: 0,
            padding: 0,
            paddingRight: 2
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 2,
        },
        '& input': {
            padding: '5px 0 5px 10px',
            // paddingTop: 5,
        },
        '& input::-webkit-input-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
        '& input::-moz-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
    },
    projectHeader: {
        backgroundColor: '#646464',
        borderRadius: 3,
        textAlign: 'center',
        color: '#fff',
        marginTop: 3
    },
    projectTh: {
        padding: '5px 0',
        borderRight: '1px solid #3d3d3d'
    },
    projectTd: {
        padding: '4px 0'
    },
    buttonLabel: {
        color: '#000'
    },
    riskLabel: {
        color: '#f58220',
        fontSize: 15,
        fontWeight: 'bold'
    },
    lowColor: {
        color: '#6ad157 !important',
        padding: '0px 5px 0px 0px !important',
    },
    mediumColor: {
        color: '#ffe04f !important',
        padding: '0px 5px 0px 0px !important',
    },
    hightColor: {
        color: '#ff534f !important',
        padding: '0px 5px 0px 0px !important',
    },
}));