import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './Styles';
import SelectComponent from './SelectComponent';
import Enum from '../../enum/Enum'

const BookingHistoryViewLeft = ({
    selectionChecked,
    setSelectionChecked,
    project,
    setProject,
    projectList,
    selfService,
    setSelfService,
    selfServiceList,
}) => {
    const classes = useStyles();
    return (
        <Grid container style={{ padding: '10px 10px 0 12px' }}>

            <Button
                startIcon={!selectionChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px',
                    // paddingLeft: 0
                }}
                onClick={() => setSelectionChecked(false)}
            >
                <div className={classes.selDateLabel}>Show all records</div>
            </Button>
            <Button
                startIcon={selectionChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px',
                    // paddingLeft: 0
                }}
                onClick={() => setSelectionChecked(true)}
            >
                <div className={classes.selDateLabel}>Selection by:</div>
            </Button>
            <div >
            </div >
            
            <Grid container style={{ paddingLeft: 8 }}>
                <Grid item>
                    <div className={classes.projectTitle} style={{ paddingTop: 0 }}>Project No.</div>
                    <SelectComponent
                        allText={'All project'}
                        disabled={!selectionChecked}
                        multiple={true}
                        value={project}
                        onChange={(v) => { setProject(v) }}
                        options={projectList}
                        valueField={'projectId'}
                        nameField={'refNum'}
                        style={{
                            width: 160,
                        }}
                    />
                    <div className={classes.projectTitle}>Self-Service No.</div>
                    <SelectComponent
                        allText={'All Self-Service'}
                        multiple={true}
                        value={selfService}
                        onChange={(v) => { setSelfService(v) }}
                        options={selfServiceList}
                        valueField={'projectId'}
                        nameField={'refNum'}
                        disabled={!selectionChecked}
                        style={{
                            width: 160,
                        }}
                    />
                </Grid>
            </Grid>
            

        </Grid>
    );
}

export default BookingHistoryViewLeft;