import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import React from 'react';
import axios from '../../CustomAxios';
import { UserContext } from '../../hooks/UserContext';


export default function TimeOutDialog(props) {
    const { setUser } = React.useContext(UserContext);
    let history = useHistory();

    const doLogout = () => {
        localStorage.clear();
        axios.defaults.headers.common['Authorization'] = "";
        setUser(null);
        history.push('/Login');
    }

    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="form-dialog-title"
        >
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    Your Session has been timeout. Please{' '}
                    <Link href="#" color="primary" underline="always"
                        onClick={() => {doLogout()}}
                    >
                    click here
                    </Link>
                    {' '}to re-login.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}
