import React from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { EditorState,convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { makeStyles } from '@material-ui/core/styles';
import htmlToDraft from 'html-to-draftjs';
const useStyles = makeStyles((theme) => ({
    edit: {
        '& span': {
            backgroundColor: 'rgba(255,255,255,0.01) !important',
      },
        '& div': {
            marginTop: 3,
            // marginBottom: 3,
      }
    },
  }));
export default function ReportDraftEditor(props) {
    const classes = useStyles();
    const { value, onChange, isError, disabled, placeholder, height=120, reload } = props;

    const [state, setState] = React.useState({
        editorState: EditorState.createEmpty(),
    })
    const editorStateRef = React.useRef()
    const { editorState } = state;
    const [isFirst, setIsFirst] = React.useState(true)
    
    const handleSetState = (newState) => {
        setState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }

    React.useEffect(() => {
        if (isFirst && value) {
            setData(value)
            setIsFirst(false)
        }
    }, [value, reload])
    React.useEffect(() => {
        const v = (value == null||value == '') ? "<p></p>": value
        setData(v)
    }, [reload])

    const onEditorStateChange = (newEditorState) => {
        const result = newEditorState.toJS();

        if (result.currentContent.blockMap[result.selection.focusKey].text.length <= 10) {
            editorStateRef.current = newEditorState
        }
        handleSetState({ editorState: newEditorState });
        onChange(getData(newEditorState))
        // 
    };

    const getData = (newEditorState)  => {
        const newText = draftToHtml(convertToRaw(newEditorState.getCurrentContent())).replaceAll("color: rgba(0,0,0,0.87);background-color: rgba(255,255,255,0.01);","");
        return newText ? newText.replaceAll('<p></p>\n', '') : ''
    }

    const handleOriginData = (data) => {
        //Compatible with older editors
        return data.replace('<u>', '<ins>').replace('</u>', '</ins>')
        .replace('<i>', '<em>').replace('</i>', '</em>')
        .replace('<s>', '<del>').replace('</s>', '</del>')
    }

    const setData = (data) => {
        if (data) {
            const formatData = handleOriginData(data);
            const contentBlock = htmlToDraft(formatData);
            const { contentBlocks, entityMap } = contentBlock;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const newEditorState = EditorState.createWithContent(contentState);
            
            handleSetState({ editorState: newEditorState });
            
        }
    }
    // useImperativeHandle(ref, () => ({

    //     setData(data) {
    //         setData(data);
    //     },
    //     getData() {
    //         return getData()
    //     }

    // }));
    
    const editorStyle = {
        border: isError? '1px solid #ff0000' :'1px solid #F1F1F1',
        overflowY: 'auto',
        padding: '0 10px 0',
        height: height,
        backgroundColor: 'rgba(255,255,255,0)',
        '& div': {
            backgroundColor: 'rgba(255,255,255,0)',
        }
    }
    
    const toolbarStyle = {
        position: 'absolute',
        left: '0',
        bottom: height,
        zIndex: 99
    }
    const wrapperStyle = {
        position: 'relative',
        width: '100%'
    }
    const handleBlur = (event, newEditorState) => {
        console.log(event)
        onChange(getData(newEditorState))
        
    }
    return (
        <div className={classes.edit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            {disabled ? <div style={{...editorStyle,wordBreak: 'break-all', backgroundColor: '#e2e2e2',lineHeight: 1.2}} dangerouslySetInnerHTML={{__html: value}}></div>: <Editor
                // initialEditorState={editorState}
                placeholder={placeholder}
                toolbarStyle={toolbarStyle}
                editorStyle={editorStyle}
                wrapperStyle={wrapperStyle}
                editorState={editorState}
                toolbarOnFocus
                onEditorStateChange={onEditorStateChange}
                onBlur={handleBlur}
                // dis
                toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'history', 'link'],
                }}
                
            />}
            {/* <Grid container className={classes.textareaTips}>{ props.children}</Grid> */}
        </div>
    )
}
