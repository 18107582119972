import { useStyles } from 'Styles';
import PickersDay from '@material-ui/lab/PickersDay';
import StaticDatePicker from '@material-ui/lab/StaticDatePicker';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import isSameDay from 'date-fns/isSameDay';

export default function HadclCalendar(props) {
    const classes = useStyles();

    const customRenderDay = (date, _selectedDates, PickersDayComponentProps) => {
        if (_selectedDates && isSameDay(date, _selectedDates)) {
            return (
                <PickersDay
                    {...PickersDayComponentProps}
                    disableMargin
                    sx={{
                        color: "red"
                    }}
                />
            );
        }

        if (props.selectedDays && props.selectedDays.some(red => isSameDay(red, date))) {

            return (
                <PickersDay
                    {...PickersDayComponentProps}
                    disableMargin
                    sx={{
                        borderRadius: 0,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.common.white,
                        '&:hover, &:focus': {
                            backgroundColor: (theme) => theme.palette.primary.dark,
                        }
                    }}
                />
            );
        }
        
        if (props.redDays && props.redDays.some(red => isSameDay(red,date))) {
            return (
                <PickersDay
                    {...PickersDayComponentProps}                    
                    disableMargin
                    sx={{
                        color: "red"
                    }}

                />
            );
        }

        return <PickersDay disableMargin {...PickersDayComponentProps} />;
    };

    return (
        <Paper variant="outlined">
            <StaticDatePicker
                className={classes.shorterCalendar}
                displayStaticWrapperAs="desktop"
                openTo="date"
                renderDay={customRenderDay}                
                renderInput={(params) => <TextField {...params} variant="standard" />}
                {...props}
                
            />
        </Paper>
    );
}