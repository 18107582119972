import { Helmet } from 'react-helmet';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Login.page';
import ResetPassword from './components/ResetPassword.page';
import './custom.css';
import useFindUser from './hooks/UseFindUser';
import { UserContext } from './hooks/UserContext';
import PrivateRoute from './PrivateRoute';
import DynamicPrivateRoutes from './DynamicPrivateRoutes';
import { MsgProvider } from './hooks/MsgProvider';
import ModalProvider from './hooks/ModalProvider';
import './styles/text-security/text-security-disc.css';
import './Global.css';
import { DirtyCheckProvider } from './hooks/DirtyCheckProvider';

const TITLE = 'Hospital Authority Data Sharing Portal';

export default function App() {

    const { user, setUser, isLoading } = useFindUser();

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DirtyCheckProvider>
                    <ModalProvider>
                        <MsgProvider>
                            <Router>
                                <UserContext.Provider value={{ user, setUser, isLoading }}>
                                    <Switch>
                                        <Route path='/login' component={Login} />
                                        <Route path='/resetPassword/:token' component={ResetPassword} />
                                        <PrivateRoute>
                                            <DynamicPrivateRoutes />
                                        </PrivateRoute>
                                    </Switch>
                                </UserContext.Provider>
                            </Router>
                        </MsgProvider>
                    </ModalProvider>
                </DirtyCheckProvider>
            </LocalizationProvider>
        </>
    );
}

