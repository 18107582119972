
import Paper from '@material-ui/core/Paper';

import { useStyles } from "./styles";
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import axios from '../../CustomAxios';
import ScannerDialog from './Scanner.dialog';
import React from 'react';

import StartOfToday from 'date-fns/startOfToday';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AttendanceGridTable from './AttendanceGridTable'
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { UserContext } from 'hooks/UserContext';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import DatePicker from '@material-ui/lab/DatePicker';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { useMsg } from 'hooks/MsgProvider';
import IconButton from '@material-ui/core/IconButton';
import ScannerIcon from 'images/QR_scanner_icon.png';
import SignInView from './signInView';
import SystemMessageDialog from '../common/SystemMessageDialog';
import useSysParam from 'hooks/UseSysParam';
import CloseIcon from '@material-ui/icons/Close';
import CustomTooltip from 'components/common/CustomTooltip';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
export default function Attendance(props) {
    const history = useHistory();
    const [selectedDate, setSelectedDate] = React.useState(StartOfToday);
    const [nextPath, setNextPath] = React.useState(null);
    const [scannerOpen, setScannerOpen] = React.useState(false);
    const [signInOpen, setSignInOpen] = React.useState(false);
    const [outstandingOpen, setOutstandingOpen] = React.useState(false);
    const [outstandingList, setOutstandingList] = React.useState([]);
    const [signInRes, setSignInRes] = React.useState(null);
    const { setUser } = React.useContext(UserContext);
    const gridTableRef = React.useRef(null);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [projectList, setProjectList] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [formula, setFormula] = React.useState('');
    const [dateOpen, setDateOpen] = React.useState(false);
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const projectListRef = React.useRef([]);
    const selectedDateRef = React.useRef(selectedDate);
    const [{
        rateState,
        bookSetupState
    }, setState] = React.useState({
        rateState: {},
        bookSetupState: {}
    });
    const classes = useStyles();
    const { setErrMsg } = useMsg();
    const closeSignViewTimer = React.useRef(null);
    const { paramIntValue: viewTimeout } = useSysParam('sign-view-timeout');

    const formulaMap = {
        labUtilizationRate: 'Lab Utilization Rate = <br />(min(No. of project A1 researcher attended, No. of capacity project A1 booked) + min(No. of project A2 researcher attended, No. of capacity project A2 booked) +…+ min(No. of project An researcher attended, No. of capacity project An booked) + No. of self-service researcher attended)/No. of seats available x 100%',
        totalAttendanceRate: 'Total Attendance Rate = <br />(min(No. of project A1 researcher attended, No. of capacity project A1 booked) + min(No. of project A2 researcher attended, No. of capacity project A2 booked) +…+ min(No. of project An researcher attended, No. of capacity project An booked) + No. of self-service researcher attended)/Total No. of capacity booked x 100%',
        detailAttendanceRateForProject: 'For Project = <br /> (min(No. of project A1 researcher attended, No. of capacity project A1 booked) + min(No. of project A2 researcher attended, No. of capacity project A2 booked) +…+ min(No. of project An researcher attended, No. of capacity project An booked))/Total No. of capacity project booked x 100%',
        detailAttendanceRateForSelfService: 'For Self-service = <br />No. of self-service researcher attended / <br />Total No. of capacity self-service booked x 100%',
    }

    const uLocation = useLocation();
    React.useEffect(() => {
        if (uLocation.state && uLocation.state.date)
            setSelectedDate(new Date(uLocation.state.date));
    }, [uLocation.state])

    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {
            history.push(nextPath);
        }
    }, [history, isDirty, nextPath])
    React.useEffect(() => {
        fetchDashboardBookSetupState()
        fetchDashboardRate()
    }, [selectedDate])

    React.useEffect(() => {
        axios.get('/attendance/get-outstanding-singout').then(res => {
            if (res?.data && res?.data.length > 0) {
                setOutstandingOpen(true)
                setOutstandingList(res?.data || [])
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])



    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (location && location.pathname === '/attendance-management')
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {

                setOpenDirtyCheckDialog(false);
                // submitClick()
                gridTableRef.current.submitClick()
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);
                }
                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>The record(s) is(are) not yet saved. </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }

    const handleChange = (newValue) => {
        setSelectedDate(newValue)
        selectedDateRef.current = newValue

    }

    const handleOpenDate = () => {

        const func = () => {
            setDateOpen(true)
        }
        if (isDirty) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    func()
                },
            })
            return;
        }
        func()
    }
    const handleCloseDate = () => {
        setDateOpen(false)
    }

    const handleScanOpen = () => {
        const func = () => {
            setScannerOpen(true)
            gridTableRef.current.editClose && gridTableRef.current.editClose()
        }
        if (isDirty) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    func()
                },
            })
            return;
        }
        func()
    }

    const scanSuccess = (res) => {
        setSignInOpen(true);
        setSignInRes(res);
        gridTableRef.current.refreshList()
        fetchDashboardRate();
        if (viewTimeout) {
            closeSignViewTimer.current && clearTimeout(closeSignViewTimer.current);
            closeSignViewTimer.current = setTimeout(() => {
                setSignInOpen(false);
            }, 1000 * 60 * viewTimeout)
        }
    }

    const updateAttendanceWork = (payload) => {
        if (signInRes.isSignIn) {

            axios.post('/attendance/update-attendance-work', payload).then(res => {

                setSignInOpen(false);
                setSignInRes(null)
                gridTableRef.current.refreshList()
            }).catch(err => {
                setErrMsg(err)
                if (err) {
                    console.log(err);
                }
            })
        } else {
            setSignInOpen(false);
            setSignInRes(null)
        }
    }
    const fetchData = () => {
        axios.get('/attendance/get-booked-projects', {
            params: {
                date: format(selectedDateRef.current, 'yyyy-MM-dd')
            }
        }).then(res => {
            setProjectList(res?.data || [])
            projectListRef.current = res?.data || []
        }).catch(err => {
            console.log(err);
        })
    }

    const fetchDashboardBookSetupState = () => {
        axios.get('/bookSetup', {
            params: {
                startDate: format(selectedDateRef.current, 'yyyy-MM-dd'),
                endDate: format(selectedDateRef.current, 'yyyy-MM-dd')
            }
        }).then(res => {
            setState((state) => ({ ...state, bookSetupState: res?.data[0] || {} }))
        }).catch(err => {
            console.log(err);
        })
    }
    const fetchDashboardRate = () => {
        axios.get('/attendance/get-attendance-rate', {
            params: {
                date: format(selectedDateRef.current, 'yyyy-MM-dd')
            }
        }).then(res => {
            setState((state) => ({ ...state, rateState: res?.data || {} }))
        }).catch(err => {
            console.log(err);
        })
    }


    const handlePopoverClose = () => {
        setAnchorEl(null);
        setFormula('')
    };
    const isProjectChecked = bookSetupState?.available?.includes('COL_RESEARCHER')
    const isSelfServiceChecked = bookSetupState?.available?.includes('SELF_RESEARCHER')

    const getValue = (value) => {
        return value || 0;
    }

    const exportReport = async (type) => {
        let exportApi = 'export-monthly-report';
        if (type === 1)
            exportApi = 'export-raw-data';
        const response = await axios.get(`/attendance/${exportApi}`,
            { responseType: "blob", params: { month: format(selectedDateRef.current, 'yyyy-MM-dd'), date: format(selectedDateRef.current, 'yyyy-MM-dd') } })
        let fileStr = response.headers['content-disposition'];
        fileStr = fileStr.split(';')[1];
        fileStr = fileStr.split('=')[1];
        if (response.data) {
            let fileName = fileStr.replace(/\"/g, '');
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(response.data, fileName)
            } else {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.download = fileName;
                document.body.appendChild(link);
                var evt = document.createEvent("MouseEvents");
                evt.initEvent("click", false, false);
                link.dispatchEvent(evt);
                document.body.removeChild(link);
            }
        }
    }

    return (
        <Grid container style={{ padding: '15px 20px' }}>
            <Grid container>
                <Grid item xs={'auto'}>
                    <Typography variant="h4" gutterBottom xs={12}>
                        Attendance Management
                    </Typography>
                </Grid>
                <Grid item xs style={{ marginLeft: 10, marginTop: '8px' }}>
                    <Button className={`${classes.exportButton}`} onClick={() => exportReport(0)}>Export monthly report</Button>
                    <Button style={{ marginLeft: 10 }} className={classes.exportButton} onClick={() => exportReport(1)}>Export raw data</Button>
                </Grid>
                <Grid item xs={'auto'}>
                    <IconButton onClick={() => { handleScanOpen() }} style={{ padding: 5 }}>
                        <img src={ScannerIcon} width={30} />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.attendanceMainTop}>
                <Grid container item xs={3} className={classes.attendanceMainTopLeft}>
                    <Paper className={classes.paper} style={{ padding: 10 }}>
                        <Grid container alignItems={'center'} justifyContent={'space-between'} className={classes.summaryTitle}>
                            <Grid item>Booking Summary</Grid>
                            <Grid item  >
                                <DatePicker
                                    value={selectedDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handleChange(newValue)
                                        }
                                    }}
                                    views={['month', 'year', 'date']}
                                    open={dateOpen}

                                    onOpen={handleOpenDate}
                                    onClose={handleCloseDate}
                                    style={{}}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "Select date";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={false} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid container className={classes.projectTitle}>Project</Grid>
                            <Grid container className={classes.projectContent} alignContent='baseline'>
                                {projectList.filter(item => item.userTypeCode === 'COL_RESEARCHER').map((item, index) => <Grid key={index} container justifyContent={'space-between'} style={{ paddingRight: 10 }}>
                                    <Grid item style={{ flex: 1, overflow: 'hidden' }}>{item.refNum}</Grid><Grid item style={{ paddingLeft: 10 }}>{item.noOfBooked}</Grid></Grid>)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid container className={classes.selfServiceTitle}>Self-service</Grid>
                            <Grid container className={classes.selfServiceContent} alignContent='baseline'>
                                {projectList.filter(item => item.userTypeCode === 'SELF_RESEARCHER').map((item, index) => <Grid key={index} container>{item.refNum}</Grid>)}
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
                <Grid container item xs={9} spacing={2} className={classes.attendanceMainTopRight}>
                    <Grid container item spacing={2} >
                        <Grid item container style={{ width: '20%', textAlign: 'center' }}>
                            <Paper className={classes.paper} style={{ height: 200, padding: 0 }} >
                                <Grid justifyContent={'center'} alignContent='center' container className={classes.totalBookedTitle}>Total Booked Capacity</Grid>
                                <Grid container className={classes.num} justifyContent={'center'} alignContent='center'>
                                    {getValue(rateState?.bookedCapacity)}
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item container style={{ width: '20%', textAlign: 'center' }}>
                            <Paper className={classes.paper} style={{ height: 200, padding: 0 }}>
                                <Grid justifyContent={'center'} container className={classes.seatsTitle} alignContent='center'>Currently Occupied Seats</Grid>
                                <Grid container className={classes.num} justifyContent={'center'} alignContent='center'>
                                    {getValue(rateState?.occupiedSeats)}
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item container style={{ width: '20%', textAlign: 'center' }}>
                            <Paper className={classes.paper} style={{ height: 200, padding: 0 }} >
                                <Grid justifyContent={'center'} container className={classes.labUtilizationTitle} alignContent='center'>Lab Utilization Rate</Grid>
                                <Grid container className={classes.num} justifyContent={'center'} alignContent='center'>
                                    <CustomTooltip title={formulaMap['labUtilizationRate']}><span>{getValue(rateState?.labUtilizationRate)}%</span></CustomTooltip>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item container style={{ width: '20%', textAlign: 'center' }}>
                            <Paper className={classes.paper} style={{ height: 200, padding: 0 }} >
                                <Grid justifyContent={'center'} container className={classes.totalAttendanceTitle} alignContent='center'>Total Attendance Rate</Grid>
                                <Grid container className={classes.num} justifyContent={'center'} alignContent='center'>
                                    <CustomTooltip title={formulaMap['totalAttendanceRate']}><span>{getValue(rateState?.totalAttendanceRate)}%</span></CustomTooltip>

                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item container style={{ width: '20%', textAlign: 'center' }}>
                            <Paper className={classes.paper} style={{ height: 200, padding: 0 }}>
                                <Grid justifyContent={'center'} container className={classes.detailedAttendanceTitle} alignContent='center'>Detailed Attendance Rate</Grid>
                                <Grid container className={classes.num} justifyContent={'center'} alignContent='center'>
                                    <Grid container justifyContent={'center'} style={{ fontSize: 13 }}>Project</Grid>
                                    <Grid style={{ lineHeight: 1 }} container justifyContent={'center'}>
                                        <CustomTooltip title={formulaMap['detailAttendanceRateForProject']}><span >{getValue(rateState?.projectAttendanceRate)}%</span></CustomTooltip>
                                    </Grid>
                                    <Grid container style={{ width: '90%', height: 1, backgroundColor: '#979797', marginTop: 8, marginBottom: 8 }}></Grid>
                                    <Grid container justifyContent={'center'} style={{ fontSize: 13 }}>Self-service</Grid>
                                    <Grid style={{ lineHeight: 1 }} container justifyContent={'center'}>
                                        <CustomTooltip title={formulaMap['detailAttendanceRateForSelfService']}><span >{getValue(rateState?.selfServiceAttendanceRate)}%</span></CustomTooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item style={{ height: 140, paddingTop: 5 }}>
                        <Paper className={classes.paper} style={{ height: 144 }}>
                            <Grid container>
                                <Grid container item xs={4} alignContent={'baseline'}>
                                    <Grid container style={{ paddingRight: 20, paddingBottom: 18 }}>
                                        <Grid container justifyContent={'space-between'} style={{ paddingBottom: 8, borderBottom: '1px solid #cccccc' }}>
                                            <Grid item>Lab capacity:</Grid>
                                            <Grid item className={classes.labCapacity}>{getValue(bookSetupState?.capacity)}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid container style={{ marginBottom: 5 }}>Available for:</Grid>
                                        <Grid container item style={{ fontFamily: 'Quicksand-SemiBold' }}>
                                            {isProjectChecked && <Grid container item alignItems={'center'} style={{ width: 110, color: '#000' }}>
                                                <CheckCircleIcon />
                                                <div style={{ paddingLeft: 5 }}>Project</div>
                                            </Grid>}
                                            {isSelfServiceChecked && <Grid container item alignItems={'center'} style={{ width: 110, color: '#000' }}>
                                                <CheckCircleIcon />
                                                <div style={{ paddingLeft: 5 }}>Self-service</div>
                                            </Grid>}

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={8} className={classes.remarks} alignContent='baseline' flexDirection={'column'}>
                                    <Grid className={classes.remarkTitle}>Remarks:</Grid>
                                    <Grid container style={{ overflowY: 'auto', flex: 1 }}>
                                        <div style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: bookSetupState?.remarks || '' }}></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            <AttendanceGridTable
                projectList={projectList}
                ref={gridTableRef}
                isDirty={isDirty}
                selectedDate={selectedDate}
                handleOpenDirtyCheck={handleOpenDirtyCheck}
                setIsDirty={setIsDirty}
                projectListRef={projectListRef}
                fetchData={fetchData}
                fetchDashboardRate={fetchDashboardRate}
            />
            {scannerOpen && <ScannerDialog signInOpen={signInOpen} setErrorMsg={setErrorMsg} scanSuccess={scanSuccess} open={scannerOpen} onClose={() => setScannerOpen(false)} />}
            {signInOpen && <SignInView open={signInOpen} userInfo={signInRes} onClose={updateAttendanceWork} />}
            {
                errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                    <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
                </SystemMessageDialog>
            }
            {
                anchorEl && <Popover
                    open={true}
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    // onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Grid id="scanner-dialog-title"
                        style={{ textAlign: 'center', padding: '15px', position: 'relative' }}>
                        <IconButton aria-label="close" style={{ position: 'absolute', top: 5, right: 5, padding: 5 }} onClick={handlePopoverClose}>
                            <CloseIcon style={{ fontSize: 22 }} size='small' />
                        </IconButton>
                    </Grid>
                    <Grid container style={{ width: 250, padding: '0 10px 10px' }}>
                        <div dangerouslySetInnerHTML={{ __html: formulaMap[formula] }} />
                    </Grid>
                </Popover>
            }
            {
                outstandingOpen && <SystemMessageDialog open={outstandingOpen} onClose={() => { setOutstandingOpen(false); setOutstandingList([]) }}>
                    <div>Please follow-up with below researchers with outstanding </div>
                    <div>sign-out records:</div>
                    <div style={{ maxHeight: 250, overflowY: 'auto' }}>
                        {outstandingList.map((item, index) => {
                            return <div key={index}>
                                {item.bookDate && `${format(new Date(item.bookDate), 'dd MMM yyyy')} - `}
                                {item.refNum}
                                {item.name && ` [${item.name}]`}
                            </div>
                        })}
                    </div>
                </SystemMessageDialog>
            }
        </Grid>
    )
}