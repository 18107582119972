import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import BookSetup from './components/BookSetup/BookSetup.page';
import UserManagement from './components/userManagement/userManagement.page';
import ChangePassword from './components/userManagement/changePassword.page';
import SelfServeRequest from './components/bookingRequest/selfServe.page';
import CollaborateRequest from './components/bookingRequest/collaborate.page';
import AttendanceManaagement from './components/attendance/Attendance.page';
import HomeLayout from './components/HomeLayout';
import PrivateRoute from './PrivateRoute';
import BookManagement from './components/BookManagement/BookManagement.page';
import ViewCapacity from './components/BookSetup/ViewCapacity.page';
import Homepage from './components/Homepage/Homepage.page';
import ManageBookings from 'components/manageBookings/ManageBookings.page';
import AccessManagement from 'components/manageBookings/AccessManagement.page';
import LoginHistory from './components/userManagement/loginHistory.page';
import ReportList from 'components/progressReports/researcher';
import AdminReportList from 'components/progressReports/admin';
import ReportDetail from 'components/progressReports/researcher/detail';
import AdminReportDetail from 'components/progressReports/admin/detail';
import ProjectManagement from 'components/projectManagement';
import ProjectDetail from 'components/projectManagement/detail';
import OutputComponent from 'components/output/researcher';
import OnlineInformation from 'components/onlineInformation/researcher/index';
import OutputManagement from 'components/output/admin';
import OnlineInformationManagement from 'components/onlineInformation/admin/index';

export default function DynamicPrivateRoutes(props) {
    const { user } = useContext(UserContext);

    //Routes to be implemented:
    //<PrivateRoute path='/self-service-book-request' component={BookingRequestSelfServe} />
    //<PrivateRoute path='/collaboration-book-request' component={BookingRequestCollaborate} />
    //<PrivateRoute path='/change-password' component={ChangePassword} />                                
    //<PrivateRoute path='/book-management' component={bookManagement} />
    //<PrivateRoute path='/attendance-management' component={Attendance} />     
    const components = [
        {
            ID: 10,
            component: ManageBookings,
            path: '/manage-bookings',
            functionCode: ['TM_MANAGE_BOOKINGS', 'MM_MANAGE_BOOKINGS', 'SM_MANAGE_BOOKINGS'],
            isSitReady: true
        },
        {
            ID: 11,
            component: AccessManagement,
            path: '/access-management',
            functionCode: ['TM_ACCESS_MANAGEMENT', 'MM_ACCESS_MANAGEMENT', 'SM_ACCESS_MANAGEMENT'],
            isSitReady: true
        },
        {
            ID: 1,
            component: Homepage,
            path: '/homepage',
            functionCode: 'TM_HOMEPAGE',
            isSitReady: true,
            isCommonMenu: true
        },
        // {
        //     ID: 2,
        //     component: BookSetup,
        //     path: '/book-setup',
        //     functionCode: 'SETUP',
        //     isSitReady: true
        // },
        {
            ID: 3,
            component: UserManagement,
            path: '/user-management',
            functionCode: ['TM_USER_MANAGEMENT', 'MM_USER_MANAGEMENT'],//'USER_MANAGEMENT',
            isSitReady: true
        },
        {
            ID: 4,
            component: ChangePassword,
            path: '/change-password',
            functionCode: ['CHANGE_PASSWORD', 'TM_CHANGE_PASSWORD'],
            isSitReady: true
        },
        // {
        //     ID: 5,
        //     component: SelfServeRequest,
        //     path: '/self-service-book-request',
        //     functionCode: 'ACCESS_REQUEST_SELFSERVICE',
        //     isSitReady: true
        // },
        // {
        //     ID: 6,
        //     component: CollaborateRequest,
        //     path: '/collaboration-book-request',
        //     functionCode: 'ACCESS_REQUEST_PROJECT',
        //     isSitReady: true
        // },
        {
            ID: 7,
            component: AttendanceManaagement,
            path: '/attendance-management',
            functionCode: ['TM_ATTENDANCE_MANAGEMENT', 'MM_ATTENDANCE_MANAGEMENT'],
            isSitReady: true
        },
        // {
        //     ID: 8,
        //     component: BookManagement,
        //     path: '/book-management',
        //     functionCode: 'ACCESS_MANAGEMENT',
        //     isSitReady: true
        // },
        // {
        //     ID: 9,
        //     component: ViewCapacity,
        //     path: '/book-setup/review/:selectedDate',
        //     functionCode: 'SETUP',
        //     isSitReady: true
        // },
        {
            ID: 12,
            component: LoginHistory,
            path: '/login-history',
            functionCode: 'MM_LOGIN_HISTORY_MANAGEMENT',
            isSitReady: true
        },
        {
            ID: 13,
            component: ReportList,
            path: '/progress-reports',
            functionCode: ['TM_PROGRESS_REPORT', 'MM_PROGRESS_REPORT', 'SM_PROGRESS_REPORT'],
            isSitReady: true
        },
        {
            ID: 14,
            component: ReportDetail,
            path: '/progress-reports/:id',
            functionCode: ['TM_PROGRESS_REPORT', 'MM_PROGRESS_REPORT', 'SM_PROGRESS_REPORT'],
            isSitReady: true
        },
        {
            ID: 15,
            component: AdminReportList,
            path: '/progress-reports-management',
            functionCode: ['TM_PROGRESS_REPORT_ADM', 'MM_PROGRESS_REPORT_ADM', 'SM_PROGRESS_REPORT_ADM'],
            isSitReady: true
        },
        {
            ID: 14,
            component: AdminReportDetail,
            path: '/progress-reports-management/:id',
            functionCode: ['TM_PROGRESS_REPORT_ADM', 'MM_PROGRESS_REPORT_ADM', 'SM_PROGRESS_REPORT_ADM'],
            isSitReady: true
        },
        {
            ID: 15,
            component: ProjectManagement,
            path: '/project-management',
            functionCode: ['TM_PROJECT_MANAGEMENT', 'MM_PROJECT_MANAGEMENT', 'SM_PROJECT_MANAGEMENT'],
            isSitReady: true
        },
        {
            ID: 16,
            component: ProjectDetail,
            path: '/project-management/:id',
            functionCode: ['TM_PROJECT_MANAGEMENT', 'MM_PROJECT_MANAGEMENT', 'SM_PROJECT_MANAGEMENT'],
            isSitReady: true
        },
        {
            ID: 17,
            component: OutputComponent,
            path: '/output-request',
            functionCode: ['TM_OUTPUT_REQUEST', 'MM_OUTPUT_REQUEST', 'SM_OUTPUT_REQUEST'],
            isSitReady: true
        },
        {
            ID: 18,
            component: OutputManagement,
            path: '/output-request-management',
            functionCode: ['TM_OUTPUT_REQUEST_MANAGEMENT', 'MM_OUTPUT_REQUEST_MANAGEMENT', 'SM_OUTPUT_REQUEST_MANAGEMENT'],
            isSitReady: true
        },
        {
            ID: 19,
            component: OnlineInformation,
            path: '/online-information',
            functionCode: ['TM_ONLINE_INFO', 'MM_ONLINE_INFO', 'SM_ONLINE_INFO'],
            isSitReady: true
        },
        {
            ID: 20,
            component: OnlineInformationManagement,
            path: '/online-information-management',
            functionCode: ['TM_ONLINE_INFO_MANAGEMENT', 'MM_ONLINE_INFO_MANAGEMENT', 'SM_ONLINE_INFO_MANAGEMENT'],
            isSitReady: true
        },
    ]

    const routes = () => {
        if (user) {

            //if (process.env.REACT_APP_APP_ENV === "local" || process.env.REACT_APP_APP_ENV === "asldev")

            return components.filter(c => {
                if (c.isCommonMenu) return true;
                const isArray = Array.isArray(c.functionCode);
                const tempUserArr = [...user.functions, ...user.menus.topMenus, ...user.menus.middleMenus, ...user.menus.siteMaps];
                if (isArray) {
                    return tempUserArr.some(f => c.functionCode.indexOf(f.code) > -1)
                } else {
                    return tempUserArr.some(f => f.code === c.functionCode)
                }

            });

        }

        return [];
    }

    return (
        <PrivateRoute>
            <HomeLayout>
                <Route exact path='/'
                    render={() => {
                        return (
                            <Redirect to={'/homepage'} />
                        )
                    }} />
                {routes().map((route) =>
                    <PrivateRoute key={route.ID} exact path={route.path} component={route.component} />
                )}
            </HomeLayout>
        </PrivateRoute>
    )
}