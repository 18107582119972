import React, { useCallback, useState } from 'react'
import MessageBar from '../components/common/MessageBar';
import { MsgContext } from './MsgContext';
const MsgProvider = props => {
    const [msg, setMsg] = useState();
    const unSetMsg = useCallback(() => {
        setMsg({
            open:false
        });
    }, [setMsg])

    const setSuccessMsg = useCallback((msgVal) => {
        setMsg({
            severity: "success",
            open:true,
            message:msgVal
        });
    }, [setMsg])

    const setErrMsg = useCallback((msgVal) => {
        setMsg({
            severity: "error",
            open:true,
            message:msgVal
        });
    }, [setMsg])


    return (
        <MsgContext.Provider value={{ unSetMsg, setMsg, setSuccessMsg, setErrMsg }} {...props} >
            {props.children}
            {msg && <MessageBar msgConfig={msg} handleClose={unSetMsg} />}
        </MsgContext.Provider>
    )
}

const useMsg = () => {
    const context = React.useContext(MsgContext)
    if (context === undefined) {
        throw new Error('useMsg must be used within a MsgContext')
    }

    return context
}

export { MsgProvider, useMsg }