import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , endOfMonth,
    isAfter, isBefore
    , addMonths,
    addDays
    , subMonths
    , isSameMonth,
    isSameYear
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ReportDraftEditor from './ReportDraftEditor';
import DatePicker from '@material-ui/lab/DatePicker';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';
import isValid from 'date-fns/isValid';
import { useLocation, Prompt, useHistory } from 'react-router-dom';
import { getSearchParams } from 'components/common/utils';
import { dateMonthMap } from '../index'
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import LoadingScreen from 'components/common/LoadingScreen';
import useModal from "hooks/useModal";
import SelectComponent from 'components/common/CommonSelect';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { warning, confirmReportDeadline, confirmReportDeadline_8, success } from 'components/common/Constants';
const milestoneOption = [
    //     {
    //     name: 'Data Requirement / Clarification',
    //     value: 'DATA_RC'
    // },{
    //     name: 'Data Specification',
    //     value: 'DATA_SPE'
    // },{
    //     name: 'Data Extraction',
    //     value: 'DATA_EXT'
    // },{
    //     name: 'Data Release',
    //     value: 'DATA_REL'
    //     },
    {
        name: 'Data Pre-processing',
        value: 'DATA_PRE'
    }, {
        name: 'Descriptive Analysis',
        value: 'DESC_ANA'
    }, {
        name: 'Coding & Modelling',
        value: 'CODE_MOD'
    }, {
        name: 'Model Validation',
        value: 'MODE_VAL'
    }]
const milestoneOptionMap = {
    DATA_RC: 'Data Requirement / Clarification',
    DATA_SPE: 'Data Specification',
    DATA_EXT: 'Data Extraction',
    DATA_REL: 'Data Release',
    DATA_PRE: 'Data Pre-processing',
    DESC_ANA: 'Descriptive Analysis',
    CODE_MOD: 'Coding & Modelling',
    MODE_VAL: 'Model Validation',
}
export default function ReportDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const uLocation = useLocation()
    const [projectId, setProjectId] = React.useState('')
    const [yearMonth, setYearMonth] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('')
    const { setUser } = React.useContext(UserContext);
    const [error, setError] = React.useState({});
    const showModal = useModal();
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();

    const [state, setBaseState] = React.useState({ projectProgress: '', supplementaries: [] })
    const [nextPath, setNextPath] = React.useState(null);
    const timerRef = React.useRef(null);
    const isErrorRef = React.useRef(null);

    const draftEditorRef = React.useRef({})
    const draftEditorLengthErrorRef = React.useRef({ supplementaries: {} })
    React.useEffect(() => {
        // setHaveOutstandingReport(localStorage.getItem('haveOutstandingReport'))
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])
    const disableSubmit = state.statusDB == 'SUBMITTED' || state.statusDB == 'REVIEWED'
    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    React.useEffect(() => { //callback of leave Anyway
        let timer = null
        if (!isDirty && nextPath) {
            // timer = setTimeout(() => {
            //     goTop()
            // }, 200)
            history.push(nextPath);
        }
        return () => {
            // clearTimeout(timer)
        }
    }, [history, isDirty, nextPath])
    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/progress-reports/form')) {
            return true;
        }
        setDirtyCheckMsgObj({
            btn1Action: () => {

                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave('SAVED')
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setTimeout(() => {
                    goTop()
                }, 20)
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your progress reports is not yet saved/ submitted </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);

        return false;
    }

    const getFormDetail = (y, isCopy) => {
        setIsLoading(true);
        axios.get('/progressReport/get-progress-report-detail', { params: { projectId: projectId, yearMonth: format(new Date(y), 'yyyy-MM-dd') } }).then((res) => {
            if (res.data) {

                let data = res.data;
                res.data.estimatedAccessEndDate = res.data.estimatedAccessEndDate || res.data.projectEndDate;
                if (isCopy) {
                    data = {
                        ...state,
                        projectProgress: res.data.projectProgress,
                        projectMilestone: res.data.projectMilestone,
                        completeProgress: res.data.completeProgress,
                        plannedProgress: res.data.plannedProgress,
                        estimatedAccessEndDate: res.data.estimatedAccessEndDate,
                        estimatedStudyCompletionDate: res.data.estimatedStudyCompletionDate,
                        // outcomes: res.data.outcomes,
                        isPublication: res.data.isPublication,
                        isAiModelAlg: res.data.isAiModelAlg,
                        isProvision: res.data.isProvision,
                        specifyContent: res.data.specifyContent,
                        isPlanToIntegrate: res.data.isPlanToIntegrate,
                        targetSystems: res.data.targetSystems,
                        relatedCOCInvolved: res.data.relatedCOCInvolved,
                        relatedHAInvolved: res.data.relatedHAInvolved,
                        dataCommentResearcher: res.data.dataCommentResearcher,
                        platformCommentResearcher: res.data.platformCommentResearcher,
                        physicalCommentResearcher: res.data.physicalCommentResearcher,
                        meetingCommentResearcher: res.data.meetingCommentResearcher,
                        othersCommentResearcher: res.data.othersCommentResearcher,
                        supplementaries: res.data.supplementaries,
                    }
                    setIsDirty(true)

                }
                draftEditorRef.current = {
                    completeProgress: data.completeProgress,
                    plannedProgress: data.plannedProgress,
                    specifyContent: data.specifyContent,
                    targetSystems: data.targetSystems,
                    relatedCOCInvolved: data.relatedCOCInvolved,
                    relatedHAInvolved: data.relatedHAInvolved,
                    dataCommentResearcher: data.dataCommentResearcher,
                    dataCommentHADCL: data.dataCommentHADCL,
                    platformCommentResearcher: data.platformCommentResearcher,
                    platformCommentHADCL: data.platformCommentHADCL,
                    physicalCommentResearcher: data.physicalCommentResearcher,
                    physicalCommentHADCL: data.physicalCommentHADCL,
                    meetingCommentResearcher: data.meetingCommentResearcher,
                    othersCommentResearcher: data.othersCommentResearcher,
                    othersCommentHADCL: data.othersCommentHADCL,
                    supplementaries: data.supplementaries,
                };
                setBaseState(data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    React.useEffect(() => {
        if (projectId && yearMonth) {
            getFormDetail(yearMonth);
        }
    }, [yearMonth, projectId])

    React.useEffect(() => {
        const params = getSearchParams(uLocation.search);
        setProjectId(params.projectId)
        setYearMonth(params.yearMonth)
    }, [uLocation.search])
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const handleChangeInput = (e, fileName) => {
        let value = e.target.value;

        if (error[fileName]) {
            setError((s) => ({ ...s, [fileName]: false }))
        }
        handleSetBaseState({ [fileName]: value })
    }
    const handleChangeSelect = (value, fileName) => {
        if (error[fileName]) {
            setError((s) => ({ ...s, [fileName]: false }))
        }
        handleSetBaseState({ [fileName]: value })
    }

    const handleChangeTextArea = (value, fileName, maxLength) => {
        let cleanCode = value.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
        let isError = false
        if (error[fileName]) {
            isError = false
        }
        if (cleanCode.length > maxLength) {
            draftEditorLengthErrorRef.current[fileName] = maxLength
            // setError((s) => ({...s,[fileName]: false}))
        } else {
            draftEditorLengthErrorRef.current[fileName] = false
        }

        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
        // handleSetBaseState({[fileName]: value})
    }
    const formatDateValue = (date) => {
        if (typeof date === 'string') {
            return new Date(date)
        }
        return date
    }

    const handleChangeDate = (newValue, filedName) => {
        if (error[filedName]) {
            setError((s) => ({ ...s, [filedName]: false }))
        }
        handleSetBaseState({ [filedName]: newValue })

    }

    const handleChangeOutcomes = (value, filedName, index) => {
        const newOutcomes = [...state.outcomes]
        newOutcomes[index].isAchieved = false
        newOutcomes[index].isNotYetAchieved = false
        newOutcomes[index].isPartiallyAchieved = false
        newOutcomes[index][filedName] = value
        handleSetBaseState({ outcomes: newOutcomes })
    }

    const handleCheckSupplementaries = (payload, errorTemp, callback) => {
        let isError = false;
        payload.supplementaries.map((item, index) => {
            if (!errorTemp.supplementaries) {
                errorTemp.supplementaries = {}
            }
            if (!item.content) {
                if (errorTemp.supplementaries[index]) {
                    errorTemp.supplementaries[index]['content'] = true;
                } else {
                    errorTemp.supplementaries[index] = { content: true }
                }
                isError = true
            }
            if (!item.documentName) {
                if (errorTemp.supplementaries[index]) {
                    errorTemp.supplementaries[index]['documentName'] = true;
                } else {
                    errorTemp.supplementaries[index] = { 'documentName': true }
                }
                isError = true
            }
            return item
        })
        if (isError) {
            callback()
        }
    }
    const handleCheckSubmit = (payload, errorTemp, callback) => {
        let isError = false;
        if (!payload.projectProgress || payload.projectMilestone.length === 0) {
            errorTemp.projectMilestone = true;
            isError = true
        }
        if (!payload.completeProgress) {
            errorTemp.completeProgress = true;
            isError = true
        }
        if (!payload.plannedProgress) {
            errorTemp.plannedProgress = true;
            isError = true
        }
        if (!payload.estimatedAccessEndDate) {
            errorTemp.estimatedAccessEndDate = true;
            isError = true
        }
        if (!payload.estimatedStudyCompletionDate) {
            errorTemp.estimatedStudyCompletionDate = true;
            isError = true
        }
        if (payload.isPlanToIntegrate && !payload.targetSystems) {
            errorTemp.targetSystems = true;
            isError = true
        }
        payload.outcomes && payload.outcomes.map((item) => {
            if (!item.isAchieved && !item.isNotYetAchieved && !item.isPartiallyAchieved) {
                isError = true
                errorTemp.outcomes = true;
                // msg = warning.reportRichLength
            }

        })
        !isError && handleCheckSupplementaries(payload, errorTemp, () => {
            isError = true;
        })
        if (isError) {
            callback()
        }
    }
    const handleCheck = (payload, status) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = ""
        msg = warning.reportMandatory;
        if (!payload.projectProgress && payload.projectProgress !== 0) {
            errorTemp.projectProgress = true;
            isError = true
        }
        if (status != 'SAVED') {

            // console.log(draftEditorRef.current)
            handleCheckSubmit(payload, errorTemp, () => {
                isError = true;
            })


        }
        Object.keys(draftEditorRef.current).forEach((key) => {
            if ('supplementaries' === key) {
                Object.keys(draftEditorLengthErrorRef.current.supplementaries).forEach((k) => {
                    if (draftEditorLengthErrorRef.current.supplementaries[k]) {
                        errorTemp['supplementaries'][k] = { content: true };
                        isError = true
                        msg = warning.reportRichLength
                    }
                })
            } else if (draftEditorLengthErrorRef.current[key]) {
                errorTemp[key] = true;
                isError = true
                msg = warning.reportRichLength
            }
        })
        isErrorRef.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }

    const handleSave = (status) => {
        timerRef.current = setTimeout(() => {

            let payload = {
                ...state,
                estimatedAccessEndDate: format(startOfDay(new Date(state.estimatedAccessEndDate)), "yyyy-MM-dd"),
                estimatedStudyCompletionDate: state.estimatedStudyCompletionDate ? format(startOfDay(new Date(state.estimatedStudyCompletionDate)), "yyyy-MM-dd") : null,
                completeProgress: draftEditorRef.current.completeProgress,
                plannedProgress: draftEditorRef.current.plannedProgress,
                specifyContent: draftEditorRef.current.specifyContent,
                targetSystems: draftEditorRef.current.targetSystems,
                relatedCOCInvolved: draftEditorRef.current.relatedCOCInvolved,
                relatedHAInvolved: draftEditorRef.current.relatedHAInvolved,
                dataCommentResearcher: draftEditorRef.current.dataCommentResearcher,
                dataCommentHADCL: draftEditorRef.current.dataCommentHADCL,
                platformCommentResearcher: draftEditorRef.current.platformCommentResearcher,
                platformCommentHADCL: draftEditorRef.current.platformCommentHADCL,
                physicalCommentResearcher: draftEditorRef.current.physicalCommentResearcher,
                physicalCommentHADCL: draftEditorRef.current.physicalCommentHADCL,
                meetingCommentResearcher: draftEditorRef.current.meetingCommentResearcher,
                othersCommentResearcher: draftEditorRef.current.othersCommentResearcher,
                othersCommentHADCL: draftEditorRef.current.othersCommentHADCL,
                supplementaries: JSON.parse(JSON.stringify(draftEditorRef.current.supplementaries)),
            };
            if (handleCheck(payload, status)) {
                return
            }
            delete payload.dataCommentHADCL
            delete payload.platformCommentHADCL
            delete payload.physicalCommentHADCL
            delete payload.meetingCommentHADCL
            delete payload.othersCommentHADCL
            delete payload.remarks
            delete payload.issueRisks

            payload.completeProgress = window.htmlEncode(payload.completeProgress)
            payload.plannedProgress = window.htmlEncode(payload.plannedProgress)
            payload.specifyContent = window.htmlEncode(payload.specifyContent)
            payload.targetSystems = window.htmlEncode(payload.targetSystems)
            payload.relatedCOCInvolved = window.htmlEncode(payload.relatedCOCInvolved)
            payload.relatedHAInvolved = window.htmlEncode(payload.relatedHAInvolved)
            payload.dataCommentResearcher = window.htmlEncode(payload.dataCommentResearcher)
            payload.platformCommentResearcher = window.htmlEncode(payload.platformCommentResearcher)
            payload.physicalCommentResearcher = window.htmlEncode(payload.physicalCommentResearcher)
            payload.meetingCommentResearcher = window.htmlEncode(payload.meetingCommentResearcher)
            payload.othersCommentResearcher = window.htmlEncode(payload.othersCommentResearcher)
            payload.supplementaries.map((item) => {
                item.content = window.htmlEncode(item.content)
            })
            onSubmit(payload, status)
        }, 200)
    }
    const getReportStatus = (callback) => {
        callback()

    }

    const onSubmit = (payload, status) => {
        const requests = {
            ...payload,
            projectProgress: payload.projectProgress * 1,
            status
        }
        setIsLoading(true);
        axios.post('/progressReport/update-progress-report-res', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.Submitted
                    if (status === 'SAVED') {
                        if (isBefore(new Date(), addDays(new Date(state.deadline),1))) {
                            m = confirmReportDeadline(state.deadline)
                        } else if (isAfter(new Date(), new Date(state.deadline)) && (isBefore(new Date(), addDays(new Date(state.deadline), 9)))) {
                            m = confirmReportDeadline_8()
                        } else {
                            m = success.ReportSaveSubmitted
                        }

                    } else if (status == 'SUBMITTED') {
                        m = success.Submitted
                    }
                    setIsDirty(false)
                    setIsLoading(false);
                    getReportStatus(() => {
                        setErrorMsg(m)
                    })
                    draftEditorLengthErrorRef.current = {}
                    // draftEditorLengthErrorRef.current = {}

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
            });
    }

    const onChangeRadio = (value, fileName) => {
        handleSetBaseState({ [fileName]: value })
    }

    const handleAddAttendance = () => {
        if (disableSubmit) return
        const newObj = {
            "content": "",
            "documentName": "",
        }
        const newSupplementaries = [...state.supplementaries]
        newSupplementaries.push(newObj)
        handleSetBaseState({ supplementaries: newSupplementaries })
        draftEditorRef.current.supplementaries = newSupplementaries
    }

    const handleChangeSupplementaries = (value, filedName, index, maxLength) => {
        const newSupplementaries = [...state.supplementaries]
        let cleanCode = value.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
        let isError = false
        let supplementariesError = { ...(error.supplementaries || {}) }
        supplementariesError[index] = { ...(supplementariesError[index] || {}) }

        if (cleanCode.length > maxLength) {
            draftEditorLengthErrorRef.current['supplementaries'][index] = maxLength
            // setError((s) => ({...s,[fileName]: false}))
        } else {
            draftEditorLengthErrorRef.current['supplementaries'][index] = false
        }
        if (isError !== supplementariesError[index]['content']) {
            supplementariesError[index]['content'] = isError
            setError((s) => ({ ...s, ['supplementaries']: supplementariesError }))
        }
        newSupplementaries[index][filedName] = value
        !disableSubmit && setIsDirty(true)

        draftEditorRef.current.supplementaries = newSupplementaries
    }
    const handleChangeSupplementariesName = (value, filedName, index) => {
        const newSupplementaries = [...state.supplementaries]

        let isError = false
        let supplementariesError = { ...(error.supplementaries || {}) }
        supplementariesError[index] = { ...(supplementariesError[index] || {}) }


        if (isError !== supplementariesError[index]) {

            supplementariesError[index]['documentName'] = isError
            // console.log(supplementariesError,1111)
            setError((s) => ({ ...s, ['supplementaries']: supplementariesError }))
        }
        newSupplementaries[index][filedName] = value
        !disableSubmit && setIsDirty(true)
        // supplementariesLengthErrorRef
        handleSetBaseState({ supplementaries: newSupplementaries })
        draftEditorRef.current.supplementaries = newSupplementaries
    }

    const onDeleteSupplementaries = (index) => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    const newSupplementaries = [...state.supplementaries]
                    let isError = false
                    let supplementariesError = { ...(error.supplementaries || {}) }
                    if (error['supplementaries'] && supplementariesError[index]) {
                        // isError = false
                        draftEditorLengthErrorRef.current['supplementaries'][index] = false
                    }
                    if (isError !== supplementariesError[index]) {
                        supplementariesError[index] = {}
                        setError((s) => ({ ...s, ['supplementaries']: {} }))
                    }
                    newSupplementaries.splice(index, 1)
                    !disableSubmit && setIsDirty(true)
                    handleSetBaseState({ supplementaries: newSupplementaries })
                    draftEditorRef.current.supplementaries = newSupplementaries
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the document?</div>
            </SystemConfirmDialog>
        ));

    }
    const goTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    const handleCancel = () => {


        if (!isDirty) {
            goTop()
        }
        history.push(`/progress-reports`);

    }
    const getRateNumber = (e) => {
        e.target.value = (e.target.value * 1).toFixed()
        let v = e.target.value * 1
        if (v < 0) {
            e.target.value = 0
        }
        if (v > 100) {
            e.target.value = 100
        }
        return e
    }
    const handleCloseDialog = () => {
        if (!isDirty && !isErrorRef.current) {
            goTop()
            setTimeout(() => {
                handleCancel();
            }, 20)
        }
        setErrorMsg('')
    }

    const handleCopy = () => {
        if (!disableSubmit) {
            getFormDetail(subMonths(new Date(yearMonth), 1), true)
        }
    }
    const isShowCopy = () => {
        const firstMonth = (state.months || [])[0];
        if (disableSubmit) {
            return false
        }
        if (firstMonth) {
            if (isSameMonth(new Date(firstMonth), new Date(state.reportingMonth)) && isSameYear(new Date(firstMonth), new Date(state.reportingMonth))) {
                return false
            }
        }
        return true
    }
    const showErrorBorder = (isShow) => {
        return isShow ? { border: '1px solid #ff0000' } : {}
    }

    const showSubmitText = () => {
        return state.statusDB == 'REVISED' ? 'Revised & Submit' : 'Submit'
    }
    const showCancelButtonText = () => {
        return disableSubmit ? 'Back' : 'Cancel'
    }
    return (
        <Paper className={classes.paper} style={{ boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Progress Report
                    </Typography>
                </Grid>
                <Grid container justifyContent={'space-between'} alignItems='center' style={{ paddingBottom: 10 }}>
                    <Grid item>* Mandatory fields</Grid>
                    <Grid item>
                        {isShowCopy() && <Button style={{
                            backgroundColor: '#4285F4 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleCopy}>Copy from Last Report</Button>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.title}>
                        HADCL Progress Report
                    </Grid>
                    <Grid container>
                        <Grid container style={{ height: 40 }} alignContent='center'>
                            <Grid item container xs={6} style={{ paddingLeft: 10 }}>{state.reportingMonth ? `${dateMonthMap[format(new Date(state.reportingMonth), 'MMM')]} ${format(new Date(state.reportingMonth), 'yyyy')}` : ''}</Grid>
                            <Grid item container xs={6}>Completion Status: {state.status}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Cohort</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.cohort}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} className={classes.label}>Project Title</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent} style={{ wordBreak: 'break-all' }}>{state.projectTitle}</Grid>
                            <Grid item container xs={3} className={classes.label}>Reporting Month</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.reportingMonth && `${dateMonthMap[format(new Date(state.reportingMonth), 'MMM')]} ${format(new Date(state.reportingMonth), 'yyyy')}`}</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container alignContent='center' xs={3} className={classes.label}>Project Progress*</Grid>
                            <Grid item container alignItems='center' xs={3} className={classes.rightContent}>
                                <TextField type='number' disabled={disableSubmit} inputProps={{
                                    min: 0, max: 100,
                                }} InputProps={{
                                    style: {
                                        paddingLeft: 10,
                                        width: 100,
                                        ...(showErrorBorder(error.projectProgress))
                                    },
                                }} value={state.projectProgress || ''} onBlur={(e) => handleChangeInput(getRateNumber(e), 'projectProgress')} onChange={(e) => handleChangeInput(getRateNumber(e), 'projectProgress')} />
                                /100%
                            </Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project Milestone*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {/* milestoneOptionMap */}
                                {disableSubmit ? <div>{state.projectMilestone.map((k) => milestoneOptionMap[k]).join(', ')}</div> : <SelectComponent disabled={disableSubmit} displayEmpty={false} multiple={true} value={state.projectMilestone || []} onChange={(v) => { handleChangeSelect(v, 'projectMilestone') }} style={{
                                    textTransform: 'none',
                                    fontSize: '13px',
                                    width: '200px',
                                    ...(showErrorBorder(error.projectMilestone))
                                }} options={milestoneOption} />}

                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} className={classes.label}>Complete Progress This Month*</Grid>
                            <Grid item container xs={9} className={classes.rightContent}><ReportDraftEditor disabled={disableSubmit} placeholder={'≤1000 characters'} isError={error.completeProgress} value={state.completeProgress} onChange={(e) => handleChangeTextArea(e, 'completeProgress', 1000)} /></Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} className={classes.label}>Planned Progress For Next Month*</Grid>
                            <Grid item container xs={9} className={classes.rightContent}><ReportDraftEditor disabled={disableSubmit} placeholder={'≤1000 characters'} isError={error.plannedProgress} value={state.plannedProgress} onChange={(e) => handleChangeTextArea(e, 'plannedProgress', 1000)} /></Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Estimated Access End Date Of HADCL*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <DatePicker
                                    disabled={disableSubmit}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.estimatedAccessEndDate || '')}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handleChangeDate(newValue, 'estimatedAccessEndDate')
                                        }
                                    }}
                                    maxDate={state.projectEndDate}
                                    views={['year', 'month', 'date']}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.estimatedAccessEndDate} />
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Estimated Study Completion Date*</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <DatePicker
                                    disabled={disableSubmit}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.estimatedStudyCompletionDate || '')}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handleChangeDate(newValue, 'estimatedStudyCompletionDate')
                                        }
                                    }}
                                    views={['year', 'month', 'date']}

                                    style={{}}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.estimatedStudyCompletionDate} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Project Outcomes */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Project Outcomes
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={5} className={classes.projectTh} justifyContent='center' >Project Objectives/Deliverables (as stated in proposal)</Grid>
                        <Grid item container xs={7}>
                            <Grid item xs={4} className={classes.projectTh}>Achieved</Grid>
                            <Grid item xs={4} className={classes.projectTh}>Partially Achieved</Grid>
                            <Grid item xs={4} className={classes.projectTh} style={{ border: 0 }}>Not Yet Achieved</Grid>
                        </Grid>
                    </Grid>
                    {
                        state.outcomes && state.outcomes.map((item, index) => {
                            return <Grid key={index} container className={`${classes.projectTr} ${classes.lineBottom}`}>
                                <Grid item container xs={5} style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: '#f3f3f3', lineHeight: '32px' }} alignItems='baseline' className={classes.projectTd} >
                                    {index + 1}. &nbsp;
                                    <Grid item style={{ wordBreak: 'break-all', flex: 1 }}>{item.projectObj}&nbsp; *</Grid>

                                </Grid>
                                <Grid item container xs={7} style={{ ...(showErrorBorder(error.outcomes && !item.isAchieved && !item.isNotYetAchieved && !item.isPartiallyAchieved)) }}>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            disabled={disableSubmit}
                                            startIcon={checkIcon(item.isAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}
                                            onClick={() => handleChangeOutcomes(true, 'isAchieved', index)}

                                        >
                                        </Button>
                                    </Grid>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            disabled={disableSubmit}
                                            startIcon={checkIcon(item.isPartiallyAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}
                                            onClick={() => handleChangeOutcomes(true, 'isPartiallyAchieved', index)}

                                        >
                                        </Button>
                                    </Grid>
                                    <Grid justifyContent='center' container item xs={4} className={classes.projectTd}>
                                        <Button
                                            disabled={disableSubmit}
                                            startIcon={checkIcon(item.isNotYetAchieved)}
                                            // className={classes.button}
                                            style={{
                                                textTransform: 'none',
                                                lineHeight: '1.5',
                                                fontSize: '13px'
                                            }}
                                            onClick={() => handleChangeOutcomes(true, 'isNotYetAchieved', index)}

                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })
                    }


                </Grid>
                {/* Expected Deliverables Upon Completion */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Expected Deliverables Upon Completion
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >Publication Of Research Papers*</Grid>
                        <Grid item container xs={8}>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(state.isPublication)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    // disabled={project.length === 0 && selfService.length === 0}
                                    onClick={() => onChangeRadio(true, 'isPublication')}
                                >
                                    <div className={classes.buttonLabel}>Yes</div>
                                </Button>
                            </Grid>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(!state.isPublication)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    // disabled={project.length === 0 && selfService.length === 0}
                                    onClick={() => onChangeRadio(false, 'isPublication')}
                                // onClick={() => selectAvailableDateClick()}
                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >AI Model/Algorithm*</Grid>
                        <Grid item container xs={8}>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(state.isAiModelAlg)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => onChangeRadio(true, 'isAiModelAlg')}

                                >
                                    <div className={classes.buttonLabel}>Yes</div>
                                    {/* <div className={classes.selDateLabel}>Select all available dates</div> */}
                                </Button>
                            </Grid>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(!state.isAiModelAlg)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => onChangeRadio(false, 'isAiModelAlg')}

                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} alignItems='center' className={classes.projectTd} >Provision Of Image Labelling*</Grid>
                        <Grid item container xs={8}>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(state.isProvision)}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}

                                    onClick={() => onChangeRadio(true, 'isProvision')}
                                >
                                    <div className={classes.buttonLabel}>Yes</div>
                                    {/* <div className={classes.selDateLabel}>Select all available dates</div> */}
                                </Button>
                            </Grid>
                            <Grid justifyContent='center' container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(!state.isProvision)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px',
                                    }}
                                    onClick={() => onChangeRadio(false, 'isProvision')}

                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Others, please specify (e.g. abstract submission/ presentation to conferences)</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.specifyContent} value={state.specifyContent} onChange={(e) => handleChangeTextArea(e, 'specifyContent', 400)} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Plan For Integration */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Plan For Integration
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Plan to integrate HA clinical system*
                                <div>(if yes, please specify the target systems)</div>
                            </div>
                        </Grid>
                        <Grid item container xs={8}>
                            <Grid style={{ paddingLeft: 10 }} container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(state.isPlanToIntegrate)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => onChangeRadio(true, 'isPlanToIntegrate')}

                                >
                                    <div className={classes.buttonLabel}>Yes</div>
                                </Button>
                            </Grid>
                            <Grid style={{ paddingLeft: 10 }} container item xs={3} className={classes.projectTd}>
                                <Button
                                    disabled={disableSubmit}
                                    startIcon={checkIcon(!state.isPlanToIntegrate)}
                                    // className={classes.button}
                                    style={{
                                        textTransform: 'none',
                                        lineHeight: '1.5',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => onChangeRadio(false, 'isPlanToIntegrate')}

                                >
                                    <div className={classes.buttonLabel}>No</div>
                                </Button>
                            </Grid>
                            <Grid item container xs={12} className={classes.rightContent}>(Please specify the target systems: )</Grid>
                            <Grid item container xs={12} className={classes.rightContent}>
                                <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.targetSystems} value={state.targetSystems} onChange={(e) => handleChangeTextArea(e, 'targetSystems', 400)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Related COCs/CC/Cluster Involved</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.relatedCOCInvolved} value={state.relatedCOCInvolved} onChange={(e) => handleChangeTextArea(e, 'relatedCOCInvolved', 400)} />
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >Related HA Collaborators Involved</Grid>
                        <Grid item container xs={8} className={classes.rightContent}>
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.relatedHAInvolved} value={state.relatedHAInvolved} onChange={(e) => handleChangeTextArea(e, 'relatedHAInvolved', 400)} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Additional Requests And Comments */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Additional Requests And Comments
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' > </Grid>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Comments from Researcher</Grid>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' style={{ border: 0 }}>Reply from HADCL Office</Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Data
                                <Grid container>e.g. requirement and justification  </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.dataCommentResearcher} value={state.dataCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'dataCommentResearcher', 400)} />
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor placeholder={'≤400 characters'} disabled={true} isError={error.dataCommentHADCL} value={state.dataCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'dataCommentHADCL', 400)} />
                        </Grid>
                    </Grid>

                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Platform And Tool
                                <Grid container>e.g. hardware and software </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} value={state.platformCommentResearcher} isError={error.platformCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'platformCommentResearcher', 400)} />
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor placeholder={'≤400 characters'} disabled={true} isError={error.platformCommentHADCL} value={state.platformCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'platformCommentHADCL', 400)} />
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Physical Site And Administration Support
                                <Grid container>e.g. booking, access control </Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} value={state.physicalCommentResearcher} isError={error.physicalCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'physicalCommentResearcher', 400)} />
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor placeholder={'≤400 characters'} disabled={true} isError={error.physicalCommentHADCL} value={state.physicalCommentHADCL} onChange={(e) => handleChangeTextArea(e, 'physicalCommentHADCL', 400)} />
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Meeting With HADCL Office
                                <Grid container>Please specify purpose and tentative meeting schedule</Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.meetingCommentResearcher} value={state.meetingCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'meetingCommentResearcher', 400)} />
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor placeholder={'≤400 characters'} isError={error.meetingCommentHADCL} value={state.meetingCommentHADCL} disabled={true} onChange={(e) => handleChangeTextArea(e, 'meetingCommentHADCL', 400)} />
                        </Grid>
                    </Grid>
                    <Grid container className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid item container xs={4} style={{ paddingLeft: 10, backgroundColor: '#f3f3f3' }} className={classes.projectTd} >
                            <div>
                                Others
                                <Grid container>Please specify</Grid>
                            </div>
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤400 characters'} isError={error.othersCommentResearcher} value={state.othersCommentResearcher} onChange={(e) => handleChangeTextArea(e, 'othersCommentResearcher', 400)} />
                        </Grid>
                        <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd} >
                            <ReportDraftEditor placeholder={'≤400 characters'} isError={error.othersCommentHADCL} value={state.othersCommentHADCL} disabled={true} onChange={(e) => handleChangeTextArea(e, 'othersCommentHADCL', 400)} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Supplementary Information */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Supplementary Information
                    </Grid>
                    <Grid container style={{ padding: '5px 0' }}>
                        If you have any supplementary information or documents, please provide us the details or document links below.
                    </Grid>
                    <Grid container >
                        <Button style={{
                            ...(state.supplementaries.length >= 5 ? {
                                backgroundColor: '#dfdfdf ',
                                color: 'rgba(0, 0, 0, 0.26) ',
                            } : {
                                backgroundColor: '#15a9d2 ',
                                color: '#ffff '
                            }),
                            marginRight: 10,
                            textTransform: 'none'
                        }} disabled={state.supplementaries.length >= 5 || disableSubmit} className={classes.activeButton} onClick={handleAddAttendance}>+Add Document</Button>
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Document name</Grid>
                        <Grid item container xs={8} className={classes.projectTh} justifyContent='center' >{`Description & Link *`}</Grid>

                    </Grid>
                    {
                        state.supplementaries && state.supplementaries.map((item, index) => {
                            return <Grid container key={index}>
                                <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10, lineHeight: '28px', wordBreak: 'break-word', whiteSpace: 'break-spaces' }} className={classes.projectTd}  >
                                    {index + 1}.&nbsp;&nbsp;{disableSubmit ? item.documentName : <TextField inputProps={{ maxLength: 500 }} InputProps={{
                                        style: {
                                            paddingLeft: 10,
                                            // width: 100,
                                            ...(showErrorBorder(error.supplementaries && error.supplementaries[index] && error.supplementaries[index]['documentName']))
                                        },
                                    }} value={item.documentName || ''} onChange={(e) => handleChangeSupplementariesName(e.target.value, 'documentName', index)} />}
                                </Grid>
                                <Grid item container xs={8} style={{ paddingLeft: 10, paddingRight: 10, }} className={classes.projectTd} justifyContent='center' >
                                    <Grid item container style={{ flex: 1, position: 'relative', height: 120 }}>
                                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>

                                            <ReportDraftEditor disabled={disableSubmit} placeholder={'≤200 characters'} isError={error.supplementaries && error.supplementaries[index] && error.supplementaries[index]['content']} value={item.content} onChange={(e) => handleChangeSupplementaries(e, 'content', index, 200)} />
                                        </div>
                                    </Grid>
                                    <Grid item container style={{ width: 50 }} justifyContent='center' alignItems={'center'}>
                                        {!disableSubmit && <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => { onDeleteSupplementaries(index) }} />}
                                    </Grid>

                                </Grid>
                            </Grid>
                        })
                    }

                </Grid>
                {/* Remarks */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Comments from HADCL Office
                    </Grid>
                    <Grid container>
                        <ReportDraftEditor disabled={true} placeholder={'≤2000 characters'} value={state.remarks} />
                    </Grid>
                </Grid>
                <Grid container>
                    {!disableSubmit && state.statusDB !== 'REVISED' && <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave('SAVED')}>Save</Button>}
                    {!disableSubmit && <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave('SUBMITTED')}>{showSubmitText()}</Button>}
                    <Button style={{
                        backgroundColor: '#646464 ',
                        color: '#ffff ',
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={handleCancel}>{showCancelButtonText()}</Button>
                </Grid>
            </Grid>
            <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>

            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            <LoadingScreen open={isLoading} />

        </Paper>
    );
}
