import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Countdown from 'react-countdown';
import { confirmDialog } from './Constants'


export default function SessionExtendDialog(props) {
    const {getExpTime, onClose, onExtend, onOpenLogOut, open, title } = props;

    const handleExtend = () => {
        onExtend();
    }; 

    const handleOk = () => {
        onClose();
        onOpenLogOut();
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            handleOk();
            return <span> Expired </span>;
        } else {
            getExpTime();
            // Render a countdown
            return <span> {minutes}m {seconds}s</span>;
        }
    };

    return (
        <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogTitle id="alert-dialog-confirm"> {!!title ? title : confirmDialog.Extend} </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    Session Timeout in:
                        <Countdown
                        date={Date.now()}
                        renderer={renderer}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleExtend} color="primary">Extend</Button>
            </DialogActions>
        </Dialog>
    )
}
