import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const components = {
    MuiTypography: {
        styleOverrides: {
            root: {
                fontFamily: 'Quicksand-SemiBold',
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                fontFamily: 'Quicksand-SemiBold',
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                fontFamily: 'Quicksand-SemiBold',
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                fontFamily: 'Quicksand-SemiBold',
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                fontFamily: 'Quicksand-SemiBold',
            }
        }
    }
}


const index = {
    default: createMuiTheme({ ...defaultTheme, components }),
};

export default index;