import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
    addMonths
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , isAfter,
    isBefore
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    // , Dialog
    , DialogTitle
    , DialogContent
    , Popover
    , TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'CustomAxios';
import { useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import useAutoHozScroll from 'hooks/useAutoHozScroll';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import SmallCalendar from '../Calendar/SmallCalendar';
import StarIcon from '@material-ui/icons/Star';
import MorePopup from 'components/manageBookings/MorePopup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomTooltip from '../CustomTooltip';
const { Provider, Consumer } = React.createContext();

export const useStyles = makeStyles(theme => ({
    boldFont: {
        fontWeight: 'bold'
    },
    rejectedWrapper: {
        backgroundColor: '#e14631',
        padding: '0 4px',
    },
    // rejectedWrapper: {
    //     backgroundColor: '#e14631',
    //     width: '100%',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    eventWrapper: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    approvedWrapper: {
        backgroundColor: '#67c451',
        padding: '0 4px',
    },
    longValueClass: {
        width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            'display': 'none'
        },
        overflowX: 'auto',
        overflowY: 'none',
        scrollbarWidth: 'none',
    },
    // approvedWrapper: {
    //     backgroundColor: '#69C456',
    //     width: 'calc(100% - 25px)',
    //     padding: '0 4px',
    //     display: 'inline-block'
    // },
    approvedValueWrapper: {
        backgroundColor: '#67c451',
        display: 'inline-block',
        width: '25px',
        textAlign: 'center'
    },
    closeFont: {
        color: '#0064E7'
    },
    closeWrapper: {
        display: 'flex',
        backgroundColor: '#C9E0FF',
        width: '100%',
        // position: 'absolute',
        bottom: '0',
        textAlign: 'left',
        padding: '0 4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    moreWrapper: {
        position: 'absolute',
        right: 0,
        backgroundColor: '#C9E0FF',
        alignItems: 'center',
        height: '100%'
    },
    ellipsisFont: {
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    publicCloseDay: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        textAlign: 'left',
        backgroundColor: '#FEE2E2',
        padding: '0 4px',
        // overflow: 'hidden'
    },
    checkedBackground: {
        backgroundColor: '#FFEFE2'
    },
    invalidBackground: {
        backgroundColor: '#ffdbdb'
    },
    witheBackground: {
        backgroundColor: 'white'
    },
    eachNo: {
        '& input': {
            textAlign: 'center'
        }
    },
    dateFont: {
        fontSize: '13px !important'
    },
    remarkTitleFont: {
        color: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute !important',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    remarksContent: {
        backgroundColor: '#EEF5FF',
        color: '#0064E7',
        margin: 16,
        minWidth: '300px'
    },
    specQuicksand: {
        fontFamily: 'Quicksand-Medium',
        // fontSize: '13px !important'
    }
}));

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const RenderToolBar = (props) => {
    const [cAnchorEl, setCAnchorEl] = React.useState(null);
    const monthClick = (e) => {
        setCAnchorEl(e.currentTarget);
    }

    const closeSmallCalendar = () => {
        setCAnchorEl(null);
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { date, label } = props;
                    const setViewDate = calendarValue.setViewDate;
                    const isDirty = calendarValue.isDirty;
                    const resetLeftData = calendarValue.resetLeftData;
                    const handleOpenDirtyCheck = calendarValue.handleOpenDirtyCheck;
                    const isRelease = calendarValue.isRelease;
                    const toNextMonth = () => {
                        const handleNextMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), 1));
                            resetLeftData()
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleNextMonth })
                            return;
                        }
                        handleNextMonth();
                    }

                    const toLastMonth = () => {
                        const handleLastMonth = () => {
                            setViewDate(addMonths(startOfMonth(date), -1));
                            resetLeftData()
                        };
                        if (isDirty) {
                            handleOpenDirtyCheck({ leaveCallback: handleLastMonth })
                            return;
                        }
                        handleLastMonth();
                    }

                    const selectEnabled = (bookingDate) => {
                        return isRelease(bookingDate);
                    }
                    return (<>
                        <Grid container alignItems={'baseline'} style={{ height: 40 }}>
                            <Grid item>
                                <IconButton onClick={toLastMonth}>
                                    <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ minWidth: '125px', textAlign: 'center', cursor: 'pointer', height: 34, lineHeight: '33px', border: '1px solid #d2d2d2', borderRadius: 3 }} onClick={(e) => monthClick(e)}>
                                {label}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={toNextMonth} disabled={!selectEnabled(addMonths(startOfMonth(date), 1))}>
                                    <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Popover
                            open={Boolean(cAnchorEl)}
                            onClose={() => setCAnchorEl(null)}
                            anchorEl={cAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SmallCalendar anchorEl={cAnchorEl} date={date} isDirty={isDirty} close={closeSmallCalendar} handleOpenDirtyCheck={handleOpenDirtyCheck} setViewDate={setViewDate} resetLeftData={resetLeftData} />
                        </Popover>
                    </>);
                }
            }
        </Consumer >
    );
}

const renderHeader = (e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays, selectionChoose) => {
    const { label, date } = e;
    const isSunday = date.getDay() === 0;
    const style = {
        height: '25px'
    }
    return (
        <Grid style={style} container alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
            <Grid item style={{ width: '38px', textAlign: 'left' }}>
                {
                    isEdit && selectionChoose && weekRdoDisplays[date.getDay()].display &&
                    <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => setWeekChecked(date.getDay())}>
                        {
                            weekCheckedList[date.getDay()].checked ?
                                <CheckCircleIcon style={{ width: '20px', height: '20px' }} /> :
                                <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                        }
                    </IconButton>
                }
            </Grid>
            <Grid item className={`${isSunday && 'rbc-public-date'} ${classes.dateFont} ${classes.specQuicksand}`}>
                {label?.substring(0, 3).toUpperCase()}
            </Grid>
            <Grid item style={{ width: '38px' }}>

            </Grid>
        </Grid>
    )
}

const RenderDateHeader = (props) => {
    // console.log('ss', setSeAble)
    const classes = useStyles();

    const getDateItemColor = (dateItem) => {
        if (dateItem?.fullPercent >= 0.8) {
            return '#ED3A4E'
        }
        if (dateItem?.fullPercent >= 0.6) {
            return '#FFB448'
        }
        return '#50E4A7'

    }
    const getClassName = (isClose) => {
        return `${isClose ? 'rbc-public-date' : 'rbc-normal-date'} ${classes.boldFont} ${classes.dateFont}`
    }
    const enableClickMore = (isClose, isPublicHoliday, calendarValue, date, dateItem) => {
        const { isDirty, selectedDateList } = calendarValue;
        let isBooking = false;
        if (isPublicHoliday || isClose) {
            isBooking = !!calendarValue.bookingRes.find(item => isEqual(startOfDay(new Date(item.bookDate)), startOfDay(date)));
        }
        const isClick = calendarValue.isEditManageRequest && (!isDirty || isEqual(new Date(date), new Date(selectedDateList[0]?.date)))
        return isClick && ((!isClose && !isPublicHoliday) || isBooking) && !calendarValue.selectionChoose && dateItem
    }
    const clickMore = (isClose, isPublicHoliday, calendarValue, date, dateItem) => {
        if (!enableClickMore(isClose, isPublicHoliday, calendarValue, date, dateItem)) return
        calendarValue.editMore(date);
    }
    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { label, date } = props;
                    // console.log(props, calendarValue)
                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(date));
                    if (isOtherMonth)
                        return <></>;

                    const dateItem = calendarValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(date)));
                    const isPublicHoliday = dateItem?.remarks.toLowerCase().includes('public holiday');
                    const isClose = dateItem && !dateItem.available;
                    const todayBooking = calendarValue.todayHasBooking(date);
                    const isFull = dateItem && dateItem.quota >= dateItem.capacity && !todayBooking;
                    const style = {
                        height: '24px'
                    }
                    const selectedItem = calendarValue.selectedDateList.find(item => isEqual(startOfDay(new Date(item.date)), startOfDay(date)));

                    const cannotBook = !dateItem || isClose || isFull;

                    const isRelease = calendarValue.isRelease;
                    return (
                        <>
                            <Grid container style={style} alignItems={'center'} justifyItems={'center'} justifyContent={'space-between'}>
                                <Grid item style={{ width: '38px', textAlign: 'left' }}>
                                    {
                                        calendarValue.isEdit &&
                                        calendarValue.selectionChoose &&
                                        !cannotBook && isRelease() &&
                                        <IconButton color="primary" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => calendarValue.checkedDate(date)}>
                                            {
                                                selectedItem ? <CheckCircleIcon style={{ width: '20px', height: '20px' }} />
                                                    : <RadioButtonUncheckedOutlinedIcon style={{ width: '20px', height: '20px', color: '#999' }} />
                                            }
                                        </IconButton>
                                    }
                                </Grid>
                                {/* <CustomTooltip title={<Grid >
                                    <Grid >Release Date:</Grid>
                                    <Grid >{dateItem?.releaseTime && format(new Date(dateItem.releaseTime), 'dd MMM yyyy HH:mm')}</Grid>
                                </Grid>}>
                                    <Grid item style={enableClickMore(isClose, isPublicHoliday, calendarValue, date, dateItem) ? { width: 30, textAlign: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5', border: '2px solid #d2d2d2', borderTopColor: '#fbfbfb', height: 20, lineHeight: 1.3 } : {}} onClick={() => clickMore(isClose, isPublicHoliday, calendarValue, date, dateItem)} className={getClassName(isClose || isPublicHoliday)}>
                                        {Number(label)}
                                    </Grid>
                                </CustomTooltip> */}
                                <Grid item style={enableClickMore(isClose, isPublicHoliday, calendarValue, date, dateItem) ? { width: 30, textAlign: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5', border: '2px solid #d2d2d2', borderTopColor: '#fbfbfb', height: 20, lineHeight: 1.3 } : {}} onClick={() => clickMore(isClose, isPublicHoliday, calendarValue, date, dateItem)} className={getClassName(isClose || isPublicHoliday)}>
                                    {Number(label)}
                                </Grid>
                                <Grid item style={{ width: '38px' }}>
                                    {
                                        dateItem && !isClose ?
                                            (
                                                <div style={{ width: '6px', height: '6px', borderRadius: '50%', backgroundColor: getDateItemColor(dateItem), margin: '0 auto' }}></div>
                                            )
                                            : null
                                    }
                                </Grid>
                            </Grid>

                        </>
                    );
                }
            }
        </Consumer >
    )
}

const RenderRateCellWrapper = (props) => {
    const classes = useStyles();
    const remarkContainer = React.useRef(null);
    const remarksRef = React.useRef(null);

    const publicRemarkRef = React.useRef(null);
    const publicContainerRef = React.useRef(null);
    const dateContainerRef = React.useRef(null);

    const { setAutoScroll: setPubAutoScroll } = useAutoHozScroll(publicRemarkRef, true, 60);
    const { setAutoScroll: setDateAutoScroll } = useAutoHozScroll(remarksRef, true, 60);

    React.useEffect(() => {
        if (publicRemarkRef.current && publicContainerRef.current &&
            publicRemarkRef.current.clientWidth > publicContainerRef.current.clientWidth) {
            publicRemarkRef.current.classList.add(classes.longValueClass);
            setPubAutoScroll(true);
        }
    });

    React.useEffect(() => {
        if (remarksRef.current && dateContainerRef.current &&
            remarksRef.current.clientWidth > dateContainerRef.current.clientWidth) {
            remarksRef.current.classList.add(classes.longValueClass);
            setDateAutoScroll(true);
        }
    });


    const getCbg = (calendarValue, dateItem, value, isChecked) => {
        const todayBooking = calendarValue.todayHasBooking(value);
        const isClose = dateItem && !dateItem.available;
        const isFull = dateItem && dateItem.quota >= dateItem.capacity && !todayBooking;
        const cannotBook = !dateItem || isClose || isFull;

        const isInvalid = calendarValue.submitResult && calendarValue.submitResult.invalidList &&
            calendarValue.submitResult.invalidList.some(e => isEqual(startOfDay(new Date(e.date)), startOfDay(value)));

        const isRelease = calendarValue.isRelease;

        let cbg = ''
        if (cannotBook || !isRelease()) cbg = 'rbc-off-range-bg';
        else if (calendarValue.isEdit && isChecked) cbg = classes.checkedBackground;
        if (isInvalid && isChecked) cbg = classes.invalidBackground;
        return cbg;
    }
    const renderNotPublicHoliday = (dateItem, isToday, isChecked, value, calendarValue) => {
        return <>
            <div style={{
                display: 'flex', position: 'absolute',
                bottom: '0',
                width: isToday ? 'calc(100% - 4px)' : '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginLeft: isToday ? '2px' : '0',
                marginBottom: isToday ? '2px' : '0'
            }}>
                <div style={{ width: 'calc(100%)', position: 'relative', height: 18 }} ref={remarkContainer}>
                    {dateItem.remarks &&
                        <div className={classes.closeWrapper} ref={dateContainerRef}>
                            <div
                                className={`${classes.boldFont} ${classes.closeFont} rbc-public-date ${classes.dateFont} ${classes.ellipsisFont}`}
                                ref={remarksRef}
                            >
                                {dateItem.remarks}
                            </div>
                        </div>
                    }
                </div>

            </div>
        </>
    }

    return (
        <Consumer>
            {
                (calendarValue) => {
                    const { value } = props;
                    const dateItem = calendarValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(value)));



                    const isPublicHoliday = dateItem?.remarks.toLowerCase().includes('public holiday');

                    const isToday = isEqual(startOfDay(new Date()), startOfDay(value));
                    const isChecked = calendarValue.selectedDateList.some(item => isEqual(startOfDay(new Date(item.date)), startOfDay(value)));
                    const publicHolidaysStyle = isToday ? { width: 'calc(100% - 4px)', marginLeft: '2px', marginBottom: '2px' } : null



                    let cbg = getCbg(calendarValue, dateItem, value, isChecked);

                    const isOtherMonth = !isEqual(startOfMonth(calendarValue.viewDate), startOfMonth(value));

                    return (
                        <div
                            className={`rbc-day-bg  ${cbg}`}
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div style={{
                                position: 'relative'
                                , height: '100%'
                                // , border: isToday && '2px solid #0064E7'
                                // , zIndex: isToday && '5'
                                // , pointerEvents: isToday && 'none'
                                // , backgroundColor: isToday && 'rgba(0,0,0,0)'
                            }} className={isOtherMonth ? 'otherMonth' : ''}>
                                {props.children}
                                {dateItem && !isPublicHoliday
                                    ?
                                    renderNotPublicHoliday(dateItem, isToday, isChecked, value, calendarValue)
                                    :
                                    (isPublicHoliday &&
                                        <div className={`${classes.boldFont} ${classes.publicCloseDay} rbc-public-date ${classes.dateFont}`}
                                            style={publicHolidaysStyle}
                                            ref={publicContainerRef}>
                                            <div ref={publicRemarkRef} className={`${classes.ellipsisFont}`}>{dateItem.remarks}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }}
        </Consumer >
    );
}

const RenderMonthEvent = (props) => {
    const classes = useStyles();
    const { capacity, title, userTypeCode, isFirst, status } = props.event;
    // console.log(props.event, 'props.event;')
    const valueWrapperRef = React.useRef(null);
    const valueRef = React.useRef(null);
    const { setAutoScroll } = useAutoHozScroll(valueRef, true, 60);

    React.useEffect(() => {
        if (valueRef.current && valueWrapperRef.current) {
            if (valueRef.current.clientWidth > valueWrapperRef.current.clientWidth) {
                valueRef.current.classList.add(classes.longValueClass);
                setAutoScroll(true);
            }
        }
    });

    const getBgColor = (color) => {
        if (status === 'REJECTED') {
            return {
                backgroundColor: 'red',
                color: '#fff'
            }
        }
        return {
            backgroundColor: color,
            color: '#000',
        }
    }
    return (
        <Consumer>
            {(calendarValue) => {
                if (calendarValue.isEdit && !calendarValue.isEditManageRequest) return <></>;
                if (!title) return <></>
                return (<>
                    {userTypeCode === 'SELF_RESEARCHER' ? <Grid
                        container
                        // className={ }
                        style={{ padding: '0 5px', fontSize: 13, height: 19, overflow: 'hidden', ...getBgColor('#c6eeff') }}
                        ref={valueWrapperRef}
                    >
                        <Grid item container alignItems={'center'} style={{ flex: 1, overflow: 'hidden' }}>{title}</Grid>
                        <Grid item  >{isFirst && <StarIcon style={{ width: 12, height: 12, marginTop: '-2px' }} />}</Grid>

                    </Grid> :
                        <Grid
                            container
                            // className={ }
                            style={{ padding: '0 5px', fontSize: 13, height: 19, overflow: 'hidden', ...getBgColor('#ffd4b9') }}
                            ref={valueWrapperRef}
                        >
                            <Grid item container style={{ flex: 1 }}>{title}</Grid>
                            <Grid item >{capacity}</Grid>

                        </Grid>}
                </>
                );
            }}
        </Consumer>
    );
}

const CalendarComponent = props => {
    const { viewDate, isEdit, selectedDateList, addNo, removeNo, replaceNo, checkedDate, setCapacityList,
        setBookingsList, setWeekChecked, weekCheckedList, setViewDate, submitResult, releaseTimes,
        isRelease, todayHasBooking, setIsLoading, projectMaxQuota, isRejectDate, reloadFlag, weekRdoDisplays,
        isEditManageRequest, projectValue, selfServiceValue, allRecordChecked, replaceSelectedData, projectList,
        selfServiceList, selectionChoose, filterListFunc, resetLeftData, bookingResMapRef } = props;
    // console.log(selectedDateList)
    const classes = useStyles();

    const [eventList, setEventList] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [bookingRes, setBookingRes] = React.useState([]);

    const capacityResMapRef = React.useRef(null);
    const eventListRef = React.useRef([]);

    const [open, setOpen] = React.useState(false)
    const [moreList, setMoreList] = React.useState([]);
    const [moreDate, setMoreDate] = React.useState('');

    const getNewList = (newList, tMap) => {
        const l = []
        newList.forEach((item) => {

            const date = item.bookDate ? new Date(item.bookDate) : new Date(item.date)
            const t = tMap[format(date, 'yyyy-MM-dd') + "_" + item.projectId]
            let capacity = t?.capacity || item.capacity;
            let status = t?.status || item.status;
            let title = t?.title || item.refno;
            let userTypeCode = item.userTypeCode;
            let projectId = t?.projectId || item.projectId;
            let id = item.id;
            let rejectReason = item.rejectReason;
            let remarks = item.remarks;
            let tempObj = t?.isCreate ? { isCreate: t.isCreate } : {};
            if (t) {
                rejectReason = t?.rejectReason || ''
                remarks = t?.remarks;
                userTypeCode = t?.userTypeCode;
                id = t?.id;
            }
            if (isEdit || item.status === 'APPROVED') {
                l.push({
                    // ...item,
                    ...tempObj,
                    start: date,
                    end: date,
                    title: title,
                    capacity: capacity,
                    rejectReason: rejectReason,
                    isFirst: item.isFirst,
                    status: status,
                    attendance: item.attendance,
                    projectId: projectId,
                    userTypeCode: userTypeCode,
                    id: id,
                    remarks: remarks,
                })
            }

        })
        return l
    }

    const editFilter = () => {
        const newList = filterListFunc(bookingRes);
        const tMap = {}
        selectedDateList.forEach(item => {
            tMap[format(new Date(item.date), 'yyyy-MM-dd') + "_" + item.projectId] = item;
            if (!item.id && item.id !== 0) {
                newList.push(item)
            }
        })
        const l = getNewList(newList, tMap);

        setEventList(l)
        eventListRef.current = l;
    }

    React.useEffect(() => {
        editFilter()

    }, [allRecordChecked, selectedDateList, selectedDateList.length, bookingRes, selectionChoose])
    React.useEffect(() => {
        if (!isEdit) {

            editFilter()
        }

    }, [projectValue.length, selfServiceValue])

    const { isDirty } = useDirtyCheck();

    const setRemarks = props.selectedProjectValue.setRemarks;

    React.useEffect(() => {
        setRemarks(null);
        setSelectedDate(null);
        setAnchorEl(null);
    }, [setRemarks, viewDate])

    const projectCode = props.selectedProjectValue.selectedProject?.projectCode || '';
    const pt = props.selectedProjectValue.selectedProject?.projectType;
    const setDateList = props.selectedProjectValue.setDateList;

    const handleOpenDirtyCheck = props.selectedProjectValue.handleOpenDirtyCheck;
    // console.log('props.selectedProjectValue.effectiveEndDate', props.selectedProjectValue.effectiveEndDate)
    const getDateRangeBaseInfo = React.useCallback(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);


        const params = {
            startDate: format(minDate, "yyyy-MM-dd"),
            endDate: format(maxDate, "yyyy-MM-dd"),
            pt: ''
        }
        // console.log('props.selectedProjectValue.selectedProject?.projectType',)
        setIsLoading(true);
        axios.get('/booksetup/capacity-full', { params }).then((capacityRes) => {
            axios.get('/bookRequest/get-alluser-bookings', { params: { bookMonth: format(maxDate, "yyyy-MM-dd") } }).then((bookingsRes) => {
                setDateList(capacityRes.data);
                setRemarks(capacityRes.data.filter(item => Boolean(item.remarks)));
                setCapacityList(capacityRes.data)
                const tObj = {};
                capacityRes.data.forEach((item) => {

                    tObj[new Date(item.bookingDate)] = item;

                })
                capacityResMapRef.current = tObj;
                const newList = [];
                let bookingsList = [];
                let bookingsResList = [];
                let filterData = bookingsRes.data ? bookingsRes.data.filter((item) => item.status !== 'CANCELLED') : []
                filterData = filterData.sort((a, b) => a.status.localeCompare(b.status))
                if (filterData) {
                    const tempMap = {};

                    filterData.map(item => {
                        tempMap[item.projectId + format(new Date(item.bookDate), 'yyyy-MM-dd')] = item;
                        if (isEdit || item.status === 'APPROVED') {
                            newList.push({
                                start: item.bookDate,
                                end: item.bookDate,
                                title: item.refno,
                                capacity: item.capacity,
                                rejectReason: item.rejectReason,
                                isFirst: item.isFirst,
                                status: item.status,
                                attendance: item.attendance,
                                projectId: item.projectId,
                                userTypeCode: item.userTypeCode,
                                remarks: item.remarks,
                                id: item.id,
                            })
                        }
                    })

                    bookingResMapRef.current = tempMap

                    bookingsList = filterData
                    bookingsResList = filterData
                }

                setEventList(newList)
                eventListRef.current = newList;
                setBookingsList(bookingsList);
                setBookingRes(bookingsResList)
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
            })
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    }, [projectCode, pt, setBookingsList, setCapacityList, setDateList, setRemarks, viewDate, isEdit])
    useEffect(() => {
        getDateRangeBaseInfo();
    }, [getDateRangeBaseInfo, reloadFlag])

    const handleSelectDate = (e) => {
        if (e.action === 'click') {
            // setOpen(true)            
        } else {
            props.handleSelectDate(e);
        }

    }

    const editMore = (date) => {
        if (isEditManageRequest && !selectionChoose) {
            const t = []
            eventListRef.current.forEach((item) => {
                if (isEqual(startOfDay(new Date(item.start)), startOfDay(date))) {
                    t.push(item);
                }
            })
            onMoreClick(t, date)
        }
    }

    function onMoreClick(e, date) {
        date = date || e[0].start
        const changeOpen = () => {
            if (selectionChoose) return
            const temp = e.map((item) => {
                return { ...item }
            })
            // console.log(e)
            setOpen(true)
            setMoreList(temp)
            setMoreDate(date)
        }
        if (isEditManageRequest && (!isDirty || isEqual(new Date(date), new Date(selectedDateList[0].date)))) {
            changeOpen()
        }
        if (!isEdit) {
            changeOpen()
        }
    }
    return (
        <Provider value={{
            anchorEl,
            setAnchorEl,
            dateList: props.selectedProjectValue.dateList,
            selectedDate,
            setSelectedDate,
            isEdit,
            selectedDateList,
            addNo,
            removeNo,
            replaceNo,
            checkedDate,
            viewDate,
            submitResult,
            releaseTimes,
            isRelease,
            todayHasBooking,
            // expiredDate,
            setViewDate,
            isDirty,
            handleOpenDirtyCheck,
            selectedProject: props.selectedProjectValue.selectedProject,
            bookingRes,
            projectMaxQuota,
            isRejectDate,
            isEditManageRequest,
            projectValue,
            selfServiceValue,
            replaceSelectedData,
            resetLeftData,
            editMore,
            selectionChoose
        }}>
            <Calendar
                localizer={localizer}
                date={viewDate}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                view={Views.MONTH}
                dateList={props.selectedProjectValue.dateList}
                components={{
                    month: {
                        // event: (e) => renderEvent(e),
                        dateHeader: RenderDateHeader,
                        header: (e) => renderHeader(e, classes, isEdit, weekCheckedList, setWeekChecked, weekRdoDisplays, selectionChoose),
                        event: RenderMonthEvent,
                    },
                    toolbar: RenderToolBar,//(e) => renderToolBar(e, classes, setViewDate, isRelease, props.selectedProjectValue.handleOpenDirtyCheck, isDirty),
                    dateCellWrapper: RenderRateCellWrapper//(e) => RenderRateCellWrapper(e, isEdit, replaceNo, selectedDateList)
                }}
                // selectable={isEdit}
                // onSelectEvent={!isEdit && onEventClick}
                onShowMore={(e) => onMoreClick(e)}
                // showAllEvents={true}
                // step={50}
                // timeslots={1}
                selectable={isEdit ? isEdit : "ignoreEvents"}
                onSelectSlot={(e) => handleSelectDate(e)}
                onSelecting={(e) => handleSelectDate(e)}
                messages={{
                    showMore: () => <Grid container style={{ height: 18, overflow: 'hidden' }} justifyContent={'center'}><ExpandMoreIcon style={{ height: 18 }} /></Grid>
                }}
            />
            <Popover
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DialogTitle style={{ backgroundColor: '#FFEFE1' }} className={classes.remarkTitleFont}>
                    <span>{selectedDate && format(selectedDate, 'dd MMM yyyy')}{` (${new Date(selectedDate).toDateString().substring(0, 3)})`}</span>
                    <IconButton className={classes.closeButton} onClick={() => setAnchorEl(null)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.remarksContent}>
                    <Typography component={'div'}>Remarks:</Typography>
                    <Typography component={'div'}>
                        {props.selectedProjectValue.dateList.find(item => isEqual(startOfDay(new Date(item.bookingDate)), startOfDay(selectedDate)))?.remarks}
                    </Typography>
                </DialogContent>
            </Popover>
            {open && <MorePopup capacityResMapRef={capacityResMapRef} projectMaxQuota={projectMaxQuota} projectList={projectList} selfServiceList={selfServiceList} replaceSelectedData={replaceSelectedData} moreDate={moreDate} moreList={moreList} setMoreList={setMoreList} isEdit={isEditManageRequest} open={open} onClose={() => { setOpen(false) }} />}
        </Provider>
    );
}

export default CalendarComponent;