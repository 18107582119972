import React from 'react';
import {
    addMonths
    , startOfMonth
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    , Popover
} from '@material-ui/core';
import SmallCalendar from './SmallCalendar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const RenderToolDate = (props) => {
    const [cAnchorEl, setCAnchorEl] = React.useState(null);
    const monthClick = (e) => {
        setCAnchorEl(e.currentTarget);
    }

    const closeSmallCalendar = () => {
        setCAnchorEl(null);
    }
    const {
        date,
        label,
        setViewDate,
        isRelease,
        isSelect = false,
        labelStyle = {},
        endDate
    } = props;

    const toNextMonth = () => {
        setViewDate(addMonths(startOfMonth(date), 1));
    }

    const toLastMonth = () => {
        setViewDate(addMonths(startOfMonth(date), -1));
    }

    const selectEnabled = (bookingDate) => {
        return !isRelease || isRelease(bookingDate);
    }


    return (
        <>
            <Grid container alignItems={'baseline'} style={{ height: 40 }}>
                {!isSelect && <Grid item>
                    <IconButton onClick={toLastMonth}>
                        <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                    </IconButton>
                </Grid>}
                <Grid item style={{ minWidth: '150px', textAlign: 'center', cursor: 'pointer', height: 34, lineHeight: '33px', border: '1px solid #d2d2d2', borderRadius: 3, position: 'relative', ...labelStyle }} onClick={(e) => monthClick(e)}>
                    {label}
                    {isSelect && <ExpandMoreIcon style={{ position: 'absolute', top:5, right: 3 }}/>}
                </Grid>
                {!isSelect && <Grid item>
                    <IconButton onClick={toNextMonth} disabled={!selectEnabled(addMonths(startOfMonth(date), 1))}>
                        <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                    </IconButton>
                </Grid>}
            </Grid>
            <Popover
                open={Boolean(cAnchorEl)}
                onClose={() => setCAnchorEl(null)}
                anchorEl={cAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <SmallCalendar endDate={endDate} anchorEl={cAnchorEl} date={date} close={closeSmallCalendar} setViewDate={setViewDate} />
            </Popover>
        </>
    );
}
export default RenderToolDate;