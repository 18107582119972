import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { Typography } from '@material-ui/core';

export default function NoticeDialog(props) {
    const { onClose, open, content } = props;

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogTitle id="alert-dialog-confirm"
                style={{ textAlign: 'center', padding: '10px', position: 'relative' }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'Important Notice'}
                </Typography>
            </DialogTitle>
            <DialogContent >
                {content}
            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
                <Button onClick={handleOk} style={{backgroundColor: '#f58220', color: '#fff', width: '60%'}} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    )
}
