import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../Styles';
import SelectComponent from '../SelectComponent';
import DatePicker from '@material-ui/lab/DatePicker';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
const LeftSetupEditComponent = ({
    enableUpdateBooking,
    selectDateClick,
    selectAvailableDateClick,
    submitClick,
    cancelClick,
    allAvailableChecked,
    selectDateChecked,
    selectedDateText,
    selectedDateList,
    forProject,
    setForProject,
    forSelfService,
    setForSelfService,
    availableSpace,
    setAvailableSpace,
    updateCalendarAvailable,
    isReleaseNow, setIsReleaseNow,
    releaseDate, setReleaseDate,
    newReleaseTime, setNewReleaseTime,
    setUpRemarks,
    setSetUpRemarks,
    releaseTimeInvalidMsg,
    viewDate
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [remarks, setRemarks] = useState(setUpRemarks);
    const [availableBooking, setAvailableBooking] = React.useState(false);
    const [notAvailableBooking, setNotAvailableBooking] = React.useState(false);

    useEffect(() => {
        setAvailableBooking(false);
        setNotAvailableBooking(false);
        setRemarks('');
        handleReleaseNow(false);
        setAvailableSpace('');
    }, [viewDate])

    useEffect(() => {
        remarks !== setUpRemarks && setRemarks(setUpRemarks)
    }, [setUpRemarks])

    // useEffect(() => {
    //     if (!forProject && !forSelfService && availableSpace !== 0) {
    //         setAvailableSpace('')
    //     }
    // }, [forProject, forSelfService])

    useEffect(() => {
        if (availableBooking) {
            setAvailableSpace('')
        }
    }, [availableBooking])

    useEffect(() => {
        if (!notAvailableBooking) {
            setAvailableSpace('')
        }
    }, [notAvailableBooking])

    const handleReleaseNow = (v) => {
        if (isReleaseNow === v) return;
        setIsReleaseNow(v);
        setNewReleaseTime('')
        setReleaseDate('');

    }
    const handleReplaceNo = (value) => {
        const regexPattern = /^\d+$/;
        let result = regexPattern.test(value);
        if (value && !result) return;
        if (value) {
            value = parseInt(value);
        }
        setAvailableSpace(value);
    }

    const disabledSubmit = selectedDateList.length <= 0 || !isReleaseNow && ((!releaseDate || releaseDate + '' === 'Invalid Date') || !newReleaseTime)

    const checkTimeIcon = (value) => {
        return value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const setAvailableByFlag = (flag) => {
        if (flag) {
            setAvailableBooking(true);
            setNotAvailableBooking(false);
        }
    }

    const setForProjectClick = (flag) => {
        setForProject(flag);
        setAvailableByFlag(flag);
    }

    const setForSelfServiceClick = (flag) => {
        setForSelfService(flag);
        setAvailableByFlag(flag);
    }

    const setAvailableBookingClick = () => {
        setAvailableBooking(!availableBooking);
        if (!availableBooking) {
            setNotAvailableBooking(false);
            setAvailableSpace('');
        }
    }

    const setNoAvailableBookingClick = () => {
        setNotAvailableBooking(!notAvailableBooking);
        if (!notAvailableBooking) {
            setAvailableBooking(false);
            setForProject(false);
            setForSelfService(false);
            setAvailableSpace(0);
        }
    }
    const handleEnableUpdateBooking = () => {
        if (!notAvailableBooking && !availableBooking) {
            return false
        }
        if (availableBooking) {
            if (!availableSpace || (!forProject&& !forSelfService)) {
                return false
            }
        }
        return enableUpdateBooking();
    }
    const renderSetUpEdit = () => {
        const style = forProject ? {
            backgroundColor: `${theme.palette.primary.main}`,
            color: 'white',
        } : {
            backgroundColor: 'white',
            color: 'black',

        }
        return <><Grid container style={{ padding: '10px 5px 0 5px' }}>
            <div style={{ marginBottom: 5 }}>Capacity Setting:</div>

            <Button

                startIcon={checkIcon(allAvailableChecked)}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px'
                }}
                onClick={() => selectAvailableDateClick()}
            >
                <div className={classes.selDateLabel}>Choose all dates</div>
            </Button>
            <div>
                <Button

                    startIcon={checkIcon(selectDateChecked)}
                    className={classes.selDateButton}
                    style={{
                        color: `${theme.palette.primary.main}`,
                        textTransform: 'none',
                        fontSize: '13px'
                    }}
                    onClick={() => selectDateClick()}
                >
                    <div className={classes.selDateLabel}>Select date</div>
                </Button>
            </div >
            <div style={{ textAlign: 'center', marginTop: '8px' }}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue={selectedDateText()}
                    style={{ backgroundColor: 'white' }}
                    className={classes.selDateText}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>

            <Button
                startIcon={checkIcon(availableBooking)}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px'
                }}
                onClick={() => setAvailableBookingClick()}
            >
                <div className={classes.selDateLabel}>Available for booking</div>
            </Button>
            {availableBooking && <div style={{ marginLeft: '5px' }}>
                <Button
                    endIcon={checkIcon(forProject)}
                    className={classes.projectButton}
                    style={{
                        textTransform: 'none',
                        marginBottom: 5,
                        marginTop: '5px',
                        width: '160px',
                        ...style
                    }}
                    onClick={() => setForProjectClick(!forProject)}
                >
                    <div className={classes.projectLabel}>For project</div>
                </Button>
                <Button
                    endIcon={checkIcon(forSelfService)}
                    className={classes.projectButton}
                    style={{
                        backgroundColor: forSelfService ? `${theme.palette.primary.main}` : 'white',
                        color: forSelfService ? 'white' : 'black',
                        textTransform: 'none',
                        width: '160px',
                    }}
                    onClick={() => setForSelfServiceClick(!forSelfService)}
                >
                    <div className={classes.projectLabel}>For self-service</div>
                </Button>
                <div style={{ marginTop: '10px'}}>Available space</div>
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <TextField
                            value={availableSpace}
                            className={classes.Reason}
                            onChange={(e) => handleReplaceNo(e.target.value)}
                            disabled={selectedDateList.length < 1 || notAvailableBooking}
                            onBlur={(e) => { handleReplaceNo(e.target.value) }}
                        />
                    </Grid>
                </Grid>
            </div>}
            <div>
                <Button

                    startIcon={checkIcon(notAvailableBooking)}
                    className={classes.selDateButton}
                    style={{
                        color: `${theme.palette.primary.main}`,
                        textTransform: 'none',
                        fontSize: '13px',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={() => setNoAvailableBookingClick()}
                >
                    <div className={classes.selDateLabel}>Not available for booking</div>
                </Button>
            </div>

            <div style={{ marginLeft: '5px' }}>
                

                <div style={{ padding: '5px 0' }}>Remarks: </div>
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <TextField
                            value={remarks}
                            className={classes.Reason}

                            onChange={(e) => {
                                if (e.target.value.length > 200) return
                                setRemarks(e.target.value)
                            }}
                            onBlur={(e) => {
                                setSetUpRemarks(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: '8px' }}>
                    <Button className={handleEnableUpdateBooking() ? classes.activeButton : classes.inActiveButton} onClick={() => updateCalendarAvailable()}
                        disabled={!handleEnableUpdateBooking()}>Update Booking</Button>
                </div>
            </div>
            <Divider flexItem style={{ pointerEvents: 'none', width: '100%', marginTop: 10 }} />
            <div style={{ marginTop: 5 }}>Release Option</div>
            <Button

                startIcon={checkTimeIcon(!isReleaseNow)}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px'
                }}
                onClick={() => handleReleaseNow(false)}
            >
                <div className={classes.selDateLabel}>Pre-set release time:</div>
            </Button>
            {!isReleaseNow && <>
                <DatePicker
                    label=""
                    value={releaseDate}
                    inputFormat="MM/dd/yyyy"
                    views={['month', 'year', 'date']}
                    onChange={(newValue) => {
                        setReleaseDate(newValue)
                    }}
                    style={{ paddingBottom: 10 }}
                    renderInput={(params) => {
                        params.inputProps.placeholder = "Select date";
                        return <TextField  {...params} helperText={null} className={classes.DateText} error={false} />
                    }}
                />

                <SelectComponent placeholder={'Select time'} value={newReleaseTime} onChange={(v) => { setNewReleaseTime(v) }} style={{
                    textTransform: 'none',
                    fontSize: '13px',
                    marginTop: 10,
                    width: 160,
                }} options={[{
                    value: '00:00',
                    name: '00:00'
                }, {
                    value: '01:00',
                    name: '01:00'
                }, {
                    value: '02:00',
                    name: '02:00'
                }, {
                    value: '03:00',
                    name: '03:00'
                }, {
                    value: '04:00',
                    name: '04:00'
                }, {
                    value: '05:00',
                    name: '05:00'
                }, {
                    value: '06:00',
                    name: '06:00'
                }, {
                    value: '07:00',
                    name: '07:00'
                }, {
                    value: '08:00',
                    name: '08:00'
                }, {
                    value: '09:00',
                    name: '09:00'
                }, {
                    value: '10:00',
                    name: '10:00'
                }, {
                    value: '11:00',
                    name: '11:00'
                }, {
                    value: '12:00',
                    name: '12:00'
                }, {
                    value: '13:00',
                    name: '13:00'
                }, {
                    value: '14:00',
                    name: '14:00'
                }, {
                    value: '15:00',
                    name: '15:00'
                }, {
                    value: '16:00',
                    name: '16:00'
                }, {
                    value: '17:00',
                    name: '17:00'
                }, {
                    value: '18:00',
                    name: '18:00'
                }, {
                    value: '19:00',
                    name: '19:00'
                }, {
                    value: '20:00',
                    name: '20:00'
                }, {
                    value: '21:00',
                    name: '21:00'
                }, {
                    value: '22:00',
                    name: '22:00'
                }, {
                    value: '23:00',
                    name: '23:00'
                }]} />
                <Grid container style={{ paddingTop: 5 }}>
                    <span style={{ lineHeight: '1', color: 'red' }}>{releaseTimeInvalidMsg}</span>
                </Grid>
            </>}
            <div>
                <Button
                    startIcon={checkTimeIcon(isReleaseNow)}
                    className={classes.selDateButton}
                    style={{
                        color: `${theme.palette.primary.main}`,
                        textTransform: 'none',
                        fontSize: '13px'
                    }}
                    onClick={() => handleReleaseNow(true)}
                >
                    <div className={classes.selDateLabel}>Release Now</div>
                </Button>
            </div >
            <Grid container justifyContent={'center'} style={{ marginTop: '8px' }}>
                <Grid item container style={{ width: '200px', marginLeft: '5px' }}>
                    <Grid item>
                        <Button disabled={disabledSubmit} className={!disabledSubmit ? classes.activeButton : classes.inActiveButton} onClick={() => submitClick()}>Confirm</Button>
                    </Grid>
                    <Grid item style={{ marginLeft: '5px' }}>
                        <Button className={classes.cancelButton} onClick={() => cancelClick()}>Cancel</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    }
    return (<>
        {renderSetUpEdit()}
    </>)
}

export default LeftSetupEditComponent;