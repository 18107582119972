import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: '#d8d8d8',
  },
  barWarning: {
    borderRadius: 0,
    backgroundColor: '#ffe456',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#46ae4a',
  },
}));
const BorderLinearProgress = ({expectedProgress, ...props}) => {
  const classes = useStyles();
  
  return <LinearProgress
    classes={{
      root: classes.root,
      colorPrimary: classes.colorPrimary,
      bar: expectedProgress - props.value > 10? classes.barWarning : classes.bar,
    }}
    {...props}
  />
}



export default BorderLinearProgress