import './polyfills'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {
  MuiThemeProvider
} from "@material-ui/core/styles";
import Themes from "themes";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <MuiThemeProvider theme={Themes.default}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  rootElement);

//Not using this as workbox is load from google CDN <- not allowed under CSP
//registerServiceWorker();

