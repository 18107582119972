import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import axios from '../../../CustomAxios';
import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';

import RecordLists from './RecordLists'

import { OutputManagementContext } from './index';
export default function SummaryPage(props) {
    const {
        setIsLoading, requestType, setErrorMsg, setRequestType,
        viewBy, setViewBy, selectedProject, setSelectedProject,
        handleEdit, reload, setSelectedProjectObj,
    } = React.useContext(OutputManagementContext)

    const [userProjectList, setUserProjectList] = React.useState([]);
    const [SDPList, setSDPList] = React.useState([]);




    React.useEffect(() => {
        getUserProject()
    }, [])

    const loadData = (callback) => {
        const payload = {
            projectId: 0
        }
        
        payload.projectId = selectedProject || 0;
        payload.type = requestType;

        if (viewBy === 'project')
            payload.projectType = 'COL_RESEARCHER';
        else if (viewBy === 'SDP')
            payload.projectType = 'SELF_RESEARCHER';

        setIsLoading(true);
        axios.get('/outputRequest/get-output-requests', { params: payload }).then((res) => {
            setIsLoading(false);
            callback(res)
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const getUserProject = () => {
        axios.get('/outputRequest/get-user-projects').then((res) => {
            // console.log(res)
            let tempSPD = [];
            let tempSDP = [];
            if (res.data) {
                res.data.forEach((item) => {
                    if (item.userTypeCode == 'COL_RESEARCHER') {
                        tempSPD.push(item)
                    } else if (item.userTypeCode == 'SELF_RESEARCHER') {
                        tempSDP.push(item)
                    }
                })
                setUserProjectList(tempSPD)
                setSDPList(tempSDP)
            } else {
                setUserProjectList([])
                setSDPList([])
            }

        }).catch(err => {
            console.log(err);
        })
    }

    const handleSelectProject = (item, obj) => {
        setSelectedProject(item)
        setSelectedProjectObj(obj)
    }


    const handleChangeRadio = (value) => {
        if (value === viewBy) return

        if (value == 'SDP') {
            setRequestType("OUTPUT")
        }
        setViewBy(value)
        setSelectedProject('')
        setSelectedProjectObj({})
        // setViewDate(startOfMonth(new Date()))
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }
    return (
        <Grid container flexDirection={'column'} style={{ height: '100%' }}>
            <Grid item xs={'auto'}>
                <Typography variant="h5" gutterBottom xs={12}>
                    Output Request / Publication Review Record
                </Typography>
            </Grid>
            <Grid container style={{ paddingTop: 10 }} >
                <Grid item style={{ lineHeight: '28px' }}>
                    View by
                </Grid>
                <Grid item >
                    <Button
                        startIcon={checkIcon(viewBy == 'all')}
                        // className={classes.button}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('all')}

                    >
                        <div style={{ color: '#000' }}>All Records</div>
                    </Button>
                    <Button
                        startIcon={checkIcon(viewBy == 'project')}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('project')}
                        disabled={userProjectList.length == 0}
                    >
                        <div style={{ color: '#000' }}>Project</div>
                    </Button>
                    <SelectComponent disabled={viewBy != 'project'} value={viewBy == 'project' ? selectedProject : ''} onChange={handleSelectProject} style={{
                        textTransform: 'none',
                        fontSize: '13px',
                        minWidth: '100px',
                        maxWidth: '200px'
                    }} nameField="refNum" valueField="projectId" options={userProjectList} />
                    <Button
                        startIcon={checkIcon(viewBy == 'SDP')}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('SDP')}
                        disabled={SDPList.length == 0}
                    >
                        <div style={{ color: '#000' }}>Self-Service</div>
                    </Button>
                    <SelectComponent disabled={viewBy != 'SDP'} value={viewBy == 'SDP' ? selectedProject : ''} onChange={handleSelectProject} style={{
                        textTransform: 'none',
                        fontSize: '13px',
                        minWidth: '100px',
                        maxWidth: '200px'
                    }} nameField="refNum" valueField="projectId" options={SDPList} />
                </Grid>

            </Grid>
            <div style={{ minHeight: 40 }}>
                <Button
                    startIcon={checkIcon(requestType == "")}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => { setRequestType("") }}

                >
                    <div style={{ color: '#000' }}>All Requests</div>
                </Button>
                <Button
                    startIcon={checkIcon(requestType == "OUTPUT")}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => { setRequestType("OUTPUT") }}

                >
                    <div style={{ color: '#000' }}>Output Request</div>
                </Button>
                {viewBy != 'SDP' && <Button
                    startIcon={checkIcon(requestType == "PUBLICATION")}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => { setRequestType("PUBLICATION") }}

                >
                    <div style={{ color: '#000' }}>Publication Review </div>
                </Button>}
            </div>
            <Grid item xs>
                <RecordLists reload={reload} requestType={requestType} handleEdit={handleEdit} selectedProject={selectedProject} loadData={loadData} setErrorMsg={setErrorMsg} setIsLoading={setIsLoading} viewBy={viewBy} />

            </Grid>

        </Grid>
    );
}
