import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../CustomAxios';
import { UserContext } from './UserContext';
export default function useAuth() {
    let history = useHistory();
    const { setUser } = useContext(UserContext);
    const [error, setError] = useState(null);
    //set user in context and push them home
    const setUserContext = async () => {
        return await axios.get('/user').then(res => {
            setUser(res.data);            
            history.push('/');
        }).catch((err) => {
            if (err) {
                console.error(err);
                if (err.response)
                    setError(err.response.data);
            }
        })
    }

    const loginUser = async (data) => {
        const { username, password } = data;
        return axios.post(`/user/authenticate`, {
                username, password
            }).then(async (res) => {
                axios.defaults.headers.common['Authorization'] = res.data.token;
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('lastLoginTime', res.data.lastLoginTime)
                await setUserContext();
            });        
    }

    return {
        loginUser,
        error
    }
}