import { FormControl, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useRef} from 'react';
import axios from '../../CustomAxios';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import QrReader from "react-qr-reader";
import { makeStyles } from '@material-ui/core/styles';
import LoadingScreen from 'components/common/LoadingScreen';
const useStyles = makeStyles((theme) => ({
    video: {
        '& div': {
            boxShadow: '#F58220 0px 0px 0px 3px inset !important',
            border: '30px !important'
        },
      
    },
   
}));
export default function EditRecord(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);

    const scanDataRef = useRef({});

    const fetchScan = (token) => {
        setIsLoading(true)
        axios.post('/attendance/sign-in-out', {token}).then(res => {
            scanDataRef.current[token] = new Date().getTime();
            props.scanSuccess(res.data)
            props.setErrorMsg('');
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false);
            scanDataRef.current[token] = new Date().getTime();
            var errorMsg = err.response?.data?.message;           
            props.setErrorMsg(errorMsg ? errorMsg : err.message);
            if (err) {
                console.log(err);
            }
        })
        
        
    }
    const handleScan = (data) => {
        if (data && !isLoading && !props.signInOpen) {
            
            // console.log(data, scanDataRef.current)
            if (scanDataRef.current[data]) {
                if (new Date().getTime() - scanDataRef.current[data] > 5000) {
                    fetchScan(data)
                } 
            } else {
                fetchScan(data)
                
            }
        }
    };
    
    const handleError = (err) => {
        console.error(err);
    };
    const previewStyle = {
        width: '100%',
        height: '100%',
    };
    const position = 80;
    const width = 105;
    const height = 105;
    const border = 3;
    const color = '#F58220'
    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="scanner-dialog"
        >
          <Grid id="scanner-dialog-title"
                style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
                    <IconButton aria-label="close" style={{position: 'absolute', top: 5, right: 5, padding: 5}} onClick={props.onClose}>
                    <CloseIcon style={{ fontSize: 28 }} size='small' />
                </IconButton>
            </Grid>
            <Grid container style={{ width: 500, height: 470, padding: '10px 30px 30px', position: 'relative' }}>
                <QrReader
                    delay={300}
                    style={previewStyle}
                    containerStyle={{width: '99%'} }
                    videoContainerStyle={{ width: '98%' }}
                    className={classes.video}
                    onError={handleError}
                    onScan={handleScan}
                />
                <span style={{position: 'absolute', left: position, top:position, width: width, height: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', right: position, top:position, width: width, height: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', left: position, bottom:position+10, width: width, height: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', right: position, bottom:position+10, width: width, height: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', left: position, top:position, height: height, width: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', left: position, bottom:position+10, height: height, width: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', right: position, top:position, height: height, width: border, backgroundColor: color}}/>
                <span style={{position: 'absolute', right: position, bottom:position+10, height: height, width: border, backgroundColor: color}}/>
            </Grid>
            <LoadingScreen open={isLoading}/>
        </Dialog>)
}
