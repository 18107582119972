import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import axios from 'CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { Prompt, useHistory } from 'react-router-dom';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import LoadingScreen from 'components/common/LoadingScreen';
import SelectComponent from 'components/common/CommonSelect';
import { warning, success } from 'components/common/Constants';

const roleOption = [
    {
        name: 'Project',
        value: 'COL_RESEARCHER'
    }, {
        name: 'Self-Service',
        value: 'SELF_RESEARCHER'
    }, {
        name: 'Workshop Participant',
        value: 'WS_PARTICIPANT'
    }]

export default function OnlineInformationDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('')
    const { setUser } = React.useContext(UserContext);
    const [error, setError] = React.useState({});
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();

    const [state, setBaseState] = React.useState({})
    const [nextPath, setNextPath] = React.useState(null);
    const timerRef = React.useRef(null);
    const isErrorRef = React.useRef(null);

    const setIsEditState = props.setIsEditState;
    const setCurIdState = props.setCurIdState;
    const refreshData = props.refreshData;
    const id = props.id;

    const handleSetBaseState = (newState) => {
        setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }
    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {
            history.push(nextPath);
        }
    }, [history, isDirty, nextPath])

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/progress-reports/form')) {
            return true;
        }
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                handleSave()
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setTimeout(() => {
                    goTop()
                }, 20)
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);
                }
                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;Your eCourse is not yet saved&nbsp;&nbsp;&nbsp;&nbsp; </div>
                    <div>{text ? text : ' Are you sure to leave?  '}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }

    const getFormDetail = () => {
        setIsLoading(true);
        axios.get('/onlineInformation/get-video', { params: { id: id } }).then((res) => {
            if (res.data) {
                let data = res.data;
                setBaseState(data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    React.useEffect(() => {
        if (id)
            getFormDetail();
    }, [])

    const handleChangeInput = (e, fileName) => {
        let value = e.target.value;

        if (error[fileName]) {
            setError((s) => ({ ...s, [fileName]: false }))
        }
        handleSetBaseState({ [fileName]: value })
    }
    const handleChangeSelect = (value, fileName) => {
        if (error[fileName]) {
            setError((s) => ({ ...s, [fileName]: false }))
        }
        handleSetBaseState({ [fileName]: value })
    }

    const handleCheckSubmit = (payload, errorTemp, callback) => {
        let isError = false;
        if (!payload.title) {
            errorTemp.title = true;
            isError = true
        }
        if (!payload.links) {
            errorTemp.links = true;
            isError = true
        }
        if (isError) {
            callback()
        }
    }
    const handleCheck = (payload) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = ""
        msg = warning.reportMandatory;
        handleCheckSubmit(payload, errorTemp, () => {
            isError = true;
        })

        isErrorRef.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }

    const handleSave = () => {
        timerRef.current = setTimeout(() => {
            let payload = {
                ...state
            };
            if (handleCheck(payload)) {
                return
            }
            onSubmit(payload)
        }, 200)
    }

    const onSubmit = (payload) => {
        const requests = {
            id: payload.id,
            title: payload.title,
            links: payload.links,
            roles: payload.roleCodes
        }

        setIsLoading(true);
        axios.post('/onlineInformation/edit-video', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.Saved;
                    setIsDirty(false)
                    setIsLoading(false);
                    setErrorMsg(m);
                    refreshData();
                }
            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
            });
    }

    const goTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    const handleCancel = () => {
        if (!isDirty) {
            goTop()
        }
        setBaseState({});
        setCurIdState(0);
        setIsEditState(false);
    }

    const handleCloseDialog = () => {
        if (!isDirty && !isErrorRef.current) {
            goTop()
            setTimeout(() => {
                handleCancel();
            }, 20)
        }
        setErrorMsg('')
    }

    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)' }}>
            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        eCourse
                    </Typography>
                </Grid>
                <Grid container justifyContent={'space-between'} alignItems='center' style={{ paddingBottom: 10 }}>
                    <Grid item>* Mandatory fields</Grid>
                </Grid>
                <Grid container>
                    <Grid container>
                        <Grid container className={`${classes.lineBottom} ${classes.lineTop}`} >
                            <Grid item container alignContent='center' xs={3} className={classes.label}>Course Title*</Grid>
                            <Grid item container alignItems='center' xs={3} className={classes.rightContent}>
                                <TextField
                                    fullWidth
                                    id="filled-CourseTitle"
                                    label=""
                                    multiline
                                    inputProps={{ maxLength: 256 }}
                                    className={classes.textareaBox}
                                    placeholder="≤256 characters"
                                    variant="outlined"
                                    value={state.title}
                                    InputProps={{
                                        style: {
                                            ...(error.title && !state.title ? { border: '1px solid #ff0000' } : {})
                                        },
                                    }}
                                    onChange={(e) => handleChangeInput(e, 'title')}
                                />
                            </Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Available to</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <SelectComponent displayEmpty={false} multiple={true} value={state.roleCodes || []} onChange={(v) => { handleChangeSelect(v, 'roleCodes') }} style={{
                                    textTransform: 'none',
                                    fontSize: '13px',
                                    width: '200px',
                                }} options={roleOption} />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom}>
                            <Grid item container xs={3} className={classes.label}>Video Link*</Grid>
                            <Grid item container xs={9} className={classes.rightContent}>
                                <TextField
                                    fullWidth
                                    id="filled-VideoLink"
                                    label=""
                                    multiline
                                    inputProps={{ maxLength: 512 }}
                                    className={classes.textareaBox}
                                    placeholder="≤512 characters"
                                    variant="outlined"
                                    value={state.links}
                                    InputProps={{
                                        style: {
                                            ...(error.links && !state.links ? { border: '1px solid #ff0000' } : {})
                                        },
                                    }}
                                    onChange={(e) => handleChangeInput(e, 'links')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{ paddingTop: 10 }}>
                    <Button style={{
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                        marginRight: 10,
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={() => handleSave()}>Save</Button>
                    <Button style={{
                        backgroundColor: '#646464 ',
                        color: '#ffff ',
                        textTransform: 'none'
                    }} className={classes.activeButton} onClick={handleCancel}>Back</Button>
                </Grid>
            </Grid>
            <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>

            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />
            <LoadingScreen open={isLoading} />

        </Paper>
    );
}
