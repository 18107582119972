import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDialog(props) {
    const {
        open,
        onClose,
        onCancel,
        onConfirm,
        title,
        description,
        okText,
        cancelText,
        ...other } = props;

    return (
        <div>
            <Dialog
                open={open}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="alert-dialog-confirm"> {!!title ? title : "Are you sure?"} </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        {props.children ? props.children : description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onConfirm && onConfirm();
                            onClose();
                        }}
                        color="primary">{!!okText ? okText : "Confirm"}</Button>
                    <Button autoFocus color="primary"
                        onClick={() => {
                            onCancel && onCancel();
                            onClose();
                        }}
                    >{!!cancelText ? cancelText : "Cancel"}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}