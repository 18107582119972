import React, { useEffect } from 'react';
import {
    format
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import axios from '../../../CustomAxios';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./Styles";
import { useHistory, Prompt } from 'react-router-dom';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import useModal from "hooks/useModal";
import { warning, success } from 'components/common/Constants';
import ReportDraftEditor from 'components/progressReports/researcher/detail/ReportDraftEditor';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import { UserContext } from 'hooks/UserContext';
import { OutputManagementContext } from './index';

import isValid from 'date-fns/isValid';
import { statusColor } from '../researcher/OutputRequestList';
import DatePicker from '@material-ui/lab/DatePicker';
const publicationTypeMap = {
    ABS: 'Abstract',
    SUM: 'Summary',
    RES: 'Research Paper',
    MAN: 'Manuscript',

}
const jouOrConMap = {
    JOU: 'Journal',
    CON: 'Conference',

}
export default function PublicationManagementDetail(props) {
    const classes = useStyles();
    const {
        isCreate, handleBack,
        currentDetail, setIsLoading, setReload, reload, selectedProject, isOutputRequest, selectedProjectObj, user
    } = React.useContext(OutputManagementContext)
    const { setUser } = React.useContext(UserContext);
    const history = useHistory();
    const showModal = useModal();
    const [state, setBaseState] = React.useState({ supplementaries: [], publication: {} })
    const [nextPath, setNextPath] = React.useState(null);
    const [error, setError] = React.useState({});
    const { setOpenDirtyCheckDialog, setDirtyCheckMsgObj, isDirty, setIsDirty } = useDirtyCheck();
    const draftEditorRef = React.useRef({})
    const [errorMsg, setErrorMsg] = React.useState('')
    const isNoBack = React.useRef(null);

    const disableSubmit = (state.statusCode == "INCOMPLETED") ? true : false;
    // console.log(isDirty, currentDetail)
    const [status, setStatus] = React.useState("")

    useEffect(() => {
        if (!isCreate && currentDetail) {
            loadDetail()
        } else {
            setBaseState((oldState) => ({
                ...oldState,
                requestByName: user.user.email,
                projectId: selectedProject,
                requestType: isOutputRequest ? "OUTPUT" : "PUBLICATION",
                refNum: selectedProjectObj.refNum
            }))
            draftEditorRef.current.supplementaries = []
        }
    }, [currentDetail, isCreate])



    const handleSetBaseState = (newState) => {
        !disableSubmit && setIsDirty(true)
        setBaseState((oldState) => ({
            ...oldState,
            ...newState
        }))
    }

    React.useEffect(() => { //callback of leave Anyway
        if (!isDirty && nextPath) {

            history.push(nextPath);
        }

    }, [history, isDirty, nextPath])

    const loadDetail = () => {
        setIsLoading(true);
        axios.get('/outputRequest/get-output-request-detail', { params: { outputId: currentDetail.id, projectId: currentDetail.projectId } }).then((res) => {
            const data = res.data;
            if (res.data) {
                draftEditorRef.current = {
                    remarksRsp: data.remarksRsp,
                };
                if (data.statusCode == "UNDER_REVIEW" || data.statusCode == "REVISION" || data.statusCode == "REVIEWED" || data.statusCode == "WITHDRAWN") {
                    setStatus(data.statusCode)
                }
                setBaseState(res.data)
                setIsLoading(false);
            } else {
                setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    const handleOpenDirtyCheck = ({ location, leaveCallback, text }) => {
        if (!isDirty || (location && location.pathname === '/output-request'))
            return true;
        setDirtyCheckMsgObj({
            btn1Action: () => {
                setOpenDirtyCheckDialog(false);
                // submitClick()
                handleSave()
            },
            btn1Label: 'Save Now',
            btn2Action: () => {
                setIsDirty(false);
                setOpenDirtyCheckDialog(false);
                // gridTableRef.current.editClose()
                if (location && location.pathname) {
                    if (location.pathname === '/login') {
                        localStorage.clear();
                        axios.defaults.headers.common['Authorization'] = "";
                        setUser(null);
                    }
                    setNextPath(location.pathname);

                }

                typeof leaveCallback === 'function' && leaveCallback();
            },
            btn2Label: 'Leave Anyway',
            MsgContent: () => (
                <>
                    <div>Your Publication is not yet saved/ submitted </div>
                    <div>{text ? text : 'Are you sure to leave?'}</div>
                </>
            )
        });
        setOpenDirtyCheckDialog(true);
        return false;
    }


    const handleCheck = (payload) => {
        let isError = false;
        let errorTemp = { ...error };
        let msg = warning.AllMandatory;
        if (!payload.status) {
            errorTemp.status = true;
            isError = true
        }
        if (payload.remarksRsp) {
            let cleanCode = payload.remarksRsp.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace('&nbsp;', '');
            // console.log(cleanCode, cleanCode.length)
            if (cleanCode.length > 2000) {
                errorTemp.remarksRsp = true;
                !isError && (msg = warning.RichLength)
                isError = true
            }
        }
        isNoBack.current = isError
        setError(errorTemp)
        setErrorMsg(isError ? msg : '')
        return isError;
    }
    const handleEmailGenerated = (payload) => {
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request', payload)
            .then((res) => {
                if (res.data.code == 0) {
                    let m = success.EmailGenerated
                    setErrorMsg(m)
                    setIsLoading(false);
                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }
            }).catch(err => {
                let tempErrorMsg = err.response?.data?.message;
                setErrorMsg(tempErrorMsg ? tempErrorMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }
    const onSubmit = (payload) => {
        const requests = {
            ...payload,
        }
        setIsLoading(true);
        axios.post('/outputRequest/update-output-request', requests)
            .then((res) => {
                if (res.data.code == 0) {
                    setIsDirty(false)
                    setIsLoading(false);
                    if (payload.status == "REVISION" || payload.status == "REVIEWED" || payload.status == "WITHDRAWN") {
                        showModal((pros) => (
                            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                                {...pros}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    payload.isSend = true;
                                    handleEmailGenerated(payload)
                                }}
                                onCancel={() => {

                                    setIsDirty(false)
                                    setReload(!reload)
                                    handleBack()
                                }}
                            >
                                <div>{success.Saved}</div>
                                <div style={{ padding: '0 10px' }}>Generate email to respective researcher?</div>
                            </SystemConfirmDialog>
                        ));
                    } else {
                        let m = success.Saved
                        setErrorMsg(m)
                    }

                } else if (res.data.code == 1) {
                    setIsLoading(false);
                    setErrorMsg(res.data.data)
                    isNoBack.current = true
                }

            }).catch(err => {
                let errMsg = err.response?.data?.message;
                setErrorMsg(errMsg ? errMsg : err.message)
                setIsLoading(false);
                isNoBack.current = true
            });
    }
    const handleSave = () => {
        const payload = {
            id: state.id,
            status: status,
            isSend: false,
            remarksRsp: draftEditorRef.current.remarksRsp
        }

        if (handleCheck(payload)) {
            return
        }
        payload.remarksRsp = window.htmlEncode(payload.remarksRsp)

        onSubmit(payload)
    }

    const handleChangeTextArea = (value, fileName) => {


        let isError = false
        if (error[fileName]) {
            isError = false
        }

        if (isError !== error[fileName]) {
            setError((s) => ({ ...s, [fileName]: isError }))
        }
        draftEditorRef.current = {
            ...draftEditorRef.current,
            [fileName]: value
        }
        !disableSubmit && setIsDirty(true)
        // handleSetBaseState({[fileName]: value})
    }

    const handleBackList = () => {
        if (isDirty && !disableSubmit) {
            handleOpenDirtyCheck({
                leaveCallback: () => {
                    handleBack()
                },
            })
        } else {
            setIsDirty(false)
            handleBack()
        }

    }
    const handleCloseDialog = () => {
        if (!isDirty && !isNoBack.current) {
            handleBackList()
            setReload(!reload)
        }
        setErrorMsg('')
    }



    const handleRenderInput = (value) => {
        return <div style={{ padding: '5px 0', width: '100%', maxHeight: 250, height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex' }}>
            <div title={value} style={{ lineHeight: "1.2", width: '100%', maxHeight: '100%', overflowY: 'auto', color: '#c1c1c1' }} dangerouslySetInnerHTML={{ __html: value }}>
            </div>

        </div>
        // return component
    }
    const handlePublication = (value, fileName) => {
        const newPublication = { ...state.publication }
        newPublication[fileName] = value
        handleSetBaseState({
            publication: newPublication
        })
    }
    const formatDateValue = (date) => {
        if (typeof date === 'string') {
            return new Date(date)
        }
        return date
    }

    return (
        <>
            <Grid container style={{ height: '100%' }} >
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Publication Review
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container className={classes.title}>
                        Part I&nbsp;&nbsp;&nbsp;Requestor Information
                    </Grid>
                    <Grid container>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Request No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestNo}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Status</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{
                                <div title={state.status} style={{ width: '150px', height: 29, lineHeight: '29px', color: "#fff", borderRadius: 5, fontSize: 13, textAlign: 'center', ...(statusColor[state.statusCode]) }} dangerouslySetInnerHTML={{ __html: state.status }}>
                                </div>

                            }</Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Requested by</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.requestByName}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>
                                <div>
                                    <div>First Submission Date</div>
                                    <div>Last Submission Date</div>
                                </div>
                            </Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                <div>
                                    <div>{state.firstSubmissionDate && format(new Date(state.firstSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                    <div>{state.lastSubmissionDate && format(new Date(state.lastSubmissionDate), 'dd MMM yyyy HH:mm')}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.lineBottom} >
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Project / Self-Service No.</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>{state.refNum}</Grid>
                            <Grid item container xs={3} alignContent='center' className={classes.label}>Organisation</Grid>
                            <Grid item container xs={3} alignItems='center' className={classes.rightContent}>
                                {handleRenderInput(
                                    state.organisation
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*  */}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part II&nbsp;&nbsp;&nbsp;Publication Details
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} className={classes.label} >Publication Type <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(
                                publicationTypeMap[state.publication.publicationType]
                            )}
                        </Grid>
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} className={classes.label} >Publication Title <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(

                                state.publication.publicationTitle
                            )}
                        </Grid>
                    </Grid>
                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} className={classes.label} >Journal or Conference <span className={classes.red}>*</span></Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(

                                jouOrConMap[state.publication.jouOrCon]
                            )}

                        </Grid>
                    </Grid>
                    {state.publication.jouOrCon == "CON" && <>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} className={classes.label} >
                                Name of Conference<span className={classes.red}>*</span>
                            </Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(

                                    state.publication.nameOfConference
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} className={classes.label} >Conference Organizer<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(

                                    state.publication.conferenceOrganizer
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} className={classes.label} >Expected Presentation Date<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                <DatePicker
                                    disabled={true}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.publication.expectedPreDate || '')}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handlePublication(newValue, 'expectedPreDate')
                                        }
                                    }}
                                    views={['year', 'month', 'date']}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.publication && !state.publication.expectedPreDate} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>}
                    {state.publication.jouOrCon == "JOU" && <>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} className={classes.label} >Name of Journal<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                {handleRenderInput(

                                    state.publication.nameOfJournal
                                )}
                            </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                            <Grid container item xs={3} className={classes.label} >Expected Publication Date<span className={classes.red}>*</span></Grid>
                            <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                                <DatePicker
                                    disabled={true}
                                    inputFormat="dd/MM/yyyy"
                                    value={formatDateValue(state.publication.expectedPubDate || '')}
                                    onChange={(newValue) => {
                                        if (isValid(newValue)) {
                                            handlePublication(newValue, 'expectedPubDate')
                                        }
                                    }}
                                    views={['year', 'month', 'date']}
                                    renderInput={(params) => {
                                        params.inputProps.placeholder = "DD/MM/YYYY";
                                        return <TextField  {...params} helperText={null} className={classes.DateText} error={error.publication && !state.publication.expectedPubDate} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>}

                    <Grid container style={{ fontSize: 13, minHeight: 40 }} className={`${classes.projectTr} ${classes.lineBottom}`}>
                        <Grid container item xs={3} className={classes.label} >Reference&nbsp;(optional)</Grid>
                        <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >
                            {handleRenderInput(

                                state.publication.reference
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Part III Model list  (Please specify in the below details for different model(s))*/}
                <Grid container style={{ paddingTop: 10 }}>
                    <Grid container className={classes.title}>
                        Part III&nbsp;&nbsp;&nbsp;Supplementary Information
                    </Grid>
                    <Grid container style={{ padding: '5px 10px', fontSize: 13 }}>
                        Please provide us the details or document links of the supplementary information below.
                    </Grid>
                    <Grid container className={classes.projectHeader}>
                        <Grid item container xs={4} className={classes.projectTh} justifyContent='center' >Document name</Grid>
                        <Grid item container xs={8} className={classes.projectTh} justifyContent='center' >{`Description & Link *`}</Grid>
                    </Grid>
                    {
                        state.supplementaries && state.supplementaries.map((item, index) => {
                            return <Grid container key={index}>
                                <Grid item container xs={4} style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.projectTd}  >
                                    <span style={{ paddingTop: 2 }}>{index + 1}.&nbsp;&nbsp;</span>
                                    <Grid item container xs>
                                        {handleRenderInput(

                                            item.documentName
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={8} style={{ paddingLeft: 10, paddingRight: 10, }} className={classes.projectTd} justifyContent='center' >
                                    <Grid item container style={{ flex: 1, position: 'relative', height: 80 }}>
                                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
                                            {handleRenderInput(

                                                item.content
                                            )}
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        })
                    }
                </Grid>

                {/* Part IV  Comments from Researchers */}
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part IV&nbsp;&nbsp;&nbsp;Remarks from Researcher
                    </Grid>
                    <Grid container>

                        <ReportDraftEditor disabled={true} placeholder={'≤2000 characters'} value={state.remarksReq} />

                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Grid container className={classes.title}>
                        Part V&nbsp;&nbsp;&nbsp;Comments from HADCL Office
                    </Grid>
                    <Grid container>

                        <ReportDraftEditor disabled={disableSubmit} placeholder={'≤2000 characters'} isError={error.remarksRsp} value={state.remarksRsp} onChange={(e) => handleChangeTextArea(e, 'remarksRsp')} />
                    </Grid>
                </Grid>
                <Grid container className={classes.lineBottom} >
                    <Grid item container xs={3} alignContent='center' className={classes.label}>Status After Review by HADCL <span className={classes.red}> *</span>:</Grid>
                    <Grid item container xs={9} alignItems='center' className={classes.rightContent}>
                        <SelectComponent
                            disabled={disableSubmit}
                            displayEmpty={false}
                            value={status}
                            onChange={(v) => {
                                setStatus(v)
                                setIsDirty(true);
                            }}
                            style={{
                                textTransform: 'none',
                                fontSize: '13px',
                                width: '200px',
                                ...(error.status ? { border: '1px solid #ff0000' } : {})
                            }}
                            options={[

                                {
                                    value: 'REVISION',
                                    name: 'Revision Required'
                                },
                                {
                                    value: 'REVIEWED',
                                    name: 'Reviewed'
                                },
                                {
                                    value: 'UNDER_REVIEW',
                                    name: 'Under Review'
                                },
                                {
                                    value: 'WITHDRAWN',
                                    name: 'Withdrawn'
                                }
                            ]} />
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <div className={classes.mandatory}>*=Mandatory</div>
                </Grid>
                <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs>
                        {!disableSubmit && <Button style={{
                            backgroundColor: '#15a9d2 ',
                            color: '#ffff ',
                            marginRight: 10,
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={() => handleSave()}>Save</Button>}
                        <Button style={{
                            backgroundColor: '#646464 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleBackList}>{disableSubmit ? "Back to Summary" : "Cancel"}</Button>
                    </Grid>
                </Grid>
                {state.lastUpdatedName && <div style={{ paddingTop: 10 }}>Last updated by {state.lastUpdatedName} on {format(new Date(state.lastUpdatedDate), 'dd MMM yyyy HH:mm')} </div>}
            </Grid>
            <Prompt
                when={isDirty}
                message={(location) => handleOpenDirtyCheck({ location })}
            />

            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={handleCloseDialog}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </>
    );
}
