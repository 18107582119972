
export const getSearchParams = (search) => {
  if (!search) return ''
  const paramsMap = {};
  let temp = search?.substr(1, search.length - 1)?.split('&') || [];
  temp.forEach(element => {
      const t = element.split('=');
      paramsMap[t[0]] = t[1];
  });
  return paramsMap
}