import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useStyles } from "Styles";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from '../../CustomAxios';
import React from 'react';
import CreateUser from './createUser.dialog';
import EditUser from './editUser.dialog';
import { useMsg } from '../../hooks/MsgProvider';
import { GridColDef } from '../common/HadclFormatter';
import useModal from "hooks/useModal";
import ConfirmDialog from "components/common/ConfirmDialog";
import { confirmDialogUser } from '../common/Constants';
import HadclGrid from '../common/HadclGrid';
import useUser from '../../hooks/UseFindUser';

export default function UserManagement(props) {
    const classes = useStyles();
    const [createOpen, setCreateOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editId, setEditId] = React.useState(null);
    const [rowData, setRowData] = React.useState([]);
    const gridApi = React.useRef();
    const { setSuccessMsg, setErrMsg } = useMsg();
    const showModal = useModal();
    const { user } = useUser();
    const [projectLists, setProjectLists] = React.useState([])

    const changeRolePermission = [
        {
            role: 'HADCL_OFFICER',
            targetRoles: ["isHADCL", "isDutyOfficer", "isSelfService", "isCollaborate", "isWSParticipant"]
        },
        {
            role: 'DUTY_OFFICER',
            targetRoles: ["isDutyOfficer", "isSelfService", "isCollaborate", "isWSParticipant"]
        },
        {
            role: 'SYSTEM_ADMIN',
            targetRoles: ["isSystemAM", "isHADCL", "isDutyOfficer", "isPMOMember", "isSelfService", "isCollaborate", "isWSParticipant"]
        }
    ];

    const roleDisabled = (roleChb) => {
        for (let i = 0; i < changeRolePermission.length; i++) {
            if (user.roles.some(e => e === changeRolePermission[i].role)) {
                if (changeRolePermission[i].targetRoles.some(f => f === roleChb))
                    return false;
            }
        }
        return true;
    }

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };
    const handleCreateClose = () => {
        setCreateOpen(false);
        getUserList();
    };
    const handleEditOpen = (userId) => {
        setEditOpen(true);
        setEditId(userId);
    };
    const handleEditClose = () => {
        setEditOpen(false);
        getUserList();
    };
    const handleDeleteOpen = (userId, email) => {
        showModal((pros) => (
            <ConfirmDialog
                {...pros}
                description={confirmDialogUser(email)}
                onConfirm={() => { handleDeleteConfirm(userId); }}
                onCancel={() => console.log("Deletion cancelled.")}
            />
        ));
    };
    const handleDeleteConfirm = (userId) => {

        axios.post('/user/delete-user?userId=' + userId)
            .then(async (res) => {
                setSuccessMsg(res.data.message);
                getUserList();
            }).catch((err) => {
                setErrMsg(err);
            });
    }

    const columnDefs = [
        GridColDef({
            headerName: 'Action',
            field: 'notes',
            width: 100,
            autoHeight: false,
            sortable: false,
            filter: false,
            cellRendererFramework: (pros) => {
                return (
                    <div>
                        <EditIcon onClick={() => { handleEditOpen(pros.data.id); }}></EditIcon>
                        &nbsp;
                        <DeleteIcon onClick={() => handleDeleteOpen(pros.data.id, pros.data.email)}></DeleteIcon>
                    </div>
                );
            }
        }),
        GridColDef({
            headerName: 'Email',
            field: 'email',
            minWidth: 160,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Roles',
            field: 'roles',
            minWidth: 180,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Status',
            field: 'status',
            width: 120
        }),
        GridColDef({
            headerName: 'Locked',
            field: 'lockstatus',
            width: 60
        }),
        GridColDef({
            headerName: 'Ref. No. (Self-Service)',
            field: 'refnoselfserve',
            width: 190,
        }),
        GridColDef({
            headerName: 'Ref. No. (Project)',
            field: 'refnocollaborate',
            width: 190,
        })
    ];

    const onGridReady = (params) => {
        setFilterStatus(params);

        gridApi.current = params;
    }

    const setFilterStatus = (params) => {
        var filterComponent = params.api.getFilterInstance("status");

        filterComponent.setModel({
            type: "equals",
            filter: "Active"
        });

        filterComponent.onFilterChanged();
    }

    function clearFilter(event) {
        event.stopPropagation();
        event.preventDefault();

        gridApi.current.api.setFilterModel(null);
        gridApi.current.api.setSortModel(null);
        setFilterStatus(gridApi.current);
    }

    function getUserList() {
        axios.get('/user/user-list')
            .then(({ data: userList }) => {
                setRowData(userList);
            }
            )

    }
    function getProjectLists() {
        axios.get('/user/get-colProject-list')
            .then(({ data }) => {
                setProjectLists(data);
            }
            )

    }

    React.useEffect(function effectFunction() {
        getUserList();
        getProjectLists();
    }, []);

    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)' }}>
            <Typography variant="h5" gutterBottom xs={12}>
                User List
            </Typography>
            <div className={`ag-theme-alpine ${classes.hadclAgGrid}`} style={{ position: 'relative', zIndex: 0 }}>
                <HadclGrid rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} clearFilter={clearFilter} />
            </div>
            <br />
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleCreateOpen()}
                >
                    Create User
                </Button>
                <CreateUser _id={0} open={createOpen} projectLists={projectLists} handleClose={() => handleCreateClose()} roleDisabled={roleDisabled}
                    disableBackdropClick disableEscapeKeyDown />
                <EditUser _id={0} open={editOpen} userId={editId} projectLists={projectLists} handleClose={() => handleEditClose()} roleDisabled={roleDisabled}
                    disableBackdropClick disableEscapeKeyDown />
            </div>
        </Paper>
    )
}
