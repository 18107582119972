import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from "Styles";
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import axios from '../../CustomAxios';
import LoadingScreen from '../common/LoadingScreen';
import { useMsg } from '../../hooks/MsgProvider';
import DateRangePicker from './DateRangePicker';
import startOfDay from 'date-fns/startOfDay'
import useModal from "hooks/useModal";
import ConfirmDialog from "components/common/ConfirmDialog";
import { warning, error, confirmDialogSetup, confirmChangeCapacity } from '../common/Constants';
import Emun from 'enum/Enum.js';

function trimTime(value, originalvalue) {
    return startOfDay(value);
}

const schema = yup.object().shape({
    // isAvailable: yup.bool().required(),
    isAvaProject: yup.bool(),
    isAvaSelfService: yup.bool(),
    capacity: yup.number().when(['isAvaProject', 'isAvaSelfService'], {
        is: (isAvaProject, isAvaSelfService) => isAvaProject || isAvaSelfService,
        then: yup.number().min(1, warning.SetupCapacityValidation).max(999).integer().typeError(warning.SetupCapacity),
        otherwise: yup.number().min(0),
    }),
    reason: yup.string().max(1000),
    remarks: yup.string().max(1000)
});

const schemaRange = yup.object().shape({
    // isAvailable: yup.bool().required(),
    isAvaProject: yup.bool(),
    isAvaSelfService: yup.bool(),
    capacity: yup.number().when(['isAvaProject', 'isAvaSelfService'], {
        is: (isAvaProject, isAvaSelfService) => isAvaProject || isAvaSelfService,
        then: yup.number().min(1, warning.SetupCapacityValidation).max(999).integer().typeError(warning.SetupCapacity),
        otherwise: yup.number().min(0),
    }),
    startDate: yup.date().transform(trimTime).required().typeError(warning.SetupStartDate),
    endDate: yup.date().transform(trimTime).required().typeError(warning.SetupEndDate)
        .when('startDate', (startDate, sche) => {
            return sche.test({
                test: endDate => !!startDate && endDate > startDate,
                message: warning.SetupDateValidaiton
            })
        }),
    reason: yup.string().max(1000),
    remarks: yup.string().max(1000)
});

export default function AvaCapacityForm(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const { setSuccessMsg, setErrMsg } = useMsg();
    const showModal = useModal();
    const isIERef = React.useRef();

    let isWeekend = props.selectedDate && props.selectedDate.getDay() % 6 === 0;
    const isTrue = isWeekend ? false : true
    const defaultValues = {
        // isAvailable: false,
        isAvaProject: (props.selectedDate && [1, 2, 3, 4, 5, 6].indexOf(props.selectedDate.getDay() % 7) > -1) ? true : false,
        isAvaSelfService: props.selectedDate ? isTrue : false,
        reason: '',
        capacity: 0,
        remarks: '',
        startDate: null,
        endDate: null,
    };
    const { watch, handleSubmit, control, errors, reset, setValue, formState } = useForm({
        resolver: yupResolver(props.type === "single" ? schema : schemaRange),
        defaultValues,
    });

    const watchIsAvaProject = watch("isAvaProject");
    const watchIsAvaSelfService = watch("isAvaSelfService");

    const { isDirty } = formState;

    React.useEffect(() => {
        if (!watchIsAvaProject && !watchIsAvaSelfService) {
            setValue('capacity', '0');
        } else {
            setValue('reason', '');
        }
    }, [setValue, watchIsAvaProject, watchIsAvaSelfService])

    const fetchData = async () => {
        const result = await axios.get('/bookSetup', {
            params: {
                startDate: props.selectedDate,
                endDate: props.selectedDate
            }
        });
        if (result && result.data && result.data.length > 0) {
            let resultJson = {
                ...result.data[0]
            }
            if (isNaN(resultJson.available)) {
                resultJson = {
                    ...resultJson,
                    isAvaProject: resultJson.available.indexOf(Emun.AVAILABLE_BOOKING.COL_RESEARCHER) > -1,
                    isAvaSelfService: resultJson.available.indexOf(Emun.AVAILABLE_BOOKING.SELF_RESEARCHER) > -1
                }
            }

            reset(resultJson);
        }
        else
            reset(defaultValues);
    }


    const updateSetup = data => {
        axios.put('/bookSetup/', data).then(res => {
            setIsLoading(false);
            setSuccessMsg(res.data.message);
        }).catch(err => {
            setIsLoading(false);
            setErrMsg(err);
        });
    }

    const submitWork = data => {
        if (!isDirty) {
            setIsLoading(false);
            setErrMsg(error.SetupDirty);
            return false;
        }
        setIsLoading(true);

        axios.get('/bookSetup/capacity-above-approved', {
            //check after changing the capacity, would some day has more approved booking than capacity? If so, give them warning.
            params: {
                startDate: data.startDate,
                endDate: data.endDate,
                capacity: data.capacity
            }
        }).then(res => {
            updateSetup(data);
        }).catch(err => {
            showModal((pros) => (
                <ConfirmDialog
                    {...pros}
                    description={confirmChangeCapacity(err.response?.data?.message)}
                    onConfirm={() => { updateSetup(data); }}
                    onCancel={() => console.log("Deletion cancelled.")}
                />
            ));
        });

        setIsLoading(false);
    }

    const onSubmit = data => {
        if (!data.startDate)
            data.startDate = props.selectedDate;
        if (!data.endDate)
            data.endDate = props.selectedDate;

        let avaArr = [];
        if (data.isAvaProject)
            avaArr.push(Emun.AVAILABLE_BOOKING.COL_RESEARCHER);
        if (data.isAvaSelfService)
            avaArr.push(Emun.AVAILABLE_BOOKING.SELF_RESEARCHER);
        data.available = avaArr.join(',');
        delete data.isAvaProject;
        delete data.isAvaSelfService;

        if (data.startDate !== data.endDate) {
            showModal((pros) => (
                <ConfirmDialog
                    {...pros}
                    description={confirmDialogSetup(data)}
                    onConfirm={() => { submitWork(data); }}
                    onCancel={() => console.log("Deletion cancelled.")}
                />
            ));
        }
        else {
            submitWork(data);
        }
    }

    const handleResetSingle = () => { fetchData(); }
    const handleResetRange = () => { reset(defaultValues); }
    const handleReset = (props.type === "single" ? handleResetSingle : handleResetRange);
    const resetButtonText = props.type === "single" ? "Reset" : "Clear";

    React.useEffect(() => {
        isIERef.current = !(document.documentMode == undefined); //document.documentMode is a IE only property. If this is defined, it means user is using IE
        handleReset();
    }, [props.selectedDate]);

    const availabltCheckbox = (ps) => (
        <Checkbox
            name={ps.name}
            color="primary"
            onChange={(e) => { ps.onChange(e.target.checked); }}
            checked={ps.value}
        />
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {props.type === "range" && <DateRangePicker control={control} errors={errors} />}
            <div>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Controller
                                name="isAvaProject"
                                control={control}
                                render={availabltCheckbox}
                            />
                        }
                        name="isAvaProject"
                        label="Available for Project"
                    />
                </FormControl>
            </div>
            <div>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Controller
                                name="isAvaSelfService"
                                control={control}
                                render={availabltCheckbox}
                            />
                        }
                        name="isAvaSelfService"
                        label="Available for Self-service"
                    />
                </FormControl>
            </div>
            <FormControl fullWidth className={classes.input}>
                <Controller
                    as={TextField}
                    control={control}
                    name="reason"
                    label="Reason for Unavailable Access"
                    disabled={watchIsAvaProject || watchIsAvaSelfService}
                    multiline
                    rows={2}
                    error={!!errors.reason}
                    helperText={errors.reason?.message}
                    inputProps={{
                        maxLength: 1000,
                    }}
                />
            </FormControl>

            <FormControl fullWidth className={classes.input}>
                <Controller
                    as={TextField}
                    control={control}
                    type={isIERef.current ? "text" : "number"}
                    name="capacity"
                    label="Capacity"
                    disabled={!watchIsAvaProject && !watchIsAvaSelfService}
                    variant="outlined"
                    inputProps={{ min: 1 }}
                    error={!!errors.capacity}
                    helperText={errors.capacity?.message}
                />
            </FormControl>

            <FormControl fullWidth className={classes.input}>
                <Controller
                    as={TextField}
                    control={control}
                    name="remarks"
                    label="Remarks (Not more than 1000 characters)"
                    multiline
                    rows={2}
                    error={!!errors.remarks}
                    helperText={errors.remarks?.message}
                    inputProps={{
                        maxLength: 1000,
                    }}
                />
            </FormControl>
            <Box>
                <FormControl className={classes.input}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!props.allowUpdate}
                    >
                        Update
                    </Button>

                </FormControl>
                <FormControl className={classes.input}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="button"
                        onClick={() => handleReset()}
                    >
                        {resetButtonText}
                    </Button>

                </FormControl>
                {
                    props.children &&
                    <FormControl className={classes.input}>
                        {props.children}
                    </FormControl>
                }

            </Box>
            <LoadingScreen open={isLoading} />
        </form>
    );
}