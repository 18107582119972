import Dialog from '@material-ui/core/Dialog';
import React, { useState, useEffect } from 'react';
import axios from 'CustomAxios';
import {
    Grid,
    IconButton,
    Divider,
    TextField,
    Button,
    Typography,
    DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { startOfDay } from 'date-fns';
import addMonths from 'date-fns/addMonths';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 0,

    },
    signTitle: {
        fontSize: '25px',
        color: '#f58220'
    },
    tdl: {
        backgroundColor: ' #f3f3f3',
        padding: '10px',
        borderBottom: '1px solid #d2d2d2'
    },
    tdr: {
        padding: '10px',
        borderBottom: '1px solid #d2d2d2'
    },
    okBtn: {
        backgroundColor: '#f58220 !important',
        // padding: '10 24px !important',
        color: '#fff !important',
        textTransform: 'none !important',
        width: '100px !important',
        marginTop: '20px !important'
    },
    followup: {
        color: '#f58220',
        textDecoration: 'underline'
    },
    red: {
        color: '#ff0000'
    },
    infobox: {
        fontSize: '13px',
        width: '100%',
        color: '#000',
        marginTop: '10px'
    },
    activeButton: {
        backgroundColor: '#f58220 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    label: {
        color: '#000'
    }
}));

export default function SignInView(props) {
    const { onClose, open, userInfo } = props;
    const [userInfoState, setUserInfoState] = React.useState({
        'isSignIn': true,
        'refNum': '',
        'name': '',
        'university': '',
        'typeOfID': '',
        'id': '',
        'expiryDate': '',
        'staffExpiryDate': '',
        'studentID': '',
        'watchedVideo': false,
        'isPermanent': false,
        'seatsReserved': 0,
        'isFirstTime': true,
        'undertakingExpiryDate': '',
        workstationNo: '',
        lockerNo: '',
        remarks: '',
        adminTasks: []
    });
    const isExpiry = userInfoState && userInfoState.staffExpiryDate && startOfDay(new Date(userInfoState.staffExpiryDate)) < startOfDay(new Date());
    const isPermanent = userInfoState && userInfoState.isPermanent;
    const isLessOrEqual = userInfoState && !isExpiry && userInfoState.staffExpiryDate && startOfDay(new Date(userInfoState.staffExpiryDate)) <= startOfDay(addMonths(new Date(), 1));
    const isUnderExpiry = userInfoState && userInfoState.undertakingExpiryDate && startOfDay(new Date(userInfoState.undertakingExpiryDate)) < startOfDay(new Date());

    const classes = useStyles();

    React.useEffect(() => {
        if (userInfo) {
            setUserInfoState(userInfo);
        }
    }, [userInfo])


    const handleChangeUserInfo = (value, key) => {
        // console.log(value, key)
        setUserInfoState((state) => ({ ...state, [key]: value }))
    }

    const handleClose = () => {
        onClose({
            attendanceId: userInfoState.attendanceId,
            workstationNo: userInfoState.workstationNo,
            lockerNo: userInfoState.lockerNo,
            remarks: userInfoState.remarks,
        })
    }

    return (
        <>
            <Dialog
                open={open}
                disableEscapeKeyDown
                // maxWidth="xs"
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
            >
                <Grid container style={{ padding: '30px' }}>
                    <Grid container>
                        <Grid item className={classes.signTitle}>{userInfoState.isSignIn ? 'Sign In' : 'Sign Out'}</Grid>
                    </Grid>
                    <Grid container className={classes.infobox}>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Project no./Self-Service No.</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.refNum}</Grid>
                        </Grid>
                        <hr />
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Name</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.name}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>University</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.university}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Type of ID</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.typeOfID}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>ID</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.id}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Expiry Date of Staff/Student ID</Grid>
                            <Grid item xs={7} className={classes.tdr}><span className={isExpiry ? classes.red : null}>{isPermanent ? 'Permanent ID' : userInfoState.staffExpiryDate}</span>
                                {
                                    isLessOrEqual ?
                                        <>
                                            <br />
                                            “Expiry date less or equal to 1 month”
                                        </> : null
                                }
                            </Grid>
                        </Grid>
                        {/* <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Watched Video?</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.watchedVideo ? 'YES' : 'NO'}</Grid>
                        </Grid> */}
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>No. of Seats Reserved</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.seatsReserved}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>1st Time Visit?</Grid>
                            <Grid item xs={7} className={classes.tdr}>{userInfoState.isFirstTime ? 'YES' : 'NO'}
                                {
                                    userInfoState.isFirstTime ?
                                        <React.Fragment>&nbsp;<span className={classes.followup}>Please follow up</span></React.Fragment> : null
                                }
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Undertaking Form Expiry Date</Grid>
                            <Grid item xs={7} className={classes.tdr}><span className={isUnderExpiry ? classes.red : null}>{userInfoState.undertakingExpiryDate}</span></Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Workstation No.</Grid>
                            <Grid item xs={7} className={classes.tdr}>
                                <TextField InputProps={{
                                    style: {
                                        paddingLeft: 10,

                                    }
                                }} inputProps={{
                                    style: {
                                        padding: '4px 0 5px'
                                    },
                                    maxLength: 20
                                }} disabled={!userInfoState.isSignIn} value={userInfoState.workstationNo} onChange={(e) => { handleChangeUserInfo(e.target.value, 'workstationNo') }} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Locker No.</Grid>
                            <Grid item xs={7} className={classes.tdr}>
                                <TextField InputProps={{
                                    style: {
                                        paddingLeft: 10,

                                    }
                                }} inputProps={{
                                    style: {
                                        padding: '4px 0 5px'
                                    },
                                    maxLength: 20
                                }} disabled={!userInfoState.isSignIn} value={userInfoState.lockerNo} onChange={(e) => { handleChangeUserInfo(e.target.value, 'lockerNo') }} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} className={classes.tdl}>Remarks</Grid>
                            <Grid item xs={7} className={classes.tdr}>
                                <TextField multiline maxRows={3} InputProps={{
                                    style: {
                                        paddingLeft: 10,

                                    }
                                }} inputProps={{
                                    style: {
                                        padding: '4px 0 5px'
                                    },
                                    maxLength: 100
                                }} disabled={!userInfoState.isSignIn} value={userInfoState.remarks} onChange={(e) => { handleChangeUserInfo(e.target.value, 'remarks') }} />
                            </Grid>
                        </Grid>

                        {
                            userInfoState.adminTasks.map((item, index) => {
                                const isEnd = userInfoState.adminTasks.length - 1 === index;
                                return [
                                    <>{index === 0 && <Grid container >
                                        <Grid item xs={5} className={classes.tdl} style={{ fontWeight: 600, borderBottom: "none", paddingBottom: 0 }}>Admin Task</Grid>
                                    </Grid>}</>,
                                    <Grid container key={index}>
                                        <Grid item xs={5} className={classes.tdl} style={isEnd ? {} : { borderBottom: "none" }}>{item.desc}</Grid>
                                        <Grid item xs={7} className={classes.tdr} style={isEnd ? {} : { borderBottom: "none" }}><span className={!item.isChecked ? classes.red : null}>{item.isChecked ? 'Yes' : 'No'}</span></Grid>
                                    </Grid>
                                ]
                            })
                        }

                        <Button color="primary" className={classes.okBtn} onClick={handleClose}>
                            {'OK'}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}
