import React from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import LoadingScreen from 'components/common/LoadingScreen';
import SystemMessageDialog from 'components/common/SystemMessageDialog';

import useUser from 'hooks/UseFindUser';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SummaryPage from './SummaryPage';
import OutputDetail from './OutputDetail'
import PublicationDetail from './PublicationDetail';

export const OutputContext = React.createContext({});
export default function OutputComponent(props) {
    const classes = useStyles();
    const { user } = useUser();
    const [isLoading, setIsLoading] = React.useState(false);
    const [viewBy, setViewBy] = React.useState('all');
    const [isOutputRequest, setIsOutputRequest] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState('')

    const [selectedProject, setSelectedProject] = React.useState("");
    const [selectedProjectObj, setSelectedProjectObj] = React.useState("");

    const [isShowDetail, setIsShowDetail] = React.useState(false);
    const [isCreate, setIsCreate] = React.useState(true)
    const [isConfirm, setIsConfirm] = React.useState(false)
    const [showConfirm, setShowConfirm] = React.useState(false)
    const [currentDetail, setCurrentDetail] = React.useState(null);

    const [reload, setReload] = React.useState(false);
    const [notesHtml, setNotesHtml] = React.useState("");

    const handleCreate = () => {
        getNotesData()

    }
    const handleEdit = (detail) => {
        setIsShowDetail(true)
        setIsCreate(false)
        setCurrentDetail(detail)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    const handleBack = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setIsShowDetail(false)
        setShowConfirm(false)
        setIsConfirm(false)
        setCurrentDetail(null)
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }

    const getNotesData = () => {
        setIsLoading(true);
        const projectStr = viewBy == "project" ? "PROJECT_OUTPUT" : "SPD_OUTPUT"
        const type = isOutputRequest ? projectStr : "PROJECT_PUBLICATION"
        axios.get('/information/get-notes-by-type', { params: { type: type } }).then((res) => {
            console.log(res)
            setIsLoading(false);

            if (res.data) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setNotesHtml(res.data.content)
                setIsShowDetail(true)
                setIsCreate(true)
                setShowConfirm(false)
                setIsConfirm(false)
            } else {
                // setBaseState({})
            }

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }
    const showNotes = () => {
        return <Grid container flexDirection={'column'} style={{ height: '100%' }}>
            <Grid item xs={'auto'}>
                <Typography variant="h5" gutterBottom xs={12}>
                    Submit New {isOutputRequest ? 'Output Request' : 'Publication for review'}
                </Typography>
            </Grid>
            <Grid container item xs>
                <Grid container style={{ borderRadius: 18, backgroundColor: '#f1f1f1', flexDirection: 'column' }}>
                    <Grid container style={{
                        fontSize: 25,
                        fontWeight: 600,
                        padding: '25px 10px 10px',
                    }} justifyContent="center">Important notes</Grid>
                    <Grid item xs style={{ position: 'relative' }}>
                        <div style={{ paddingLeft: 30, paddingRight: 30, position: 'absolute', top: 0, left: 0, width: "100%", height: '100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: notesHtml }}>

                        </div>
                    </Grid>
                    <div style={{ padding: '10px 20px' }}>

                        <Button
                            startIcon={checkIcon(isConfirm)}
                            style={{
                                textTransform: 'none',
                                lineHeight: '1.5',
                                fontSize: '13px'
                            }}
                            onClick={() => { setIsConfirm(!isConfirm) }}
                        >
                            <div style={{ color: '#000' }}>I confirm that I have fully read and understand the above important notes.*</div>
                        </Button>
                        <div style={{ color: '#f00', paddingLeft: 10 }}>*=Mandatory</div>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={'start'} style={{ paddingTop: 15 }}>
                <Button style={{
                    ...(isConfirm ? {
                        backgroundColor: '#15a9d2 ',
                        color: '#ffff ',
                    } : {
                        backgroundColor: '#dfdfdf ',
                        color: '#ffff ',
                    }),
                    textTransform: 'none',
                    // lineHeight: 1.2
                }} disabled={!isConfirm} onClick={handleNext}>Next</Button>
            </Grid>
        </Grid>
    }
    const handleNext = () => {

        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setShowConfirm(true)
    }
    const showDetail = () => {
        return isOutputRequest ? <OutputDetail /> : <PublicationDetail />
    }
    const isShowNotes = () => {
        return isCreate && !showConfirm ?
            <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>
                {showNotes()}
            </Paper> :
            <Paper className={classes.paper} style={{ boxShadow: 'unset' }}>
                {showDetail()}
            </Paper>
    }
    return (
        <OutputContext.Provider value={{
            setIsLoading, isOutputRequest, setErrorMsg, setIsOutputRequest,
            viewBy, setViewBy, selectedProject, setSelectedProject,
            handleCreate, handleEdit, isCreate, handleBack, currentDetail, isShowDetail, reload, setReload, setSelectedProjectObj, selectedProjectObj, user
        }}>

            {
                isShowDetail ? isShowNotes()
                    :
                    <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>
                        <SummaryPage />
                    </Paper>
            }
            <LoadingScreen open={isLoading} />
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}

        </OutputContext.Provider>
    );
}
