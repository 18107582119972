import React, { useState, useCallback, Fragment } from "react";
import ModalContext from "./ModalContext";

const ModalProvider = ({ children }) => {
  const [render, setRender] = useState(null);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const modal = useCallback((crender) => {
    setRender(() => crender);
    setOpen(true);
  }, []);

  return (
    <Fragment>
      <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
      {render && render({ open, onClose })}
    </Fragment>
  );
};

export default ModalProvider;
