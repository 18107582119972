import { useState, useEffect } from 'react';
import axios from '../CustomAxios';

export default function useSysParam(code) {
    const [param, setParam] = useState([]);

    const getParam = async () => {
        return axios.get(`/information/get-param`, {
            params: {
                code
            }
        }).then(async (res) => {
            setParam(res.data);
        });
    }

    useEffect(() => {
        if (code)
            getParam();
    }, [code]);

    return {
        param, paramIntValue: param?.intValue, paramTextValue: param?.textValue
    }
}