import React, { useState, useRef, useEffect } from 'react';
import {
    Divider,
    Grid,
    Select,
    MenuItem,
    ListItemText,
    makeStyles,
    Input
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    
    bookingModSelected: {
        // width: 160,
        border: '1px solid #d2d2d2 ',
        borderRadius: '5px',
        color: 'black !important',
        fontSize: '15px',
        padding: '0',
        backgroundColor: '#fff',
        '&:before, &:after': {
            borderBottom: '0px !important'
        },
        '& .MuiSelect-select': {
            paddingLeft: '10px'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff'
        }
    },
    modSelectedPopover: {
        '& .MuiPaper-root': {
            minWidth: 250,
            marginTop: '10px',
            maxHeight: 450,
        },
        '& .MuiList-root': {
            padding: '0'
        },
    },
    tag: {
        display: 'flex',
        backgroundColor: '#f5f5f5',
        border: '1px solid #b5b5b5',
        borderRadius: 5,
        padding: '0 5px'
    }
}));
const SelectComponent = ({onChange, value = '', options = [], valueField = 'value', nameField = 'name',multiple = false,multipleIsEnd=false, placeholder = 'Please select', allText= 'Select all', displayEmpty=true,cls='',optionclick, style={},...props}) => {
    const classes = useStyles();
    const tagRef = useRef(null);
    const tempOptionsObjRef = useRef(null);

    useEffect(() => {
        const o = {};
        !multiple && options&& options.length>0 && options.forEach((item) => {
            o[item[valueField]] = item;
        })
        tempOptionsObjRef.current = o;
    }, [options, options.length])
    const [tempValue, setTempValue] = useState(value)

    useEffect(() => {
        if (multiple && value.length === options.length && options.length!==0) {
            setTempValue(['all'])
        } else {
            setTempValue(value)
        }
        
    }, [value])
   
    const handleChange = (e, t,r) => {
        if (e.nativeEvent.target == tagRef.current) return
        let v = e.target.value;
        let objValue = {};
        if (multiple) {
            if (v[v.length - 1] === '') {
                v = [];
            }
            if (v.length >1 && v[0] === 'all') {
                const temp = [...value];
                const index = temp.indexOf(v[1]);
                temp.splice(index, 1);
                v = temp;
            }
            
            if (v[v.length - 1] === 'all') {
                v = options.map((item) => item[valueField]);
            }
        } else {
            objValue = tempOptionsObjRef.current[v]
        }
        onChange(v, objValue)
    }
    const getValueMultiple = (selected) => {
        const res = [];
        options.forEach((item) => {
            if (selected.includes(item[valueField])) {
                res.push(item[nameField])
            }
        })
        return res
    }
    const getValueSinge = (selected) => {
        let res = ''
            options.forEach((item) => {
                if (selected === item[valueField]) {
                    res = item[nameField]
                    return res
                }
            })
            return res;
    }
    

    const isSelect = (v, select) => {
        if (multiple && Array.isArray(select)) {
            
            return select?.includes(v)
        }
        return v === select;
    }
    const onDele = (e, index, item) => {
        e.stopPropagation();
        const newValue = [...value];
        let i = newValue.indexOf(item)
        newValue.splice(i, 1);
        onChange(newValue);
    }
    return (<>
        <Select
            value={tempValue}
            className={`${classes.bookingModSelected} ${cls}`}
            onChange={handleChange}
            multiple={multiple}
            input={<Input />}
            displayEmpty={true}
            renderValue={(selected) => {
                if (selected === '' || selected === undefined) {
                    return placeholder;
                }
                if (multiple && Array.isArray(selected)) {
                    if (selected.length === 1 && selected[0] === 'all') {
                        return allText
                    }
                    if (selected.length === 0) {
                      return placeholder;
                    }
        
                    return getValueMultiple(selected).join(', ');
                }
                return getValueSinge(selected)
              }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null,
                PopoverClasses: { root: classes.modSelectedPopover },
                MenuListProps: {

                },
                PaperProps: {
                    
                  },
            }}
            style={{...style}}
            IconComponent={ExpandMoreIcon}
            {...props}
        >
            {multiple && tempValue.length > 0 && <Grid ref={tagRef} style={{ padding: 5, width: 250 }} spacing={1} container>{
                getValueMultiple(tempValue).map((item, i) => {
                    return <Grid item key={item+ '_'+i} onClick={(e) => { e.stopPropagation();}}><span className={classes.tag}>{item} <CloseIcon style={{cursor: 'pointer'}} onClick={(e) => onDele(e, i, item)}/></span></Grid>
                })
            }</Grid>}
            <MenuItem value={''} style={{display: displayEmpty ? 'block':'none'}}>
                <em>{placeholder}</em>
            </MenuItem>
            
            {multiple && options.length > 0 && !multipleIsEnd && [
                <Divider flexItem style={{ pointerEvents: 'none' }} />,
                <MenuItem value={'all'}>
                    <ListItemText primary={allText} style={{ margin: '0', color: '#000', }} />
                    {isSelect('all', tempValue) && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                </MenuItem>
            ]}
            {options.map((item, index) => {
                return [
                    (<Divider flexItem style={{ pointerEvents: 'none' }} />),
                    <MenuItem key={index} name={item} value={item[valueField]} disabled={item?.disabled} style={{ backgroundColor: '#fff' }} onClick={() => {
                        optionclick && optionclick(item[valueField], item)
                    }}>
                        <ListItemText primary={item[nameField]} style={{ margin: '0', color: '#000', }} />
                        {isSelect(item[valueField], value) && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                    </MenuItem>
                ]
            })}
            {multiple && options.length > 0 && multipleIsEnd && [
                <Divider flexItem style={{ pointerEvents: 'none' }} />,
                <MenuItem value={'all'}>
                    <ListItemText primary={allText} style={{ margin: '0', color: '#000', }} />
                    {isSelect('all', tempValue) && <CheckIcon style={{ marginLeft: '10px', color: '#f58220' }} />}
                </MenuItem>
            ]}
        </Select>
    </>)
}

export default SelectComponent;
