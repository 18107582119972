import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
    , isAfter

} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import LoadingScreen from 'components/common/LoadingScreen';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';
import useUser from 'hooks/UseFindUser';
import OutputRequestList from './OutputRequestList'
import PublicationReviewList from './PublicationReviewList'
import CustomTooltip from 'components/common/CustomTooltip';
import { OutputContext } from './index';
export default function SummaryPage(props) {
    const classes = useStyles();
    const {
        setIsLoading, isOutputRequest, setErrorMsg, setIsOutputRequest,
        viewBy, setViewBy, selectedProject, setSelectedProject,
        handleCreate, handleEdit, reload, setSelectedProjectObj,
    } = React.useContext(OutputContext)

    const [userProjectList, setUserProjectList] = React.useState([]);
    const [SDPList, setSDPList] = React.useState([]);
    const { user } = useUser();

    const [isCOL, setIsCOL] = React.useState(false);


    React.useEffect(() => {

        if (user) {
            user.roles.forEach(role => {
                if (role.toUpperCase().includes("COL_RESEARCHER")) {
                    setIsCOL(true)
                } else if (role.toUpperCase().includes("SELF_RESEARCHER")) {
                    // setIsSDP(true)
                }
            });
        }

        // setHaveOutstandingReport(localStorage.getItem('haveOutstandingReport'))
    }, [user])

    React.useEffect(() => {
        getUserProject()
    }, [])

    const loadData = (callback) => {


        const payload = {
            projectId: 0
        }
        // if (viewBy !== 'all') { 
        //     if (!selectedProject) { 
        //         callback({data:[]})
        //         return 
        //     }
        //     payload.projectId = selectedProject
        // }
        payload.projectId = selectedProject || 0;
        if (isOutputRequest) {
            payload.type = "OUTPUT"
        } else {
            payload.type = "PUBLICATION"
        }
        if (viewBy === 'project')
            payload.projectType = 'COL_RESEARCHER';
        else if (viewBy === 'SDP')
            payload.projectType = 'SELF_RESEARCHER';

        setIsLoading(true);
        axios.get('/outputRequest/get-output-requests-res', { params: payload }).then((res) => {
            setIsLoading(false);
            callback(res)
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const getUserProject = () => {
        axios.get('/outputRequest/get-user-projects').then((res) => {
            // console.log(res)
            let tempSPD = [];
            let tempSDP = [];
            if (res.data) {
                res.data.forEach((item) => {
                    if (item.userTypeCode == 'COL_RESEARCHER') {
                        tempSPD.push(item)
                    } else if (item.userTypeCode == 'SELF_RESEARCHER') {
                        tempSDP.push(item)
                    }
                })
                setUserProjectList(tempSPD)
                setSDPList(tempSDP)
            } else {
                setUserProjectList([])
                setSDPList([])
            }

        }).catch(err => {
            console.log(err);
        })
    }

    const handleSelectProject = (item, obj) => {
        setSelectedProject(item)
        setSelectedProjectObj(obj)
    }


    const handleChangeRadio = (value) => {
        if (value === viewBy) return

        if (value == 'SDP') {
            setIsOutputRequest(true)
        }
        setViewBy(value)
        setSelectedProject('')
        setSelectedProjectObj({})
        // setViewDate(startOfMonth(new Date()))
    }
    const checkIcon = (value) => {
        return value ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />
    }
    const newRequestButton = () => {
        return <Button style={{
            ...(viewBy != 'all' && selectedProject ? {
                backgroundColor: '#15a9d2 ',
                color: '#ffff ',
            } : {
                backgroundColor: '#dfdfdf ',
                color: '#ffff ',
            }),
            textTransform: 'none',
            // lineHeight: 1.2
        }} disabled={viewBy == 'all' || !selectedProject} className={classes.activeButton} onClick={handleCreate}>Submit New Request</Button>
    }
    return (
        <Grid container flexDirection={'column'} style={{ height: '100%' }}>
            <Grid item xs={'auto'}>
                <Typography variant="h5" gutterBottom xs={12}>
                    Output Request / Publication Review Record
                </Typography>
            </Grid>
            <Grid container style={{ paddingTop: 10 }} >
                <Grid item style={{ lineHeight: '28px' }}>
                    View by
                </Grid>
                <Grid item >
                    <Button
                        startIcon={checkIcon(viewBy == 'all')}
                        // className={classes.button}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('all')}

                    >
                        <div style={{ color: '#000' }}>All Records</div>
                    </Button>
                    <Button
                        startIcon={checkIcon(viewBy == 'project')}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('project')}
                        disabled={userProjectList.length == 0}
                    >
                        <div style={{ color: '#000' }}>Project</div>
                    </Button>
                    <SelectComponent disabled={viewBy != 'project'} value={viewBy == 'project' ? selectedProject : ''} onChange={handleSelectProject} style={{
                        textTransform: 'none',
                        fontSize: '13px',
                        minWidth: '100px',
                        maxWidth: '200px'
                    }} nameField="refNum" valueField="projectId" options={userProjectList} />
                    <Button
                        startIcon={checkIcon(viewBy == 'SDP')}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px'
                        }}
                        onClick={() => handleChangeRadio('SDP')}
                        disabled={SDPList.length == 0}
                    >
                        <div style={{ color: '#000' }}>Self-Service</div>
                    </Button>
                    <SelectComponent disabled={viewBy != 'SDP'} value={viewBy == 'SDP' ? selectedProject : ''} onChange={handleSelectProject} style={{
                        textTransform: 'none',
                        fontSize: '13px',
                        minWidth: '100px',
                        maxWidth: '200px'
                    }} nameField="refNum" valueField="projectId" options={SDPList} />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                    {viewBy == 'all' || !selectedProject ? <CustomTooltip title={"Please select a project or self-service first"}>
                        <span>
                            {newRequestButton()}

                        </span>
                    </CustomTooltip> : newRequestButton()}

                </Grid>
            </Grid>
            <div style={{ minHeight: 40 }}>
                <Button
                    startIcon={checkIcon(isOutputRequest)}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => { setIsOutputRequest(true) }}

                >
                    <div style={{ color: '#000' }}>Output Request</div>
                </Button>
                {viewBy != 'SDP' && isCOL && <Button
                    startIcon={checkIcon(!isOutputRequest)}
                    style={{
                        textTransform: 'none',
                        lineHeight: '1.5',
                        fontSize: '13px'
                    }}
                    onClick={() => { setIsOutputRequest(false) }}

                >
                    <div style={{ color: '#000' }}>Publication Review </div>
                </Button>}
            </div>
            <Grid item xs>
                {isOutputRequest ? <OutputRequestList reload={reload} handleEdit={handleEdit} selectedProject={selectedProject} loadData={loadData} setErrorMsg={setErrorMsg} setIsLoading={setIsLoading} viewBy={viewBy} /> : <PublicationReviewList reload={reload} handleEdit={handleEdit} selectedProject={selectedProject} loadData={loadData} setErrorMsg={setErrorMsg} setIsLoading={setIsLoading} viewBy={viewBy} />}

            </Grid>
            <Grid container>

                <Grid container style={{ fontSize: 15 }}>
                    <Grid container>Notes: </Grid>
                    {isOutputRequest && <Grid container alignItems={'center'} style={{ paddingLeft: 8 }}><span style={{ width: 3, height: 3, borderRadius: '50%', backgroundColor: '#000', marginRight: 5 }} />Download link of the output will be sent to you by email upon approval. All output has to be collected by the output retention date.</Grid>}
                    <Grid container alignItems={'center'} style={{ paddingLeft: 8 }}><span style={{ width: 3, height: 3, borderRadius: '50%', backgroundColor: '#000', marginRight: 5 }} />Please contact HADCL Office at &nbsp;<a style={{ color: '#f58220', textDecoration: 'underline' }} href={`mailto:hadcl@ha.org.hk`}>hadcl@ha.org.hk</a>&nbsp; if you encounter any problem.</Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}
