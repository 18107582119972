import { useState, useEffect } from 'react';
import axios from '../CustomAxios';
export default function useFindUser() {
   const [user, setUser] = useState(null);
   const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        if (localStorage.getItem('token'))
            (axios.defaults.headers.common['Authorization'] = localStorage.getItem('token'));
   async function findUser() {
     await axios.get('/user')
        .then(res => {
        setUser(res.data);
        setLoading(false);
     }).catch(err => {
        setLoading(false);
    });
  }
   findUser();
}, []);
return {
    user,
    setUser,
   isLoading
   }
}