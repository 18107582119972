import React, { useImperativeHandle, forwardRef} from "react";
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { Link, useHistory, Link as DomLink } from 'react-router-dom';
import axios from 'CustomAxios';
import {
    format
} from 'date-fns';
const GoReportDialog = forwardRef((props, ref) => {
    const [dialogMsg, setDialogMsg] = React.useState('');
    let history = useHistory();
    const getReportStatus = (projectId, callback) => {
        axios.get('/progressReport/get-is-report-outstanding', { params: { projectId: projectId } }).then((res) => {
            callback(res.data)
            if (res.data) {
                setDialogMsg(`The progress report for ${res.data.refNum} for ${format(new Date(res.data.reportingMonth), 'MMM yyyy')} <br/> is still outstanding. <br/> Please contact PI to submit the progress <br/>report to unlock HADCL access.`)
               
            } else {
                
                setDialogMsg('')
                
            }
            
        }).catch(err => {
            console.log(err);
        })
    }
    useImperativeHandle(ref, () => {
        return {
          getReportStatus(id, callback) {
            return getReportStatus(id, callback);
          },
    
        };
    });
    const handleCloseDialogMsgDialog = () => {
       
        history.push(`/progress-reports`);
        setDialogMsg('')
        props.onClose && props.onClose();
    }
    return <>
       
        {dialogMsg && <SystemConfirmDialog open={!!dialogMsg} onClose={() => {setDialogMsg('')}} style={{ whiteSpace: "pre-wrap" }}
            onConfirm={handleCloseDialogMsgDialog}
            okText="Progress Report"
            >
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: dialogMsg }} />
            </SystemConfirmDialog>}
    </>
})

export default GoReportDialog