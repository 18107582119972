import Dialog from '@material-ui/core/Dialog';
import React, { useState, useEffect } from 'react';
import axios from 'CustomAxios';
import {
    Grid,
    IconButton,
    Button,
    Typography,
    DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import SelectComponent from 'components/common/CommonSelect';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useUser from 'hooks/UseFindUser';
import CloseIcon from '@material-ui/icons/Close';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import bwipjs from 'bwip-js';
import { NotSignOutViewContext } from 'components/accessQrCode/NotSignOut';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 0,

    },
    title: {

        height: 40,
        paddingLeft: 20,
        fontSize: 13,
        color: '#f58220',
        backgroundColor: '#ffefe1'
    },
    red: {
        color: '#ff0000'
    },
    activeButton: {
        backgroundColor: '#f58220 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    label: {
        color: '#000'
    }
}));

export default function AccessQrCodePC(props) {
    const {
         open,  minute, setMinute, hour, setHour, isCorrect, setIsCorrect
        , isComply, setIsComply
        , isError, isLeavingTimeError
        , renderMinuteOption, checkIcon, submit
        , fDate, hourOption
    } = React.useContext(NotSignOutViewContext)

    const classes = useStyles();
   

    const renderMessage = () => {
        return <>
            <DialogTitle
                style={{ textAlign: 'center', padding: '10px', position: 'relative', }}>
                <Typography color={'primary'} style={{ fontSize: 25 }}>
                    {'System Message'}
                </Typography>


            </DialogTitle>
            <Grid container style={{ paddingLeft: 10 }}>
                {/* 11/02/2022 */}
                <div style={{ fontSize: '13px', marginBottom: 10 }}>
                    You did not sign out on {fDate} according to our record. Please complete the following to reactivate your account.
                </div>
                <Grid container style={{ fontSize: '13px', height: 40, alignItems: 'center', borderBottom: '1px solid #d2d2d2' }}>
                    <Grid item container style={{ width: 120, backgroundColor: '#f3f3f3', height: '100%', alignItems: 'center', paddingLeft: 10 }}>Date: </Grid>
                    <Grid item style={{ flex: 1, paddingLeft: 10 }}>{fDate}</Grid>
                </Grid>
                <Grid container style={{ fontSize: '13px', height: 40, alignItems: 'center', borderBottom: '1px solid #d2d2d2', marginBottom: 10 }}>
                    <Grid item container style={{ width: 120, backgroundColor: '#f3f3f3', height: '100%', alignItems: 'center', paddingLeft: 10 }}>Leaving time:<span className={classes.red}>*</span> </Grid>
                    <Grid item container style={{ flex: 1, paddingLeft: 10 }} alignItems='center'>
                        <Grid item style={{ width: 60, marginRight: 10 }}>
                            <SelectComponent placeholder='HH' displayEmpty={false} value={hour} onChange={(v) => { setHour(v) }} style={{
                                textTransform: 'none',
                                fontSize: '13px',
                                width: 60,
                                ...(isLeavingTimeError || (isError && !hour) ? { border: '1px solid #ff0000' } : {})
                            }} options={hourOption} />
                        </Grid>
                        {/* <Grid item style={{ width: 50, paddingLeft: 5 }}>Hr </Grid> */}
                        <Grid item>
                            <SelectComponent placeholder='MM' displayEmpty={false} value={minute} onChange={(v) => { setMinute(v) }} style={{
                                textTransform: 'none',
                                fontSize: '13px',
                                width: 60,
                                ...(isLeavingTimeError || (isError && !hour) ? { border: '1px solid #ff0000' } : {})
                            }} options={renderMinuteOption()} />
                        </Grid>
                        {/* <Grid item style={{ paddingLeft: 5 }}>Minute </Grid> */}
                    </Grid>
                </Grid>
                <Grid container>
                    <Button
                        startIcon={checkIcon(isCorrect)}
                        className={classes.selDateButton}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px',
                            marginBottom: 5,
                            ...(isError && !isCorrect ? { border: '1px solid #ff0000' } : {})
                        }}
                        onClick={() => { setIsCorrect(!isCorrect) }}
                    >
                        <div className={classes.label}>I certify the above leaving time is correct and accurate.<span className={classes.red}>*</span></div>
                    </Button>
                </Grid>
                <Grid container style={{ marginBottom: 10 }}>
                    <Button
                        startIcon={checkIcon(isComply)}
                        className={classes.selDateButton}
                        style={{
                            textTransform: 'none',
                            lineHeight: '1.5',
                            fontSize: '13px',
                            ...(isError && !isComply ? { border: '1px solid #ff0000' } : {})
                        }}
                        onClick={() => { setIsComply(!isComply) }}
                    >
                        <div className={classes.label}> I will comply with HADCL rules and sign out for coming access.<span className={classes.red}>*</span></div>
                    </Button>
                </Grid>
                <div style={{ fontSize: '13px', marginBottom: 10, fontWeight: 600 }}>Notes: Please be reminded to sign-out when leaving HADCL. Your access right may be impacted if multiple failure to sign-out are identified. </div>
                <div style={{ fontSize: '13px', marginBottom: 10 }}><span className={classes.red}>*=mandatory</span></div>
                <Grid container >
                    <Button className={classes.activeButton} onClick={() => { submit() }}>Submit</Button>
                </Grid>
                {isLeavingTimeError && <Grid container style={{ paddingTop: 10, fontSize: 13 }} className={classes.red}>Leaving time should be later than Sign-in time. Please input again.</Grid>}
                {isError && <Grid container style={{ paddingTop: 5, fontSize: 13 }} className={classes.red}>Please complete all mandatory fields.</Grid>}
            </Grid>
        </>
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    // isQrCode && onClose()
                }}
                disableEscapeKeyDown
                // maxWidth="xs"
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
            >
                {/* <QRCodeSVG value="https://reactjs.org/" /> */}
                <Grid container style={{ width: 600, padding: '5px 20px 20px' }}>
                    {renderMessage()}
                </Grid>
            </Dialog>
        </>
    )
}
