import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    leftWrapper: {
        backgroundColor: '#F1F1F1',
        width: '200px',
        height: '100%',
        overflowY: 'auto',
        fontSize: '13px',
        overflowX: 'hidden',
        paddingBottom: '10px'
    },
    rightWrapper: {
        width: 'calc(100% - 200px)',
        backgroundColor: 'white'
    },
    projectTitle: {
        width: '142px',
        paddingTop: '10px',
        paddingBottom: '0px',
    },
    projectLabel: {
        width: '155px',
        textAlign: 'left',
        fontSize: '12px'
    },
    projectButton: {
        height: '32px',
    },
    titleWrapper: {
        // height: '30px',
        // width: '100%'
    },
    calendarWrapper: {
        flex: 'auto',
        overflow: 'auto',
        width: '100%',
        padding: '4px 8px',
        color: '#000'
    },
    titleFontRoot: {
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px !important',
        },
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        paddingLeft: '8px'
    },
    labelWrapper: {
        width: '100%',
        height: '24px',
        paddingLeft: '8px',
        fontSize: '13px'
    },
    activeButton: {
        backgroundColor: '#15A9D2 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    inActiveButton: {
        backgroundColor: '#dfdfdf !important',
        color: 'rgba(0, 0, 0, 0.26) !important',
        textTransform: 'none !important'
    },
    cancelButton: {
        backgroundColor: '#646464 !important',
        color: '#ffff !important',
        textTransform: 'none !important'
    },
    selDateButton: {
        height: '32px',
        width: '100% !important',
        paddingRight: '0 !important',
        '& .MuiButton-startIcon': {
            marginRight: '3px !important',
            marginLeft: '-1px !important'
        }
    },
    selDateLabel: {
        width: '165px',
        color: 'black',
        textAlign: 'left',

    },
    selDateText: {
        '& .MuiInputBase-root': {
            padding: '5px',
            fontSize: '13px !important'
        },
        width: '185px'
    },
    bookingModSelected: {
        width: '160px',
        border: '1px solid #d2d2d2 !important',
        borderRadius: '5px',
        color: 'black !important',
        fontSize: '15px',
        padding: '0',
        backgroundColor: '#fff',
        '&:before, &:after': {
            borderBottom: '0px !important'
        },
        '& .MuiSelect-select': {
            paddingLeft: '10px'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff'
        }
    },
    ResNoText: {
        backgroundColor: 'white',
        width: '50px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important'
        },
        '& input': {
            textAlign: 'center'
        },
    },
    Reason: {
        backgroundColor: 'white',
        width: '160px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important'
        },
        '& input': {
            paddingLeft: 5
        },
        
    },
    DateText: {
        backgroundColor: 'white',
        width: '160px',
        '& .MuiInputBase-root': {
            fontSize: '13px !important'
        },
        '& input': {
            paddingLeft: 10,
            paddingTop: 5
        },
        '& input::-webkit-input-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
        '& input::-moz-placeholder': {
            color: '#000 !important',
            opacity: 1
        },
    },
    modSelectedPopover: {
        '& .MuiPaper-root': {
            marginTop: '10px'
        },
        '& .MuiList-root': {
            padding: '0'
        },
    },
    iconOpen: {
        transform: 'rotate(90deg)'
    },
    mainColorTitle:{
        color: theme.palette.primary.main,
    },
    outlinedDatePicker:{
        marginTop: 0,
        '& .MuiOutlinedInput-input':{
            padding:"4px 5px"
        },
        '& .MuiIconButton-root':{
            padding:3
        }
    },
    controlLabel:{
        marginBottom:0
    },
    notTextTransform:{
        textTransform: 'none !important'
    },
    emailTable: {
        width: 458,
        paddingTop: 10
    },
    emailTableHead:{
        backgroundColor: '#ffefe1',
        color: '#f58220',
        height: 30,
        lineHeight: '30px',
        fontSize: 15
    },
    emailTableBody: {
        maxHeight: 187,
        overflowY: 'auto',
        border: '1px solid #d2d2d2',
        borderRadius: 3,
        paddingLeft: 0
    },
    emailTableTd1: {
        width: 200,
        paddingLeft: 10,
        wordBreak: 'break-all'
    },
    emailTableTd2: {
        flex: 1
    },
    
    emailTableTr: {
        padding: '5px 0', 
        lineHeight: '20px',
        fontSize: 13,
        borderTop: '1px solid #d2d2d2'
    },
    selTableButton: {
        
        '& .MuiButton-startIcon': {
            marginRight: '0 !important'
        }
    },
}));