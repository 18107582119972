import React, { forwardRef,  useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import TimePicker from '@material-ui/lab/TimePicker';
import { useStyles } from "./styles";
import { AttendanceGridContext } from './AttendanceGridTable'
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
const AttendanceTimePicker = ({ value, onChange, id, ...res }) => {
    const { editStatusRef } = useContext(AttendanceGridContext)
    const classes = useStyles();
    const [time, setTime] = React.useState(value);
    const [error, setError] = React.useState(false);

    const checkTimeError = (ctime) => {
        if (ctime) {
            if (isAfter(new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 09:00'), ctime)
                || isAfter(ctime, new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 20:00'))) {
                return true;
            }
        }
        return false
    }
    const isEarlier = (newValue, isSignIn) => {
        if (isSignIn) {
            return (editStatusRef.current.signoutTime && isAfter(newValue, new Date(editStatusRef.current.signoutTime)))
        }
        let signinTime = editStatusRef.current.signinTime && new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' ' + format(new Date(editStatusRef.current.signinTime), 'HH:mm'))
        return signinTime && isAfter(signinTime, newValue)
    }
    const handleEmptyValue = (svalue) => {
        onChange(svalue)
        if (id !== 'signinTime') {
            editStatusRef.current.isSignOutError = false
            editStatusRef.current.signOutErrorMsg = ''
            setError(false)
        }
    }
    const handleSignInTime = (svalue) => {
        setTime(svalue)
        editStatusRef.current.isError = false
        editStatusRef.current.errorMsg = ''
        if (!svalue) {
            handleEmptyValue(svalue)
            return
        }
        if (!isValid(svalue)) {
            editStatusRef.current.isError = true
            editStatusRef.current.errorMsg = 'Invalid time'
            setError(true)
            return
        }
        
        let newValue = new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' ' + format(svalue, 'HH:mm'))
        
        
        onChange(newValue)
        
        if (id === 'signinTime') {
            if (isEarlier(newValue, true)) {
                editStatusRef.current.isSignInError = true
                editStatusRef.current.signInErrorMsg = 'Sign-out time cannot be earlier than Sign-in time'
                setError(true)
            } else if (checkTimeError(newValue)) {
                editStatusRef.current.signInErrorMsg = 'Only can input 9am - 8pm'
                editStatusRef.current.isSignInError = true
                setError(true)
            } else {
                editStatusRef.current.isSignInError = false
                editStatusRef.current.signInErrorMsg = ''
                setError(false)
            }
        } else {
            if (isEarlier(newValue, false)) {
                editStatusRef.current.signOutErrorMsg = 'Sign-out time cannot be earlier than Sign-in time'
                editStatusRef.current.isSignOutError = true
                setError(true)
            } else if (checkTimeError(newValue)) {
                editStatusRef.current.signOutErrorMsg = 'Only can input 9am - 8pm '
                editStatusRef.current.isSignOutError = true
                setError(true)
            } else {
                editStatusRef.current.isSignOutError = false
                editStatusRef.current.signOutErrorMsg = ''
                setError(false)
            }
        }
        if (isAfter(newValue, new Date())) {
            editStatusRef.current.timeOverError = true
            editStatusRef.current.timeOverErrorMsg = 'Sign in/out time over the current datetime'
            setError(true)
            return
        } else {
            editStatusRef.current.timeOverError = false
            editStatusRef.current.timeOverErrorMsg = ''
        }
    }
    let minAndMax = {}
    if (id === 'signinTime') {
        minAndMax.minTime = new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 09:00')
        minAndMax.maxTime = editStatusRef.current.signoutTime ? editStatusRef.current.signoutTime : new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 20:00')
    } else {
        minAndMax.minTime = editStatusRef.current.signinTime ? editStatusRef.current.signinTime : new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 09:00')
        minAndMax.maxTime = new Date(format(new Date(editStatusRef.current.date), 'yyyy-MM-dd') + ' 20:00')
    }

    return <TimePicker
        id
        value={time}
        ampm={false}
        onChange={handleSignInTime}
        renderInput={(params) => <TextField {...params} className={classes.timeField} error={error} helperText='' InputProps={{
            endAdornment: (
                <span />
            ),

        }} />}
        {...minAndMax}
    />
}

export default AttendanceTimePicker;