import React from 'react';
import {
    format
    , isAfter
    , isBefore
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import { makeStyles } from '@material-ui/core/styles';
import PointerIcon from 'images/pointer.png'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt } from 'react-router-dom';
import LoadingScreen from 'components/common/LoadingScreen';
import BorderLinearProgress from './BorderLinearProgress';
const useStyles2 = makeStyles((theme) => ({
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    button: {
        backgroundColor: '#dfdfdf ',
        color: '#000000 ',
        textTransform: 'none '
    },
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
            borderLeft: '1px solid #3d3d3d'
        },
        '& .MuiDataGrid-colCell:first-child': {
            // padding: '0 8px !important',
            borderLeft: '0'
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
}));

export const dateMonthMap = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
}
export default function ReportList(props) {
    const classes = useStyles();
    const currentClasses = useStyles2();
    const [lists, setLists] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const idRef = React.useRef(0);
    let history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);

    const [userProjectList, setUserProjectList] = React.useState([]);
    const [selectedProject, setSelectedProject] = React.useState({});

    const ref = React.useRef()

    const [haveOutstandingReport, setHaveOutstandingReport]= React.useState(false)

    React.useEffect(() => {
        setHaveOutstandingReport(localStorage.getItem('haveOutstandingReport'))
    }, [])

    React.useEffect(() => {
        if (selectedProject.projectId) {
            setIsLoading(true);
            axios.get('/progressReport/get-progress-reports-res', { params: { projectId: selectedProject.projectId } }).then((res) => {
                setIsLoading(false);
                if (res.data) {
                    const data = res.data.map((item) => {
                        item.id = idRef.current
                        idRef.current = idRef.current + 1
                        return item;
                    })
                    setLists(data);
                } else {
                    setLists([]);
                }
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
            })
        }
        
    }, [selectedProject])

    React.useEffect(() => {
        getUserProject()
    }, [])

    const getUserProject = () => {
        axios.get('/progressReport/get-user-projects').then((res) => {
            if (res.data) {
                setUserProjectList(res.data)
                setSelectedProject(res.data[0] || {})
            } else {
                setUserProjectList([])
                setSelectedProject({})
            }
            
        }).catch(err => {
            console.log(err);
        })
    }

    const handleSelectProject = (item) => {
        setSelectedProject(item)
    }

    useEffect(() => {
        const fullLists = []
        lists.forEach(item => {
            fullLists.push({
                ...item,
            })
        })
        setGridData(fullLists);
    }, [lists])
    
    const goDetail = (item) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        history.push(`/progress-reports/form?projectId=${item.projectId}&yearMonth=${item.reportMonth}`);
    }

    
    const columns = [
        {
            field: 'reportMonth',
            headerName: 'Reporting Month',
            // width: 94,
            type: 'date',
            // flex: 1,
            width: 150,
            sortable: true,
            // editable: true,
            renderCell: (pros) => {
                return (
                    <div>
                        { pros.row.canEdit ? <><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() =>goDetail(pros.row)}>{pros.value && format(new Date(pros.value), 'MMM yyyy')} </span>
                        <span><img src={PointerIcon} style={{width: 18}} /></span></> : <span >{pros.value && format(new Date(pros.value), 'MMM yyyy')} </span>}
                        
                    </div>
                );
            },
            
            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // flex: 1,
            width: 170,
            renderCell: (pros) => {
                const isRed = (pros.row.statusDB !== 'SUBMITTED' && pros.row.statusDB !== 'REVIEWED' && isAfter(new Date(), new Date(pros.row.deadline))) ||  pros.row.statusDB == 'REVISED';
                const isSubmit = pros.row.statusDB == 'SUBMITTED' || pros.row.statusDB == 'REVIEWED'
                const style = isRed ? {
                    color: '#ff0909'
                } : {
                    color: isSubmit ? '#46ae4a' : '#000'
                }

                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',...style}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                    
                );
            },
           
        },
        {
            field: 'deadline',
            headerName: 'Deadline',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // flex: 1,
            width: 180,
            type: 'date',
            renderCell: (pros) => {
                let year='', m='', d='';
                if (pros.value) {
                    year = format(new Date(pros.value), 'yyyy')
                    m = format(new Date(pros.value), 'MMM')
                    d = format(new Date(pros.value), 'dd')
                }
                const date =`${d} ${dateMonthMap[m]} ${year}`;
                const isRed = pros.row.statusDB !== 'SUBMITTED' && pros.row.statusDB !== 'REVIEWED' && pros.row.statusDB !== 'REVISED' && isAfter(new Date(), new Date(pros.value))
                const style = isRed ? {
                    backgroundColor: '#FFD6D6'
                } : {}
                return (
                    <Grid container style={{height: 40}}  alignContent='center'>
                        <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', height: 25, width: 150, lineHeight: '25px', padding: '0 10px', textAlign: 'center', ...style }}>{date}</Grid>
                    </Grid>
                    
                );
            },
            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }
          
        },
        {
            field: 'lastSaveSubmittedBy',
            headerName: 'Last Saved/ Submitted by',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                    
                );
            },
            
        },
        {
            field: 'progress',
            headerName: 'Progress',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // flex: 1,
            width: 160,
            renderCell: (pros) => {
               
                return (
                    <div style={{ overflow: 'hidden', lineHeight: 1.2, textOverflow: 'ellipsis', width: '100%' }}>
                        <Box  alignItems="center">
                        <Box width="100%">
                            {`${Math.round(
                            pros.value,
                            )}%`}
                        </Box>
                        <Box width="100%">
                            <BorderLinearProgress variant="determinate" value={pros.value} />
                        </Box>
                        </Box>
                    </div>
                );
            },
           
        },
        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 140,
            sortable: true,
            flex: 1,
            renderCell: (pros) => {
                let v = ''
               
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',}}>
                        {pros.value}
                        </div>
                    </div>
                );
            },
           
        },
    ];

    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Progress Report Summary
                    </Typography>
                </Grid>
                <Grid container style={{ paddingTop: 10 }} >
                    {userProjectList.map((item) => { 
                        const isActive = selectedProject.projectId == item.projectId;
                        const activeStyle = isActive ? {
                            backgroundColor:'#f58220',
                            color: '#ffff ',
                        } : {
                            backgroundColor: '#dfdfdf ',
                            color: '#000000 ',
                        }
                        return <Button style={{
                            marginRight: 10,
                            marginBottom: 10,
                            ...activeStyle
                        }} key={item.refNum} className={currentClasses.activeButton} onClick={ () => handleSelectProject(item)}>{item.refNum}</Button>
                    })}
                    
                   
                </Grid>
                <Grid item xs>
                    <DataGrid
                        // sortingOrder={['desc', 'asc']}
                        ref={ref}
                        rows={gridData}
                        columns={columns}
                        className={currentClasses.dataGridRoot}
                        disableColumnMenu
                        disableSelectionOnClick
                        headerHeight={40}
                        components={{
                            Toolbar: GridToolbar,
                          }}
                        // rowHeight={40}
                        hideFooter={gridData.length < 100}
                    />
                    
                </Grid>
            </Grid>
            <LoadingScreen open={isLoading} />
            <Prompt
                when={haveOutstandingReport=='true'}
                message={(location) => { 
                    if (haveOutstandingReport=='false' || (location && (location.pathname === '/progress-reports/form' || location.pathname === '/Login'))) {
                        return true
                    }
                    return false;
                }}
            />
        </Paper>
    );
}
