import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import { useStyles } from "Styles";
import RenderPcOrMobile from '../RenderPcOrMobile';
import MessageBarMobile from 'view/mobile/common/MessageBar.mobile';
import MessageBarPC from 'view/pc/common/MessageBar.pc';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MessageBar(props) {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.handleClose();
    };

    const getMsg = () => {
        //backend errors general handling

        if (props.msgConfig?.message?.response?.data) {
            if (props.msgConfig.message.response.data?.errors) {
                var backendErr = props.msgConfig.message.response?.data?.errors;
                return backendErr[Object.keys(backendErr)[0]];
            }
            if (props.msgConfig.message.response.data?.message) {
                return props.msgConfig.message.response?.data?.message;
            }
        }

        

        if (props.msgConfig.error) {
            if (props.msgConfig.error.errors) {
                var backendError = props.msgConfig.error.errors;
                return backendError[Object.keys(backendError)[0]];
            }
        }

        if (props.msgConfig.message?.message) {
            return props.msgConfig.message?.message;
        }
        
        return props.msgConfig.message;
    }
    return (
        <div className={classes.messagerBar}>
            <RenderPcOrMobile pc={<MessageBarPC handleClose={handleClose} getMsg={getMsg} classes={classes} {...props}/>} mobile={<MessageBarMobile handleClose={handleClose} getMsg={getMsg} classes={classes} {...props} />}/>
        </div>
    );
}