import React from 'react';
import {
    format
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button
} from '@material-ui/core';
import { useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from '../../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HadclGrid from '../../common/HadclGrid';
import { GridColDef } from '../../common/HadclFormatter';
import { useStyles } from "Styles";
import SystemConfirmDialog from "../../common/SystemConfirmDialog";
import useModal from "hooks/useModal";
import OnlineInformationDetail from './detail';
import LoadingScreen from '../../common/LoadingScreen';

export default function OnlineInformationManagement(props) {
    const classes = useStyles();
    const [videoList, setVideoList] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const gridApi = React.useRef();
    const [isLoading, setIsLoading] = React.useState(false);
    const showModal = useModal();
    const [isEdit, setIsEdit] = React.useState(false);
    const [curId, setCurId] = React.useState(0);

    const refreshData = () => {
        setIsLoading(true);
        axios.get('/onlineInformation/get-video-list', null).then((res) => {
            if (res.data) {
                setVideoList(res.data.sort((a, b) => new Date(a.bookDate) - new Date(b.bookDate)));
            } else {
                setVideoList([]);
            }
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    React.useEffect(() => {
        refreshData();
    }, [])

    useEffect(() => {
        const fullVideos = []
        videoList.forEach(item => {
            fullVideos.push({
                ...item,
            })
        })

        // console.log('fullVideos', fullVideos)
        setGridData(fullVideos);
    }, [videoList])

    const exportRecord = async () => {
        let exportApi = 'export-completion-record';
        const response = await axios.get(`/onlineInformation/${exportApi}`,
            { responseType: "blob", params: null })
        let fileStr = response.headers['content-disposition'];
        fileStr = fileStr.split(';')[1];
        fileStr = fileStr.split('=')[1];
        if (response.data) {
            let fileName = fileStr.replace(/\"/g, '');
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(response.data, fileName)
            } else {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.download = fileName;
                document.body.appendChild(link);
                var evt = document.createEvent("MouseEvents");
                evt.initEvent("click", false, false);
                link.dispatchEvent(evt);
                document.body.removeChild(link);
            }
        }
    }

    const handleEditOpen = (id) => {
        setCurId(id);
        setIsEditState(true);
    };

    const onDelete = id => {
        showModal((pros) => (
            <SystemConfirmDialog style={{ whiteSpace: "pre-wrap" }}
                {...pros}
                onConfirm={() => {
                    setIsLoading(true);
                    axios.post('/onlineInformation/delete-video', { "id": id }).then(res => {
                        console.log(res)
                        refreshData();
                    }).catch(err => {
                        if (err) {
                            console.log(err);
                        }
                    })
                    setIsLoading(false);
                }}
            >
                <div style={{ padding: '0 10px' }}>Do you confirm to remove the eCourse?</div>
            </SystemConfirmDialog>
        ));
    }

    const columnDefs = [
        GridColDef({
            headerName: 'Course Title',
            field: 'title',
            // minWidth: 120,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Video Link',
            field: 'links',
            // width: 140,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Available to',
            field: 'rolesSplit',
            // width: 140,
            flex: 1,
            // cellRendererFramework: (pros) => {
            //     var roles = pros.data.roles;
            //     if (roles) {
            //         var rolesDesc = roles.map(e => e.desc);
            //         var roleString = rolesDesc.join(' / ');
            //         return roleString;
            //     } else {
            //         return <div></div>
            //     }
            // }
        }),
        GridColDef({
            headerName: 'Upload Date',
            field: 'uploadDate',
            // width: 180,
            flex: 1,
            cellRendererFramework: (pros) => {
                return format(new Date(pros.data.uploadDate), 'dd-MMM-yyyy')
            }
        }),
        GridColDef({
            headerName: 'Last Edit Date',
            field: 'lastEditDate',
            // width: 170,
            flex: 1,
            cellRendererFramework: (pros) => {
                return format(new Date(pros.data.lastEditDate), 'dd-MMM-yyyy')
            }
        }),
        GridColDef({
            headerName: '',
            width: 80,
            minWidth: 80,
            sortable: false,
            filter: false,
            editable: false,
            cellRendererFramework: (pros) => {
                return (
                    <div style={{ lineHeight: '35px' }}>
                        <EditIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => handleEditOpen(pros.data.id)}></EditIcon>
                        <DeleteIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => onDelete(pros.data.id)} />
                    </div>
                );
            }
        }),
    ];

    const onGridReady = (params) => {
        gridApi.current = params;
    }

    function clearFilter(event) {
        event.stopPropagation();
        event.preventDefault();

        gridApi.current.api.setFilterModel(null);
        gridApi.current.api.setSortModel(null);
    }

    const setIsEditState = (isEd) => {
        setIsEdit(isEd);
    }

    const setCurIdState = (id) => {
        setCurId(0);
    }

    return (
        <>
            {
                isEdit ? <OnlineInformationDetail id={curId} setIsEditState={setIsEditState} setCurIdState={setCurIdState} refreshData={refreshData} /> :
                    < Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }
                    }>
                        <LoadingScreen open={isLoading} />
                        <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                            <Grid container item>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h5" gutterBottom xs={12}>
                                        eCourse
                                    </Typography>
                                </Grid>
                                <Grid item xs style={{ marginLeft: 10 }}>
                                    <Button className={`${classes.exportButton}`} onClick={() => exportRecord()}>Export Completion Record</Button>
                                </Grid>
                                <Grid item xs={'auto'} >
                                    <Button className={`${classes.exportButton}`} onClick={() => setIsEdit(true)}>+ New</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <div className={`ag-theme-alpine ${classes.nhadclAgGrid}`}
                                    style={{ position: 'relative', zIndex: 0, height: '100%', width: '100%' }}>
                                    <HadclGrid
                                        rowData={gridData}
                                        columnDefs={columnDefs}
                                        onGridReady={onGridReady}
                                        clearFilter={clearFilter}
                                        paginationAutoPageSize={true}
                                        pagination={true}
                                        headerHeight={40}
                                        rowHeight={40}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Paper >
            }
        </>
    );
}
