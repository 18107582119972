import React from 'react';
import {
    format
    , startOfMonth
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { useEffect } from 'react';
import axios from '../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "Styles";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useHistory, Prompt } from 'react-router-dom';
import LoadingScreen from 'components/common/LoadingScreen';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectComponent from 'components/common/CommonSelect';
import SystemMessageDialog from 'components/common/SystemMessageDialog';
import SystemConfirmDialog from 'components/common/SystemConfirmDialog';
import { success, confirmReportSendEmail } from 'components/common/Constants';
import EditIcon from '@material-ui/icons/Edit';
const useStyles2 = makeStyles((theme) => ({
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    button: {
        backgroundColor: '#dfdfdf ',
        color: '#000000 ',
        textTransform: 'none '
    },
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
            borderLeft: '1px solid #3d3d3d'
        },
        '& .MuiDataGrid-colCell:first-child': {
            // padding: '0 8px !important',
            borderLeft: '0'
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
    remarks: {
        lineHeight: 1.2, width: '100%',
        maxHeight: '100%', overflowY: 'auto',
        display: 'flex',
        "& p": {
            marginBottom: 0
        }
    }
}));

export default function ProjectManagement(props) {
    const classes = useStyles();
    const currentClasses = useStyles2();
    const [lists, setLists] = React.useState([]);
    const [sourceData, setSourceData] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    let history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);

    const [errorMsg, setErrorMsg] = React.useState('')


    const [cohortList, setCohortList] = React.useState([]);
    const [selectedCohort, setSelectedCohort] = React.useState([]);

    const ref = React.useRef()

    const loadData = () => {
        setIsLoading(true);

        axios.get('/project/get-project-list', null).then((res) => {

            // console.log(res)
            if (res.data) {
                const currentYear = new Date().getFullYear()
                const tempCohort = [
                    {
                        value: currentYear,
                        name: currentYear
                    }
                ];
                const tempCohortMap = {
                    [currentYear]: true
                };
                const data = res.data.map((item) => {
                    if (item.cohort && !tempCohortMap[item.cohort]) {
                        tempCohortMap[item.cohort] = true;
                        tempCohort.push({
                            value: item.cohort,
                            name: item.cohort
                        })
                    }
                    // item.cohort = null
                    if (item.piUsers && item.piUsers[0]) {
                        item.PIName = item.piUsers[0].userName
                    } else {
                        item.PIName = ''
                    }
                    item.id = item.projectId
                    return item;
                })
                const newList = [...tempCohort].sort((a, b) => a.value - b.value)
                setCohortList(newList)
                setSourceData(data);
                const refGrid = ref.current;
                refGrid && refGrid.children[1].children[1].scroll(0, 0)
            } else {
                setLists([]);
                setSourceData([])
            }
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }
    React.useEffect(() => {
        // getUserProject()
        loadData()
    }, [])
    React.useEffect(() => {
        if (sourceData.length > 0) {
            const oldSelectedCohort = localStorage.getItem('selectedCohort')
            if (oldSelectedCohort) {
                localStorage.removeItem('selectedCohort');
                handleFilterList(JSON.parse(oldSelectedCohort), sourceData)
            } else {
                handleFilterList([new Date().getFullYear()], sourceData)
            }
        }

    }, [sourceData])

    const handleFilterList = (list, sourceList) => {
        // console.log(cohortList, list)
        let newLists = []
        let tempList = [];
        if (cohortList.length == list.length) {
            newLists = [...sourceList]
            tempList = list
        } else {
            cohortList.forEach((item) => {
                if (list.includes(item.value)) {
                    tempList.push(item.value)
                }
            })
            // console.log(tempList, 222)
            if (tempList.length == 0) tempList.push(new Date().getFullYear())
            sourceList.forEach((item) => {
                if (tempList.includes(item.cohort)) {
                    newLists.push(item)
                }
            })
        }
        setLists(newLists);
        setSelectedCohort(tempList)
        //
    }

    const handleSelectCohort = (list) => {

        if (list.length == 0 && cohortList.length == 1) {
            list = [cohortList[0].value]
        }
        handleFilterList(list, sourceData)
        // setSelectedProjectObj(obj)
    }

    useEffect(() => {
        const fullLists = []
        lists.forEach(item => {
            fullLists.push({
                ...item,
            })
        })
        setGridData(fullLists);
    }, [lists])

    const handleEditOpen = (item) => {
        
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        localStorage.setItem('selectedCohort', JSON.stringify(selectedCohort))
        // localStorage.setItem('selectedProject', JSON.stringify(selectedProjectObj))
        history.push(`/project-management/${item.projectId}`);
    }


    const columns = [
        {
            field: 'cohort',
            headerName: 'Cohort',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            // width: 170,
            renderCell: (pros) => {
               
                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div></div>

                );
            },

        },
        {
            field: 'refNum',
            headerName: 'Project No.',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            // width: 180,
            renderCell: (pros) => {
                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>

                    </div>

                );
            },

        },

        {
            field: 'projectTitle',
            headerName: 'Project Title',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}><div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>

                    </div>
                );
            },

        },
        {
            field: 'statusDesc',
            headerName: 'Status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            renderCell: (pros) => {

                const styles = pros.row.statusCode == 'ACTIVE' ? { color: '#6ad157' } : {}
                return (
                    <Grid container justifyContent={'center'} style={styles}>
                        <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                            <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                            </div>

                        </div>
                    </Grid>
                );
            },

        },
        {
            field: 'PIName',
            headerName: 'PI',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{ lineHeight: 1.2, width: '100%', maxHeight: '100%', overflowY: 'auto', }} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>

                    </div>
                );
            },

        },
        {
            field: 'accessStartDate',
            headerName: 'Access Start Date',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            type: 'date',
            flex: 1,
            renderCell: (pros) => {
                return (
                    <div>
                        <span >{pros.value && format(new Date(pros.value), 'dd MMM yyyy')} </span>

                    </div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },


        {
            field: 'accessEndDate',
            headerName: 'Access End Date',
            // width: 140,
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                return (
                    <div>
                        <span >{pros.value && format(new Date(pros.value), 'dd MMM yyyy')} </span> </div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },
        {
            field: 'firstVisitDate',
            headerName: '1st Visit Date',
            // width: 140,
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                return (
                    <div><span >{pros.value && format(new Date(pros.value), 'dd MMM yyyy')} </span>

                    </div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },
        {
            field: 'studyCompletionDate',
            headerName: 'Study Completion Date',
            // width: 140,
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                return (
                    <div><span >{pros.value && format(new Date(pros.value), 'dd MMM yyyy')} </span></div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },
        {
            field: 'portalAccessEndDate',
            headerName: 'Portal Access End Date',
            // width: 140,
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                return (
                    <div>
                        <span>{pros.value && format(new Date(pros.value), 'dd MMM yyyy')} </span></div>
                );
            },

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }

        },
        {
            field: 'internalRemarks',
            headerName: 'Internal Remarks',
            width: 140,
            sortable: true,
            flex: 1,
            renderCell: (pros) => {
                let v = ''

                return (
                    <div style={{ padding: '5px 0', width: '100%', height: '100%', wordBreak: 'break-word', whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>
                        <div title={pros.value} className={currentClasses.remarks} dangerouslySetInnerHTML={{ __html: pros.value }}>
                        </div>
                       
                    </div>
                );
            },

        },
        {
            field: '',
            headerName: '',
            width: 50,
            renderCell: (pros) => {
                return (
                    <div >
                        <EditIcon style={{ color: '#15a9d2', cursor: 'pointer' }} onClick={() => handleEditOpen(pros.row)}></EditIcon>

                    </div>
                );
            },

        },
    ];

    const handleCreate = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        history.push(`/project-management/create`);
    }
    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Project Management
                    </Typography>
                </Grid>
                <Grid container style={{ paddingTop: 10, paddingBottom: 10 }} justifyContent={'space-between'} alignItems='center' >

                    <Grid item >
                        Cohort: &nbsp;
                        <SelectComponent multipleIsEnd={true} displayEmpty={false} allText="All Projects" multiple={true} value={selectedCohort} onChange={handleSelectCohort} style={{
                            textTransform: 'none',
                            fontSize: '13px',
                            minWidth: '100px',
                            maxWidth: '200px'
                        }} options={cohortList} />
                    </Grid>
                    <Grid item style={{ lineHeight: '28px' }}>
                        <Button style={{
                            backgroundColor: '#15A9D2 ',
                            color: '#ffff ',
                            textTransform: 'none'
                        }} className={classes.activeButton} onClick={handleCreate}>Create Project</Button>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <DataGrid
                        // sortingOrder={['desc', 'asc']}
                        ref={ref}
                        rows={gridData}
                        columns={columns}
                        className={currentClasses.dataGridRoot}
                        disableColumnMenu
                        disableSelectionOnClick
                        headerHeight={40}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        // rowHeight={40}
                        hideFooter={gridData.length < 100}
                    />

                </Grid>
            </Grid>
            <LoadingScreen open={isLoading} />
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
        </Paper>
    );
}
