import { DateNoTime } from '../common/HadclFormatter'
import { format } from 'date-fns';

const warning = {
    // Booking Management (Attendance)
    AttendanceStartTime: 'Please provide start time',
    AttendanceEndTime: 'Please provide end time',
    AttendanceValidation: "End time should be after start time",

    // Booking Management (Setup)
    SetupCapacity: "Please provide integer",
    SetupCapacityValidation: "Capacity must be greater than 0",
    SetupStartDate: "Please enter valid start date",
    SetupEndDate: "Please enter valid end date",
    SetupDateValidaiton: "End date should be after start date",

    // Booking Management (Approval)
    ApprovalBookDate: "Please provide valid book date",
    ApprovalCapacity: "Please provide integer",

    // User Management
    UserEffectiveDate: "Please provide valid effective date",
    UserExpiryDate: "Please input valid expiry date",
    UserEffectiveDateValidation: "Effective date must be later than 2018/01/01",

    // ProgressReport
    reportMandatory: 'Please complete all mandatory field(s).',
    reportRichLength: 'Rich text exceeds the maximum value that can be entered.',
    reportRiskSeverity: 'Please complete overall risk severity.',
    // project
    AllMandatory: 'Please complete all mandatory field(s).',
    RichLength: 'Rich text exceeds the maximum value that can be entered.',
    ObjectivesAdd: 'Please add Objectives.',

    // output
    confirmNotes: 'Please confirm notes.',
    filesAdd: 'Please add file.',
    modelAdd: 'Please add model.',
    AdmApproved: "Please input password and output retention date before saving"
};


const error = {
    // Booking Request (Project)
    ProjectQuota: "Please fill in No. of Access Requested (1-6)",
    ProjectQuotaValidation: "No. of access requested must be between 1 and 6",
    ProjectBookDateValidaiton: "Booking date cannot be later than expiry date",

    // Booking Management (Setup)
    SetupDirty: "Setting is not updated because no fields modified",

    // User Management
    UserQuota: "No. of Access Day requested should be a number (1 to 5)",
    UserQuotaValidation: "No. of Access Day requested must be between 1 and 5",
    UserRole: "You have to choose at least 1 role",
    UserRoleValidaiton: "If System Admin has been chosen, the user cannot have any other role",
    UserResearcherValidation: "No other role can be chosen if the user is a researcher or workshop participant.",
    UserLastName: "Last name is required",
    UserFirstName: "First name is required",
    UserEmail: "Email is required",
    UserAccountEffectiveDate: "Account Effective Date is required",
    UserAccessStartDateForResearchers: 'Access start date of self-service researchers is required',

    // Booking Management (Approval)
    ApprovalApproved: "One or more selected booking(s) is/are in the status APPROVED. Cannot approve the booking(s) again",
    ApprovalRejected: "One or more selected booking(s) is/ are in the status REJECTED. Cannot reject the booking(s) again",
    ApprovalEmail: "Date format is incorrect",
    ApprovalEmailList: "Ref. No. - Email is required",
    ApprovalDuplcate: "Another access request has been approved under the same Ref. No. on that date",

    //Token Extend
    TokenExtend: 'System error happened and token udpate failed.'

}

const confirmDialog = {

    // Booking Management (Approval)
    EditRejectApproved: 'The booking is in the status APPROVED. Are you sure to reject it?',
    EditApproveRejected: 'The booking is in the status REJECTED. Are you sure to approve it?',
    EditCancelApproved: 'The booking is in the status APPROVED. Are you sure to cancel it?',
    EditApprove: 'Are you sure to approve the booking?',
    EditReject: 'Are you sure to reject the booking?',
    Delete: 'Are you sure to delete the booking?',

    // Booking Management (Setup)
    SetupUpdateRange: 'Setting for {0} - {1} will be updated',

    // Change Password
    ChangePassword: 'Confirm to change password?',

    // Booking Request 
    Booking: 'Do you want to submit this access request?',

    // Session Extend
    Extend: 'Action Required to Extend User Session'
}

function confirmDialogRequest(deleteDate) {
    return `Confirm to cancel the access request on ${DateNoTime(deleteDate)}?`
}

function confirmDialogApproval(action, i) {
    switch (action) {
        case "ApproveRejected":
            return 'One or more selected booking(s) is/are in the status REJECTED. Are you sure to approve ' + i + ' booking(s)?';
        case "RejectApproved":
            return 'One or more selected booking(s) is/are in the status APPROVED. Are you sure to reject ' + i + ' booking(s)?';
        case "Approve":
            return 'Are you sure to approve ' + i + ' booking(s)?';
        case "Reject":
            return 'Are you sure to reject ' + i + ' booking(s)?';
    }
}


function confirmReportGenerate(reportMonth) {
    return `Do you want to generate email for booking request of the month: ${reportMonth}?`
}

function confirmReportRegenerate(lastGenDate, reportMonth) {
    return `The Email of all project have already been generated on ${lastGenDate}. Do you want to re-generate email for booking request of the month: ${reportMonth}?`;
}

function confirmReportIndRegenerate(project, lastGenDate, reportMonth) {
    return `The Email of project ${project} have already been generated on ${lastGenDate}. Do you want to re-generate email for booking request of the month: ${reportMonth}?`;
}

function confirmDialogSetup(data) {
    return `Setting for ${DateNoTime(data.startDate)} - ${DateNoTime(data.endDate)} will be updated`
}

function confirmChangeCapacity(datesOfWarning) {
    return 'Warning: If you apply the change, the following date(s) has more approved booking than capacity: ' + datesOfWarning
}

const success = {
    TokenExtend: 'Token has been updated',
    Submitted: 'Submitted successfully!',
    // report
    ReportSaveSubmitted: 'Saved successfully. Please submit the progress </br>report to unlock HADCL access.',
    ReportSend: 'Sent successfully. ',
    ReportReviewed: 'Reviewed successfully. ',
    ReportRevised: 'Returned for revision successfully. ',
    Saved: 'Saved successfully!',
    Deleted: 'Deleted successfully!',
    Confirmed: 'Confirmed successfully!',
    Withdraw: 'Withdraw successfully!',

    EmailGenerated: 'Generated successfully!',
}

function confirmDialogUser(email) {
    return `Confirm to delete user ${email}?`
}

const message = {
    // User Management
    AtLeast8Characters: "contain at least 8 characters",
    AtLeast1Number: "contain at least one number (0-9)",
    AtLeast1LowAndUp: "contain at least one lowercase letter (a-z) and one uppercase letter (A-Z)",
    AtLeast1Special: "contain at least one special character",
    NotContainEmail: "NOT contain your email address",
    PasswordMustBeTheSame: "password not match",
    // PasswordMustBeTheSame: "New Password and Confirm Password must be the same",
};

function projectRepeatedMsg(refNum) {
    return `Ref. No. (Project) «${refNum}» is repeated`
}

function confirmReportDeadline(deadline) {
    return `Saved successfully. Please be reminded to submit on/</br>before ${format(new Date(deadline), 'dd-MMM-yyyy')}.`
}
function confirmReportDeadline_8(deadline) {
    return `Saved successfully. Access to HADCL will be </br>suspended if report is not submitted by 08 ${format(new Date(), 'MMM yyyy')}.`
}

function confirmReportSendEmail(project, date) {
    return `Are you sure to send email reminder to ${project} for revision </br>of Progress Report ${format(new Date(date), 'MMM yyyy')}?`

}


export {
    warning, error, confirmDialog, confirmDialogRequest, confirmDialogApproval,
    confirmReportGenerate, confirmReportRegenerate, confirmReportIndRegenerate,
    confirmDialogSetup, confirmChangeCapacity, success, confirmDialogUser, message, projectRepeatedMsg, /*confirmDialogOverApproval*/ confirmReportDeadline, confirmReportDeadline_8, confirmReportSendEmail
}