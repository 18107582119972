import React from 'react';  

export function isMobilePhone() { 
    const ua = navigator.userAgent.toLowerCase();
    return (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(ua) || window.screen.width <= 480)
}

const RenderPcOrMobile = (props) => {
    
    return !isMobilePhone() ? props?.pc : props?.mobile
}

export default RenderPcOrMobile