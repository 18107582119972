import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "Styles";
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useForm } from "react-hook-form";
import axios from '../CustomAxios';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { useMsg } from '../hooks/MsgProvider';
import clsx from 'clsx';
import SiteHeader from './common/SiteHeader';
import { Container } from 'reactstrap';
import NewPWInput from 'components/common/NewPWInput';
import usePwdValidation from 'hooks/usePwdValidation';

const schema = yup.object().shape({
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
});

const defaultValues = {
    newPassword: "",
    confirmPassword: "",
};

export default function ResetPassword(props) {
    const classes = useStyles();
    const { setSuccessMsg, setErrMsg } = useMsg();
    const [email, setEmail] = useState('');
    const token = props.match.params.token;

    const { watch, handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const watchNewPassword = watch('newPassword');
    const {
        atLeast8Valid
        , atLeast1Num
        , atLeast1LowAndUp
        , atLeast1Special
        , notContainEmail
        , saveEnabled
    } = usePwdValidation(watchNewPassword, email);

    const onSubmit = data => {
        const reqData = { 
            token: token,
            password: {
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword
            }
         }
        axios.put('/user/reset-password', reqData).then(res => {
            setSuccessMsg(res.data.message);
            reset(defaultValues);
        }).catch(err => {
            setErrMsg(err);
        });
    }

    const handleReset = () => reset(defaultValues);

    useEffect(() => {
        axios.get('/user/get-user-by-unlockToken?token=' + token)
            .then(({ data: user }) => {
                setEmail(user?.email);
            });
    }, []);

    return <div>
        <AppBar position="relative">
            <Toolbar className={clsx(classes.appBar)}>
                <SiteHeader showWelcome={true} />
            </Toolbar>
        </AppBar>
        <main className={clsx(classes.content, {
            [classes.contentShift]: false,
        })}>
            <Container >
                <Typography variant="h4" gutterBottom xs={12}>
                    Unlock Account
                </Typography>

                <Paper className={classes.paper}>
                    <h5 style={{ marginBottom: '8px' }}>{email}</h5>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <NewPWInput
                            classes={classes}
                            control={control}
                            atLeast8Valid={atLeast8Valid}
                            atLeast1Num={atLeast1Num}
                            atLeast1LowAndUp={atLeast1LowAndUp}
                            atLeast1Special={atLeast1Special}
                            notContainEmail={notContainEmail}
                        />
                        <div>
                            {/* <WarnningMsg isSuccess={passwordSame} message={message.PasswordMustBeTheSame} /> */}
                            {/* NOT be one of the previous 5 used passwords */}
                        </div>
                        <div className={classes.input}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={!saveEnabled}
                            >
                                Save
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                type="button"
                                onClick={() => handleReset()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Paper>
            </Container>
        </main>

    </div >
}