import React from 'react';
import {
    format
    , startOfMonth
    , startOfDay
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid
} from '@material-ui/core';
import { useEffect } from 'react';
import RenderToolBar from '../common/Calendar/RenderToolBar'
import axios from '../../CustomAxios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HadclGrid from '../common/HadclGrid';
import { GridColDef } from '../common/HadclFormatter';
import { useStyles } from "Styles";

export default function LoginHistory(props) {
    const classes = useStyles();
    const [bookingHistorys, setBookingHistorys] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const [viewDate, setViewDate] = React.useState(startOfMonth(new Date()));
    const gridApi = React.useRef();

    React.useEffect(() => {
        axios.get('/user/login-history', { params: { month: format(viewDate, "yyyy-MM-dd") } }).then((bookingsRes) => {
            if (bookingsRes.data) {
                setBookingHistorys(bookingsRes.data.sort((a, b) => new Date(a.bookDate) - new Date(b.bookDate)));
            } else {
                setBookingHistorys([]);
            }
        }).catch(err => {
            console.log(err);
        })

    }, [viewDate])

    useEffect(() => {
        const fullBookings = []
        bookingHistorys.forEach(item => {
            fullBookings.push({
                ...item,
            })
        })

        // console.log('fullBookings', fullBookings)
        setGridData(fullBookings);
    }, [bookingHistorys])

    const columnDefs = [
        GridColDef({
            headerName: 'Email',
            field: 'email',
            // minWidth: 120,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Surname',
            field: 'surname',
            // width: 140,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Staff ID',
            field: 'staffID',
            // width: 140,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Login Status',
            field: 'loginStatus',
            // width: 180,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Login Date',
            field: 'loginDate',
            // width: 170,
            flex: 1,
        }),
        GridColDef({
            headerName: 'Login Time',
            field: 'loginTime',
            // width: 170,
            flex: 1,
        }),
    ];

    const onGridReady = (params) => {
        gridApi.current = params;
    }

    function clearFilter(event) {
        event.stopPropagation();
        event.preventDefault();

        gridApi.current.api.setFilterModel(null);
        gridApi.current.api.setSortModel(null);
    }

    return (
        <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>

            <Grid container flexDirection={'column'} style={{ height: '100%' }}>
                <Grid item xs={'auto'}>
                    <Typography variant="h5" gutterBottom xs={12}>
                        Login history
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <RenderToolBar
                        date={viewDate}
                        label={format(startOfDay(viewDate), 'MMMM yyyy')}
                        setViewDate={setViewDate}
                    />
                </Grid>
                <Grid item xs>
                    <div className={`ag-theme-alpine ${classes.nhadclAgGrid}`}
                        style={{ position: 'relative', zIndex: 0, height: '100%' }}>
                        <HadclGrid
                            rowData={gridData}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            clearFilter={clearFilter}
                            paginationAutoPageSize={true}
                            pagination={true}
                            // multiSortKey={'ctrl'}
                            headerHeight={40}
                            rowHeight={40}
                        />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}
