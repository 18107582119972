
import { useStyles } from "Styles";
import { Link, useHistory, Link as DomLink } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import * as React from 'react';
import { useMsg } from '.././hooks/MsgProvider';
import axios from '../CustomAxios';
import useUser from '../hooks/UseFindUser';
import { UserContext } from '../hooks/UserContext';
import { success, error } from './common/Constants';
import { DateWithTime } from 'components/common/HadclFormatter';
import { useLocation } from 'react-router'
import useInformation from '../hooks/useInformation';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import useSysParam from 'hooks/UseSysParam';
import useNavFixed from 'hooks/useNavFixed';
import HomeLayoutPC from 'view/pc/layout/HomeLayout.pc';
import HomeLayoutMobile from 'view/mobile/layout/HomeLayout.mobile';
import RenderPcOrMobile from './RenderPcOrMobile';

export const HomeLayoutViewContext = React.createContext({});
function HomeLayout(props) {
    const classes = useStyles();
    const { setUser } = React.useContext(UserContext);
    const { user } = useUser();
    const [sessionExtendOpen, setSessionExtendOpen] = React.useState(false);
    const [timeoutOpen, setTimeoutOpen] = React.useState(false);
    const sessionTimeout = React.useRef();
    const promptTimeoutTimer = React.useRef(null);
    const refreshTokenTimer = React.useRef(null);
    const eventFuncRef = React.useRef(null);
    const countNoActionTimer = React.useRef(Date.now());
    const { setSuccessMsg, setErrMsg } = useMsg();
    const { informations: emails } = useInformation('HADCL_EMAIL');
    const { informations: addresses } = useInformation('HADCL_ADDRESS');
    const [usefulLinks, setUsefulLinks] = React.useState([]);
    const [notifications, setNotifications] = React.useState([]);
    const sitemaps = user && user.menus && user.menus.siteMaps || [];
    const { isDirty } = useDirtyCheck();
    let history = useHistory();
    const { paramTextValue: logoLink } = useSysParam('sys_logo_hyperlink');
    const { paramTextValue: contactEmail } = useSysParam('hadcl_other_email');

    const location = useLocation();

    const [openQrCode, setOpenQrCode] = React.useState(false);

    const { navRef, isFixed } = useNavFixed();

    const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);

    const [onlyOnPCDialog, setOnlyOnPCDialog] = React.useState(false)

    const getUserEmail = () => {

        var fullName = user?.user?.fullName;
        return (fullName ? "Hello! " + fullName : "");
    }

    const promptTimeout = () => {
        setSessionExtendOpen(true);
    }

    const promptLogout = () => {
        setTimeoutOpen(true);
    }

    const onSessionExtend = () => {
        setSessionExtendOpen(false);
        onExtendUpdateToken();
    }

    const closeSessionExtend = () => {
        setSessionExtendOpen(false);
    }

    const closeTimeout = () => {
        setTimeoutOpen(false);
    }

    const doLogout = () => {
        // console.log('doLogout')
        if (!isDirty) {
            localStorage.clear();
            axios.defaults.headers.common['Authorization'] = "";
            setUser(null);
        }

        history.push('/Login');
    }

    const clearTimer = () => {
        const body = document.querySelector('html');
        body.removeEventListener('click', eventFuncRef.current);
        body.removeEventListener('mousemove', eventFuncRef.current);
        body.removeEventListener('mousewheel', eventFuncRef.current);
        body.removeEventListener('keydown', eventFuncRef.current);
        clearInterval(refreshTokenTimer.current);
        clearInterval(promptTimeoutTimer.current);
    }

    const onExtendUpdateToken = async (isAuto) => {
        var checkUserID = user?.user?.id;
        var UserID = checkUserID ? checkUserID : "";
        await axios.put('/user/renew-token', {
            userid: UserID
        }).then((res) => {
            var resToken = res?.data?.token
            if (resToken) {
                axios.defaults.headers.common['Authorization'] = resToken;
                localStorage.setItem('token', resToken);

                if (!isAuto)
                    setSuccessMsg(success.TokenExtend);
                clearTimer();
                startTimeoutTimer();
            }
            else {
                setErrMsg(error.TokenExtend);
            }
        }).catch((err) => {
            if (err) {
                if (err.response) {
                    setErrMsg(error.TokenExtend);
                }
            }
        });
    }

    const timeoutFunc = () => {
        const nowDate = Date.now();
        if (nowDate - countNoActionTimer.current >= sessionTimeout.current) {
            clearTimer();
            promptTimeout();
            localStorage.clear();
            axios.defaults.headers.common['Authorization'] = "";
        }
    }

    const eventFunc = () => {
        clearInterval(promptTimeoutTimer.current);
        countNoActionTimer.current = Date.now();
        promptTimeoutTimer.current = setInterval(() => timeoutFunc(), 1000);
    }

    const startTimeoutTimer = () => {
        const expSecond = getExpTime();
        const autoExtendTokenTime = expSecond / 2;

        const refreshTimeoutFunc = () => {
            onExtendUpdateToken(true);
        };
        refreshTokenTimer.current = setInterval(refreshTimeoutFunc, autoExtendTokenTime);

        promptTimeoutTimer.current = setInterval(() => timeoutFunc(), 1000);
        const body = document.querySelector('html');
        eventFuncRef.current = () => eventFunc();
        body.addEventListener('click', eventFuncRef.current);
        body.addEventListener('mousemove', eventFuncRef.current);
        body.addEventListener('mousewheel', eventFuncRef.current);
        body.addEventListener('keydown', eventFuncRef.current);
    }

    const getExpTime = () => {
        let token = localStorage.getItem('token');
        var expSecond = 0;
        if (token) {
            const { exp, expiredTime } = jwtDecode(token);
            sessionTimeout.current = expiredTime * 60 * 1000;
            const secondNow = new Date().getTime();
            expSecond = exp * 1000 - secondNow;
        }
        return expSecond;
    }

    React.useEffect(() => {
        if (user) {
            startTimeoutTimer();
        }

        // clear on component unmount
        return () => {
            clearTimer();
        };
    }, [user])

    React.useEffect(() => {
        axios.get(`/information/get-useful-links`, null).then((res) => {
            setUsefulLinks(res.data);
        });
        return () => {
            clearInterval(refreshTokenTimer.current)
            clearInterval(promptTimeoutTimer.current)
        }
    }, [])

    const lastLoginTime = (datetime) => {
        const timeTitle = 'Last Successful Login';
        return `${timeTitle}: ${datetime === 'null' ? '--' : DateWithTime(datetime)}`;
    }
    const goTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const clickMenu = (code) => {
        const mcode = code.slice(3)
        if (mcode === 'PROGRESS_REPORT' || mcode === 'PROGRESS_REPORT_ADM' ||
            mcode === 'OUTPUT_REQUEST' || mcode === 'OUTPUT_REQUEST_MANAGEMENT' ||
            mcode === 'ONLINE_INFO' || mcode === 'ONLINE_INFO_MANAGEMENT') {
            setOnlyOnPCDialog(true);
            return;
        }

        if (localStorage.getItem('haveOutstandingReport') == 'true') {
            return
        }

        goTop();
        if (code && (code === 'TM_ACCESS_QR_CODE' || code === 'MM_ACCESS_QR_CODE' || code === 'SM_ACCESS_QR_CODE')) {
            setOpenQrCode(true)
        }
    }

    const loadNotifications = () => {
        axios.get(`/information/get-notification`, null).then((res) => {
            setNotifications(res.data);
        });
    }

    const clickNotification = (event) => {
        if (notifications.length) {
            setNotificationAnchorEl(event.currentTarget)
            const notificationIds = notifications?.filter(item => !item.isRead).map(item => item.id);
            if (notificationIds.length)
                axios.post('/information/read-notification', notificationIds)
                    .catch(err => {
                        console.log(err)
                    });
        }
    }

    const handlePinned = (notification) => {
        axios.post('/information/set-isPinned', { id: notification.id, isPinned: !notification.isPinned, type: notification.type })
            .then(({ data: result }) => {
                loadNotifications();
            }).catch(err => {
                console.log(err)
            });
    }

    const handleDeleteNotification = (notification) => {
        axios.post('/information/delete-notification', { id: notification.id })
            .then(({ data: result }) => {
                loadNotifications();
            }).catch(err => {
                console.log(err)
            });
    }

    const notificationRedirect = (notification) => {
        const isResearcher = user.roles.some(e => e === 'COL_RESEARCHER' || e === 'SELF_RESEARCHER');
        switch (notification.type) {
            case "NTF_URGENT_CLOSE":
                if (isResearcher) {
                    history.push('/manage-bookings', { date: notification.busDate }); break;
                } else {
                    history.push('/access-management', { isSetup: 1, date: notification.busDate }); break;
                }
            case "NTF_BOOKING_UPDATED":
                history.push(isResearcher ? '/manage-bookings' : '/access-management', { date: notification.busDate }); break;
            case "NTF_NOT_SIGNED_OUT":
                history.push('/attendance-management', { date: notification.busDate }); break;
            case "NTF_LOW_RATE":
                history.push('/manage-bookings', { date: notification.busDate }); break;
            case "NTF_PROGRESS_BEFORE":
            case "NTF_PROGRESS_REVIEWED":
            case "NTF_PROGRESS_COMMENTS":
            case "NTF_PROGRESS_RETURNED":
                history.push('/progress-reports'); break;
        }
    }

    const showNtfRedirect = (ntf) => {
        return true;
    }

    const showNtfClose = (ntf) => {
        return ntf.type === 'NTF_LOW_RATE' || ntf.type === 'NTF_BOOKING_UPDATED'
            || ntf.type === 'NTF_PROGRESS_REVIEWED' || ntf.type === 'NTF_PROGRESS_COMMENTS';
    }

    React.useEffect(() => {
        loadNotifications();
    }, [location.pathname])





    return <HomeLayoutViewContext.Provider value={{
        classes, user, sessionExtendOpen, timeoutOpen, emails,
        addresses, usefulLinks, sitemaps, logoLink, contactEmail,
        location, openQrCode, navRef, isFixed, notificationAnchorEl, setOpenQrCode,
        setNotificationAnchorEl, getUserEmail, promptLogout,
        onSessionExtend, closeSessionExtend, closeTimeout, doLogout, getExpTime, lastLoginTime, goTop, clickMenu, clickNotification,
        notifications, handlePinned, notificationRedirect, handleDeleteNotification, loadNotifications, showNtfRedirect, showNtfClose,
        onlyOnPCDialog, setOnlyOnPCDialog
    }}>
        <RenderPcOrMobile pc={<HomeLayoutPC {...props} />} mobile={<HomeLayoutMobile {...props} />} />
    </HomeLayoutViewContext.Provider>
}


export default HomeLayout;
