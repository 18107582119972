import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../Styles';
import SelectComponent from '../SelectComponent';
const LeftViewComponent = ({
    
    selfServiceList,
    projectList,
    allRecordChecked,
    setAllRecordChecked,
    selectionChecked,
    setSelectionChecked,
    setProject,
    project,
    selfService,
    setSelfService,
    isBatch,
    setIsShowFirstTime,
    isShowFirstTime,
    firstTimeRef
}) => {
    const classes = useStyles();

    const selectAllRecordClick = () => {
        setAllRecordChecked(true)
        setSelectionChecked(false)
        setIsShowFirstTime(false)
    }
    const selectSelectionClick = () => {
        setAllRecordChecked(false)
        setSelectionChecked(true)
    }

    const handleChange = (v) => {
        let isFirst = v.includes('firsttime');
        setSelfService(v)
        setIsShowFirstTime(isFirst)
    }

    
    const getOptionList = () => {
        return selfServiceList
        
    }

    const renderView = () => {
        return <>
        <Grid container style={{ padding: '10px 10px 0 12px' }}>

            <Button
                startIcon={allRecordChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px',
                    // paddingLeft: 0
                }}
                    onClick={() => { selectAllRecordClick(); setProject([]); setSelfService([]);}}
            >
                <div className={classes.selDateLabel}>Show all records</div>
            </Button>
            <Button
                startIcon={selectionChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlinedIcon style={{ color: '#999' }} />}
                className={classes.selDateButton}
                style={{
                    textTransform: 'none',
                    lineHeight: '1.5',
                    fontSize: '13px',
                    // paddingLeft: 0
                }}
                onClick={() => selectSelectionClick()}
            >
                <div className={classes.selDateLabel}>Selection by:</div>
            </Button>
            <div >
            </div >
             <Grid container style={{ paddingLeft: 8 }}>
                <Grid item>
                    <div className={classes.projectTitle} style={{ paddingTop: 0 }}>Project No.</div>
                        <SelectComponent disabled={allRecordChecked} allText={isBatch && 'All project'} multiple={isBatch} value={project} onChange={(v) => { setProject(v)}} options={projectList} valueField={'projectId'} nameField={'refNum'}
                        style={{
                            width: 160,
                        }}
                        />
                    <div className={classes.projectTitle}>Self-Service No.</div>
                        <SelectComponent
                            disabled={allRecordChecked} allText={isBatch && 'All Self-Service'} multiple={isBatch} value={selfService} onChange={handleChange} options={getOptionList()} valueField={'projectId'} nameField={'refNum'}
                            style={{
                                width: 160,
                            }}
                            customOption={{projectId: 'firsttime', refNum: 'First-time Self-Service'}}
                        />
                </Grid>
            </Grid>
        </Grid>

    </>
    }
    return (<>
        {renderView()}
    </>)
}

export default LeftViewComponent;