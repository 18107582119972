import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MessageBarMobile(props) {

    const { classes, handleClose, getMsg } = props;
    return (
        props.msgConfig.open && <Snackbar open={props.msgConfig.open} autoHideDuration={6000} onClose={handleClose} style={{left: 0, right: 0, bottom: 0}}>
            <Alert onClose={handleClose} severity={props.msgConfig.severity} className={classes.messagerAlert} style={{borderRadius: 0}}>
                {/* <div dangerouslySetInnerHTML={{ __html: getMsg() }} > */}
                <div>
                    {
                        Array.isArray(getMsg()) ?
                            getMsg().map(msg => { return <div>{msg}</div> })
                            :
                            getMsg()
                    }
                </div>
                {/* </div> */}
            </Alert>
        </Snackbar>
    );
}