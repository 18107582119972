import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "Styles";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller, useForm } from "react-hook-form";
import axios from '../../CustomAxios';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useMsg } from '../../hooks/MsgProvider';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import useModal from "hooks/useModal";
import ConfirmDialog from "components/common/ConfirmDialog";
import { confirmDialog } from "components/common/Constants";
import useUser from 'hooks/UseFindUser';
import NewPWInput from 'components/common/NewPWInput';
import usePwdValidation from 'hooks/usePwdValidation';

const schema = yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
});

const defaultValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

export default function ChangePassword(props) {
    const classes = useStyles();
    const { setSuccessMsg, setErrMsg } = useMsg();
    const showModal = useModal();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const { handleSubmit, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = data => {
        showModal((pros) => (
            <ConfirmDialog
                {...pros}
                description={confirmDialog.ChangePassword}
                onConfirm={() => {
                    axios.put('/user/password', {
                        currentPassword: data.currentPassword,
                        password: {
                            newPassword: data.newPassword,
                            confirmPassword: data.confirmPassword
                        }
                    }).then(res => {
                        setSuccessMsg(res.data.message);
                        reset(defaultValues);
                    }).catch(err => {
                        setErrMsg(err);
                    });
                }}
                onCancel={() => console.log("cancelled.")}
            />
        ))
    }

    const handleReset = () => reset(defaultValues);

    const handleClickShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const watchNewPassword = watch('newPassword');
    const { user } = useUser();
    const getUserEmail = () => {
        return user?.user?.email;
    }
    const email = getUserEmail();
    const {
        atLeast8Valid
        , atLeast1Num
        , atLeast1LowAndUp
        , atLeast1Special
        , notContainEmail
        , saveEnabled
    } = usePwdValidation(watchNewPassword, email);

    return <div style={{ height: 'calc(100vh - 94.5px)' }}>
        <Typography variant="h4" gutterBottom xs={12} style={{ paddingTop: '0.35em', marginLeft: '15px' }}>
            Change Password
        </Typography>

        <Paper className={classes.paper}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth className={classes.input}>
                    <Controller
                        as={TextField}
                        control={control}
                        name="currentPassword"
                        required
                        label="Current Password"
                        type={'text'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCurrentPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            maxLength: 64,
                            style: { fontFamily: showCurrentPassword || 'text-security-disc' }
                        }}
                    />
                </FormControl>
                <NewPWInput
                    classes={classes}
                    control={control}
                    atLeast8Valid={atLeast8Valid}
                    atLeast1Num={atLeast1Num}
                    atLeast1LowAndUp={atLeast1LowAndUp}
                    atLeast1Special={atLeast1Special}
                    notContainEmail={notContainEmail}
                />
                <div className={classes.input}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!saveEnabled}
                    >
                        Save
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="button"
                        onClick={() => handleReset()}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Paper>
    </div>
}