
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'; 
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    label: {
        minHeight: 32,
        padding: '6px 10px 4px 20px',
        lineHeight: '1.2',
        backgroundColor: '#f3f3f3',
    },
    title: {
        minHeight: 32,
        padding: '6px 10px 4px 20px',
        lineHeight: '1.2',
        backgroundColor: '#f3f3f3',
        fontWeight: 600
    },
    rightContent: {
        padding: '5px 0',
        borderRight: '1px solid #dcdcdc',
        // lineHeight: '32px',
        lineHeight: '1.2',
        color: '#b1b1b1',
        fontSize:14
    },
    projectHeader: {
        backgroundColor: '#646464',
        // borderRadius: 3,
        textAlign: 'center',
        color: '#fff',
        // marginTop: 3
    },
    projectTh: {
        padding: '5px 0',
        borderRight: '1px solid #3d3d3d'
    },
    projectTd: {
        padding: '5px 0',
        borderRight: '1px solid #dcdcdc'
    },
    lineBottom: {
        // minHeight: 40,
        borderBottom: '1px solid #dcdcdc'
    },
    round: {
        display: 'inline-block',
        width: 5,
        height: 5,
        borderRadius: "50%",
        backgroundColor: '#b1b1b1',
        marginRight: 10,
        verticalAlign: 'middle'
    }
}));
export default function ExamplePart2(props) {
    const {
        open,
        onClose,
        onCancel,
        cancelText, } = props;
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            disableEscapeKeyDown
            aria-labelledby="ExamplePart2-dialog"
            maxWidth={'md'}
        >
            <Grid container style={{width: "100%", fontSize: 16, fontWeight: 600, padding: 10}} className={classes.lineBottom}>
                Example
            </Grid>
            <Grid container style={{ maxHeight: 500, overflow: 'auto' }}>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.projectHeader}>
                    <Grid container item xs={3} style={{ padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Part II:</Grid>
                    <Grid container item xs style={{ padding: '5px 10px' }} className={classes.projectTh} alignItems='center' >Requested File List  <i>(For export of models, please fill in details in Part III)</i></Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >No.</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >Name</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >Path</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.projectTd} alignItems='center' >Description of the file(s)</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.projectTd} alignItems='center' >Reason of exporting the file(s)</Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.rightContent} alignItems='center' ># e.g. 1</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent} alignItems='center' >(e.g. AUC1.jpg)</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent} alignItems='center' >(e.g. in XXXXXX server folder “/result/”)</Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent} alignItems='center' >(e.g. This graph shows the performance of model A) </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.rightContent} alignItems='center' >(e.g. The graph will be used in our research paper.)</Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.rightContent}  >
                        # e.g. 2
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. Table.csv)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. in XXXXXX server folder “/table/”)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. This table shows the comparison of model X and model Y)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.rightContent}  >
                    (e.g. This table will be used in further analysis in our stage 2 research.)
                    </Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.rightContent}  >
                    # e.g. 3
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. *.bin)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. in XXXXXX server folder “/model/”)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. The file is the prediction model, please find details in Part III.)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.rightContent}  >
                    (e.g. The model will be used for further implementation of our XXX system.)
                    </Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.rightContent}  >
                    # e.g. 4
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    ( e.g. Program.sas )
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. in SAS folder “Users/user1/My Folder/”)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. This is the SAS program to generate graph and report. The program reads diagnosis progress and patient demographic tables and generates a graph to show the distribution of the cancer cases for male and female from year 2013 to 2017.)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.rightContent}  >
                    (e.g. This table will be used in further analysis in our stage 2 research.)
                    </Grid>
                </Grid>
                <Grid container style={{ fontSize: 13, minHeight: 40 }} className={classes.lineBottom}>
                    <Grid container item style={{ width: 100, padding: '5px 10px' }} className={classes.rightContent}  >
                    # e.g. 5
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    ( e.g. Report1 )
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. in SAS folder “Users/user1/My Folder/”)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px' }} className={classes.rightContent}  >
                    (e.g. The file includes graphical and summary report on diagnosis progress and laboratory test result dataset. It stores the count of patients with different cancers and laboratory tests performed from 2010 to 2017.)
                    </Grid>
                    <Grid container item style={{ flex: 1, padding: '5px 10px', textAlign: 'left' }} className={classes.rightContent}  >
                    (e.g. This table will be used in further analysis in our stage 2 research.)
                    </Grid>
                </Grid>
                
            </Grid>
            <Grid style={{ paddingBottom: '16px', marginTop: 25 }}>
                <Button color="primary"
                    style={{ backgroundColor: '#646464', padding: '10 24px', color: '#fff', marginLeft: 10, textTransform: 'none', }}
                    onClick={() => {
                        onCancel && onCancel();
                        onClose();
                    }}>
                    {cancelText || 'Back'}
                </Button>
            </Grid>
        </Dialog>
    )
}
