import React from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import Paper from '@material-ui/core/Paper';
import { useStyles } from "Styles";

import LoadingScreen from 'components/common/LoadingScreen'; 
import SystemMessageDialog from 'components/common/SystemMessageDialog';

import useUser from 'hooks/UseFindUser'; 
import SummaryPage from './SummaryPage';
import OutputDetail from './OutputDetail'
import PublicationManagementDetail from './PublicationDetail'

export const OutputManagementContext = React.createContext({});
export default function OutputComponent(props) {
    const classes = useStyles();
    const { user } = useUser();
    const [isLoading, setIsLoading] = React.useState(false);
    const [viewBy, setViewBy] = React.useState('all');
    
    const [requestType, setRequestType] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState('')
    
    const [selectedProject, setSelectedProject] = React.useState("");
    const [selectedProjectObj, setSelectedProjectObj] = React.useState("");
    
    const [isShowDetail, setIsShowDetail] = React.useState(false);
    const [isCreate, setIsCreate] = React.useState(true)
    const [currentDetail, setCurrentDetail] = React.useState(null);

    const [reload, setReload] = React.useState(false);
   

    
    const handleEdit = (detail) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setIsShowDetail(true)
        setIsCreate(false)
        setCurrentDetail(detail)
    }
    const handleBack = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setIsShowDetail(false)
        setCurrentDetail(null)
    }
    const handleRenderDetail = () => {
        return currentDetail.requestType=="OUTPUT" ? <OutputDetail /> : <PublicationManagementDetail />
    }

    return (
        <OutputManagementContext.Provider value={{
            setIsLoading, requestType, setErrorMsg, setRequestType,
            viewBy, setViewBy, selectedProject, setSelectedProject,
            handleEdit, isCreate, handleBack, currentDetail, isShowDetail, reload, setReload, setSelectedProjectObj, selectedProjectObj, user
        }}>
           
            {
                isShowDetail ? 
                    <Paper className={classes.paper} style={{ boxShadow: 'unset' }}> 
                        {handleRenderDetail()}
                    </Paper>
                    :
                    <Paper className={classes.paper} style={{ height: 'calc(100vh - 94.5px)', boxShadow: 'unset' }}>
                        <SummaryPage />
                    </Paper>
            }
            <LoadingScreen open={isLoading} />
            {errorMsg && <SystemMessageDialog open={!!errorMsg} onClose={() => setErrorMsg('')}>
                <div style={{ minWidth: 300 }} dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </SystemMessageDialog>}
           
        </OutputManagementContext.Provider>
    );
}
