import React, {useEffect} from 'react';
import {
    format
    , isAfter
    
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';


import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import { statusColor } from './OutputRequestList';
const useStyles2 = makeStyles((theme) => ({
    activeButton: {
        backgroundColor: '#f58220 ',
        color: '#ffff ',
        textTransform: 'none '
    },
    button: {
        backgroundColor: '#dfdfdf ',
        color: '#000000 ',
        textTransform: 'none '
    },
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-colCellTitleContainer ': {
            alignItems:'center',
            height: 45
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
            borderLeft: '1px solid #3d3d3d'
        },
        '& .MuiDataGrid-colCell:first-child': {
            // padding: '0 8px !important',
            borderLeft: '0'
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
}));

export default function PublicationReviewList(props) {
    const { viewBy, loadData, selectedProject, handleEdit} = props;
    const currentClasses = useStyles2();
    const [lists, setLists] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const idRef = React.useRef(0);
    
    const ref = React.useRef()
    
   
    React.useEffect(() => {
        loadData((res)=> { 
            if (res.data) {
                const data = res.data.map((item) => {
                    idRef.current = item.id
                    return item;
                })
                setLists(data);
                const refGrid = ref.current;
                refGrid && refGrid.children[1].children[1].scroll(0, 0)
            } else {
                setLists([]);
            }
        })
    }, [viewBy, selectedProject])



    useEffect(() => {
        const fullLists = []
        lists.forEach(item => {
            fullLists.push({
                ...item,
            })
        })
        setGridData(fullLists);
    }, [lists])
    
  

    let dynamicsColumns = [];

    
    const columns = [
        ...dynamicsColumns,
        {
            field: 'refNum',
            // headerName: <div style={{ lineHeight: 1.2 }}>Project <br />/SDP. No.</div>,
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Project /Self-service No.</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            // width: 100,
           
        },
        {
            field: 'requestNo',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Request No.</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            renderCell: (pros) => {
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',color: '#f58220', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {handleEdit(pros.row)}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div> </div>
                    
                );
            },
            sortComparator: (v1, v2) => {
                
                if (v1.split('#')[0] !==v2.split('#')[0]) {
                    return v1.split('#')[0].length - v2.split('#')[0].length
                } else {
                    let value1 = v1.split('#')[1]
                    let value2 = v2.split('#')[1]
                    return value1*1 - value2*1;
                }
            }
            
        },
        {
            field: 'publicationTypeDesc',
            // headerName: 'Publication Type',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Publication Type</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {
                
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                );
            },
            
        },
        {
            field: 'publicationTitle',
            // headerName: 'Publication Title',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Publication Title</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {
                
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}> <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                );
            },
            
        },
        {
            field: 'joOrCoName',
            // headerName: 'Journal Title/Conference Name',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Journal Title/Conference Name</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 280,
            flex: 1,
            renderCell: (pros) => {
                
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}><div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto',}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                );
            },
            
        },
        {
            field: 'firstSubmissionDate',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>First Submission Date</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                
                
                const date =pros.value ?format(new Date(pros.value), 'dd MMM yyy HH:mm'): ""
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={date} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto'}} dangerouslySetInnerHTML={{__html: date}}>
                        </div>
                        
                    </div>
                );
            },
            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }
          
        },
        {
            field: 'lastSubmissionDate',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Last Submission Date</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            flex: 1,
            type: 'date',
            renderCell: (pros) => {
                
                
                const t =pros.value ?format(new Date(pros.value), 'dd MMM yyy HH:mm'): ""
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={t} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto'}} dangerouslySetInnerHTML={{__html: t}}>
                        </div>
                        
                    </div>
                    
                );
            },
            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
               
            }
          
        },
        
        {
            field: 'status',
           
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Review Status</div>,
            sortable: true,
            width: 150,
            renderCell: (pros) => {
              
               
                const style = statusColor[pros.row.statusCode]
                return (
                    <div title={pros.value} style={{ width: '100%',height: 29, lineHeight:'29px', color: "#fff", borderRadius: 5, fontSize:13, textAlign:'center', ...style}} dangerouslySetInnerHTML={{__html: pros.value}}>
                    </div>
                    
                );
            },
            
        },
        
        {
            field: 'actionRequired',
            headerName: <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces',lineHeight: 1.2}}>Action Required</div>,
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            // width: 50,
            flex: 1,
            renderCell: (pros) => {
                
                return (
                    <div style={{  padding: '5px 0', width: '100%',height: '100%',wordBreak: 'break-word', whiteSpace: 'break-spaces',  display:'flex', alignItems: 'center' }}>
                        <div title={pros.value} style={{  lineHeight: 1.2, width: '100%',maxHeight: '100%', overflowY:'auto'}} dangerouslySetInnerHTML={{__html: pros.value}}>
                        </div>
                        
                    </div>
                    
                );
            },
            
        },
    ];

   
    return (
        
        <DataGrid
            ref={ref}
            rows={gridData}
            columns={columns}
            className={currentClasses.dataGridRoot}
            disableColumnMenu
            disableSelectionOnClick
            headerHeight={45}
            components={{
                Toolbar: GridToolbar,
            }}
            // rowHeight={40}
            hideFooter={gridData.length < 100}
    />
    );
}
