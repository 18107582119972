import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Divider, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoginViewContext } from 'components/Login.page';
import LoginIcon from 'images/loginIcon.png';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    Grid,
    Link,
    MobileStepper
} from '@material-ui/core';
import LoadingScreen from 'components/common/LoadingScreen';
import LockPromptDialog from 'components/common/LockPromptDialog';
import { Controller, useForm } from "react-hook-form";
import { useStyles } from './Login.page.style';

const LoginPC = () => {
    const {
        isLoading, theme, backgroundImgList, noticeHeightPx, iptNotice, inforHeightPx, usefulInfo, activeBackgroundIndex, logoLink,
        handleSubmit, onSubmit, control, email, setEmail, errors, password, setPassword,
        handleClickShowPassword, handleMouseDownPassword, showPassword, showLockTip,
        setShowLockTip, lockTipContent, loginRemainTime
    } = useContext(LoginViewContext)

    const classes = useStyles();
    return <div style={{ height: '100vh' }}>
        <LoadingScreen open={isLoading} />
        <ThemeProvider theme={theme}>
            <Grid container className={classes.fullHeight} flexWrap={'nowrap'}>
                <Grid item style={{ width: '65%' }} className={`${classes.leftWrapper} ${classes.fullHeight}`}>
                    {
                        Array.isArray(backgroundImgList) && backgroundImgList.map((item, index) => {
                            return (
                                <img
                                    src={item.icon}
                                    key={index}
                                    alt={index}
                                    className={`${classes.backgourndImageClass} ${index === activeBackgroundIndex ? classes.animInItem : classes.animExitItem}`}
                                />
                            );
                        })
                    }
                    <Grid container justifyContent={'center'} className={`${classes.fullHeight}`}>
                        <Grid item container direction={'column'}>
                            <Paper className={classes.InfoWrapper} style={{ height: noticeHeightPx }}>
                                <Typography color={'primary'} className={classes.titleFont}>IMPORTANT NOTICE</Typography>
                                <Divider className={classes.InfoDivider} />
                                <div className={classes.InfoContainer}>
                                    {Array.isArray(iptNotice) && iptNotice.length > 0 &&
                                        iptNotice.map((item, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                        ))
                                    }
                                </div>
                            </Paper>
                            <Paper className={classes.InfoWrapper} style={{ height: inforHeightPx }}>
                                <Typography color={'primary'} className={classes.titleFont}>USEFUL INFORMATION</Typography>
                                <Divider className={classes.InfoDivider} />
                                <div className={classes.InfoContainer}>
                                    {Array.isArray(usefulInfo) && usefulInfo.length > 0 &&
                                        usefulInfo.map((item, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                        ))
                                    }
                                </div>
                            </Paper>
                            <MobileStepper
                                classes={{
                                    root: classes.stepperRoot,
                                    dot: classes.stepperDotRoot,
                                    dotActive: classes.dotActiveRoot
                                }}
                                steps={backgroundImgList.length}
                                activeStep={activeBackgroundIndex}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={`${classes.rightWrapper} ${classes.fullHeight}`}>
                    <Grid container>
                        <a href={logoLink} target={'_blank'}><img src={LoginIcon} alt={'icon'} className={classes.loginIcon} /></a>
                    </Grid>
                    <Grid container className={classes.iconDivider}></Grid>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction={'column'} className={classes.loginFormContainer}>
                            <Grid item>
                                <div className={classes.welcomeFont}>Welcome to Hospital Authority</div>
                                <div className={classes.welcomeFont}>Data Collaboration Laboratory Portal{process.env.REACT_APP_ENV_DESC || ""}</div>
                            </Grid>
                            <Grid item container direction={'column'} className={classes.loginInputBox}>
                                <Grid item className={classes.loginFont}>
                                    LOGIN
                                </Grid>
                                <Grid item className={classes.LoginInputWrapper}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor="email" className={classes.blackColor}>Email</FormLabel>
                                        <Controller
                                            as={TextField}
                                            id={'email'}
                                            name="email"
                                            control={control}
                                            required
                                            fullWidth={true}
                                            value={email}
                                            onInput={e => setEmail(e.target.value)}
                                            error={errors.email && true}
                                            helperText={errors.email?.message}
                                            variant="standard"
                                            InputProps={{
                                                className: classes.inputStyle,
                                            }}
                                            inputProps={{ maxLength: 128 }}
                                        // {...props}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.loginPwdInputWrapper}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor="password" className={classes.blackColor}>Password</FormLabel>
                                        <Controller
                                            as={TextField}
                                            control={control}
                                            required
                                            id='password'
                                            name='password'
                                            value={password}
                                            onInput={e => setPassword(e.target.value)}
                                            type={showPassword? 'text' : 'password'}
                                            error={errors.password && true}
                                            helperText={errors.password?.message}
                                            style={{ height: '30px' }}
                                            variant="standard"
                                            InputProps={{
                                                className: classes.inputStyle,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            style={{ color: 'black' }}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                maxLength: 64,
                                                style: { fontFamily: showPassword || 'text-security-disc' }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item className={classes.forgetPwdWrapper}>
                                    <Link
                                        href={'#'}
                                        className={classes.forgetPwd}
                                    >
                                        Forget Password?
                                    </Link>
                                </Grid> */}
                                <Grid item className={classes.LoginInputWrapper}>
                                    <FormControl>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                textTransform: 'none',
                                                fontFamily: 'Quicksand-SemiBold',
                                                fontSize: 16
                                            }}
                                            disabled={loginRemainTime > 0}
                                        >
                                            Login
                                            {loginRemainTime > 0 ? <CircularProgress size={16} /> : null}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </ThemeProvider>
        <LockPromptDialog open={showLockTip} onClose={() => setShowLockTip(false)} content={lockTipContent} />
    </div>
}

export default LoginPC;
