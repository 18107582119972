import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
    addMonths
    , startOfMonth
    , startOfDay
    , isEqual
    , endOfMonth
    , isAfter,
    isBefore
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    IconButton
    , Grid
    // , Dialog
    , DialogTitle
    , DialogContent
    , Popover
    , TextField,
    Typography,
    Chip,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'CustomAxios';
import { useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import useAutoHozScroll from 'hooks/useAutoHozScroll';
import { useDirtyCheck } from 'hooks/DirtyCheckProvider';
import Emun from 'enum/Enum.js';
import renderCellExpand from './RenderCellExpand';
import RenderToolBar from '../Calendar/RenderToolBar';

const { Provider, Consumer } = React.createContext();
export const useStyles = makeStyles(theme => ({
    dataGridRoot: {
        border: '0',
        fontSize: '13px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#646464',
            borderRadius: '3px',
            color: '#fff',
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
        },
        '& .MuiDataGrid-cell': {
            // minHeight: '0 !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-colCell': {
            padding: '0 8px !important',
        },
        '& .MuiDataGrid-window': {
            // top: '40px !important'
        },
        '& .MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#fff'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-dataContainer': {
            // minHeight: '0px !important'
        },

    },
    statusButton: {
        borderRadius: '5px',
        color: '#fff',
        width: '75px',
        '& .MuiChip-label': {
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }

}));

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const getCancelledColor = (params) => {
    switch (params) {
        case "APPROVED": return "#67c451";
        case "SUBMITTED": return "#67c451";
        case "REJECTED": return "#e14631";
        case "CANCELLED": return "#646464";
        default: return "#646464";
    }
}

const getStatusNo = (params) => {
    switch (params) {
        case "APPROVED": return 0;
        case "SUBMITTED": return 1;
        case "REJECTED": return 2;
        case "CANCELLED": return 3;
        default: return 999;
    }
}

const BookingHistoryComponent = props => {
    const classes = useStyles();
    const [bookingHistorys, setBookingHistorys] = React.useState([]);
    const ref = React.useRef()

    const { viewDate, setViewDate } = props;
    const fullBookings = bookingHistorys.map(item => {
        item.fullDate = new Date(item.bookDate);
        item.statusNo = getStatusNo(item.status)
        return item;
    })



    const projectCode = props.selectedProjectValue.selectedProject?.projectCode || '';
    const getDateRangeBaseInfo = React.useCallback(() => {
        const minDate = startOfMonth(viewDate);
        const maxDate = endOfMonth(viewDate);

        const params = {
            startDate: format(minDate, "yyyy-MM-dd"),
            endDate: format(maxDate, "yyyy-MM-dd"),
        }

        axios.get('/bookrequest/get-user-booking-historys', { params: { ...params, projectCode } }).then((bookingsRes) => {
            const refGrid = ref.current;
            refGrid && refGrid.children[1].children[1].scroll(0, 0)
            if (bookingsRes.data) {
                setBookingHistorys(bookingsRes.data.sort((a, b) => new Date(a.bookDate) - new Date(b.bookDate)));
            }
            else {
                setBookingHistorys([]);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [projectCode, viewDate])

    useEffect(() => {
        getDateRangeBaseInfo();
    }, [getDateRangeBaseInfo])
    const columns = [
        {
            field: 'fullDate',
            headerName: 'Date',
            width: 100,
            type: 'date',
            sortable: true,
            // editable: true,
            valueGetter: (params) => {
                // console.log(params)
                return format(startOfDay(new Date(params.value)), 'd MMM yyyy')
            },
            sortComparator: (v1, v2, param1, param2) => {
                return v1 - v2;
            }
        },
        {
            field: 'Day',
            headerName: 'Day',
            // description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 110,
            valueGetter: (params) => {
                return Emun.WEEKS[new Date(params.row.bookDate).getDay()]
            },
            sortComparator: (v1, v2, param1, param2) => {
                return param1.row.fullDate.getDay() - param2.row.fullDate.getDay();
            }
        },
        {
            field: 'capacity',
            headerName: 'No. of Researcher',
            // type: 'number',
            sortable: true,
            width: 160,
            editable: true,
            renderCell: (params) => (
                <div style={{ width: '113px', textAlign: 'center' }}>{params.value}</div>
            ),
        },
        {
            field: 'statusNo',
            headerName: 'Status',
            sortable: true,
            width: 110,
            renderCell: (params) => (
                <Chip label={`${params.row.status.substring(0, 1)}${params.row.status.toLowerCase().substring(1, params.row.status.length)}`}
                    className={classes.statusButton}
                    style={{
                        backgroundColor: getCancelledColor(params.row.status),
                    }} />
            ),
        },
        {
            field: 'attendance',
            headerName: 'Actual Attendance',
            // type: 'number',
            sortable: true,
            width: 200,
            renderCell: (params) => (
                <div style={{ width: '113px', textAlign: 'center' }}>{params.value}</div>
            ),
        },
        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 150,
            sortable: true,
            flex: 1,
            renderCell: renderCellExpand
        },
    ];

    const rows = fullBookings;


    return (
        // <Provider value={{
        //     isEdit,
        //     selectedDateList,
        //     viewDate,
        //     submitResult,
        //     releaseTimes,
        //     isRelease
        // }}>
        <Grid container flexDirection={'column'} style={{ height: '100%' }}>
            <Grid item xs={'auto'}>
                {/* <Grid container alignItems={'center'}>
                    <Grid item>
                        <IconButton onClick={toLastMonth}>
                            <ArrowBackIosIcon style={{ width: '10px', height: '10px' }} />
                        </IconButton>
                    </Grid>
                    <Grid item style={{ minWidth: '125px', textAlign: 'center' }}>
                        {format(startOfDay(viewDate), 'MMMM yyyy')}
                    </Grid>
                    <Grid item>
                        <IconButton onClick={toNextMonth} disabled={!selectEnabled(addMonths(startOfMonth(viewDate), 1))}>
                            <ArrowForwardIosIcon style={{ width: '10px', height: '10px' }} />
                        </IconButton>
                    </Grid>
                </Grid> */}
                <RenderToolBar
                    date={viewDate}
                    label={format(startOfDay(viewDate), 'MMMM yyyy')}
                    setViewDate={setViewDate}
                />
            </Grid>
            <Grid item xs>
                <DataGrid
                    // sortingOrder={['desc', 'asc']}
                    ref={ref}
                    rows={rows}
                    columns={columns}
                    rowsPerPageOptions={[5]}
                    className={classes.dataGridRoot}
                    disableColumnMenu
                    disableSelectionOnClick
                    // sortModel={sortModel}
                    // onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    // autoPageSize
                    headerHeight={40}
                    rowHeight={40}
                    hideFooter
                />
            </Grid>
        </Grid>
        //</Provider>  
    );
}

export default BookingHistoryComponent;