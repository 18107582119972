
import Grid from '@material-ui/core/Grid';
import { useStyles } from "Styles";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ThreeDVirIcon from 'images/3DVir.png';
import AccessQrCode from 'components/accessQrCode';
import NotSignOut from 'components/accessQrCode/NotSignOut';
import { HomepageViewContext } from 'components/Homepage/Homepage.page';
import format from 'date-fns/format';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

export default function HomepagePC(props) {
    const {
        classes, activeStep, openQrCode, setOpenQrCode, maxSteps,
        activeMenus, handleNext, handleBack, isPageClass, midMenuClick, canQrCode, setCanQrCode
    } = React.useContext(HomepageViewContext)

    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Grid container direction='column' style={{ backgroundColor: '#333333' }}>
                <Grid item container justifyContent='center' direction='column' alignItems='center' className={classes.middleMenuBox}>
                    <Grid item container alignItems='center' style={{ marginTop: '80px', minWidth: '1019px', width: 'auto' }}>
                        <Grid item xs='auto'>
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}
                                className={`${classes.middleMenuArrow} ${isPageClass()}`}
                            >
                                <KeyboardArrowLeft fontSize='large' />
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container justifyContent='center'>
                                    {
                                        activeMenus.map(menu => {
                                            return <Grid key={menu.code} item className={classes.middleSigle} alignItems='center'
                                                style={{ margin: '0 10px' }}
                                                onClick={() => midMenuClick(menu.path, menu.code)}>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        display: 'block',
                                                        width: '100px',
                                                        height: '100px',
                                                        margin: '38px auto 0px auto'
                                                    }}
                                                    src={menu.icon}
                                                />
                                                <Typography className={classes.middleMenuText}>
                                                    {menu.title}
                                                </Typography>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                className={`${classes.middleMenuArrow} ${isPageClass()}`}
                            >
                                <KeyboardArrowRight fontSize='large' />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems='center' style={{ width: '90%' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ padding: '0px 10px 10px 10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E' }}>
                                        What's New
                                    </Grid>
                                    <Grid item xs='auto' style={{ textAlign: 'right' }}>

                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap' alignItems={'center'} className={classes.newsList}>
                                    <Grid item container alignItems={'center'} justifyContent={'center'} style={{ width: '91%' }} className={classes.newTextBox}>Welcome to the HADCL Portal! More functions will be coming soon. Stay tuned!</Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center' style={{ width: '90%', marginBottom: '10px' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ paddingBottom: '10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Video Corner
                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <Grid container alignItems='center' style={{ overflow: 'hidden' }}>

                                        <Grid item container xs justifyContent={'space-evenly'}>
                                            <Grid item container alignItems={'center'} justifyContent={'center'} className={classes.newTextBox} style={{ height: 180, width: '90%' }}>Welcome to the HADCL Portal! More functions will be coming soon. Stay tuned!</Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*<Grid item container alignItems='center' style={{ width: '90%', marginBottom: '10px' }}>
                        <Grid item xs container alignItems='flex-start' className={classes.new3DBox}>
                            <Grid item xs container style={{ paddingBottom: '10px' }}>
                                <Grid item container alignItems='center' justifyContent='space-between' className={classes.moreHeader}>
                                    <Grid item xs style={{ color: '#F68A2E', marginLeft: '10px' }}>
                                        Explore HADCL
                                    </Grid>
                                </Grid>
                                <Grid item container direction='column' wrap='nowrap'>
                                    <a href='https://my.matterport.com/show/?m=cWm4Ys2ypKH'
                                        target='_blank'
                                        className={classes.threeDImg}
                                    >
                                        <img src={ThreeDVirIcon} alt={'icon'} style={{ width: '100%' }} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>

                </Grid>*/}
                </Grid>
            </Grid>
            {openQrCode && canQrCode && <AccessQrCode onClose={() => { setOpenQrCode(false) }} />}
            <NotSignOut setCanQrCode={setCanQrCode} />
        </>
    )
}
